import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Thrid Library
import {NgProgressService} from "ng2-progressbar";
import { Location } from '@angular/common';

//Service
import { HttpServices } from '../service/httpservices';
import { AuthService } from '../service/authservice';
import { DataChangedEvent } from '../service/datachangedevent';

//Model
import { Job } from '../job/job';

@Component({
    selector: 'i-searchjob',
    templateUrl: './searchjob.component.html'
})
export class SearchJobCP implements OnInit, OnDestroy {
    //@Input() jid;
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    @Input() searchData;
    @Input() isModalDialog;
    public sub: any;
    public validJobId: boolean;
    public searchjob: any;
    public jt: any;
    public BAmo: number;
    public srobj: any;
    public toastkey = "i-searchjob";
    current_route : any;

    constructor(private pService: NgProgressService ,public activeRoute: ActivatedRoute, public httpservice: HttpServices, public router: Router, public auth: AuthService, public loca: Location, public _datachange: DataChangedEvent) {
        this.pService.start();
        this.searchjob = '';
        this.BAmo = 0;
        this.validJobId = false;

    }

    ngOnInit(): void {
        // if (this.searchData && this.searchData.searchdata !== '' && this.searchData.searchtype !== '') { this.getSearchJob(); }

        // this.srobj = this._datachange.searchjobChanged.subscribe(
        //     emitobj => {
        //         // this.jid = emitobj;
        //         this.searchData = emitobj;
        //         this.getSearchJob();
        //     },
        //     err => { },
        //     () => { }
        // );
 
        this.current_route = this.activeRoute.params.subscribe(param => {
            if (param !== undefined && param.searchdata !== undefined && param.searchtype !== undefined) {
                this.searchData = param;
                setTimeout(() => { // for authorized error in browser refresh(f5)
                    this.getSearchJob();
                });
                
            } else {
                this.getSearchJob();
               // this.pService.done();
            }
        });
    }

    ngOnDestroy(): void {
        if (this.sub != undefined) {
            this.sub.unsubscribe();
        }
        if (this.srobj != undefined) {
            this.srobj.unsubscribe();
        }
    }

    getSearchJob(): void {
        if (this.searchData) {
            this.httpservice.postData('api/getsearchjob', this.searchData).subscribe(
                data => { this.searchjob = data[0] },
                err => {
                    this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                 },
                () => {
                    if (!this.searchjob) {
                        this.validJobId = false;
                        this.httpservice.showToast(this.toastkey, 'info', 'There is no job by this job Id!');
                    }
                    else {
                        this.validJobId = true;
                        this.jt = this.searchjob.JT;

                        if (this.searchjob.SAmo != undefined && this.searchjob.PAmo != undefined && this.searchjob.DAmo != undefined) {
                            this.BAmo = this.searchjob.TAmo - this.searchjob.DAmo;
                        }
                        else {
                            this.BAmo = 0;
                        }
                    }
                    this.pService.done();
                }
            );
        }
    }

    saveRSM() {
        let tmp = new Job();
        tmp.JID = this.searchjob.JID;
        tmp.RSM = this.searchjob.RSM;

        this.pService.start();
        this.httpservice.postData('api/saveRSM', tmp).subscribe(
            data => { },
            err => {
                this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while updating !');
                this.pService.done();
            },
            () => {
                this.httpservice.showToast(this.toastkey, 'success', 'Sucess Update !');
                this.pService.done();
            }
        );
    }

    goBack() {
        this.loca.back();
    }
}