import { Component, OnInit, ViewChild } from '@angular/core';

// Thrid Library
import {NgProgressService} from "ng2-progressbar";
import { SelectItem } from 'primeng/api';

//Service
import { HttpServices } from '../service/httpservices';


//Model
import { JobListQuery } from '../joblist/joblistquery';
import { JobViewModel } from '../joblist/jobviewmodel';
import { MCB } from '../job/mcb';

@Component({
    selector: 'i-techjoblist',
    templateUrl: './techjoblist.component.html'
})

export class TechJobListCP implements OnInit {
    public types: SelectItem[];
    public _listOfMon: MCB[] = [];
    public jobs: JobViewModel[] = [];
    public joblistquery: JobListQuery;
    public periods: SelectItem[];
    public printMode: boolean = false;
    public toastkey = "i-techjoblist";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public httpService: HttpServices) {
        this.pService.start();
        this.joblistquery = new JobListQuery();

        this.bindtechStatus();
        this.bindPeriod('d');

        this.joblistquery.FromDate = new Date();
        this.joblistquery.ToDate = new Date();
        this.joblistquery.Type = 'd';
        this.joblistquery.Period = 'a';
    }

    ngOnInit() {
        this.Get_MCB();
    }

    bindtechStatus() {
        this.types = [];
        this.types.push({ value: 'd', label: 'Done' });
        this.types.push({ value: 'c', label: 'Waiting Warranty' });
        this.types.push({ value: 'b', label: 'Waiting Customer Reply' });
        this.types.push({ value: 'a', label: 'Work In Progress' });
    }

    bindPeriod(_type) {
        this.periods = [];
        if (_type != 'd') { this.periods.push({ value: 'g', label: 'All' }); }
        this.periods.push({ value: 'a', label: 'Today' });
        this.periods.push({ value: 'b', label: 'This week' });
        this.periods.push({ value: 'c', label: 'This Month' });
        this.periods.push({ value: 'd', label: 'Within 2 Months' });
        this.periods.push({ value: 'e', label: 'Within 3 Months' });
        this.periods.push({ value: 'f', label: 'Custom Date' });
    }

    getTechJobList(): void {
        this.httpService.postData('api/techjoblist', this.joblistquery).subscribe(
            data => this.jobs = data,
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!');
                this.pService.done();
            },
            () => {
                if(this.jobs){
                    this.jobs.map(j => {
                        j.MoN = this.getmcbName(j.MoID);
                    });
                }
                this.pService.done();
            });
    }

    getmcbName(moid: number): string {
        if (this._listOfMon == undefined) return "";
        for (let mon of this._listOfMon) {
            if (mon.MoID == moid) {
                return mon.MoN + " / " + mon.CatN + " / " + mon.BN;
            }
        }
    }
    Get_MCB(): void {
        this.httpService.getData('api/job/getmcb').subscribe(
            data => this._listOfMon = data,
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!');
                this.pService.done();
            },
            () => {
                this.getTechJobList();
            });
    }

    printjob(): void {
        window.print();
    }
    Refresh(): void {
        this.pService.start();
        this.getTechJobList();
    }

    typeChanged(): void {
        if (this.joblistquery.Type != null) {
            this.bindPeriod(this.joblistquery.Type);
        }
        this.pService.start();
        this.getTechJobList();
    }

    periodChanged(): void {
        if (this.joblistquery.Period == 'f') {
            this.joblistquery.FromDate = new Date();
            this.joblistquery.ToDate = new Date();
        }
        this.pService.start();
        this.getTechJobList();
    }
    onSelectDate(): void {
        this.pService.start();
        this.getTechJobList();
    }
}