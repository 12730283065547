import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { AppRoutes } from './app.routes';
import 'rxjs/add/operator/toPromise';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { GMapModule } from 'primeng/gmap';
import { ToastModule } from 'primeng/toast';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';


import { AppComponent } from './app.component';
import { AppMenuComponent, AppSubMenu } from './app.menu.component';
import { AppTopBar } from './app.topbar.component';
import { AppFooter } from './app.footer.component';
import { InlineProfileComponent } from './app.profile.component';
import { ExternalLoginComponent } from './external-login.cp';

//third party libraries
//import { ImageUploadModule } from 'ng2-imageupload';
import { BsModalModule } from 'ng2-bs3-modal';
import { NgProgressModule } from 'ng2-progressbar';

//my references
import { HttpServices } from './service/httpservices';
import { AuthService } from './service/authservice';
import { SignalRService } from './service/signalrservice';
import { DataChangedEvent } from './service/datachangedevent';

//my component
import { AccessoryCP } from './accessory/accessory.component';
import {AccessoryDialogCP} from './accessory/accessorydialog.component';
import {DashboardCP} from './dashboard/dashboard.component';
import {BrandCP} from './brand/brand.component';
import { LogCP } from './log/log.component';
import { LoginApplication } from './login/login';
import { HomeCP } from './home/home';
import {BlankCP} from './blank/blank.component';
import {CategoryCP} from './category/category.component';
import { CustomerCP } from './customer/customer.component';
import { CustomerDialogCP } from './customer/customerdialog.component';
import {DepartmentCP} from './department/department.component';
import {ServiceCenterCP} from './servicecenter/servicecenter.component';
import {ServiceTypeCP} from './servicetype/servicetype.component';
import {StockItemCP} from './stockitem/stockitem.component';
import {ModelCP} from './model/model.component';
import {ModelDialogCP} from './model/modeldialog.component';
import {LoginUserCP} from './loginuser/loginuser.component';
import {JobCP} from './job/job.component';
import {JobPrintViewCP} from './job/jobprintview.component';
import {JobListCP} from './joblist/joblist.component';
import {TechJobListCP} from './joblist/techjoblist.component';
import {TechJobListCP2} from './joblist/techjoblist2.component';
import {JobPartCP} from './job/jobpart.component';
import {JobPhotoCP} from './job/jobphoto.component';
import {JobPhotoViewCP} from './job/jobphotoview.component';
import {JobDoneCP} from './dashboardsup/jobdone.component';
import {DashBoardTechCP} from './dashboardtech/dashboardtech.component';
import {DashboardViewCP} from './dashboardtech/dashboardview.component';
import {DashBoardSupCP} from './dashboardsup/dashboardsupervisor.component';
import {DashboardSupViewCP} from './dashboardsup/dashboardsupervisorview.component';
import {WarrantyCP} from './warranty/warranty.component';
import {CreateStockTransferCP} from './stocktransfer/createstocktransfer.component';
import {StockTransferDetailCP} from './stocktransfer/stocktransferdetail.component';
import {TransferListCP} from './stocktransfer/transferlist.component';
import {StockAdjustmentListCP} from './stockadjustment/stockadjustmentlist.component';
import {TakenListCP} from './takenlist/takenlist.component';
import {TechReviewCp} from './techreview/techreview.component';
import {StockInListCP} from './stockin/stockinlist.component';
import {StockInCP} from './stockin/stockin.component';
import {StockInDetailCP} from './stockin/stockindetail.component';
import {StockLedgerCP} from './stockledger/stockledger.component';
import {StockBalance} from './stockbalance/stockbalance.component';
import {CustomerTakenCP} from './customertaken/customertaken.component';
import {UNAndResetCP} from './prepareresetpwd/prepareresetpwd.component';
import {ResetPwdCP} from './saveresetpwd/saveresetpwd.component';
import {DeviceIDCP} from './deviceid/deviceid.component';

import {SearchJobCP} from './searchjob/searchjob.component';
import {BlankWithParamCP} from './blank/blankwithparam.component'; 
import {QRCodeComponentOwn} from './qrcodeown/qrcodeown.component';
import {JobListNewCP} from './joblistnew/joblistnew.component';
import { MainDashboardCP } from './main-dashboard/main-dashboard.component';
import { TotalDashboardCP } from './total-dashboard/total-dashboard.component';
import {AppNotiColorDirective} from './appDirectives/appointmentNotiColor.directive';
import {JobDonePrintViewCP} from './dashboardsup/jobdoneprintview.component';
import {MessageTemplateCP} from './messagetemplates/messagetemplate.component';
import { Over7CP } from './over7DayJobs/over7.component';

// //Service
import { JustLogin } from './service/auth_guard/justlogin';
import { Admin_Role } from './service/auth_guard/admin_role';
import { Service_Manager_Role } from './service/auth_guard/service_manager_role';
import { Assistance_Manager_Role } from './service/auth_guard/assistance_manager_role';
import { Operator_Role } from './service/auth_guard/operator_role';
import { Service_Tech_Role } from './service/auth_guard/service_tech_role';
import { Warranty_Role } from './service/auth_guard/warranty_role';
import { Else_Warranty_Role } from './service/auth_guard/else_warranty_role';

//test
import { PrintCusTakenVoucherComp } from './customertaken/printcomp';

import {AdminJobListCP} from './adminjoblist/adminjoblist.component';

import { MessageService } from 'primeng/api';

import { from } from 'rxjs';
import { TechDashboardCP } from './tech-dashboard/tech_dashboard.component';
import { DescriptionCp } from './Description/description.component';
import { ConfirmPasswordEntryCP } from './changedPassword/confirmPassword-entry.component';
import { ChangedPasswordEntryCP } from './changedPassword/changedPassword-entry.component';
import { PurchaseEntryCP } from './stockin/purchase-entry.component';

@NgModule({
    imports: [
        NgProgressModule,
        BrowserModule,
        FormsModule, ReactiveFormsModule,
        AppRoutes,
        HttpModule,HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        //SharedModule,
        ContextMenuModule,
        // DataGridModule,
        // DataListModule,
        DataViewModule,
        // DataScrollerModule,
        // DataTableModule,
        TableModule,
        DialogModule,
        DragDropModule,
        DropdownModule,
        EditorModule,
        FieldsetModule,
        FileUploadModule,
        GalleriaModule,
        GMapModule,
        //GrowlModule,
        ToastModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        //ScheduleModule,
        SelectButtonModule,
        SlideMenuModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        StepsModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        BsModalModule,
        //ImageUploadModule,
        
    ],
    declarations: [
        AppComponent,
        AppMenuComponent,
        AppSubMenu,
        AppTopBar,
        AppFooter,
        InlineProfileComponent, ExternalLoginComponent,
        AccessoryCP, AccessoryDialogCP,  

        HomeCP,
        LoginApplication,
        CustomerCP,
        CustomerDialogCP,
        LogCP,
        DashboardCP,
        JobListNewCP,
        AppNotiColorDirective,
        JobDoneCP,
        SearchJobCP,
        JobCP,
        CustomerTakenCP,
        QRCodeComponentOwn,
        JobPartCP,
        JobDonePrintViewCP,
        JobPhotoCP,
        ModelDialogCP,
        CategoryCP,
        BrandCP,
        DepartmentCP,
        ServiceCenterCP,
        ServiceTypeCP,
        StockItemCP,
        ModelCP,
        LoginUserCP,
        JobPrintViewCP,
        JobListCP,
        TechJobListCP,
        TechJobListCP2,
        DashBoardTechCP,
        DashboardViewCP,
        JobPhotoViewCP,
        DashBoardSupCP,
        DashboardSupViewCP,
        WarrantyCP,
        CreateStockTransferCP,
        StockTransferDetailCP,
        TransferListCP,
        StockInListCP,
        StockInCP,
        StockInDetailCP,
        StockLedgerCP,
        StockAdjustmentListCP,
        TakenListCP,
        TechReviewCp,
        BlankCP,
        BlankWithParamCP,
        StockBalance,
        UNAndResetCP,
        ResetPwdCP,
        DeviceIDCP,
        MessageTemplateCP,
        Over7CP,
        PrintCusTakenVoucherComp,
        AdminJobListCP,
        // BrandCP, LogCP, CategoryCP, CustomerCP, CustomerDialogCP, DepartmentCP,
        // ServiceCenterCP, ServiceTypeCP, StockItemCP,ModelCP, ModelDialogCP, LoginApplication, HomeCP, LoginUserCP, JobListCP,JobCP, JobPrintViewCP, JobPartCP, JobPhotoCP, JobDoneCP,
        // DashboardCP, DashBoardTechCP, DashboardViewCP, DashBoardSupCP, DashboardSupViewCP, TechJobListCP, StockInCP, StockInListCP, StockInDetailCP, StockLedgerCP, WarrantyCP,
        // CreateStockTransferCP, StockTransferDetailCP, TransferListCP, StockAdjustmentListCP, JobPhotoViewCP, TakenListCP, TechReviewCp, BlankCP, StockBalance, CustomerTakenCP, UNAndResetCP, ResetPwdCP, DeviceIDCP, TechJobListCP2, SearchJobCP, BlankWithParamCP,
        // QRCodeComponentOwn, JobListNewCP, AppNotiColorDirective, JobDonePrintViewCP, MessageTemplateCP, Over7CP, PrintCusTakenVoucherComp, AdminJobListCP,
        MainDashboardCP,TotalDashboardCP , TechDashboardCP , DescriptionCp, ConfirmPasswordEntryCP, ChangedPasswordEntryCP,
        PurchaseEntryCP
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        HttpServices, AuthService, JustLogin, Admin_Role, Service_Manager_Role, Assistance_Manager_Role, Operator_Role, Service_Tech_Role, Warranty_Role, SignalRService, DataChangedEvent, Else_Warranty_Role, MessageService, DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {

    }
}