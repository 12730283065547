import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';//Form, ReactiveFormsModule,

//third libraries
import { BsModalComponent } from 'ng2-bs3-modal';
import {MessageService} from 'primeng/api';

//service
import { GlobalFunc } from './service/globalfunction';
import { HttpServices } from './service/httpservices';
import { AuthService } from '././service/authservice';

//model
import { LoginUser } from './loginuser/loginuser';
import { AppComponent } from './app.component';

@Component({
    selector: 'inline-profile',
    templateUrl: './app.profile.component.html',
    animations: [
        trigger('menu', [
            state('hidden', style({
                height: '0px'
            })),
            state('visible', style({
                height: '*'
            })),
            transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class InlineProfileComponent implements OnInit {
    public active: boolean;

    public profileEditForm: FormGroup;

    @ViewChild('profileEditModal', { static: false }) profileEditModal: BsModalComponent;

    constructor(public app: AppComponent, public auth: AuthService, public httpservice: HttpServices, public fb: FormBuilder, public messageService: MessageService) { }

    ngOnInit() {
        this.profileEditForm = this.fb.group({
            'UT': new FormControl(''),
            'Name': new FormControl(''),
            'Pwd': new FormControl('', Validators.required),
            'RePwd': new FormControl('', Validators.required)
        });
    }

    onClick(event) {
        this.active = !this.active;
        event.preventDefault();
    }

    showProfileEditModal() {
        this.profileEditForm.patchValue({ UT: this.auth.role, Name: this.auth.loginName, Pwd: '', RePwd: '' });
        //this.profileEditForm.patchValue({ UT: '', Name: '', Pwd: '', RePwd: '' });
        this.profileEditForm.controls['UT'].disable();//make disable inputText in HTML
        this.profileEditForm.controls['Pwd'].markAsPristine();
        this.profileEditForm.controls['RePwd'].markAsPristine();
        this.profileEditModal.open();
    }

    saveAuthInfo(profileEditData: LoginUser) {
        let objt = ({ 'Name': profileEditData.Name, 'Pwd': GlobalFunc.HashString(profileEditData.Pwd), 'UN': this.auth.UN });

        this.httpservice.postData("api/editlogin", objt).subscribe(
            data => { },
            // err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Error occurs while inserting/updating data!' }),
            err => this.messageService.add({severity:'error', summary:'Error Message', detail:'Error occurs while inserting/updating data!'}),
            () => {
                // this.msgs.push({ severity: 'info', summary: 'Info Message', detail: 'Success inserting/updating data!' });
                //this.messageService.add({ severity: 'info', summary: 'Info Message', detail: 'Success inserting/updating data!' });
                this.httpservice.showToast('i-editProfile', 'success', 'Successfully inserted/updated');  
                this.profileEditModal.close();
                this.httpservice.logout();
            }
        );
    }
    
}