import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

// Thrid Library
import {NgProgressService} from "ng2-progressbar";
import { SelectItem } from 'primeng/api';

//Service
import { HttpServices } from '../service/httpservices';

//Model
import { MessageTemplate } from './messagetemplate';

@Component({
    selector: 'i-message-template',
    templateUrl: './messagetemplate.component.html'
})
export class MessageTemplateCP implements OnInit {
    @ViewChild('templatetxt', { static: false }) templatetxt;
    messageTypeDDown: SelectItem[] = [];
    messagetemplate: MessageTemplate;
    messagetemplates: MessageTemplate[] = [];
    public toastkey = "i-message-template";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public locat: Location, public httpservice: HttpServices) {
        this.messagetemplate = new MessageTemplate('', '');
    }
    ngOnInit() {
        this.setMsgTypeDDown();
        this.getMsgTemplates();
    }
    setMsgTypeDDown() {
        this.messageTypeDDown = [{ label: '- Select Message Type -', value: null }, { label: 'SMS', value: 'SMS' }, { label: 'Email', value: 'Email' }];
    }
    getMsgTemplates() {
        this.messagetemplates = [];
        this.pService.start();
        this.httpservice.getData('api/getMessageTemplates').subscribe(
            expdata => { this.messagetemplates = expdata; },
            err => { },
            () => { 
                this.pService.done(); 
            }
        );
    }
    msgTypeChange(event) {
        if (event.value != null) {
            let idx = this.messagetemplates.findIndex(x => x.mtType === event.value);
            if (idx >= 0) this.messagetemplate.mTemplate = this.messagetemplates[idx].mTemplate;
            this.templatetxt.nativeElement.focus();
        }
        else this.messagetemplate.mTemplate = '';
    }
    saveData() {
        this.pService.start();
        this.httpservice.postData('api/saveMessageTemplates', this.messagetemplate).subscribe(
            expdata => { },
            err => {
                this.httpservice.showToast(this.toastkey, 'error', 'Error occurs !' + '\n' + err);
                this.pService.done();
            },
            () => {
                this.getMsgTemplates();
                this.httpservice.showToast(this.toastkey, 'success', 'Successfully done !');
                this.pService.done();
            }
        );
    }
}