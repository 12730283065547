import { Component, OnInit, OnChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import { Observable } from 'rxjs';
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';
import { GlobalFunc } from '../service/globalfunction';

//Model
import { UNAndReset } from '../prepareresetpwd/prepareresetpwd';

@Component({
    selector: 'i-resetpwd',
    templateUrl: './saveresetpwd.component.html'
})
export class ResetPwdCP implements OnInit, OnChanges {
    @ViewChild('successSaveModal', { static: false }) successSaveModal: BsModalComponent;
    @ViewChild('newpwd', { static: false }) newpwd;
    @ViewChild('cfmpwd', { static: false }) cfmpwd;

    public resetPwdForm: FormGroup;
    public sub: any;
    public UNResetObj: UNAndReset;
    public isRKeyValid: boolean;
    public toastkey = "i-resetpwd";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public fb: FormBuilder, public router: Router, public route: ActivatedRoute, public httpservice: HttpServices) {
        this.UNResetObj = new UNAndReset();
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => { this.UNResetObj.UN = params['UN']; this.UNResetObj.recoveryKey = params['rKey'] });

        let rkey: any;
        this.pService.start();
        this.httpservice.getData('api/rk/' + this.UNResetObj.UN).subscribe(
            data => { rkey = data },
            err => {
                this.httpservice.showToast(this.toastkey, 'error', 'Error occurs !!');
               this.pService.done();
            },
            () => {
                if (rkey != this.UNResetObj.recoveryKey) {
                    this.isRKeyValid = false;
                }
                else {
                    this.isRKeyValid = true;
                    this.createForm();
                    let timerSub = Observable.timer(10, 100000).subscribe(t => { this.newpwd.nativeElement.focus(); }, err => { }, () => { timerSub.unsubscribe(); });
                }
                this.pService.done();
            }
        );
    }

    ngOnChanges(event) { }

    ngAfterViewInit() { }

    createForm(): void {
        this.resetPwdForm = this.fb.group({
            'NewPwd': new FormControl('', Validators.required),
            'ConfPwd': new FormControl('', Validators.required)
        });
    }

    saveResetPwd(): void {
        this.UNResetObj.Pwd = GlobalFunc.HashString(this.resetPwdForm.controls['NewPwd'].value);
        this.pService.start();
        this.httpservice.postData('api/saveResetPwd', this.UNResetObj).subscribe(
            data => { },
            err => {
                this.httpservice.showToast(this.toastkey, 'error', 'Error occurs !!');
                this.pService.done();
            },
            () => {
                this.successSaveModal.open(); 
                this.pService.done();
            }
        );
    }

    cancelFun(): void {
        this.router.navigate(['/default']);
    }

    OK(): void {
        this.router.navigate(['/default']);
    }

    setFocusNext(event) {
        if (event.keyCode === 13) {
            this.cfmpwd.nativeElement.focus();
        }
    }

    gotoSaveResetPwd(event) {
        if (event.keyCode === 13) {
            this.saveResetPwd();
        }
    }
}