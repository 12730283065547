import { Component, OnInit, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import { SelectItem } from 'primeng/api';
import {NgProgressService} from "ng2-progressbar";

//Service
import { DataChangedEvent } from '../service/datachangedevent';
import { HttpServices } from '../service/httpservices';

//Model
import { Category } from '../category/category';
import { Model } from './model';
import { Brand } from '../brand/brand';

@Component({
    selector: 'i-model',
    templateUrl: './model.component.html'
})
export class ModelCP implements OnInit, OnDestroy {
    @ViewChild('entryModal', { static: false }) entryModal: BsModalComponent;
    @ViewChild('actionModal', { static: false }) actionModal: BsModalComponent;
    @ViewChild('deleteModal', { static: false }) deleteModal: BsModalComponent;
    @ViewChild('otherModal', { static: false }) otherModal: BsModalComponent;
    @ViewChild('input', { static: false }) vc;

    apiName: string = "model";
    isNew: boolean;
    categories: Category[] = [];
    catlist: SelectItem[] = [];

    models: Model[] = [];
    selectedChk: Model[] = [];

    brands: Brand[] = [];
    brandlist: SelectItem[] = [];

    actionlogs: any[] = [];

    model: Model = new Model();
    selectedData: Model;
    selectedBId: any;

    totalRecords: number;
    toastkey = "i-model";
    isShowCatModal: boolean = false;

    modelChanged: any;
    catChanged: any;
    brandChanged: any;
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public httpService: HttpServices, public fb: FormBuilder, public _dataChanged: DataChangedEvent, public _ngZone: NgZone) {
        this.pService.start();

        this.modelChanged = this._dataChanged.modelChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === this.apiName.toLowerCase()) {
                        this.afterBroadCastEvent(sRobj, this.models, "MoID");
                    }
                });
            }
        });

        this.catChanged = this._dataChanged.categoryChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "category") {
                        this.afterBroadCastEvent(sRobj, this.categories, 'CatID');
                    }
                });
            }
        });

        this.brandChanged = this._dataChanged.brandChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "brand") {
                        this.afterBroadCastEvent(sRobj, this.brands, 'BID');
                    }
                });
            }
        });
    }

    ngOnInit() {
        this.GetRequiredData();
    }

    ngOnDestroy() {
        this.categories = this.catlist = this.models = this.selectedChk = this.brands = this.brandlist = this.actionlogs = this.model = this.selectedData = undefined;

        if (this.modelChanged != undefined) this.modelChanged.unsubscribe();
        if (this.catChanged != undefined) this.catChanged.unsubscribe();
        if (this.brandChanged != undefined) this.brandChanged.unsubscribe();

    }

    GetRequiredData(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let bList: any = [];
            let cList: any = [];
            let mList: any = [];
            this.httpService.getDataFJ(['api/category', 'api/brand', 'api/model']).subscribe(
                data => {
                    cList = data[0];
                    bList = data[1];
                    mList = data[2];
                },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                    resolve(false);
                },
                () => {
                    if (cList) {
                        this.categories = cList.map(a => Object.assign({}, a));
                        this.setCategoryData(this.categories);
                    }

                    if (bList) {
                        this.brands = bList.map(b => Object.assign({}, b));
                        this.setBrandData(this.brands);
                    }

                    if (mList) {
                        this.models = mList.map(a => Object.assign({}, a));
                        this.models.sort((a, b) => a.MoN > b.MoN ? 1 : (b.MoN > a.MoN ? -1 : 0));
                        this.totalRecords = this.models.length;
                    }

                    this.pService.done();
                    resolve(true);
                }
            );
        });
    }

    getModelbyConditionChange(bid) {
        if (bid === null) {
            this.pService.start();
            this.httpService.getData('api/model').subscribe(
                data => { this.models = data || []; },
                err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                 this.pService.done(); 
                },
                () => {
                    this.models.sort((a, b) => a.MoN > b.MoN ? 1 : (b.MoN > a.MoN ? -1 : 0));
                    this.totalRecords = this.models.length;
                    this.pService.done();
                }
            );
        }
        else {
            this.getModelsByBId(bid);
        }
    }

    getModelsByBId(bid): void {
        let mList = [];
        this.pService.start();
        this.httpService.getData('api/model/getbybid/' + bid).subscribe(
            expdata => {
                mList = expdata;
            },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data!');
                this.pService.done();
            },
            () => {
                if (mList) {
                    this.models = mList.map(a => Object.assign({}, a));
                    this.models.sort((a, b) => a.MoN > b.MoN ? 1 : (b.MoN > a.MoN ? -1 : 0));
                    this.totalRecords = this.models.length;
                }
                this.pService.done();
            }
        );
    }

    afterBroadCastEvent(objData, dataList, id) {
        if (objData && objData.Obj && dataList) {
            let ind = dataList.findIndex(temp => temp[id] === objData.Obj[id]);
            if (objData.Msg === 'deleted') {
                if (objData.ObjType.toLowerCase() === "category") {

                    this.httpService.getData('api/category').subscribe(
                        data => { this.categories = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'); 
                        this.pService.done(); 
                    },
                        () => { this.setCategoryData(this.categories);
                            this.pService.done(); 
                            }
                    );

                } else if (objData.ObjType.toLowerCase() === "brand") {

                    this.httpService.getData('api/brand').subscribe(
                        data => { this.brands = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'); 
                        this.pService.done();
                     },
                        () => { 
                            this.setBrandData(this.brands); 
                           this.pService.done(); 
                        }
                    );

                } else if (objData.ObjType.toLowerCase() === this.apiName.toLowerCase()) {
                    this.getModelbyConditionChange(this.selectedBId);
                }
            }
            else {
                if (objData.ObjType.toLowerCase() === this.apiName.toLowerCase()) {
                    if (this.selectedBId && this.selectedBId !== objData.Obj.BID) { //need to update data by selected brandid
                        return;
                    }
                }

                if (ind >= 0) dataList[ind] = objData.Obj;
                else dataList.unshift(objData.Obj);

                if (objData.ObjType.toLowerCase() === "category") {
                    this.setCategoryData(dataList);
                } else if (objData.ObjType.toLowerCase() === "brand") {
                    this.setBrandData(dataList);
                }
            }
        }
    }

    refresh() {
        this.pService.start();
        this.GetRequiredData();
    }

    setCategoryData(dataList: Category[]) {
        this.catlist = [];
        this.catlist.push({ label: 'Select category', value: null });
        if (dataList) {
            dataList.map(x => { this.catlist.push({ label: x.CatN + " (" + x.BN + ")", value: x.CatID }); });
        }
    }

    setBrandData(dataList: Brand[]) {
        this.brandlist = [];
        this.brandlist.push({ label: '- All -', value: null });
        if (dataList) {
            dataList.map(x => { this.brandlist.push({ label: x.BN, value: x.BID }); });
        }
    }

    showDialogToAdd(): void {
        this.isNew = true;
        this.model = new Model();
        this.entryModal.open();
        this.vc.nativeElement.focus();
    }

    showDialogToEdit(model: Model): void {
        this.isNew = false;
        this.model = model;
        this.selectedData = model;

        this.entryModal.open();
        this.vc.nativeElement.focus();
    }

    showDialogToDelete(): void {
        if (this.selectedChk != null && this.selectedChk != undefined && this.selectedChk.length > 0) {
            this.deleteModal.open();
        } else {
            this.httpService.showToast(this.toastkey, 'warn', 'Need to select row!');
        }
    }

    showLogDialog(): void {
        if (this.selectedData != undefined) {
            let temp = [];
            this.pService.start();
            this.httpService.getData('api/actionlog/' + this.selectedData.MoID + '/' + 'Model').subscribe(
                data => temp = data,
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading log data from server!');
                    this.pService.done();
                },
                () => {
                    if (temp != undefined) {
                        this.actionlogs = temp;
                        this.actionModal.open();
                    }
                    else { this.httpService.showToast(this.toastkey, 'warn', 'There is no record to show!'); }
                    this.pService.done();
                });
        }
    }

    save(pdt, gb): void {
        //if (this.modelForm != undefined) {
        if (!this.model) return;
        this.pService.start();
        if (this.categories) {
            let mCategory = this.categories.find(c => c.CatID === this.model.CatID);
            this.model.CatN = mCategory?.CatN;
            this.model.BID = mCategory?.BID;
            this.model.BN = mCategory?.BN;
        }


        this.httpService.postData('api/model', this.model).subscribe(
            data => { },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while inserting/updating model!');
                this.pService.done();
            },
            () => {
                this.resetDatable(pdt, gb);

                //this.GetData();
                this.httpService.showToast(this.toastkey, 'success', 'Successfully inserted/updated');
                this.entryModal.close();
                this.pService.done();
            }
        );
        //}
    }

    deleteData() {
        this.httpService.postData('api/model/multidelete/', this.selectedChk).
            subscribe(
                data => {
                    this.httpService.showUnsuccessDelMsg(this.toastkey, data);
                },
                err => {
                    this.doneDeleteProcess('error', 'Error occurs while deleting data!');
                },
                () => {
                    this.doneDeleteProcess('success', 'Successfully deleted');
                });
    }

    deleteModalClose() {
        this.deleteModal.close();
        this.httpService.AddClassToModal(true);
    }


    doneDeleteProcess(header: string, msg: string) {
        this.selectedChk = null;
        this.deleteModalClose();
        this.httpService.showToast(this.toastkey, header, msg);
    }

    resetDatable(pdt, gb): void {
        if (gb && gb.value && gb.value != '') {
            gb.value = '';
            if (pdt) pdt.reset();
        }
    }

    BrandsChanged(event): void {
        this.selectedBId = event.value;
        this.getModelbyConditionChange(this.selectedBId);
    }

    actionModalClose() {
        this.actionModal.close();
        this.httpService.AddClassToModal(true);
    }

    otherModalOpen() {
        this.isShowCatModal = true;
        this.otherModal.open();
    }

    otherModalClose() {
        this.isShowCatModal = false;
        this.otherModal.close();
        this.httpService.AddClassToModal(true);
    }

    exportToCSV() {
        this.httpService.postData('api/model/export', null).subscribe(
            data => { },
            err => { },
            () => {
                //window.location.href = this.httpService._url + 'excel/model.csv';
                this.httpService.DirectLocToWindow("model");
            }
        );
    }
}