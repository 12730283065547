import { Component, ViewChild, OnInit, OnChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';

//Model
import { UNAndReset } from '../prepareresetpwd/prepareresetpwd';

@Component({
    selector: 'i-unandreset',
    templateUrl: './prepareresetpwd.component.html'
})
export class UNAndResetCP implements OnInit, OnChanges {
    @ViewChild('successConfirmEmailModal', { static: false }) successConfirmEmailModal: BsModalComponent;
    @ViewChild('waitLoadingModal', { static: false }) waitLoadingModal: BsModalComponent;
    @ViewChild('input', { static: false }) vc;

    public un: any;
    public formUN: FormGroup;
    public unandreset: UNAndReset;
    public uns: string[] = [];
    public toastkey = "i-unandreset";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public fb: FormBuilder, public httpservice: HttpServices, public router: Router) {
        this.unandreset = new UNAndReset();
    }

    ngOnInit() {
        this.GetUN();
        this.creatForm();
        if (this.vc != undefined) this.vc.nativeElement.focus();
    }

    ngOnChanges(event) {
    }

    creatForm(): void {
        this.formUN = this.fb.group({
            'UN': new FormControl(null, Validators.required)
        });
    }

    ResetPwd(): void {
        let tmpUN = this.uns.filter(x => x == this.formUN.controls['UN'].value);
        if (tmpUN.length <= 0) {
            this.httpservice.showToast(this.toastkey, 'error', 'Sorry, login user_name is invalid !');
            return;
        }

        this.waitLoadingModal.open();

        this.unandreset.UN = this.formUN.controls['UN'].value;//'tpa'

        let tmpunandreset: UNAndReset;

        this.pService.start();

        this.httpservice.postData('api/prepareresetpwd', this.unandreset).subscribe(
            data => { tmpunandreset = data },
            err => { this.pService.done(); 
                this.waitLoadingModal.close(); this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while updating recoveryKey !') },
            () => {
                this.unandreset.recoveryKey = tmpunandreset.recoveryKey;

                if (tmpunandreset.successEmailSend) {
                    this.waitLoadingModal.close();
                    this.successConfirmEmailModal.open();
                }
                else {
                    this.httpservice.showToast(this.toastkey, 'error', 'Fail to send email');
                }
                this.pService.done();
            }
        );
    }

    GetUN(): void {
        this.pService.start();
        this.httpservice.getData('api/getUN').subscribe(
            data => { this.uns = data; },
            err => {
                this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while getting login user names !');
                this.pService.done();
            },
            () => { 
                this.pService.done(); 
            }
        );
    }

    closeFun(): void {
        this.successConfirmEmailModal.close();
        this.router.navigate(['/default']);
        //this.router.navigate(['/saveresetpwd', 'admin', '214590FA09CD1563FB7FC3E672EE8A3A77BA0626A0DCC6F2B6C3030E1DAAB45A']);

    }
} 