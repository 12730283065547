import {Routes,RouterModule} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';

import { AccessoryCP } from './accessory/accessory.component';
import { DashboardCP } from './dashboard/dashboard.component';
import { BrandCP } from './brand/brand.component';
import { CategoryCP } from './category/category.component';
import { CustomerCP } from './customer/customer.component';
import { DepartmentCP } from './department/department.component';
import { ServiceCenterCP } from './servicecenter/servicecenter.component';
import { ServiceTypeCP } from './servicetype/servicetype.component';
import { StockItemCP } from './stockitem/stockitem.component';
import { LoginApplication } from './login/login';
import { HomeCP } from './home/home';
import { ModelCP } from './model/model.component';
import { LoginUserCP } from './loginuser/loginuser.component';
import { JobCP } from './job/job.component';
import { JobPrintViewCP } from './job/jobprintview.component';
import { JobListCP } from './joblist/joblist.component';
import { TechJobListCP } from './joblist/techjoblist.component';
import { TechJobListCP2 } from './joblist/techjoblist2.component';
import { JobDoneCP } from './dashboardsup/jobdone.component';
import { DashBoardTechCP } from './dashboardtech/dashboardtech.component';
import { DashBoardSupCP } from './dashboardsup/dashboardsupervisor.component';
import { WarrantyCP } from './warranty/warranty.component';
import { CreateStockTransferCP } from './stocktransfer/createstocktransfer.component';
import { StockTransferDetailCP } from './stocktransfer/stocktransferdetail.component';
import { TransferListCP} from './stocktransfer/transferlist.component';
import { StockInListCP } from './stockin/stockinlist.component';
import { StockInCP } from './stockin/stockin.component';
import { StockInDetailCP} from './stockin/stockindetail.component';
import { StockLedgerCP } from './stockledger/stockledger.component';
import { StockAdjustmentListCP} from './stockadjustment/stockadjustmentlist.component';
import { TakenListCP} from './takenlist/takenlist.component';
import { TechReviewCp } from './techreview/techreview.component';
import { BlankCP } from './blank/blank.component';
import { Admin_Role } from './service/auth_guard/admin_role';
import { Service_Manager_Role } from './service/auth_guard/service_manager_role';
import { Assistance_Manager_Role } from './service/auth_guard/assistance_manager_role';
import { Operator_Role } from './service/auth_guard/operator_role';
import { Service_Tech_Role } from './service/auth_guard/service_tech_role';
import { Warranty_Role } from './service/auth_guard/warranty_role';
import { Else_Warranty_Role } from './service/auth_guard/else_warranty_role';
import { StockBalance } from './stockbalance/stockbalance.component';
import { CustomerTakenCP } from './customertaken/customertaken.component';
import { UNAndResetCP } from './prepareresetpwd/prepareresetpwd.component';
import { ResetPwdCP } from './saveresetpwd/saveresetpwd.component';
import { DeviceIDCP } from './deviceid/deviceid.component';

import { SearchJobCP } from './searchjob/searchjob.component';
import { BlankWithParamCP } from './blank/blankwithparam.component';
import {JobListNewCP} from './joblistnew/joblistnew.component';
import { JobDonePrintViewCP } from './dashboardsup/jobdoneprintview.component';
import { MessageTemplateCP } from './messagetemplates/messagetemplate.component';
import { Over7CP } from './over7DayJobs/over7.component';

import { MainDashboardCP } from './main-dashboard/main-dashboard.component';
import { TotalDashboardCP } from './total-dashboard/total-dashboard.component';
// //test
import { PrintCusTakenVoucherComp } from './customertaken/printcomp';

// //admin job list
import {AdminJobListCP} from './adminjoblist/adminjoblist.component';
import { AppComponent } from "./app.component";
import { ExternalLoginComponent } from './external-login.cp';
import { TechDashboardCP } from './tech-dashboard/tech_dashboard.component';
import { DescriptionCp } from './Description/description.component';
import { PurchaseEntryCP } from './stockin/purchase-entry.component';

export const routes: Routes = [
    { path: '', component: HomeCP },
    { path: 'default', component: BlankCP },
    { path: 'default2/:jid', component: BlankWithParamCP },
    { path: 'login', component: LoginApplication },
    { path: 'chart', component: DashboardCP, canActivate: [Admin_Role] },//admin        
    { path: 'dashboardsup', component: DashBoardSupCP, canActivate: [Operator_Role] },   //operator
    { path: 'dashboardtech', component: DashBoardTechCP, canActivate: [Service_Tech_Role] },    //service tech
    
    { path: 'joblist', component: JobListCP, canActivate: [Operator_Role] }, //operator
    { path: 'jobprintview/:jid', component: JobPrintViewCP }, //, canActivate: [Operator_Role]
    { path: 'techjoblist', component: TechJobListCP, canActivate: [Service_Tech_Role] }, //manager
    { path: 'warranty', component: WarrantyCP, canActivate: [Warranty_Role] },   //warranty
    { path: 'servicecenter', component: ServiceCenterCP, canActivate: [Assistance_Manager_Role] }, //manager
    { path: 'department', component: DepartmentCP, canActivate: [Assistance_Manager_Role] },   //manager
    { path: 'servicetype', component: ServiceTypeCP, canActivate: [Assistance_Manager_Role] }, //manager
    { path: 'accessory', component: AccessoryCP, canActivate: [Assistance_Manager_Role] }, //manager
    { path: 'brand', component: BrandCP, canActivate: [Assistance_Manager_Role] }, //manager
    { path: 'category', component: CategoryCP, canActivate: [Assistance_Manager_Role] },   //manager
    { path: 'model', component: ModelCP, canActivate: [Assistance_Manager_Role] }, //manager
    { path: 'stockitem', component: StockItemCP, canActivate: [Assistance_Manager_Role] }, //manager
    { path: 'customer', component: CustomerCP, canActivate: [Operator_Role] },   //operator
    { path: 'loginuser', component: LoginUserCP, canActivate: [Service_Manager_Role] }, //service manager    
    { path: 'stockinlist', component: StockInListCP, canActivate: [Assistance_Manager_Role] }, //manager
    { path: 'transferlist', component: TransferListCP, canActivate: [Assistance_Manager_Role] }, //manager
    { path: 'adjustmentlist', component: StockAdjustmentListCP, canActivate: [Assistance_Manager_Role] }, //manager
    { path: 'stockledger', component: StockLedgerCP, canActivate: [Assistance_Manager_Role] }, //manager
    { path: 'jobdone', component: JobDoneCP, canActivate: [Operator_Role] }, //operator
    { path: 'jobdone/:jobid', component: JobDoneCP, canActivate: [Operator_Role] }, //operator
    { path: 'createjob', component: JobCP },     //operator
    { path: 'jobedit/:jid', component: JobCP, canActivate: [Operator_Role] }, //operator
    { path: 'jobedit/:jid/:sourcecomp', component: JobCP, canActivate: [Operator_Role] }, //operator
    { path: 'stockin', component: StockInCP, canActivate: [Assistance_Manager_Role] }, //manager
    { path: 'stockindetail/:siid', component: StockInDetailCP, canActivate: [Assistance_Manager_Role] }, //manager
    { path: 'stocktransfer', component: CreateStockTransferCP, canActivate: [Assistance_Manager_Role] }, //manager
    { path: 'stocktransferedit/:tid', component: CreateStockTransferCP, canActivate: [Assistance_Manager_Role] }, //manager
    { path: 'stocktransferdetail/:tid', component: StockTransferDetailCP, canActivate: [Assistance_Manager_Role] }, //manager
    { path: 'takenlist', component: TakenListCP, canActivate: [Operator_Role] },//operator
    { path: 'techreview', component: TechReviewCp, canActivate: [Operator_Role] },//operator
    { path: 'stockbalance', component: StockBalance, canActivate: [Else_Warranty_Role] },
    { path: 'customertaken', component: CustomerTakenCP, canActivate: [Operator_Role] },
    { path: 'customertaken/:sourcecomp', component: CustomerTakenCP, canActivate: [Operator_Role] },
    { path: 'prepareresetpwd', component: UNAndResetCP},
    { path: 'saveresetpwd/:UN/:rKey', component: ResetPwdCP },///:UN/:recoveryKey
    { path: 'jobsbydeviceid', component: DeviceIDCP, canActivate: [Else_Warranty_Role] }, 
    { path: 'techjoblist2', component: TechJobListCP2, canActivate: [Service_Tech_Role] }, //manager
    { path: 'searchjob', component: SearchJobCP },
    { path: 'searchjob/:jid', component: SearchJobCP }, //operator////, canActivate: [Operator_Role]
    { path: 'searchjob/:searchdata/:searchtype', component: SearchJobCP },
    { path: 'joblistnew', component: JobListNewCP }, //operator////, canActivate: [Operator_Role]
    { path: 'jobdoneprintview/:jobid/:deviceid', component: JobDonePrintViewCP, canActivate: [Else_Warranty_Role] },
    
    { path: 'messagetemplate', component: MessageTemplateCP, canActivate: [Operator_Role] },
    { path: 'over7', component: Over7CP, canActivate: [Service_Tech_Role] },
    { path: 'custakenvprint/:jid', component: PrintCusTakenVoucherComp }, //, canActivate: [Operator_Role]
    { path: 'adminjoblist', component: AdminJobListCP, canActivate: [Admin_Role] },
    { path: 'external-login/:loginObj', component: ExternalLoginComponent },

    { path: 'main-dashboard', component: MainDashboardCP, canActivate: [Service_Manager_Role] },   
    { path: 'ground-dashboard', component: TotalDashboardCP, canActivate: [Service_Manager_Role] }, 

    { path: 'tech-dashboard', component: TechDashboardCP, canActivate: [Service_Tech_Role] },   

    { path: 'description/:type', component: DescriptionCp, canActivate: [Assistance_Manager_Role] },   //manager

    { path: 'purchase-entry', component: PurchaseEntryCP, canActivate: [Assistance_Manager_Role] }, //manager
    { path: 'purchase-entry/:siid', component: PurchaseEntryCP, canActivate: [Assistance_Manager_Role] }, //manager

];

export const AppRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes);
