export class TechDashQuery {
    constructor(
        public PType?: any,        
        public Period?: any,
        public FromDate?: any,
        public ToDate?: any,
    
        public SCID?: any,            
        public DID?: any,
    ){}
    
    SearchText?: any;
}