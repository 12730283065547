import { Component, ViewChild, NgZone, Input } from '@angular/core';//OnInit, OnDestroy
import { Router } from '@angular/router';


// Thrid Library
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { BsModalComponent } from 'ng2-bs3-modal';
import { Observable } from 'rxjs';
import { NgProgressService } from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';
import { AuthService } from '../service/authservice';
import { DataChangedEvent } from '../service/datachangedevent';
import { lFservice } from '../service/lFservice';

//Model
import { JobViewModel } from '../joblist/jobviewmodel';
import { Job } from '../job/job';
import { Customer } from '../customer/customer';
import { JobListNewDeptQuery } from './joblistnewDeptquery';

@Component({
    selector: 'i-joblistnew',
    templateUrl: './joblistnew.component.html'
})
export class JobListNewCP {
    @Input() joblistnewQry = new JobListNewDeptQuery(1, null, null, null, 'a',
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0),
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 0), true, true, null);
    @Input() isModalMode: boolean = false;
    @Input() callFormName: string;

    @ViewChild('confirmModal', { static: false }) confirmModal: BsModalComponent;
    @ViewChild('jobdoneModal', { static: false }) jobdoneModal: BsModalComponent;
    @ViewChild('jobModal', { static: false }) jobModal: BsModalComponent;
    @ViewChild('customerTakenModal', { static: false }) customerTakenModal: BsModalComponent;
    @ViewChild('takenDetailModal', { static: false }) takenDetailModal: BsModalComponent;
    @ViewChild('searchTypeModel', { static: false }) searchTypeModel: BsModalComponent;
    @ViewChild('rsm', { static: false }) rsm;
    @ViewChild('pdt', { static: false }) pdt;
    @ViewChild('table', { static: false }) table: Table;

    public printMode: boolean = false;

    public scList: any = null;
    public stList: any = null;
    public jobstatusddown: SelectItem[] = [];
    public servicecenterddown: SelectItem[] = [];
    public servicetypeddown: SelectItem[] = [];
    public periodddown: SelectItem[] = [];

    public joblistnew: any[] = [];

    public srObjT: any;
    public srObjSM: any;
    public srObjJ: any;

    public jobv: JobViewModel;
    public customer: Customer;

    public techDoneRadioVal: string;

    public pRadioReChk: boolean = false;

    public jidr: string = '';

    public job: Job;
    public jobmodaltitle = '';
    public jid: string;
    public showJobModal: boolean;

    public jobVPs: any[] = [];
    public techName: string = '';
    public showIncome: boolean = false;

    public datasource: any[] = [];
    public totalRecords: number;

    public _searchjid: string = '';

    public searchVal: string = '';
    public searchData: any;// = {searchdata:'', searchtype: ''};
    public tmpsearchJV: any;
    jobRemark: any;
    public toastkey = "i-joblistnew";

    selectedServiceCenter: number;
    selectedServiceType: number;

    jobRemarks = [
        { label: 'Service Only', value: 'Service Only' },
        { label: 'Parts Change', value: 'Parts Change' },
        { label: 'YGN Service', value: 'YGN Service' },
        { label: 'YGN Service2', value: 'YGN Service2' },
        { label: 'MDY Service', value: 'MDY Service' }];

    selectedJobRemark: Array<any>;

    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;

    constructor(private pService: NgProgressService, public httpService: HttpServices, public router: Router, public auth: AuthService, public _ngZone: NgZone, public _dataChanged: DataChangedEvent) {
        this.jobv = new JobViewModel();
        this.customer = new Customer();
        this.initJob();
        this.jidr = '';
        // this._searchjid = null;
        this._searchjid = '';

        this.srObjT = _dataChanged.jobTStatusChanged.subscribe(
            srDataT => { this._ngZone.run(() => { this.getJobListNew() }) },
            err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!'),
            () => { }
        );

        this.srObjSM = _dataChanged.jobSmStatusChanged.subscribe(
            srDataSM => { this._ngZone.run(() => { this.getJobListNew() }) },
            err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!'),
            () => { }
        );

        this.srObjJ = _dataChanged.jobChanged.subscribe(
            srDataJ => { this._ngZone.run(() => { this.getJobListNew(); }) },
            err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!'),
            () => { }
        );

        this.jobstatusddown = [
            { label: 'Received Jobs', value: 1 },
            { label: 'Service Manager Done', value: 2 },
            { label: 'Technician Done', value: 3 },
            { label: 'Taken', value: 4 },
            { label: 'Remaining Jobs', value: 17 },
            { label: 'Work In Progress', value: 5 },
            { label: 'Waiting Warranty', value: 6 },
            { label: 'Waiting Customer Reply', value: 7 },
            { label: 'FOC', value: 8 },

            { label: 'No Taken', value: 9 },
            { label: 'Unable to repair', value: 10 },
            { label: 'Unable to repair(Manager done)', value: 11 },
            { label: 'All Unable to repair', value: 110 }, //custom value
            { label: 'Customer Cancel', value: 18 },
            { label: 'Customer Cancel(Manager done)', value: 19 },
            { label: 'All Customer Cancel', value: 118 },//custom value
            // work in progress for no period -- 12
            // { label: 'Hardware YGN', value: 13 },
            // { label: 'Hardware MDY', value: 14 },
            { label: 'Waiting Part', value: 15 },
            //{ label: 'Part Done', value: 16 },
            //{ label: 'Total', value: 20 },
        ];

        this.periodddown = [
            { label: 'Today', value: 'a' },
            { label: 'This week', value: 'b' },
            { label: 'This Month', value: 'c' },
            { label: 'Within 2 Months', value: 'd' },
            { label: 'Within 3 Months', value: 'e' },
            { label: 'Custom Date', value: 'f' }];

        this.pService.start();

        this.showIncome = false;

        this.searchVal = 'JID';

        this.servicecenterddown.push({ label: '- Select Service Center -', value: null });
        this.servicetypeddown.push({ label: '- Select Service Type -', value: null });
    }

    ngOnInit() {
        this._ngZone.run(() => {
            lFservice.Get_LF('authinfo').then(res => {
                if (res) {
                    this.httpService.auth.authtoken = res.access_token;
                    this.getJobListNew();
                }
            }).catch(err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data!') })
        });
        this.showIncome = false;

        this.httpService.getDataFJ(['api/getsc', 'api/getst']).subscribe(
            data => {
                this.scList = data[0];
                this.stList = data[1];

                for (let sc of this.scList) {
                    this.servicecenterddown.push({ label: sc.Name, value: sc.ID });
                }

                for (let st of this.stList) {
                    this.servicetypeddown.push({ label: st.Name, value: st.ID });
                }

                this.selectedServiceCenter = this.joblistnewQry.servicecenterId;
                this.selectedServiceType = this.joblistnewQry.servicetypeId;

                this.pService.done();
            },
            err => { this.httpService.showToast(this.toastkey, 'error', err); },
            () => {
            });
    }

    ngOnDestroy() {
        if (this.srObjT != undefined) this.srObjT.unsubscribe();
        if (this.srObjSM != undefined) this.srObjSM.unsubscribe();
        if (this.srObjJ != undefined) this.srObjJ.unsubscribe();
    }

    showSearchTypeModel(jv) {
        this.tmpsearchJV = Object.assign({}, jv);
        this.searchTypeModel.open();
    }

    searchTypeModelClose() {
        this.searchTypeModel.close();
        this.searchVal = 'JID';
        this.httpService.AddClassToModal(this.isModalMode);
    }

    searchTypeOK() {
        this.searchTypeModel.dismiss();
        this.httpService.AddClassToModal(this.isModalMode);
        this.searchData = { searchdata: (this.searchVal === 'JID' ? this.tmpsearchJV.JIDR : this.tmpsearchJV.Imei), searchtype: this.searchVal };
        this.takenDetailModal.open();
    }

    goCustomerTaken(jv: JobViewModel) {
        this.changeJobViewToJob(jv).then(() => {
            this.getJobDescription();
            this.getTechName();
            this.customerTakenModal.open();
        });

    }

    goTechDoneBySM(jv: JobViewModel): void {
        if (jv != undefined || this.jobv != undefined) {
            this.jobv = jv;
            this.confirmModal.open();
        }
    }

    goEdit(jv: JobViewModel): void {
        this.jobmodaltitle = 'Edit Job';
        this.showJobModal = true;
        this.jid = jv.JID;
        this.jobModal.open();
    }

    confirmModalClose() {
        this.confirmModal.close();
        this.httpService.AddClassToModal(this.isModalMode);
    }

    supStatusChanges(): void {
        this.confirmModalClose();
        if (this.jobv != undefined) {
            if (this.techDoneRadioVal == "Re-Check") {
                this.jobv.SStat = 'b';
                this.jobv.TStat = 'a';
                this.jobv.SDnDT = null;
                this.pService.start();
                this.httpService.postData('api/jobsupervisorrecheck2', this.jobv).
                    subscribe(
                        data => { },
                        err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while updating data!'),
                        () => {
                            // this._ngZone.run(() => {
                            //     if (this.jobv.SStat == 'b') {
                            //         this.jobv.TStat = 'a';
                            //         this.jobv.TProg = 0;
                            //     }
                            //     this.getJobListNew();
                            // });
                            this.pService.done();
                        });
            }
            else if (this.techDoneRadioVal == "Done") {
                this._ngZone.run(() => {
                    this.jobv.SStat = 'c';
                    this.GetCustomerByID();
                    this.jobdoneModal.open();
                });
            }
        }
    }

    jobdoneModalDismiss() {
        this.jobdoneModal.dismiss();
        this.httpService.AddClassToModal(this.isModalMode);
    }

    // takenDetail(JIDR) {
    //     this.jidr = JIDR;
    //     this.takenDetailModal.open();
    // }

    ngModChg(result: boolean): void {
        this.pRadioReChk = result;
        if (result) {
            let timerSub = Observable.timer(50, 1000).subscribe(t => { this.rsm.nativeElement.focus(); }, err => { }, () => { timerSub.unsubscribe(); });
        }
    }

    filterChanged() {
        this.joblistnewQry.servicecenterId = this.selectedServiceCenter;
        this.joblistnewQry.servicetypeId = this.selectedServiceType;

        this.getJobListNew();
    }

    filterChangedJobremark() {
        this.getJobListNew();
    }

    onSelectDate() {
        this.getJobListNew();
    }

    printjob() {
        window.print();
    }

    exportToCSV() {
        //this.pdt.exportCSV();
        this.httpService.postData('api/exportjoblistnew', this.joblistnewQry).subscribe(
            data => { },
            err => { },
            () => {
                //window.location.href = this.httpService._url + 'excel/joblist_not_currentjobs.csv';
                this.httpService.DirectLocToWindow("joblist_not_currentjobs");
            }
        )
    }

    initJob() {
        this.job = new Job(
            null, null, null, this.auth.DID, this.auth.loginName, '', '', null, '', null, null, true, new Date(), 0, '', '', 'S', '', '', 0, null, '', '', null, '', false, null, 0, 0, 0,
            0, 0, null, null, '', 0, this.auth.ScID, '', this.auth.loginName, '', '', false, '', '', null, 0, '', '', null, null, [], ''
        );
    }

    getJobListNew() {
        if (!this.joblistnewQry.jobstatusId) { return; }
        this.pService.start();
        if (this.callFormName == "totalDashboard") {
            let query2 = {
                ScID: this.joblistnewQry.servicecenterId, DID: null,
                STID: this.joblistnewQry.servicetypeId, JobStatusId: this.joblistnewQry.jobstatusId
            };

            if (this.joblistnewQry.jobstatusId == 10) query2.JobStatusId = 1;
            else if (this.joblistnewQry.jobstatusId == 13) query2.JobStatusId = 2;
            else if (this.joblistnewQry.jobstatusId == 14) query2.JobStatusId = 3;
            else if (this.joblistnewQry.jobstatusId == 15) query2.JobStatusId = 4;
            else if (this.joblistnewQry.jobstatusId == 16) query2.JobStatusId = 6;
            else if (this.joblistnewQry.jobstatusId == 3) query2.JobStatusId = 7;
            else if (this.joblistnewQry.jobstatusId == 2) query2.JobStatusId = 8;
            else if (this.joblistnewQry.jobstatusId == 6) query2.JobStatusId = 9;
            else if (this.joblistnewQry.jobstatusId == 7) query2.JobStatusId = 10;
            else if (this.joblistnewQry.jobstatusId == 18) query2.JobStatusId = 11;
            else if (this.joblistnewQry.jobstatusId == 20) query2.JobStatusId = 12;

            this.httpService.postData('api/totaljoblistdetail', query2).subscribe(
                data => {
                    this.datasource = data[0] || [];
                    this.jobRemark = data[1] || [];
                    this.filterData();
                    this.pService.done();
                },
                err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!'); }
            );

        } else {
            var dataquery = Object.assign({}, this.joblistnewQry);
            if (this.joblistnewQry.jobstatusId == 110) {
                dataquery.jobstatusId = 10;
                dataquery.noSDone = false;
            } else if (this.joblistnewQry.jobstatusId == 118) {
                dataquery.jobstatusId = 18;
                dataquery.noSDone = false;
            }
            if (this.selectedJobRemark && this.selectedJobRemark.length > 0) {
                dataquery.jobremark = this.selectedJobRemark.map((x: any) => {
                    return x.value;
                });
            } else {
                dataquery.jobremark = null;
            }
            if (this.joblistnewQry.jobstatusId == 2 || this.joblistnewQry.jobstatusId == 3 ||
                this.joblistnewQry.jobstatusId == 17 || this.joblistnewQry.jobstatusId == 5 ||
                this.joblistnewQry.jobstatusId == 7 || this.joblistnewQry.jobstatusId == 9 ||
                this.joblistnewQry.jobstatusId == 10 || this.joblistnewQry.jobstatusId == 11 ||
                this.joblistnewQry.jobstatusId == 110 || this.joblistnewQry.jobstatusId == 18 ||
                this.joblistnewQry.jobstatusId == 118 || this.joblistnewQry.jobstatusId == 15) {  //(Taken မပါရပါ။)
                dataquery.noTaken = true;
            } else if (this.joblistnewQry.jobstatusId == 4) {
                dataquery.noTaken = false;
            }
            else {
                dataquery.noTaken = null;
            }

            this.httpService.postData('api/joblistnew', dataquery).subscribe(
                data => {
                    this.datasource = data[0] || [];
                    this.jobRemark = data[1] || [];
                    this.filterData();
                    this.pService.done();
                },
                err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!'); }
            );
        }

    }

    // filterData() {
    //     this.totalRecords = this.datasource.length;
    //     this.joblistnew = this.datasource.slice(0, 20);
    //     this.joblistnew.sort((a, b) => a.JDT < b.JDT ? 1 : (b.JDT < a.JDT ? -1 : 0));
    //     if (this.tpa) this.tpa.paginate({ first: 0, rows: 20 });
    //     // this.tpa.paginate({ first: 0, rows: 20 });
    // }

    filterData() {
        this.joblistnew = this.datasource;
        if (this._searchjid && this._searchjid !== '')
            this.joblistnew = this.joblistnew.filter((s) => (s.JIDR.trim() + '¶»' + s.Ph.trim() + '¶»' + s.CNPh.trim()).toLowerCase().indexOf(this._searchjid.toLowerCase().trim()) !== -1);

        this.joblistnew.sort((a, b) => a.JDT < b.JDT ? 1 : (b.JDT < a.JDT ? -1 : 0));
        this.totalRecords = this.joblistnew.length;
        //if (this.table) this.table.paginate({ first: 0, rows: 20 });
    }

    GetCustomerByID(): void {
        let temp;
        this.pService.start();
        this.httpService.getData('api/getcustomer/' + this.jobv.CID).subscribe(
            data => temp = data,
            err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'),
            () => {
                if (temp != undefined) {
                    this.customer = temp;
                }
                this.pService.done();
            }
        );
    }

    getJobDescription(): void {
        if (this.job.JID !== undefined || this.job.JID !== null) {
            this.jobVPs = [];
            this.pService.start();
            this.httpService.postData('api/getJobDescription', this.job.JID).subscribe(
                data => { this.jobVPs = data; },
                err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading job_voucher_print data !'); },
                () => {
                    this.pService.done();
                }
            );
        }
    }

    getTechName(): void {
        if (this.job.JAT1 !== undefined || this.job.JAT1 !== null || this.job.JAT1 !== '') {
            this.pService.start();
            this.httpService.getData('api/gettechname/' + this.job.JAT1).subscribe(
                data => { this.techName = data },
                err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while getting technician name !'); },
                () => {
                    this.pService.done();
                }
            );
        }
    }

    changeJobViewToJob(jv: JobViewModel): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.initJob();
            this.job.JID = jv.JID;
            this.job.JIDR = jv.JIDR;
            this.job.JDT = jv.JDT;
            this.job.JAT1 = jv.JAT1;
            this.job.JAT2 = jv.JAT2;
            this.job.CID = jv.CID;
            this.job.JOB = jv.JOB;
            this.job.STID = jv.STID;
            this.job.MoID = jv.MoID;
            this.job.HAPP = jv.HAPP;
            this.job.APPDT = new Date(jv.APPDT);
            this.job.DAmo = jv.DAmo;
            this.job.OAcc = jv.OAcc;
            this.job.RC = jv.RC;
            this.job.JT = jv.JT;
            this.job.RT = jv.RT;
            this.job.TStat = jv.TStat;
            this.job.TProg = jv.TProg;
            this.job.TDnDT = jv.TDnDT;
            this.job.TDBy = jv.TDBy;
            this.job.SStat = jv.SStat;
            this.job.SDnDT = jv.SDnDT;
            this.job.SDBy = jv.SDBy;
            this.job.Taken = jv.Taken;
            this.job.TDT = jv.TDT;
            this.job.SAmo = jv.SAmo;
            this.job.PAmo = jv.PAmo;
            this.job.TAmo = jv.TAmo;
            this.job.Crec = jv.Crec;
            this.job.Chg = jv.Chg;
            this.job.WSDT = jv.WSDT;
            this.job.WADT = jv.WADT;
            this.job.WR = jv.WR;
            this.job.RCC = jv.RCC;
            this.job.SuppN = jv.SuppN;
            this.job.DeviceID = jv.DeviceID;
            this.job.RSM = jv.RSM;
            this.job.FOC = jv.FOC;
            this.job.STN = jv.STN;
            this.job.MoN = jv.MoN;
            resolve(true);
        })
    }

    onClickEmit(event) {
        this.showJobModal = false;
        this.jobModal.close();
        if (this.jid != undefined) this.jid = undefined;

        this.httpService.AddClassToModal(this.isModalMode);
    }

    onClickCusTake() {
        this.customerTakenModal.close();
        this.httpService.AddClassToModal(this.isModalMode);
        if (this.job != undefined) this.job = undefined;
        this._ngZone.run(() => this.getJobListNew());
    }

    // goSearch(event) {
    //     if (this._searchjid === '') {
    //         this.filterData();
    //     }
    //     if (event.keyCode === 13) {
    //         if (this._searchjid === '') { this.filterData(); }
    //         else {
    //             this.joblistnew = this.datasource.filter(job => job.JIDR === this._searchjid);
    //             this.totalRecords = this.joblistnew.length;
    //         }
    //     }
    // }

    goSearch(event) {
        if (event.keyCode === 13) {
            this.filterData();
        }
    }

    closeTakenDetailModal() {

        this.takenDetailModal.dismiss();
        this.httpService.AddClassToModal(this.isModalMode);
        this.pService.start();
        // this.httpService.getData('api/getsearchjob/' + this.jidr).subscribe(
        this.httpService.postData('api/getsearchjob', this.searchData).subscribe(
            data => {
                let idx = this.joblistnew.findIndex(x => x.JIDR === data[0].JIDR);
                if (idx >= 0) {
                    this.joblistnew[idx].RSM = data[0].RSM;
                }
                let _idx = this.datasource.findIndex(x => x.JIDR === data[0].JIDR);
                if (_idx >= 0) {
                    this.datasource[_idx].RSM = data[0].RSM;
                }
                this.searchData = undefined;
            },
            err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while getting Service_Manager\'s remark !'); },
            () => {
                this.jidr = '';
                this.pService.done();
            }
        );
    }
}
export var exportJobnew: Job;