import { Component, Input, OnInit, EventEmitter, Inject, forwardRef } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Location } from '@angular/common';
import { MenuItem } from 'primeng/api';

import { AppComponent } from './app.component';
import { AuthService } from './service/authservice';

@Component({
    selector: 'app-menu',
    template: `
        <ul app-submenu [item]="model" root="true" class="ultima-menu ultima-main-menu clearfix" [reset]="reset" visible="true"></ul> 
    `
})
export class AppMenuComponent implements OnInit {

    @Input() reset: boolean;

    public model: any[] = [];

    constructor(@Inject(forwardRef(() => AppComponent)) public app: AppComponent, public auth: AuthService) { }

    ngOnInit() {
        if (this.auth.appRole == 'Admin') {
            this.model = [
                // { label: 'Daily Dashboard', icon: 'dashboard', command: () => { this.app.gotoMainDashboard() } },
                // { label: 'Total Dashboard', icon: 'dashboard', command: () => { this.app.gotoTotalDashboard() } },
                // { label: 'Dashboard', icon: 'dashboard', command: () => { this.app.gotoDashboard() } },
                {
                    label: 'Dashboard', icon: 'dashboard',
                    items: [
                        { label: 'Main Dashboard', icon: 'dashboard', command: () => { this.app.gotoMainDashboard() } },
                        { label: 'Ground Dashboard', icon: 'dashboard', command: () => { this.app.gotoTotalDashboard() } },
                        { label: 'Chart', icon: 'dashboard', command: () => { this.app.gotoDashboard() } },
                        {label:'Technician Dashboard',icon:'dashboard',command:()=>{this.app.gotoTechDashboard()}},
                    ]
                },
                { label: 'Current Jobs', icon: 'list', command: () => { this.app.gotoJobList() } },
                { label: 'Job List', icon: 'list', command: () => { this.app.gotoJobListNew() } },
                { label: 'Warranty', icon: 'memory', routerLink: ['/warranty'] },
                //{label:'Taken List',icon:'done_all',routerLink: ['/takenlist']},
                { label: 'Technician Review', icon: 'person_pin', routerLink: ['/techreview'] },

                {
                    label: 'Administration', icon: 'apps',
                    items: [
                        { label: 'Service Center', icon: 'location_on', routerLink: ['/servicecenter'] },
                        { label: 'Department', icon: 'book', routerLink: ['/department'] },
                        { label: 'Service Type', icon: 'merge_type', routerLink: ['/servicetype'] },
                        { label: 'Accessory', icon: 'markunread_mailbox', routerLink: ['/accessory'] },
                        { label: 'Brand', icon: 'ac_unit', routerLink: ['/brand'] },
                        { label: 'Category', icon: 'ac_unit', routerLink: ['/category'] },
                        { label: 'Model', icon: 'ac_unit', routerLink: ['/model'] },
                        { label: 'Stock Item', icon: 'assignment', routerLink: ['/stockitem'] },
                        { label: 'Customer', icon: 'accessibility', routerLink: ['/customer'] },
                        { label: 'Description', icon: 'description', routerLink: ['/description/a'] },
                        { label: 'Technician Remark', icon: 'collections_bookmark', routerLink: ['/description/b'] },
                        { label: 'Login User', icon: 'perm_identity', routerLink: ['/loginuser'] }]
                },

                {
                    label: 'Stock Control', icon: 'toys',
                    items: [                        
                        { label: 'Stock In', icon: 'input', routerLink: ['/stockinlist'] },
                        { label: 'Stock Transfer', icon: 'transform', routerLink: ['/transferlist'] },
                        { label: 'Stock Adjustment', icon: 'adjust', routerLink: ['/adjustmentlist'] },
                        { label: 'Stock Ledger', icon: 'library_books', routerLink: ['/stockledger'] }]
                },

                { label: 'Stock Balance', icon: 'account_balance_wallet', routerLink: ['/stockbalance'] },
                { label: 'Job List By Device ID', icon: 'work', routerLink: ['/jobsbydeviceid'] },

                { label: 'Message Template', icon: 'insert_comment', routerLink: ['/messagetemplate'] },

                { label: 'Unfinished Job List', icon: 'search', routerLink: ['/over7'] }
            ];
        }
        if (this.auth.appRole == 'Service Manager') {
            this.model = [
                // { label: 'Daily Dashboard', icon: 'dashboard', command: () => { this.app.gotoMainDashboard() } },
                // { label: 'Total Dashboard', icon: 'dashboard', command: () => { this.app.gotoTotalDashboard() } },
                // { label: 'Dashboard', icon: 'dashboard', command: () => { this.app.gotoDashboard() } },
                {
                    label: 'Dashboard', icon: 'dashboard',
                    items: [
                        { label: 'Main Dashboard', icon: 'dashboard', command: () => { this.app.gotoMainDashboard() } },
                        { label: 'Ground Dashboard', icon: 'dashboard', command: () => { this.app.gotoTotalDashboard() } },
                        { label: 'Chart', icon: 'dashboard', command: () => { this.app.gotoDashboard() } },
                        {label:'Technician Dashboard',icon:'dashboard',command:()=>{this.app.gotoTechDashboard()}},
                    ]
                },
                //{label:'Create New Job',icon:'add',command:()=>{this.app.gotoJob()}},
                { label: 'Current Jobs', icon: 'list', command: () => { this.app.gotoJobList() } },
                { label: 'Job List', icon: 'list', command: () => { this.app.gotoJobListNew() } },
                { label: 'Warranty', icon: 'memory', routerLink: ['/warranty'] },
                //{label:'Taken List',icon:'done_all',routerLink: ['/takenlist']},
                { label: 'Technician Review', icon: 'person_pin', routerLink: ['/techreview'] },

                {
                    label: 'Administration', icon: 'apps',
                    items: [
                        { label: 'Service Center', icon: 'location_on', routerLink: ['/servicecenter'] },
                        { label: 'Department', icon: 'book', routerLink: ['/department'] },
                        { label: 'Service Type', icon: 'merge_type', routerLink: ['/servicetype'] },
                        { label: 'Accessory', icon: 'markunread_mailbox', routerLink: ['/accessory'] },
                        { label: 'Brand', icon: 'ac_unit', routerLink: ['/brand'] },
                        { label: 'Category', icon: 'ac_unit', routerLink: ['/category'] },
                        { label: 'Model', icon: 'ac_unit', routerLink: ['/model'] },
                        { label: 'Stock Item', icon: 'assignment', routerLink: ['/stockitem'] },
                        { label: 'Customer', icon: 'accessibility', routerLink: ['/customer'] },
                        { label: 'Description', icon: 'description', routerLink: ['/description/a'] },
                        { label: 'Technician Remark', icon: 'collections_bookmark', routerLink: ['/description/b'] },
                        { label: 'Login User', icon: 'perm_identity', routerLink: ['/loginuser'] }]
                },

                {
                    label: 'Stock Control', icon: 'toys',
                    items: [
                        // { label: 'Purchase Entry', icon: 'input', routerLink: ['/purchase-entry'] },
                        { label: 'Purchase', icon: 'input', routerLink: ['/stockinlist'] },
                        { label: 'Stock Transfer', icon: 'transform', routerLink: ['/transferlist'] },
                        { label: 'Stock Adjustment', icon: 'adjust', routerLink: ['/adjustmentlist'] },
                        { label: 'Stock Ledger', icon: 'library_books', routerLink: ['/stockledger'] }]
                },

                { label: 'Stock Balance', icon: 'account_balance_wallet', routerLink: ['/stockbalance'] },
                { label: 'Job List By Device ID', icon: 'work', routerLink: ['/jobsbydeviceid'] },

                { label: 'Message Template', icon: 'insert_comment', routerLink: ['/messagetemplate'] },

                { label: 'Unfinished Job List', icon: 'search', routerLink: ['/over7'] },

                // {label:'Test DateTime',icon:'insert_comment',routerLink: ['/testdatetime']}
            ];//insert-comment
        }
        if (this.auth.appRole == 'Assistance Manager') {

            this.model = [
                //{label:'Dashboard',icon:'dashboard',command:()=>{this.app.gotoDashboard()}},
                //{label:'Create New Job',icon:'add',command:()=>{this.app.gotoJob()}}, 
                { label: 'Current Jobs', icon: 'list', command: () => { this.app.gotoJobList() } },
                { label: 'Job List', icon: 'list', command: () => { this.app.gotoJobListNew() } },
                { label: 'Warranty', icon: 'memory', routerLink: ['/warranty'] },
                //{label:'Taken List',icon:'done_all',routerLink: ['/takenlist']},
                { label: 'Technician Review', icon: 'person_pin', routerLink: ['/techreview'] },

                {
                    label: 'Administration', icon: 'apps',
                    items: [
                        { label: 'Service Center', icon: 'location_on', routerLink: ['/servicecenter'] },
                        { label: 'Department', icon: 'book', routerLink: ['/department'] },
                        { label: 'Service Type', icon: 'merge_type', routerLink: ['/servicetype'] },
                        { label: 'Accessory', icon: 'markunread_mailbox', routerLink: ['/accessory'] },
                        { label: 'Brand', icon: 'ac_unit', routerLink: ['/brand'] },
                        { label: 'Category', icon: 'ac_unit', routerLink: ['/category'] },
                        { label: 'Model', icon: 'ac_unit', routerLink: ['/model'] },
                        { label: 'Stock Item', icon: 'assignment', routerLink: ['/stockitem'] },
                        { label: 'Customer', icon: 'accessibility', routerLink: ['/customer'] }]
                },

                {
                    label: 'Stock Control', icon: 'toys',
                    items: [                        
                        { label: 'Stock In', icon: 'input', routerLink: ['/stockinlist'] },
                        { label: 'Stock Transfer', icon: 'transform', routerLink: ['/transferlist'] },
                        { label: 'Stock Adjustment', icon: 'adjust', routerLink: ['/adjustmentlist'] },
                        { label: 'Stock Ledger', icon: 'library_books', routerLink: ['/stockledger'] }]
                },

                { label: 'Stock Balance', icon: 'account_balance_wallet', routerLink: ['/stockbalance'] },
                { label: 'Job List By Device ID', icon: 'work', routerLink: ['/jobsbydeviceid'] },

                { label: 'Message Template', icon: 'insert_comment', routerLink: ['/messagetemplate'] },

                { label: 'Unfinished Job List', icon: 'search', routerLink: ['/over7'] }
            ];
        }
        if (this.auth.appRole == 'Service Technician') {
            this.model = [
                
                { label: 'Current Jobs', icon: 'list', command: () => { this.app.gotoJobList() } },
                //{ label: 'Job List', icon: 'list', command: () => { this.app.gotoJobListNew() } },
                { label: 'Stock Balance', icon: 'account_balance_wallet', routerLink: ['/stockbalance'] },
                { label: 'Job List By Device ID', icon: 'work', routerLink: ['/jobsbydeviceid'] },

                { label: 'Unfinished Job List', icon: 'search', routerLink: ['/over7'] }
            ];
        }
        if (this.auth.appRole == 'Operator') {
            this.model = [
                //{label:'Dashboard',icon:'dashboard',command:()=>{this.app.gotoDashboard()}},
                //{label:'Create New Job',icon:'add',command:()=>{this.app.gotoJob()}},
                { label: 'Current Jobs', icon: 'list', command: () => { this.app.gotoJobList() } },
                { label: 'Job List', icon: 'list', command: () => { this.app.gotoJobListNew() } },
                //{label:'Taken List',icon:'done_all',routerLink: ['/takenlist']},
                { label: 'Technician Review', icon: 'person_pin', routerLink: ['/techreview'] },

                { label: 'Administration', icon: 'apps', items: [{ label: 'Customer', icon: 'accessibility', routerLink: ['/customer'] }] },

                { label: 'Stock Balance', icon: 'account_balance_wallet', routerLink: ['/stockbalance'] },
                { label: 'Job List By Device ID', icon: 'work', routerLink: ['/jobsbydeviceid'] },

                { label: 'Message Template', icon: 'insert_comment', routerLink: ['/messagetemplate'] }
            ];
        }
        if (this.auth.appRole == 'Warranty') {
            this.model = [{ label: 'Warranty', icon: 'memory', routerLink: ['/warranty'] }];
        }
    }

    changeTheme(theme) {
        let themeLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('theme-css');
        let layoutLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('layout-css');

        themeLink.href = 'assets/theme/theme-' + theme + '.css';
        layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';
    }
}

@Component({
    selector: '[app-submenu]',
    template: `
        <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
            <li [ngClass]="{'active-menuitem': isActive(i)}" *ngIf="child.visible === false ? false : true">
                <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" class="ripplelink" *ngIf="!child.routerLink" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
                    <i class="material-icons">{{child.icon}}</i>
                    <span>{{child.label}}</span>
                    <i class="material-icons" *ngIf="child.items">keyboard_arrow_down</i>
                </a>

                <a (click)="itemClick($event,child,i)" class="ripplelink" *ngIf="child.routerLink"
                    [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink" [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
                    <i class="material-icons">{{child.icon}}</i>
                    <span>{{child.label}}</span>
                    <i class="material-icons" *ngIf="child.items">keyboard_arrow_down</i>
                </a>
                <ul app-submenu [item]="child" *ngIf="child.items" [@children]="isActive(i) ? 'visible' : 'hidden'" [visible]="isActive(i)" [reset]="reset"></ul>
            </li>
        </ng-template>
    `,
    animations: [
        trigger('children', [
            state('hidden', style({
                height: '0px'
            })),
            state('visible', style({
                height: '*'
            })),
            transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppSubMenu {

    @Input() item: MenuItem;

    @Input() root: boolean;

    @Input() visible: boolean;

    public _reset: boolean;

    public activeIndex: number;

    constructor(@Inject(forwardRef(() => AppComponent)) public app: AppComponent, public location: Location) { }

    itemClick(event: Event, item: MenuItem, index: number) {
        //avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        //activate current item and deactivate active sibling if any
        this.activeIndex = (this.activeIndex === index) ? null : index;

        //execute command
        // if (item.command) {
        //     if (!item.eventEmitter) {
        //         item.eventEmitter = new EventEmitter();
        //         item.eventEmitter.subscribe(item.command);
        //     }

        //     item.eventEmitter.emit({
        //         originalEvent: event,
        //         item: item
        //     });
        // }
        if(item.command){
            item.command({
                originalEvent: event,
                item: item
            });
        }
        

        //prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            event.preventDefault();
        }

        //hide menu
        if (!item.items) {
            if (this.app.isHorizontal())
                this.app.resetMenu = true;
            else
                this.app.resetMenu = false;

            this.app.overlayMenuActive = false;
            this.app.staticMenuMobileActive = false;
        }
    }

    isActive(index: number): boolean {
        return this.activeIndex === index;
    }

    @Input() get reset(): boolean {
        return this._reset;
    }

    set reset(val: boolean) {
        this._reset = val;

        if (this._reset && this.app.isHorizontal()) {
            this.activeIndex = null;
        }
    }
}