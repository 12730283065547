import { Component, AfterViewInit, Renderer2, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

//service
import { HttpServices } from './service/httpservices';
import { AuthService } from '././service/authservice';
import { SignalRService } from './service/signalrservice';
import { NgProgressService } from "ng2-progressbar";

//model
import { DataChangedEvent } from './service/datachangedevent';

enum MenuOrientation {
    STATIC,
    OVERLAY,
    HORIZONTAL
};

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

    public layoutCompact: boolean = true;
    public layoutMode: MenuOrientation = MenuOrientation.STATIC;
    public darkMenu: boolean = false;
    public profileMode: string = 'inline';

    public topbarMenuActive: boolean;
    public overlayMenuActive: boolean;
    public staticMenuDesktopInactive: boolean;
    public staticMenuMobileActive: boolean;
    public menuClick: boolean;
    public topbarItemClick: boolean;
    public activeTopbarItem: any;
    public documentClickListener: Function;
    public resetMenu: boolean;


    public notiMessages: any = [];
    public notiCount: number = 0;
    current_route: any;

    constructor(public ngzone: NgZone ,public _signalR: SignalRService, private pService: NgProgressService, public activeRoute: ActivatedRoute, public renderer: Renderer2, public httpservice: HttpServices, public auth: AuthService, public router: Router, public _dataChanged: DataChangedEvent) {
        // this.isAuthorized = auth.isLoggedIn;
        //this.pService.start();

        this.auth.getAuthInfo().then(n => {
            if (n) {
                this.auth.setAllData(n);
            }
            else {
                if (!this.router.url.includes("external-login") && !this.router.url.includes("saveresetpwd")) {
                    this.logout();
                }
            }
        }).catch(err => {
            this.logout();
        });
        this.subscribeToEvents();
        this.httpservice.GetDepartment();
        // setTimeout(()=>{
        //     this.pService.done();
        // },3000);

    }

    logout() {
        this.httpservice.logout().then(res => {
            this.notiCount = 0;
            this.notiMessages = [];
        });
    }

    resize(e) {
        this.httpservice.mobileWidth = window.innerWidth < 650;
    }

    goSearch(event) {
        if (event.keyCode === 13) {

            if (!this.httpservice.searchData || this.httpservice.searchData === '') {
                return;
            }
            // this.router.navigate(['/searchjob']).then(obj => {
            //     this._dataChanged.searchjobChanged.emit({ searchdata: this.httpservice.searchData, searchtype: this.httpservice.searchType });
            // });
            this.router.navigate(['/searchjob', this.httpservice.searchData, this.httpservice.searchType]);
        }
    }

    ngAfterViewInit() {
        //hides the horizontal submenus or top menu if outside is clicked
        this.documentClickListener = this.renderer.listen('body', 'click', (event) => {
            if (!this.topbarItemClick) {
                this.activeTopbarItem = null;
                this.topbarMenuActive = false;
            }

            if (!this.menuClick && this.isHorizontal()) {
                this.resetMenu = true;
            }

            this.topbarItemClick = false;
            this.menuClick = false;
        });

        this.httpservice.mobileWidth = window.innerWidth < 650;
    }

    // //

    gotoMainDashboard() {
        if (this.auth.role == 'Admin' || this.auth.role == 'Service Manager') this.router.navigate(['/main-dashboard']);
        else this.router.navigate(['/']);
    }

    gotoTotalDashboard() {
        if (this.auth.role == 'Admin' || this.auth.role == 'Service Manager') this.router.navigate(['/ground-dashboard']);
        else this.router.navigate(['/']);
    }


    gotoDashboard() {
        if (this.auth.role == 'Admin' || this.auth.role == 'Service Manager') this.router.navigate(['/chart']);
        else
            this.router.navigate(['/']);
    }

    gotoTechDashboard() {
        if (this.auth.role == 'Admin' || this.auth.role == 'Service Manager') {
            this.router.navigate(['tech-dashboard']);
        }
    }

    gotoJobList() {
        if (this.auth.role == 'Admin' || this.auth.role == 'Service Manager' || this.auth.role == 'Assistance Manager' || this.auth.role == 'Operator')
            this.router.navigate(['/joblist'])
        else if (this.auth.role == 'Service Technician')
            this.router.navigate(['/techjoblist2']);
        else
            this.router.navigate(['/']);
    }

    gotoJobListNew() {
        //if (this.auth.role == 'Admin') { this.router.navigate(['/adminjoblist']); }
        //else
        if (this.auth.role == 'Admin' || this.auth.role == 'Service Manager' || this.auth.role == 'Assistance Manager' || this.auth.role == 'Service Technician' || this.auth.role == 'Operator') {
            this.router.navigate(['/joblistnew']);
        }
        else { this.router.navigate(['/']); }
    }

    subscribeToEvents(): void {
        this._signalR.connectionEstablished.subscribe(() => { });

        this._signalR.messageReceived.subscribe((data) => {

            switch (data.ObjType) {
                case 'Accessory': {
                    //start accessory sync  
                    this._dataChanged.accessoryChanged.emit(data);
                    // lFservice.Get_LF(GlobalFunc.AcKey).then(value => {
                    //     if (value == null) return;
                    //     if (data.token == this.auth.authtoken) return;
                    //     temp = value;

                    //     if (data.Msg == 'updated') {
                    //         let _index: number = GlobalFunc.findIndexByID(temp, data.Obj, 'Accessory');
                    //         if (_index >= 0) {
                    //             temp[_index] = data.Obj;
                    //         } else {
                    //             temp.unshift(data.Obj);
                    //         }
                    //         lFservice.Set_LF(GlobalFunc.AcKey, temp).
                    //             then(value => { if (this.router.url == '/accessory') { this._dataChanged.accessoryChanged.emit(data); } }).
                    //             catch(err => { });
                    //     } else {
                    //         this.httpservice.getData('api/accessory').subscribe(
                    //             data => temp = data,
                    //             err => { },
                    //             () => {
                    //                 lFservice.Set_LF(GlobalFunc.AcKey, temp).
                    //                     then(value => { if (this.router.url == '/accessory') { this._dataChanged.accessoryChanged.emit(data); } }).
                    //                     catch(err => { });
                    //             });
                    //     }
                    // }).catch(err => { });
                    //end accessory sync
                    break;
                }
                case 'Brand': {
                    //start brand sync
                    this._dataChanged.brandChanged.emit(data);
                    // lFservice.Get_LF(GlobalFunc.BrKey).then(value => {
                    //     if (value == null) return;
                    //     if (data.token == this.auth.authtoken) return;
                    //     temp = value;

                    //     if (data.Msg == 'updated') {
                    //         let _index: number = GlobalFunc.findIndexByID(temp, data.Obj, 'Brand');
                    //         if (_index >= 0) {
                    //             temp[_index] = data.Obj;
                    //         } else {
                    //             temp.unshift(data.Obj);
                    //         }
                    //         lFservice.Set_LF(GlobalFunc.BrKey, temp).
                    //             then(value => { if (this.router.url == '/brand') { this._dataChanged.brandChanged.emit(data); } }).
                    //             catch(err => { });
                    //     } else {
                    //         this.httpservice.getData('api/brand').subscribe(
                    //             data => temp = data,
                    //             err => { },
                    //             () => {
                    //                 lFservice.Set_LF(GlobalFunc.BrKey, temp).
                    //                     then(value => { if (this.router.url == '/brand') { this._dataChanged.brandChanged.emit(data); } }).
                    //                     catch(err => { });
                    //             });
                    //     }
                    // }).catch(err => { });
                    //end brand sync
                    break;
                }
                case 'Category': {
                    //start category sync
                    this._dataChanged.categoryChanged.emit(data);
                    // lFservice.Get_LF(GlobalFunc.CTKey).then(value => {
                    //     if (value == null) return;
                    //     if (data.token == this.auth.authtoken) return;
                    //     temp = value;
                    //     if (data.Msg == 'updated') {
                    //         let _index: number = GlobalFunc.findIndexByID(temp, data.Obj, 'Category');
                    //         if (_index >= 0) {
                    //             temp[_index] = data.Obj;
                    //         } else {
                    //             temp.unshift(data.Obj);
                    //         }
                    //         lFservice.Set_LF(GlobalFunc.CTKey, temp).
                    //             then(value => { if (this.router.url == '/category') { this._dataChanged.categoryChanged.emit(data); } }).
                    //             catch(err => { });
                    //     } else {
                    //         this.httpservice.getData('api/category').subscribe(
                    //             data => temp = data,
                    //             err => { },
                    //             () => {
                    //                 lFservice.Set_LF(GlobalFunc.CTKey, temp).
                    //                     then(value => { if (this.router.url == '/category') { this._dataChanged.categoryChanged.emit(data); } }).
                    //                     catch(err => { });
                    //             });
                    //     }
                    // }).catch(err => { });
                    //end category sync
                    break;
                }
                case 'Customer': {
                    //start customer sync
                    this._dataChanged.customerChanged.emit(data);
                    // lFservice.Get_LF(GlobalFunc.CusKey).then(value => {
                    //     if (value == null) return;
                    //     if (data.token == this.auth.authtoken) return;
                    //     temp = value;

                    //     if (data.Msg == 'updated') {
                    //         let _index: number = GlobalFunc.findIndexByID(temp, data.Obj, 'Customer');
                    //         if (_index >= 0) {
                    //             temp[_index] = data.Obj;
                    //         } else {
                    //             temp.unshift(data.Obj);
                    //         }
                    //         lFservice.Set_LF(GlobalFunc.CusKey, temp).
                    //             then(value => { if (this.router.url == '/customer') { this._dataChanged.customerChanged.emit(data); } }).
                    //             catch(err => { });
                    //     } else {
                    //         this.httpservice.getData('api/customer').subscribe(
                    //             data => temp = data,
                    //             err => { },
                    //             () => {
                    //                 lFservice.Set_LF(GlobalFunc.CusKey, temp).
                    //                     then(value => { if (this.router.url == '/customer') { this._dataChanged.customerChanged.emit(data); } }).
                    //                     catch(err => { });
                    //             });
                    //     }
                    // }).catch(err => { });
                    //end customer sync
                    break;
                }
                case 'Department': {
                    //start Department sync
                    this._dataChanged.departmentChanged.emit(data);
                    // lFservice.Get_LF(GlobalFunc.DPKey).then(value => {
                    //     if (value == null) return;
                    //     if (data.token == this.auth.authtoken) return;
                    //     temp = value;

                    //     if (data.Msg == 'updated') {
                    //         let _index: number = GlobalFunc.findIndexByID(temp, data.Obj, 'Department');
                    //         if (_index >= 0) {
                    //             temp[_index] = data.Obj;
                    //         } else {
                    //             temp.unshift(data.Obj);
                    //         }
                    //         lFservice.Set_LF(GlobalFunc.DPKey, temp).
                    //             then(value => { if (this.router.url == '/department') { this._dataChanged.departmentChanged.emit(data); } }).
                    //             catch(err => { });
                    //     } else {
                    //         this.httpservice.getData('api/department').subscribe(
                    //             data => temp = data,
                    //             err => { },
                    //             () => {
                    //                 lFservice.Set_LF(GlobalFunc.DPKey, temp).
                    //                     then(value => { if (this.router.url == '/department') { this._dataChanged.departmentChanged.emit(data); } }).
                    //                     catch(err => { });
                    //             });
                    //     }
                    // }).catch(err => { });
                    // //end Department sync
                    break;
                }
                case 'Model': {
                    //start Model sync
                    this._dataChanged.modelChanged.emit(data);
                    // lFservice.Get_LF(GlobalFunc.MKey).then(value => {
                    //     if (value == null) return;
                    //     if (data.token == this.auth.authtoken) return;
                    //     temp = value;

                    //     if (data.Msg == 'updated') {
                    //         let _index: number = GlobalFunc.findIndexByID(temp, data.Obj, 'Model');
                    //         if (_index >= 0) {
                    //             temp[_index] = data.Obj;
                    //         } else {
                    //             temp.unshift(data.Obj);
                    //         }
                    //         lFservice.Set_LF(GlobalFunc.MKey, temp).
                    //             then(value => { if (this.router.url == '/model') { this._dataChanged.modelChanged.emit(data); } }).
                    //             catch(err => { });
                    //     } else {
                    //         this.httpservice.getData('api/model').subscribe(
                    //             data => temp = data,
                    //             err => { },
                    //             () => {
                    //                 lFservice.Set_LF(GlobalFunc.MKey, temp).
                    //                     then(value => { if (this.router.url == '/model') { this._dataChanged.modelChanged.emit(data); } }).
                    //                     catch(err => { });
                    //             });
                    //     }
                    // }).catch(err => { });
                    //end Model sync
                    break;
                }
                case 'ServiceCenter': {
                    //start ServiceCenter sync
                    this._dataChanged.serviceCenterChanged.emit(data);
                    // lFservice.Get_LF(GlobalFunc.SCKey).then(value => {
                    //     if (value == null) return;
                    //     if (data.token == this.auth.authtoken) return;
                    //     temp = value;
                    //     if (data.Msg == 'updated') {
                    //         let _index: number = GlobalFunc.findIndexByID(temp, data.Obj, 'ServiceCenter');
                    //         if (_index >= 0) {
                    //             temp[_index] = data.Obj;
                    //         } else {
                    //             temp.unshift(data.Obj);
                    //         }

                    //         lFservice.Set_LF(GlobalFunc.SCKey, temp).
                    //             then(value => { if (this.router.url == '/servicecenter') { this._dataChanged.serviceCenterChanged.emit(data); } }).
                    //             catch(err => { });
                    //     } else {
                    //         this.httpservice.getData('api/servicecenter').subscribe(
                    //             data => temp = data,
                    //             err => { },
                    //             () => {
                    //                 lFservice.Set_LF(GlobalFunc.SCKey, temp).
                    //                     then(value => { if (this.router.url == '/servicecenter') { this._dataChanged.serviceCenterChanged.emit(data); } }).
                    //                     catch(err => { });
                    //             });
                    //     }
                    // }).catch(err => { });
                    //end ServiceCenter sync
                    break;
                }
                case 'ServiceType': {
                    //start ServiceType sync
                    this._dataChanged.serviceTypeChanged.emit(data);
                    // lFservice.Get_LF(GlobalFunc.STKey).then(value => {
                    //     if (value == null) return;
                    //     if (data.token == this.auth.authtoken) return;
                    //     temp = value;

                    //     if (data.Msg == 'updated') {
                    //         let _index: number = GlobalFunc.findIndexByID(temp, data.Obj, 'ServiceType');
                    //         if (_index >= 0) {
                    //             temp[_index] = data.Obj;
                    //         } else {
                    //             temp.unshift(data.Obj);
                    //         }
                    //         lFservice.Set_LF(GlobalFunc.STKey, temp).
                    //             then(value => { if (this.router.url == '/servicetype') { this._dataChanged.serviceTypeChanged.emit(data); } }).
                    //             catch(err => { });
                    //     } else {
                    //         this.httpservice.getData('api/servicetype').subscribe(
                    //             data => temp = data,
                    //             err => { },
                    //             () => {
                    //                 lFservice.Set_LF(GlobalFunc.STKey, temp).
                    //                     then(value => { if (this.router.url == '/servicetype') { this._dataChanged.serviceTypeChanged.emit(data); } }).
                    //                     catch(err => { });
                    //             });
                    //     }
                    // }).catch(err => { });
                    //end ServiceType sync
                    break;
                }
                case 'StockItem': {
                    //start StockItem sync
                    this._dataChanged.stockItemChanged.emit(data);
                    // lFservice.Get_LF(GlobalFunc.SIKey).then(value => {
                    //     if (value == null) return;
                    //     if (data.token == this.auth.authtoken) return;
                    //     temp = value;

                    //     if (data.Msg == 'updated') {
                    //         let _index: number = GlobalFunc.findIndexByID(temp, data.Obj, 'StockItem');
                    //         if (_index >= 0) {
                    //             temp[_index] = data.Obj;
                    //         } else {
                    //             temp.unshift(data.Obj);
                    //         }
                    //         lFservice.Set_LF(GlobalFunc.SIKey, temp).
                    //             then(value => { if (this.router.url == '/stockitem') { this._dataChanged.stockItemChanged.emit(data); } }).
                    //             catch(err => { });
                    //     } else {
                    //         this.httpservice.getData('api/stockitem').subscribe(
                    //             data => temp = data,
                    //             err => { },
                    //             () => {
                    //                 lFservice.Set_LF(GlobalFunc.SIKey, temp).
                    //                     then(value => { if (this.router.url == '/stockitem') { this._dataChanged.stockItemChanged.emit(data); } }).
                    //                     catch(err => { });
                    //             });
                    //     }
                    // }).catch(err => { });
                    //end StockItem sync
                    break;
                }
                case 'LoginUser': {
                    //start LoginUser sync                   
                    this._dataChanged.loginUserChanged.emit(data);
                    break;
                }
                
                case 'Service technician rate': {
                    this._dataChanged.jobTRateChanged.emit(data);
                    break;
                }
                case 'Job': {
                    if (data.Msg === 'deleted') {
                        if (data.Value2.DID === this.auth.DID && data.Value2.ScID === this.auth.ScID) {
                            this._dataChanged.jobChanged.emit(data);
                        }
                    }
                    else {
                        if (data.Obj.DID === this.auth.DID && data.Obj.ScID === this.auth.ScID) {
                            this._dataChanged.jobChanged.emit(data);
                        }
                    }
                    break;
                }
                case 'Service technician status': {
                    if (data.Value2.DID === this.auth.DID && data.Value2.ScID === this.auth.ScID) {
                        this._dataChanged.jobTStatusChanged.emit(data);
                    }
                    break;
                }
                case 'Service manager job status': {
                    if (data.Value2.DID === this.auth.DID && data.Value2.ScID === this.auth.ScID) {
                        this._dataChanged.jobSmStatusChanged.emit(data);
                    }
                    break;
                }
                case 'Job warranty status': {
                    this._dataChanged.jobWarrantyStatusChanged.emit(data);
                    break;
                }
                case 'Service Center Changed': {
                    if (data.Obj) {
                        this._dataChanged.jobServiceCenterChanged.emit(data);
                    }
                    break;
                }
                default: { }
            }
        });
    }

    //built in functions
    onMenuButtonClick(event) {

        this.topbarMenuActive = false;

        if (this.layoutMode === MenuOrientation.OVERLAY) {
            this.overlayMenuActive = !this.overlayMenuActive;
        }
        else {
            if (this.isDesktop())
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            else
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
        }

        event.preventDefault();
    }

    onMenuClick($event) {
        this.menuClick = true;
        this.resetMenu = false;

    }


    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;
        if (this.activeTopbarItem === item)
            this.activeTopbarItem = null;
        else
            this.activeTopbarItem = item;

        event.preventDefault();
    }

    isTablet() {
        let width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.layoutMode === MenuOrientation.OVERLAY;
    }

    isHorizontal() {
        return this.layoutMode === MenuOrientation.HORIZONTAL;
    }

    changeToStaticMenu() {
        this.layoutMode = MenuOrientation.STATIC;
    }

    changeToOverlayMenu() {
        this.layoutMode = MenuOrientation.OVERLAY;
    }

    changeToHorizontalMenu() {
        this.layoutMode = MenuOrientation.HORIZONTAL;
    }

    ngOnDestroy() {
        if (this.documentClickListener) {
            this.documentClickListener();
        }
        if (this.current_route != undefined) this.current_route.unsubscribe();
    }
}