import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';
import { ServiceCenter } from './servicecenter';
import { DataChangedEvent } from '../service/datachangedevent';

@Component({
    selector: 'i-servicecenter',
    templateUrl: './servicecenter.component.html'
})

export class ServiceCenterCP implements OnInit {
    @ViewChild('modal', { static: false }) modal: BsModalComponent;
    @ViewChild('entryModal', { static: false }) entryModal: BsModalComponent;
    @ViewChild('deleteModal', { static: false }) deleteModal: BsModalComponent;

    @ViewChild('input', { static: false }) vc;

    apiName: string = "servicecenter";
    id = 'ScID';
    serviceCenterList: ServiceCenter[] = [];
    isNew: boolean;

    selectedChk: ServiceCenter[] = [];
    selectedService: ServiceCenter;
    actionlogs: any[] = [];

    serviceForm: FormGroup;

    totalRecords: number;
    toastkey = "i-servicecenter";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public httpService: HttpServices, public fb: FormBuilder, public _dataChanged: DataChangedEvent, public _ngZone: NgZone) {
        this.pService.start();
        this._dataChanged.serviceCenterChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === this.apiName.toLowerCase()) {
                        this.afterBroadCastEvent(sRobj);
                    }
                });
            }
        });
    }

    ngOnInit() {
        this.CreateForm();
        //intialize data
        this.GetData();
    }

    afterBroadCastEvent(objData) {
        if (objData && objData.Obj) {
            let ind = this.serviceCenterList.findIndex(temp => temp[this.id] === objData.Obj[this.id]);
            if (objData.Msg === 'deleted') {
                this.refresh();
            }
            else {

                if (ind >= 0) this.serviceCenterList[ind] = objData.Obj;
                else this.serviceCenterList.unshift(objData.Obj);
            }
        }
    }

    GetData(): void {
        let scList = [];
        this.httpService.getData('api/servicecenter').subscribe(
            data => {
                scList = data;
            },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                this.pService.done();
            },
            () => {
                if (scList) {
                    this.serviceCenterList = scList.map(a => Object.assign({}, a));
                    this.totalRecords = this.serviceCenterList.length;
                }
                this.pService.done();
            });
    }

    refresh() {
        this.pService.start();
        this.GetData();
    }

    CreateForm(): void {
        this.serviceForm = this.fb.group({
            'ScID': new FormControl(0),
            'ScN': new FormControl('', Validators.compose([Validators.required, Validators.maxLength(50)])),
            'Addr': new FormControl('')
        });
    }

    showDialogToAdd(): void {
        this.isNew = true;
        this.CreateForm();
        this.entryModal.open();
        this.vc.nativeElement.focus();
    }

    showDialogToEdit(service: ServiceCenter): void {
        this.isNew = false;
        this.CreateForm();
        this.selectedService = service;
        this.serviceForm.patchValue({ ScID: service.ScID, ScN: service.ScN, Addr: service.Addr });
        this.entryModal.open();
        this.vc.nativeElement.focus();
    }

    showDialogToDelete(): void {
        if (this.selectedChk != null && this.selectedChk != undefined && this.selectedChk.length > 0) {
            this.deleteModal.open();
        } else {
            this.httpService.showToast(this.toastkey, 'warn', 'Need to select row!');
        }
    }

    showLogDialog(): void {
        if (this.selectedService != undefined) {
            let temp = [];
            this.httpService.getData('api/actionlog/' + this.selectedService.ScID + '/' + 'ServiceCenter').subscribe(
                data => temp = data,
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                },
                () => {
                    if (temp != undefined) { this.actionlogs = temp; this.modal.open(); }
                    else { this.httpService.showToast(this.toastkey, 'warn', 'There is no record to show!'); }
                });
        }
    }

    save(pdt, gb): void {
        if (this.serviceForm != undefined) {
            this.httpService.postData('api/servicecenter', this.serviceForm.value).
                subscribe(
                    data => { },
                    err => {
                        this.httpService.showToast(this.toastkey, 'error', 'Error occurs while inserting/updating data!');
                        this.pService.done();
                    },
                    () => {
                        this.resetDatable(pdt, gb);
                        this.httpService.showToast(this.toastkey, 'success', 'Successfully inserted/updated');
                        this.entryModal.close();
                    });
        }
    }

    deleteData(): void {
        this.httpService.postData('api/servicecenter/multidelete/', this.selectedChk).
            subscribe(
                data => {
                    this.httpService.showUnsuccessDelMsg(this.toastkey, data);
                },
                err => {
                    this.doneDeleteProcess('error', 'Error occurs while deleting data!');
                },
                () => {
                    this.doneDeleteProcess('success', 'Successfully deleted');
                });
    }

    deleteModalClose() {
        this.deleteModal.close();
        this.httpService.AddClassToModal(true);
    }


    doneDeleteProcess(header: string, msg: string) {
        this.selectedChk = null;
        this.deleteModalClose();
        this.httpService.showToast(this.toastkey, header, msg);
    }

    resetDatable(pdt, gb): void {
        if (gb && gb.value && gb.value != '') {
            gb.value = '';
            if (pdt) pdt.reset();
        }
    }

    modalClose() {
        this.modal.close();
        this.httpService.AddClassToModal(true);
    }

    exportToCSV() {
        this.httpService.postData('api/servicecenter/export', null).subscribe(
            data => { },
            err => { },
            () => {
                //window.location.href = this.httpService._url + 'excel/servicecenter.csv';
                this.httpService.DirectLocToWindow("servicecenter");
            }
        );
    }
}