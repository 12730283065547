import { Component, OnInit, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import {NgProgressService} from "ng2-progressbar";

//Service
import { DataChangedEvent } from '../service/datachangedevent';
import { HttpServices } from '../service/httpservices';
import { GlobalFunc } from '../service/globalfunction';

//Model
import { StockItem } from './stockitem';
import { ServiceType } from '../servicetype/servicetype';
import { AuthService } from '../service/authservice';
import { Brand } from '../brand/brand';


@Component({
    selector: 'i-stockitem',
    templateUrl: './stockitem.component.html'
})
export class StockItemCP implements OnInit, OnDestroy {
    @ViewChild('modal', { static: false }) modal: BsModalComponent;
    @ViewChild('entryModal', { static: false }) entryModal: BsModalComponent;
    @ViewChild('deleteModal', { static: false }) deleteModal: BsModalComponent;
    @ViewChild('input', { static: false }) vc;
    @ViewChild('ServiceTypeChangeModal', { static: false }) ServiceTypeChangeModal: BsModalComponent;
    @ViewChild('BrandChangeModal', { static: false }) BrandChangeModal: BsModalComponent;
    @ViewChild('table', { static: false }) table: Table;
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    
    apiName: string = "stockitem";
    id = 'SID';
    stockItem: StockItem = new StockItem();
    selectedStockItem: StockItem;
    stockForm: FormGroup;
    selectSType: any;

    stockitems: StockItem[] = [];
    selectedChk: StockItem[] = [];
    actionlogs: any[];

    oServiceTypeList: ServiceType[] = [];
    servicetypes: SelectItem[] = [];

    oBrandList: Brand[] = [];
    brandlist: SelectItem[] = [];

    sourcedata: any[] = [];
    totalRecords: number;

    isNew: boolean = false;
    toastkey = "i-stockitem";

    siChanged: any;
    stChanged: any;
    brandChanged: any;

    constructor(private pService: NgProgressService ,public httpService: HttpServices, public fb: FormBuilder, public _dataChanged: DataChangedEvent, public _ngZone: NgZone, public auth: AuthService) {
        this.brandlist = [];
        this.siChanged = this._dataChanged.stockItemChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === this.apiName.toLowerCase()) {
                        this.afterBroadCastEvent(sRobj, this.stockitems, 'SID');
                    }
                });
            }
        });

        this.stChanged = this._dataChanged.serviceTypeChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "servicetype") {
                        this.afterBroadCastEvent(sRobj, this.oServiceTypeList, 'STID');
                    }
                });
            }
        });

        this.brandChanged = this._dataChanged.brandChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "brand") {
                        this.afterBroadCastEvent(sRobj, this.oBrandList, 'BID');
                    }
                });
            }
        });
    }

    ngOnInit() {
        this.CreateForm();
        this.GetRequiredData();
    }

    ngOnDestroy() {
        this.stockItem = this.selectedStockItem = this.stockForm = this.selectSType = this.stockitems = this.selectedChk = this.actionlogs = this.oServiceTypeList =
            this.servicetypes = this.oBrandList = this.brandlist = undefined;

        if (this.siChanged != undefined) this.siChanged.unsubscribe();
        if (this.stChanged != undefined) this.stChanged.unsubscribe();
        if (this.brandChanged != undefined) this.brandChanged.unsubscribe();
    }

    CreateForm(): void {
        this.stockForm = this.fb.group({
            'SID': new FormControl('', Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z0-9-_]*$")])),
            'SN': new FormControl('', Validators.compose([Validators.required])),
            'Cat': new FormControl('', Validators.compose([Validators.required, Validators.maxLength(50)])),
            'STID': new FormControl(null),
            'BID': new FormControl(null),
            'MinQ': new FormControl(0),
            'UN': new FormControl(this.auth.UN),
            'STN': new FormControl(''),
            'BN': new FormControl(''),
            'EFN': new FormControl(null),
            'UploadFile': new FormControl(null)
        });
    }

    GetRequiredData(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let siList: any = [];
            let stList: any = [];
            let bList: any = [];
            this.httpService.getDataFJ(['api/stockitem', 'api/servicetype', 'api/brand']).subscribe(
                data => {
                    siList = data[0];
                    stList = data[1];
                    bList = data[2];
                },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                    resolve(false);
                },
                () => {

                    if (siList) {
                        this.sourcedata = siList.map(si => Object.assign({}, si));
                        this.stockitems = this.sourcedata.map(d => Object.assign({}, d));
                        this.totalRecords = this.sourcedata.length;

                        //if (pdt) { pdt.paginate({ first: tmpfirst, rows: 20 }); }
                    }

                    if (stList) {
                        this.oServiceTypeList = stList.map(d => Object.assign({}, d));
                        this.setServiceTypeData(this.oServiceTypeList);
                    }

                    if (bList) {
                        bList.sort((a, b) => a.BID > b.BID ? 1 : (a.BID < b.BID ? -1 : 0));
                        this.oBrandList = bList.map(d => Object.assign({}, d));
                        this.setBrandData(this.oBrandList);
                    }

                    this.pService.done();
                    resolve(true);
                }
            );
        });
    }

    afterBroadCastEvent(objData, dataList, id) {
        if (objData && objData.Obj && dataList) {
            let ind = dataList.findIndex(temp => temp[id] === objData.Obj[id]);
            if (objData.Msg === 'deleted') {
                if (objData.ObjType.toLowerCase() === "servicetype") {

                    this.httpService.getData('api/servicetype').subscribe(
                        data => { this.oServiceTypeList = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'); 
                        this.pService.done(); 
                    },
                        () => { this.setServiceTypeData(this.oServiceTypeList); 
                            this.pService.done(); 
                        }
                    );

                } else if (objData.ObjType.toLowerCase() === "brand") {

                    this.httpService.getData('api/brand').subscribe(
                        data => { this.oBrandList = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'); 
                        this.pService.done(); 
                    },
                        () => {
                            this.oBrandList.sort((a, b) => a.BID > b.BID ? 1 : (a.BID < b.BID ? -1 : 0));
                            this.setBrandData(this.oBrandList);
                            this.pService.done();
                        }
                    );

                } else if (objData.ObjType.toLowerCase() === this.apiName.toLowerCase()) {

                    this.httpService.getData('api/stockitem').subscribe(
                        data => { this.sourcedata = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'); 
                        this.pService.done(); 
                    },
                        () => {
                            this.stockitems = this.sourcedata.map(d => Object.assign({}, d));
                            this.totalRecords = this.sourcedata.length;
                            this.pService.done();
                        }
                    );

                }
            }
            else {
                if (ind >= 0) dataList[ind] = objData.Obj;
                else dataList.unshift(objData.Obj);

                if (objData.ObjType.toLowerCase() === "servicetype") {
                    this.setServiceTypeData(dataList);
                } else if (objData.ObjType.toLowerCase() === "brand") {
                    this.setBrandData(dataList);
                }
            }
        }
    }

    setServiceTypeData(dataList: ServiceType[]) {
        this.servicetypes = [];
        this.servicetypes.push({ label: 'Select a service type', value: null });
        if (dataList) {
            dataList.map(x => { this.servicetypes.push({ label: x.STN, value: x.STID }); });
        }
    }

    setBrandData(dataList: Brand[]) {
        this.brandlist = [];
        this.brandlist.push({ label: 'Select a brand', value: null });
        if (dataList) {
            dataList.map(x => { this.brandlist.push({ label: x.BN, value: x.BID }); });
        }
    }

    refresh() {
        this.pService.start();
        this.GetRequiredData();
    }

    uploadFile(): void {
        if (!this.stockItem.UploadFile) return;

        if (this.stockItem.EFN != '' || this.stockItem.EFN) this.stockItem.EFN = '';

        this.pService.start();
        this.httpService.postData('api/stockitem/uploadfile', this.stockItem).subscribe(
            ddd => { },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while inserting/updating data!');
               this.pService.done();
            },
            () => {
                this.pService.done();
                this.httpService.showToast(this.toastkey, 'success', 'Successfully uploaded');
                this.stockItem.UploadFile = null;
                this.stockItem.EFN = null;
                this.GetRequiredData();
            });
    }

    save(): void {
        if (!this.stockForm.value) return;

        if (this.stockForm) {
            if (!this.stockForm.controls['MinQ'].value) {
                this.stockForm.controls['MinQ'].setValue(0);
            }
            this.httpService.postData('api/stockitem', this.stockForm.value).subscribe(
                ddd => { },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while inserting/updating data!');
                    this.pService.done();
                },
                () => {
                    let tmpstn = '', tmpbn = '';
                    if (this.stockForm.controls['STID'].value) {
                        let tmpidx = this.servicetypes.findIndex(x => x.value === this.stockForm.controls['STID'].value);
                        if (tmpidx >= 0) tmpstn = this.servicetypes[tmpidx].label;
                    }
                    if (this.stockForm.controls['BID'].value) {
                        let _tmpidx = this.brandlist.findIndex(x => x.value === this.stockForm.controls['BID'].value);
                        if (_tmpidx >= 0) tmpbn = this.brandlist[_tmpidx].label;
                    }

                    let idx = this.sourcedata.findIndex(x => x.SID === this.stockForm.controls['SID'].value);
                    if (idx >= 0) {
                        this.sourcedata[idx].SID = this.stockForm.controls['SID'].value;
                        this.sourcedata[idx].SN = this.stockForm.controls['SN'].value;
                        this.sourcedata[idx].Cat = this.stockForm.controls['Cat'].value;
                        this.sourcedata[idx].STID = this.stockForm.controls['STID'].value;
                        this.sourcedata[idx].BID = this.stockForm.controls['BID'].value;
                        this.sourcedata[idx].MinQ = this.stockForm.controls['MinQ'].value;
                        this.sourcedata[idx].UN = this.stockForm.controls['UN'].value;

                        this.sourcedata[idx].STN = this.stockForm.controls['STID'].value ? tmpstn : '';
                        this.sourcedata[idx].BN = this.stockForm.controls['BID'].value ? tmpbn : '';

                        this.sourcedata[idx].EFN = this.stockForm.controls['EFN'].value;
                        this.sourcedata[idx].UploadFile = this.stockForm.controls['UploadFile'].value;
                    }
                    else {
                        this.sourcedata.unshift({
                            'SID': this.stockForm.controls['SID'].value,
                            'SN': this.stockForm.controls['SN'].value,
                            'Cat': this.stockForm.controls['Cat'].value,
                            'STID': this.stockForm.controls['STID'].value,
                            'BID': this.stockForm.controls['BID'].value,
                            'MinQ': this.stockForm.controls['MinQ'].value,
                            'UN': this.auth.UN,
                            'STN': tmpstn,
                            'BN': tmpbn,
                            'EFN': null,
                            'UploadFile': null
                        });

                        this.totalRecords += 1;
                    }

                    this.httpService.showToast(this.toastkey, 'success', 'Successfully inserted/updated');
                    this.entryModal.close();
                }
            );
        }
    }

    stockItem_ModelChange(event): void {
        if (this.stockitems) {
            let temp: StockItem = new StockItem();
            let idx = this.stockitems.findIndex(x => x.SID === event);
            if (idx >= 0) {
                this.isNew = false;
                this.stockForm.patchValue({ 'SN': this.stockitems[idx].SN, 'Cat': this.stockitems[idx].Cat, 'STID': this.stockitems[idx].STID, 'BID': this.stockitems[idx].BID, 'MinQ': this.stockitems[idx].MinQ });
            }
            else {
                this.stockForm.patchValue({ 'SN': '', 'Cat': '', 'STID': null, 'BID': null, 'MinQ': 0 });
            }
        }
    }

    showDialogToAdd(): void {
        this.stockForm.controls['SID'].enable({ onlySelf: false });
        this.isNew = true;
        this.CreateForm();
        this.entryModal.open();
        this.vc.nativeElement.focus();
    }

    showDialogToEdit(rdata, ridx): void {
        this.isNew = false;
        this.selectedStockItem = rdata;
        this.stockForm.patchValue({
            SID: rdata.SID,
            SN: rdata.SN,
            Cat: rdata.Cat,
            STID: rdata.STID,
            BID: rdata.BID,
            MinQ: rdata.MinQ,
            UN: rdata.UN,
            STN: rdata.STN,
            BN: rdata.BN,
            EFN: rdata.EFN,
            UploadFile: rdata.UploadFile
        });
        this.entryModal.open();
        this.vc.nativeElement.focus();
    }

    showDialogToDelete(): void {
        if (this.selectedChk != null && this.selectedChk != undefined && this.selectedChk.length > 0) {
            this.deleteModal.open();
        } else {
            this.httpService.showToast(this.toastkey, 'warn', 'Need to select row!');
        }
    }

    showLogDialog(): void {
        if (this.selectedStockItem != undefined) {
            let temp = [];
            this.httpService.getData('api/actionlog/' + this.selectedStockItem.SID + '/' + 'StockItem').subscribe(
                data => temp = data,
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                },
                () => {
                    if (temp != undefined) { this.actionlogs = temp; this.modal.open(); }
                    else { this.httpService.showToast(this.toastkey, 'warn', 'There is no record to show!'); }
                });
        }
    }

    onChange(evt) {
        var files = evt.target.files;
        var file = files[0];
        if (files && file) {
            var reader = new FileReader();
            reader.onload = (function (file) {
                return file;
            })(this.stockItem.EFN = file.name);

            reader.onloadend = () => {
                this.stockItem.UploadFile = btoa(reader.result.toString());
                this.stockItem.EFN = file.name;
            }
            reader.readAsBinaryString(file);
        }
    }
    
    deleteData(): void {
        this.httpService.postData('api/stockitem/multidelete/', this.selectedChk).
            subscribe(
                data => {
                    this.httpService.showUnsuccessDelMsg(this.toastkey, data);
                },
                err => {
                    this.doneDeleteProcess('error', 'Error occurs while deleting data!');
                },
                () => {
                    this.doneDeleteProcess('success', 'Successfully deleted');
                });
    }

    deleteModalClose() {
        this.deleteModal.close();
        this.httpService.AddClassToModal(true);
    }

    doneDeleteProcess(header: string, msg: string) {
        this.selectedChk = null;
        this.deleteModalClose();
        this.httpService.showToast(this.toastkey, header, msg);
    }

    modalClose() {
        this.modal.close();
        this.httpService.AddClassToModal(true);
    }

    exportToCSV() {
        this.httpService.postData('api/stockitem/export', null).subscribe(
            data => { },
            err => { },
            () => {
                //window.location.href = this.httpService._url + 'excel/stockitem.csv';
                this.httpService.DirectLocToWindow("stockitem");
            }
        );
    }
}