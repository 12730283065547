import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

//Third Library
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';
import { AuthService } from '../service/authservice';

//Model
import { JobViewModel } from '../joblist/jobviewmodel';
import { MCB } from '../job/mcb';

@Component({
    selector: 'i-jobprintview',
    templateUrl: './jobprintview.component.html'
})

export class JobPrintViewCP implements OnInit, OnDestroy {
    public jvm: JobViewModel;
    public _listOfMon: MCB[] = [];
    public jid: string;
    public sub: any;
    public toastkey = "i-jobprintview";

    public Qrdata: string = '';
   // @ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public httpService: HttpServices, public currentRoute: ActivatedRoute, public router: Router, public loca: Location, public auth: AuthService) {
        this.sub = this.currentRoute.params.subscribe(param => {
            this.jid = param['jid'];
        });
        this.jvm = new JobViewModel();
    }

    ngOnInit() {
        this.GetJobByID();
    }
    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    printjob() {
        window.print();
    }

    goBack() {
        this.loca.back();
    }

    GetJobByID() {
        this.httpService.getData('api/jobprintview/' + this.jid).subscribe(
            data => {
                this.jvm = data;
                this.Qrdata = 'http://view.linnservice.com/#/jobdetail/' + this.jvm.JID;//this.jvm.JIDR;
            },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!');
                this.pService.done();
            },
            () => {
                this.jvm.Addr = this.auth.Addr;
                this.jvm.ScN = this.auth.ScN;
            });
    }
}