import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Thrid Library
import { Location } from '@angular/common';
import {NgProgressService} from "ng2-progressbar";
import { Observable } from 'rxjs/Rx';

//Service
import { HttpServices } from '../service/httpservices';

//Model
import { JobVoucherPrint } from './jobvoucherprint';

@Component({
    selector: 'i-customertaken',
    templateUrl: './customertaken.component.html'
})

export class CustomerTakenCP implements OnInit, OnDestroy {
    @Input() job;
    @Output() onClick = new EventEmitter<boolean>();
    @Input() JVPs;
    @Input() technicianName;

    public jobVP: JobVoucherPrint;
    public BAmo: number = 0;
    public printMode: boolean = false;
    public techName: string = '';
    public sub: any;
    public source_comp: string = '';
    public isSaveAndNotiClick: boolean = false;

    public customer: any = null;
    public takenDate: Date;

    public printObj: any;
    public toastkey = "i-customertaken";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;

    constructor(private pService: NgProgressService ,public httpService: HttpServices, public currentRoute: ActivatedRoute, public router: Router, public location: Location) {
        this.jobVP = new JobVoucherPrint(null, null, null, 0);
        this.isSaveAndNotiClick = false;
        this.BAmo = 0;
        this.takenDate = new Date();

        this.printObj = { JID: null, JIDR: null, JOB: '', TAmo: 0, DAmo: 0, Crec: 0, Chg: 0, CN: '', Ph: '', Addr: '', jobVoucherData: null, MCB: '', MoID: null }
    }

    ngOnInit() {
        this.isSaveAndNotiClick = false;
        if (this.job !== undefined || this.job !== null) {
            this.calculateAmount();
        }
    }

    ngOnDestroy() { }

    calculateAmount(): void {
        if (this.job != undefined || this.job != null) {
            this.BAmo = this.job.TAmo - this.job.DAmo;
            this.job.Chg = this.BAmo - this.job.Crec;
        }
    }

    onclick(isSaving: boolean) {
        this.isSaveAndNotiClick = true;
        if (isSaving) {
            if (!this.printMode) {
                this.printMode = true;
            }

            //Update Data
            this.job.SStat = 'd';
            this.job.Taken = true;
            //this.job.TDT=Date.now();

            this.pService.start();
            this.httpService.postData('api/job/customertaken', this.job).subscribe(
                data => { },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while updating data!');
                    this.pService.done();
                },
                () => {
                    this.pService.done();
                    if (this.job.FOC) this.onClick.emit();
                    else {
                        //window.print();
                        this.router.navigate(['/custakenvprint', this.job.JID]);
                        //this.onClick.emit();
                    }
                });
        }
        else {
            this.onClick.emit();
            //this.location.back();
        }
    }

    addJobVPData(): void {
        this.jobVP.JID = this.job.JID;
        this.jobVP.LID = 0;
        if (this.jobVP.Des == null) this.jobVP.Des = '';

        let tmp: JobVoucherPrint;
        this.httpService.postData('api/addJobVoucherPrint', this.jobVP).subscribe(
            data => tmp = data,
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while adding job_voucher_print data !');
                this.pService.done();
            },
            () => {
                // var idx = this.JVPs.indexOf(x => { x.JID == tmp.JID && x.LID == tmp.LID });
                // if (idx < 0) this.JVPs.push(tmp);
                // else this.JVPs.splice(idx, 1, tmp);
                this.JVPs.push(tmp);
            });
    }

    deleteDes(jobvp): void {
        let tmp: JobVoucherPrint;
        this.httpService.postData('api/jobVoucherPrintdelete', jobvp).subscribe(
            data => tmp = data,
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while deleting job_voucher_print data !');
                this.pService.done();
            },
            () => {
                var idx = this.JVPs.findIndex(x => x.LID == tmp.LID);
                this.JVPs.splice(idx, 1);
            });
    }

    CheckChangeFOC() {
        if (this.job.FOC) {
            this.job.Crec = 0;
            this.calculateAmount();
        }
    }

    getPrintPreViewData() {
        this.httpService.getData('api/getjobvoucherdata/' + this.job.JID).subscribe(
            data => { this.printObj = data; },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while getting data!');
                this.pService.done();
            },
            () => {
                let tmp = Observable.timer(50, 1).subscribe(rps => {
                    tmp.unsubscribe();
                    this.printMode = true;
                });
            }
        );
    }

    printModeChange() {
        this.getPrintPreViewData();
    }
}