import { Component, OnInit, ViewChild, NgZone } from '@angular/core';

// Thrid Library
import {NgProgressService} from "ng2-progressbar";

//Service
import { DataChangedEvent } from '../service/datachangedevent';
import { HttpServices } from '../service/httpservices';

//Model
import { Job } from '../job/job';
import { MCB } from '../job/mcb';

@Component({
    selector: 'i-dashboardtech',
    templateUrl: './dashboardtech.component.html'
})
export class DashBoardTechCP implements OnInit {
    public _listOfMon: MCB[] = [];
    public jobs: Job[] = [];
    public toastkey = "i-dashboardtech";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService , public httpService: HttpServices, public _dataChanged: DataChangedEvent, public _ngZone: NgZone) {
        this.pService.start();
        this._dataChanged.jobTStatusChanged.subscribe((changedData) => {
            //call associated function..
            //this.GetTechData();
            this.GetRequiredData();
        });
        this._dataChanged.jobSmStatusChanged.subscribe((changedData) => {
            //service manager status done is not concern with technician.
            if (changedData.Value1 == null) {
                //this.GetTechData();
                this.GetRequiredData();
            }
        });
    }

    ngOnInit() {
        this.GetRequiredData();
    }

    getmcbName(moid: number): string {
        if (this._listOfMon == undefined) return "";
        
        for(let obj of this._listOfMon){
            if (obj.MoID == moid) {
                return obj.MoN + " / " + obj.CatN + " / " + obj.BN;
            }
        }
    }

    GetRequiredData(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let mcbList: any = [];
            let dtList: any = [];

            this.httpService.getDataFJ(['api/job/getmcb','api/dashboardtech']).subscribe(
                data => {
                    mcbList = data[0];
                    dtList = data[1];
                },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                    resolve(false);
                },
                () => {

                    if(mcbList){
                        this._listOfMon = mcbList.map(m => Object.assign({},m));
                    }

                    if(dtList){
                        this.jobs = dtList.map(dt => Object.assign({},dt));
                    }

                    this.pService.done();
                    resolve(true);
                }
            );
        });
    }
}
