import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Thrid Library
import { SelectItem } from 'primeng/api';
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';

//Model
import { StockTransfer } from './stocktransfer';
import { ServiceCenter } from '../servicecenter/servicecenter';

@Component({
    selector: 'i-createstocktransfer',
    templateUrl: './createstocktransfer.component.html'
})
export class CreateStockTransferCP implements OnInit {
    public stockTransfer: StockTransfer;
    public deptllist: SelectItem[];
    public servicecenters: ServiceCenter[];
    public servicecenterlist: SelectItem[];
    public sub: any;
    public toastkey = "i-createstocktransfer";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    
    constructor(private pService: NgProgressService ,public currentRoute: ActivatedRoute, public httpService: HttpServices, public router: Router) {
        this.pService.start();
        this.stockTransfer = new StockTransfer(null, null, null, null, null, null, null, null);
        this.stockTransfer.TDT = new Date().toLocaleDateString();
        this.sub = this.currentRoute.params.subscribe(param => {
            this.stockTransfer.TID = param['tid'];
        });
    }

    ngOnInit() {
        this.GetServiceCenter();
        if (this.stockTransfer.TID != undefined) {
            this.getTransfer();
        }else{
           this.pService.done();
        }
    }

    getTransfer() {
        this.httpService.getData('api/getstocktransfer/' + this.stockTransfer.TID).subscribe(
            data => {
                this.stockTransfer = data;
                this.stockTransfer.TDT = new Date(data.TDT).toLocaleDateString();
            },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!');
                this.pService.done();
            },
            () => { this.pService.done(); 
            });
    }

    GetServiceCenter(): void {
        this.httpService.getData('api/servicecenter').subscribe(
            data => this.servicecenters = data,
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                this.pService.done();
            },
            () => {
                this.servicecenterlist = [];
                this.servicecenterlist.push({ label: 'Select Service Center', value: null });
                if (this.servicecenters) {
                    this.servicecenters.map(sc => this.servicecenterlist.push({ label: sc.ScN, value: sc.ScID }));
                }
            });
    }

    CreateStockTransfer(): void {
        if (this.stockTransfer != undefined) {
            this.httpService.postData('api/stocktransfer', this.stockTransfer).
                subscribe(
                    data => { this.stockTransfer.TID = data.TID; },
                    err => {
                        this.httpService.showToast(this.toastkey, 'error', 'Error occurs while inserting/updating data!');
                        this.pService.done();
                    },
                    () => { this.goToStockTransferDetail(); });
        }
    }

    goToStockTransferDetail(): void {
        let link = ['/stocktransferdetail', this.stockTransfer.TID];
        this.router.navigate(link);
    }
}