import { Component, OnInit, ViewChild, NgZone, OnDestroy, AfterViewInit,HostListener } from '@angular/core';
import { Router } from '@angular/router';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import { Observable } from 'rxjs';
import { Table } from 'primeng/table';
import {NgProgressService} from "ng2-progressbar";

//Service
import { DataChangedEvent } from '../service/datachangedevent';
import { lFservice } from '../service/lFservice';
import { HttpServices } from '../service/httpservices';
import { AuthService } from '../service/authservice';
import { GlobalFunc } from '../service/globalfunction';

//Model
import { JobListQuery } from '../joblist/joblistquery';
import { JobViewModel } from '../joblist/jobviewmodel';
import { Customer } from '../customer/customer';
import { Job } from '../job/job';
import { ServiceCenter } from '../servicecenter/servicecenter';

@Component({
    selector: 'i-joblist',
    templateUrl: './joblist.component.html'
})
export class JobListCP implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('entryModal', { static: false }) entryModal: BsModalComponent;
    @ViewChild('confirmModal', { static: false }) confirmModal: BsModalComponent;
    @ViewChild('jobdoneModal', { static: false }) jobdoneModal: BsModalComponent;
    @ViewChild('jobModal', { static: false }) jobModal: BsModalComponent;
    @ViewChild('customerTakenModal', { static: false }) customerTakenModal: BsModalComponent;
    @ViewChild('serviceCenterModal', { static: false }) serviceCenterModal: BsModalComponent;
    @ViewChild('rsm', { static: false }) rsm;
    @ViewChild('pdt', { static: false }) pdt;
    @ViewChild('confirmSMDoneModal', { static: false }) confirmSMDoneModal: BsModalComponent;
    @ViewChild('table', { static: false }) table: Table;

    @ViewChild('UnRepairModal', { static: false }) UnRepairModal: BsModalComponent;

    public jobs: JobViewModel[] = [];
    public joblistquery: JobListQuery;
    public printMode: boolean = false;
    public techDoneRadioVal: string;
    public jobv: JobViewModel;
    public customer: Customer;
    public srObjT: any;
    public srObjSM: any;
    public srObjJ: any;
    public srCenterChanged;
    public pRadioReChk: boolean = false;

    public job: any;
    public jobmodaltitle = '';
    public showJobDoneDialog: boolean = false;
    public jobVPs: any[] = [];
    public techName: string = '';
    public tmpdate: Date;
    public tmpjv: JobViewModel;
    public smDoneRadioVal: string;
    public filteredjobs: any[] = [];
    public selectedJobStatus: string = '';
    public tmpscid: number;
    public sourcedata: any[] = [];
    public totalRecords: number;
    public subobj: any;
    public showTaken: boolean;
    public showServiceCenter: boolean;
    public toastkey = "i-joblist";

    public jid: string;
    public showJobModal: boolean;

    public jobstatusddown = [
        { label: 'Service Manager Done', value: 'Service Manager Done' },
        { label: 'Technician Done', value: 'Technician Done' },
        // { label: 'Hardware YGN', value: 'Hardware YGN' },
        // { label: 'Hardware MDY', value: 'Hardware MDY' },
        { label: 'Waiting Part', value: 'Waiting Part' },
        // { label: 'Part Done', value: 'Part Done' },
        { label: 'Waiting Customer Reply', value: 'Waiting Customer Reply' },
        { label: 'Waiting Warranty', value: 'Waiting Warranty' },
        { label: 'Work In Progress', value: 'Work In Progress' },
        { label: 'Unable to repair', value: 'Unable to repair' },
        { label: 'Customer Cancel', value: 'Customer Cancel' }
    ];
    public _searchdata: string = '';

    public statusVal: string;
    public reAssigCheck: boolean;
    public technicians: any[];
    public assignedTechs: any[];
    public jobShift: any;
    public tsourcedata: any[];
    public scsourcedata: ServiceCenter[];
    public servicecenterlist: any[] = [];

    public searchType: string = '';

    // public tmpScId : string = '';
    // public scddown: SelectItem[] = [];
    // public scList;

    jobRemark: any;
    isExpWidth: boolean;
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public httpService: HttpServices, public router: Router, public auth: AuthService, public _ngZone: NgZone, public _dataChanged: DataChangedEvent) {
        // router.navigateByUrl("www.linnservice.com/currentjob.csv");

        //this.scddown.push({ value: null, label: '- Select Service Center -' });

        this.showTaken = false;
        this.jobv = new JobViewModel();
        this.customer = new Customer();
        this._searchdata = null;


        //this.selectedJobStatus = this.jobstatusddown[0].value;
        //this.joblistquery = new JobListQuery('c', 'a', new Date(), new Date(), this.auth.ScID == null ? -1 : this.auth.ScID, this.auth.DID == null ? -1 : this.auth.DID);
        this.joblistquery = new JobListQuery(this.selectedJobStatus, 'a', new Date(), new Date(), this.auth.ScID == null ? -1 : this.auth.ScID, this.auth.DID == null ? -1 : this.auth.DID);

        this.statusVal = '';
        this.reAssigCheck = false;
        this.technicians = [];
        this.assignedTechs = [];
        this.jobShift = { JID: null, JAT1: null, Status: '' };
        this.tsourcedata = [];

        /// service center Changed
        this.srCenterChanged = _dataChanged.jobServiceCenterChanged.subscribe(
            srDataObj => {
                if (this.selectedJobStatus === srDataObj.Obj.JobState) {
                    this._ngZone.run(() => {
                        this.RefreshData();
                    });
                }
            },
            err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!')
        )

        ////job changed
        this.srObjJ = _dataChanged.jobChanged.subscribe(
            srDataJ => {
                if (srDataJ.Msg === 'deleted' && srDataJ.Value2 && this.selectedJobStatus === srDataJ.Value2.JobState && this.auth.ScID === srDataJ.Value2.ScID) {
                    this._ngZone.run(() => {
                        this.RefreshData();
                    });
                }
                else if (srDataJ.Obj && this.selectedJobStatus === srDataJ.Obj.JobState && this.auth.ScID === srDataJ.Obj.ScID) {
                    this._ngZone.run(() => {
                        this.RefreshData();
                    });
                }
            },
            err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!')
        );
        ////technician status changed
        this.srObjT = _dataChanged.jobTStatusChanged.subscribe(
            srDataT => {
                if (srDataT.Value2 && this.auth.ScID == srDataT.Value2.ScID) {
                    this._ngZone.run(() => {
                        this.RefreshData();
                    });
                }
            },
            err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!') }

        );
        ////service manager status changed
        this.srObjSM = _dataChanged.jobSmStatusChanged.subscribe(
            srDataSM => {
                //need to modify Emit Data in app.component
                if (srDataSM.Value2 && this.auth.ScID == srDataSM.Value2.ScID) {
                    this._ngZone.run(() => {
                        this.RefreshData();
                    });
                }
            },
            err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!') }
        );
        ////loginuser chnaged
        this.srObjSM = _dataChanged.loginUserChanged.subscribe(
            srLU => {
                this._ngZone.run(() => {
                    this.Get_ServiceTechnician();
                });
            },
            err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  loginuser(Technicians) from server!') }
        );
    }

    ngOnInit() {
        this.getLocalJobStatus().then(exp=>{
            if(exp){
                this.RefreshData();
            }
        });
        
        this.Get_ServiceTechnician();
        this.Get_ServiceCenter();
        this.searchType = 'JID';
        if (this.auth != null || this.auth != undefined) this.tmpscid = this.auth.ScID;
    }

    ngAfterViewInit(){
        this.isExpWidth = window.innerWidth < 700;
    }

    ngOnDestroy() {
        if (this.srObjT != undefined) this.srObjT.unsubscribe();
        if (this.srObjSM != undefined) this.srObjSM.unsubscribe();
        if (this.srObjJ != undefined) this.srObjJ.unsubscribe();
        if (this.srCenterChanged != undefined) this.srCenterChanged.unsubscribe();
    }

    @HostListener('window:resize', ['$event'])
    resize(e) {
        this.isExpWidth = window.innerWidth < 700;
    }

    getLocalJobStatus(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            lFservice.Get_LF("currentjobs_jobstatus").then(value => {
                if (value == null) {
                    this.selectedJobStatus = this.jobstatusddown[0].value;
                }
                else {
                    this.selectedJobStatus = value;
                }
                resolve(true);
            }).catch(err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data!');
                resolve(false);
            });
        });
    }


    in_upd_Bcast(_jvm, notjobchange, val1 = null) {
        let index = this.jobs.findIndex(j => j.JID === _jvm.JID);
        if (index >= 0) {
            this.jobs[index] = _jvm;
        } else {
            this.jobs.unshift(_jvm);
        }

        this.jobs = this.jobs.filter(j => j.Taken === false);
        lFservice.Set_LF(GlobalFunc.CJKey, this.jobs);

        if (val1) {
            this.httpService.getData('api/getjobshift/' + _jvm.JID).subscribe(
                data => {
                    lFservice.Set_LF(_jvm.JID, data);
                },
                err => {
                },
                () => {
                });
        }


        if (this.selectedJobStatus === _jvm.JobState) {

            let idx = this.sourcedata.findIndex(x => x.JID === _jvm.JID);
            if (idx >= 0) {
                this.sourcedata[idx] = _jvm;
                this.sourcedata = this.sourcedata.filter(j => j.Taken === false); // not good

                let idx1 = this.filteredjobs.findIndex(xx => xx.JID === _jvm.JID);
                if (idx1 >= 0) {
                    this.filteredjobs[idx1] = _jvm;
                    this.filteredjobs = this.filteredjobs.filter(j => j.Taken === false); // not good
                    let btn = document.getElementById('customerSpan');
                    if (btn) {
                        btn.click();
                    }
                }
            }
            else {
                this.sourcedata.unshift(_jvm);
                this.sourcedata = this.sourcedata.filter(j => j.Taken === false); // not good

                this.sourcedata.sort((a, b) => a.JDT < b.JDT ? 1 : (b.JDT < a.JDT ? -1 : 0));

                if (this.table && this.table['first'] === 0) {
                    this.filteredjobs.unshift(_jvm);
                    this.filteredjobs = this.filteredjobs.filter(j => j.Taken === false); // not good


                    let btn = document.getElementById('customerSpan');
                    if (btn) {
                        btn.click();
                    }
                }

                this.totalRecords = this.sourcedata.length;
            }
        }
        else {
            if (notjobchange) {
                let idx = this.sourcedata.findIndex(x => x.JID === _jvm.JID);
                if (idx >= 0) {
                    this.sourcedata.splice(idx, 1);
                    this.sourcedata = this.sourcedata.filter(j => j.Taken === false); // not good

                    let idx1 = this.filteredjobs.findIndex(x => x.JID === _jvm.JID);
                    if (idx1 >= 0) {
                        this.filteredjobs.splice(idx1, 1);
                        this.filteredjobs = this.filteredjobs.filter(j => j.Taken === false); // not good

                        let btn = document.getElementById('customerSpan');
                        if (btn) {
                            btn.click();
                        }
                    }
                    this.totalRecords = this.sourcedata.length;
                }
            }
            else {
                this.filterJobs(this.selectedJobStatus);
            }
        }
    }

    RefreshData() {
        this.joblistquery.Type = this.selectedJobStatus;

        this.pService.start();
        this.httpService.postData('api/currentjoblist', this.joblistquery).subscribe(
            data => {
                this.jobs = data[0] || [];
                this.jobs = this.jobs.filter(j => j.Taken === false); // not good
                this.jobRemark = data[1];
                lFservice.Set_LF(GlobalFunc.CJKey, this.jobs).then(value => { }).catch(err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while setting data into Local!'));

                this.filterJobs(this.selectedJobStatus);
                this.pService.done();
            },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  current jobs from server!');
                this.pService.done();
            },
            () => {

            }
        );
    }

    filterJobs(jobstatus = undefined) {
        this.sourcedata = [];
        this.filteredjobs = [];
        let tempJobs = this.jobs.filter(j => j.Taken === false && j.JobState === jobstatus); // not good
        this.sourcedata = tempJobs.map(j => Object.assign({}, j));
        this.sourcedata.sort((a, b) => a.JDT < b.JDT ? 1 : (b.JDT < a.JDT ? -1 : 0));
        this.totalRecords = this.sourcedata.length;
        //this.filteredjobs = this.sourcedata.slice(0, 20);       
        this.filteredjobs = this.sourcedata.map(a => Object.assign({}, a));
        let tmp = this.filteredjobs.filter(obj => !obj.CNPh && !obj.MoNSTN);
        if (tmp.length > 0) {
            this.RefreshData();
        }

        //if (this.table) this.table.paginate({ first: 0, rows: 20 });
    }

    jobStatusChanged(event) {
        if (!event) return;
        this.selectedJobStatus = event.value;
        this.searchType = 'JID';
        this._searchdata = '';

        lFservice.Set_LF('currentjobs_jobstatus', this.selectedJobStatus);
        this.RefreshData();
        //this.filterJobs(event.value);
    }

    goSMDone(jv: JobViewModel) {
        this.jobv = jv;
        this.smDoneRadioVal = '';
        this.confirmSMDoneModal.open();
    }
    gotoCusTaken() {
        this.confirmSMDoneModal.close();
        this.changeJobViewToJob(this.jobv);
        this.getJobDescription();
        this.getTechName();
        this.showTaken = true;
        this.customerTakenModal.open();
    }

    onClickCusTake() {
        this.showTaken = false;
        this.customerTakenModal.close();
        if (this.job != undefined) this.job = undefined;
    }

    gotoServiceCenter(jv: JobViewModel = undefined) {
        if (jv) {//for work in process chnaged service center
            this.jobv = jv;
        }
        this.confirmSMDoneModal.close();
        this.showServiceCenter = true;
        this.serviceCenterModal.open();
    }

    onClickServiceCenter() {
        this.httpService.postData('api/jobs/UpdateServiceCenter/' + this.jobv.JID + '/' + this.tmpscid, null).subscribe(
            data => {
                // this.RefreshData();
                this.showServiceCenter = false;
                this.serviceCenterModal.close();
                if (this.job != undefined) this.job = undefined;
            },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while updating data!');
            },
        );
    }

    goTechDoneBySM(jv: JobViewModel): void {
        if (jv != undefined || this.jobv != undefined) {
            this.jobv = jv;
            this.techDoneRadioVal = '';
            this.confirmModal.open();
            this.pRadioReChk = false;
        }
    }

    gotoCreateJob() {
        // this.jobmodaltitle = 'Create Job';
        // this.showJobModal = true;
        // this.jid = null;
        // this.jobModal.open();

        this.router.navigate(['/createjob']);

    }

    goEdit(jv): void {
        this.jobmodaltitle = 'Edit Job';
        this.jid = jv.JID;
        this.showJobModal = true;
        this.jobModal.open();
    }

    supStatusChanges(): void {
        this.confirmModal.close();
        this.confirmSMDoneModal.close();
        if (this.jobv) {
            if (this.techDoneRadioVal == "Re-Check" || this.smDoneRadioVal == "Re-Check") {
                this.jobv.SStat = 'b';
                this.jobv.TStat = 'a';
                this.jobv.SDnDT = null;
                this.httpService.postData('api/jobsupervisorrecheck2', this.jobv).subscribe(
                    data => {
                        this.smDoneRadioVal = this.techDoneRadioVal = '';
                    },
                    err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while updating data!'),
                    () => {
                    });
            }
            else {
                this.showJobDoneDialog = true;
                this._ngZone.run(() => {
                    this.jobv.SStat = 'c';
                    this.jobv.SDnDT = new Date();
                    this.getCustomerByID();
                    this.jobdoneModal.open();
                });
            }
        }
    }

    ReceiveEmitClick() {
        this.jobdoneModal.close();
        this.showJobDoneDialog = false;
        this.jobv.JobState = 'Service Manager Done';
        this._ngZone.run(() => { this.in_upd_Bcast(this.jobv, true); });
    }

    onClickEmit(event) {
        this.showJobModal = false;
        this.jobModal.close();
        if (this.jid != undefined) this.jid = undefined;
        this.searchType = 'JID';
        this._searchdata = '';
    }

    canEdit(): boolean {
        if (this.printMode) return true;
        else {
            if (this.joblistquery.Type != 'd') return false;
            else return true;
        }
    }

    ngModChg(result: boolean): void {
        this.pRadioReChk = result;
        if (result) {
            let timerSub = Observable.timer(50, 1000).subscribe(t => { this.rsm.nativeElement.focus(); }, err => { }, () => { timerSub.unsubscribe(); });
        }
    }

    goSearch(event) {
        if (this._searchdata === '') {
            this.filterJobs(this.selectedJobStatus);
        }
        if (event.keyCode === 13) {
            if (this._searchdata === '') { this.filterJobs(this.selectedJobStatus); }
            else {
                if (this.searchType === 'JID') {
                    this.filteredjobs = this.sourcedata.filter(job => (job.JIDR.trim()).toLowerCase().indexOf(this._searchdata.toLowerCase().trim()) !== -1);
                }
                else if (this.searchType === 'IMEI') {
                    this.filteredjobs = this.sourcedata.filter(job => (job.Imei.trim()).toLowerCase().indexOf(this._searchdata.toLowerCase().trim()) !== -1);
                } else {
                    this.filteredjobs = this.sourcedata.filter(job => (job.Ph.trim()).toLowerCase().indexOf(this._searchdata.toLowerCase().trim()) !== -1);
                }

                this.totalRecords = this.filteredjobs.length;
            }
        }
    }

    printJobFun() {
        if (this.jobv != undefined || this.jobv != null) {
            let link = ['/jobprintview', this.jobv.JID];
            this.router.navigate(link);
        }
    }
    printjob(): void {
        window.print();
    }

    exportToCSV() {
        this.httpService.postData('api/exportcurrentjoblist', this.joblistquery).subscribe(
            data => { },
            err => { },
            () => {
                //window.location.href = this.httpService._url + 'excel/currentjobs.csv';
                this.httpService.DirectLocToWindow("currentjobs");
            }
        );
    }
    getCustomerByID(): void {
        if (this.jobv.CID != undefined || this.jobv.CID != null) {
            let temp;
            this.pService.start();
            this.httpService.getData('api/getcustomer/' + this.jobv.CID).subscribe(
                data => temp = data,
                err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'),
                () => {
                    if (temp != undefined) {
                        this.customer = temp;
                    }
                    this.pService.done();
                }
            );
        }
    }
    getJobDescription(): void {
        if (this.job.JID != undefined || this.job.JID != null || this.job.JID != '') {
            this.jobVPs = [];
            this.pService.start();
            this.httpService.postData('api/getJobDescription', this.job.JID).subscribe(
                data => { this.jobVPs = data; },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading job_voucher_print data !');
                },
                () => {
                    this.pService.done();
                }
            );
        }
    }
    getTechName(): void {
        if (this.job.JAT1 != undefined || this.job.JAT1 != null || this.job.JAT1 != '') {
            this.pService.start();
            this.httpService.getData('api/gettechname/' + this.job.JAT1).subscribe(
                data => { this.techName = data },
                err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while getting technician name !') },
                () => {
                    this.pService.done();
                }
            );
        }
    }
    changeJobViewToJob(jv: JobViewModel) {
        if (!this.job) {
            this.job = new Job();
        }

        if (jv === null || jv === undefined) return;

        this.job.JID = jv.JID;
        this.job.JIDR = jv.JIDR;
        this.job.JDT = jv.JDT;
        this.job.DID = jv.DID;
        this.job.JCUN = jv.JCUN;
        this.job.JAT1 = jv.JAT1;
        this.job.JAT2 = jv.JAT2;
        this.job.CID = jv.CID;
        this.job.JOB = jv.JOB;
        this.job.STID = jv.STID;

        this.job.MoID = jv.MoID;
        this.job.HAPP = jv.HAPP;
        this.job.APPDT = jv.APPDT === null ? null : new Date(jv.APPDT);
        this.job.DAmo = jv.DAmo;
        this.job.OAcc = jv.OAcc;
        this.job.RC = jv.RC;
        this.job.JT = jv.JT;
        this.job.RT = jv.RT;
        this.job.TStat = jv.TStat;
        this.job.TProg = jv.TProg;

        this.job.TDnDT = jv.TDnDT;
        this.job.TDBy = jv.TDBy;
        this.job.SStat = jv.SStat;
        this.job.SDnDT = jv.SDnDT;
        this.job.SDBy = jv.SDBy;
        this.job.Taken = jv.Taken;
        this.job.TDT = jv.TDT;
        this.job.SAmo = jv.SAmo;
        this.job.PAmo = jv.PAmo;
        this.job.TAmo = jv.TAmo;

        this.job.Crec = jv.Crec;
        this.job.Chg = jv.Chg;
        this.job.WSDT = jv.WSDT;
        this.job.WADT = jv.WADT;
        this.job.WR = jv.WR;
        this.job.RCC = jv.RCC;
        this.job.ScID = jv.ScID;
        this.job.SuppN = jv.SuppN;
        this.job.UN = jv.UN;
        this.job.DeviceID = jv.DeviceID;
        this.job.RSM = jv.RSM;
        this.job.FOC = jv.FOC;

        this.job.JobAccessories = jv.Accessories;

        this.job.STN = jv.STN;
        this.job.MoN = jv.MoN;

        this.job.Imei = jv.Imei;
        this.job.JK = jv.JK;
    }
    Get_ServiceTechnician(): Promise<any> {
        return new Promise((resolve, reject) => {
            let temp = [];
            lFservice.Get_LF(GlobalFunc.LUKey).then(value => {
                if (!value) {
                    this.pService.start();
                    this.httpService.getData('api/loginuser').subscribe(
                        data => {
                            temp = data;
                        },
                        err => {
                            this.pService.done();
                            reject(err);
                        },
                        () => {
                            this.pService.done();
                            this.tsourcedata = temp;
                            lFservice.Set_LF(GlobalFunc.LUKey, temp);
                            resolve(true);
                        });
                }
                else {
                    this.tsourcedata = value;
                    resolve(true);
                }
            }).catch(err => reject(err));
        });
    }

    Get_ServiceCenter(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpService.getData('api/servicecenter').subscribe(
                data => {
                    this.scsourcedata = data;
                },
                err => {
                    reject(err);
                },
                () => {
                    for (let sc of this.scsourcedata) {
                        this.servicecenterlist.push({ label: sc.ScN, value: sc.ScID });
                    }
                    resolve(true);
                });
        });
    }

    ReAssignTechDropoDown(data, reassigntechs): Promise<any> {
        return new Promise((resolve, reject) => {
            if (data) {
                this.technicians = [];
                this.technicians.push({ label: "Select Technician to re-assign", value: null });

                if (!this.auth.DID) {
                    let len = data.length;
                    for (let i = 0; i < len; i++) {
                        if (data[i].UT == "Service Technician" && (!reassigntechs.includes(data[i].Id))) {
                            this.technicians.push({ label: data[i].Name + ' (' + data[i].ScN + ')', value: data[i].UN });
                        }
                        if (i === (len - 1)) {
                            resolve(true);
                        }
                    }
                }
                else {
                    let len = data.length;
                    for (let i = 0; i < len; i++) {
                        if (data[i].UT == "Service Technician" && data[i].DID === this.auth.DID && data[i].ScID === this.auth.ScID && (!reassigntechs.includes(data[i].Id))) {
                            this.technicians.push({ label: data[i].Name + ' (' + data[i].ScN + ')', value: data[i].UN });
                        }

                        if (i === (len - 1)) {
                            resolve(true);
                        }
                    }
                }
            }
        });
    }
    getAssignedTechnicians(jid): Promise<any> {
        return new Promise((resolve, reject) => {
            this.assignedTechs = [];

            lFservice.Get_LF(jid).then(value => {
                if (!value || (value && value.length <= 0 )) {
                    this.pService.start();
                    this.httpService.getData('api/getjobshift/' + jid).subscribe(
                        data => { this.assignedTechs = data; },
                        err => {
                            this.pService.done();
                            reject(err);
                        },
                        () => {
                            this.pService.done();
                            lFservice.Set_LF(jid, this.assignedTechs);
                            resolve(true);
                        });
                }
                else {
                    this.assignedTechs = value;
                    resolve(true);
                }
            });
        });
    }

    gotoUnRepairOrCancelCustomer(jv: JobViewModel) {
        this.statusVal = '';
        this.reAssigCheck = false;
        this.jobShift.JAT1 = null;

        if (jv) {
            this.jobv = Object.assign({}, jv);
            this.jobShift.JID = jv.JID;
            this.getAssignedTechnicians(jv.JID).then(rps => {
                let tmp = [];
                let len = this.assignedTechs.length;
                if (len > 0) {
                    for (let i = 0; i < len; i++) {
                        tmp.push(this.assignedTechs[i].JAT1);

                        if (i === (len - 1)) {
                            this.ReAssignTechDropoDown(this.tsourcedata, tmp).then(rps => {
                                this.UnRepairModal.open();
                            });
                        }
                    }
                }
                else {
                    this.UnRepairModal.open();
                }
            });
        }
    }

    statusChange(result: boolean) {
        this.reAssigCheck = result;
    }
    saveReAssignTechnician() {
        if (this.statusVal == "Done") {
            this.UnRepairModal.close();
            if (this.jobv) {
                this.showJobDoneDialog = true;
                this._ngZone.run(() => {
                    this.jobv.SStat = 'c';
                    this.jobv.SDnDT = new Date();
                    this.getCustomerByID();
                    this.jobdoneModal.open();
                });
            }
            else {
                this.httpService.showToast(this.toastkey, 'error', 'Job is not defined!');
            }
        }
        else if (this.statusVal == "Re-Assign") {

            if (this.jobShift.JAT1 === null) return;
            this.UnRepairModal.close();
            this.pService.start();
            this.httpService.postData('api/savereassigntech', this.jobShift).subscribe(
                data => { },
                err => {
                    this.pService.done();
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while insert/update re-assign technician!');
                },
                () => {
                    this.pService.done();
                    this.httpService.showToast(this.toastkey, 'success', 'Successfully saved re-assign technician!');
                }
            );
        }
    }
}
//comments
//      let objs=this.sourcedata;
//      let len=objs.length;
//      for(let i=0;i<len;i++){
//      if(objs[i].CNPh.includes(',')) {
//          let cnph=objs[i].CNPh.replace(',','/');
//          objs[i].CNPh=cnph;
//      }
//      if(objs[i].MoNSTN.includes(',')) {
//          let monstn=objs[i].MoNSTN.replace(',','/');
//          objs[i].MoNSTN=monstn;
//      }
//      if(objs[i].TechName.includes(',')) {
//          let techname=objs[i].TechName.replace(',','/');
//          objs[i].TechName=techname;
//      }
//      if(objs[i].RT.includes(',')) {
//          let rt=objs[i].RT.replace(',','/');
//          objs[i].RT=rt;
//      }
//      if(objs[i].RSM.includes(',')) {
//          let rsm=objs[i].RSM.replace(',','/');
//          objs[i].RSM=rsm;
//      }
// }
