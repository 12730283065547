import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';

// Thrid Library
import { MenuItem } from 'primeng/api';
import { BsModalComponent } from 'ng2-bs3-modal';
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';

//Model
import { Job } from '../job/job';

@Component({
    selector: 'i-dashboardview',
    templateUrl: './dashboardview.component.html'
})
export class DashboardViewCP implements OnInit, OnChanges {
    @ViewChild('confirmModal', { static: false }) confirmModal: BsModalComponent;
    @ViewChild('photoModal', { static: false }) photoModal: BsModalComponent;
    @Input() job: Job;
    @Input() mcb_name: string;

    public items: MenuItem[];
    public confirmMsgs: string = "";
    //public tempStatus: string = "";
    public isPhotoView: boolean = false;
    public toastkey = "i-dashboardview";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public httpService: HttpServices) {
        this.items = [{
            label: 'Menu',
            items: [
                {
                    label: 'Waiting Customer Reply', command: (event) => {
                        this.job.TStat = "b";
                        this.confirmMsgs = "Are you this job is waiting customer reply ? ";
                        this.confirmModal.open();
                    }
                },
                {
                    label: 'Waiting Warranty', command: (event) => {
                        this.job.TStat = "c";
                        this.confirmMsgs = "Are you this job is waiting warranty ? ";
                        this.confirmModal.open();
                    }
                },
                {
                    label: 'Done', command: (event) => {
                        this.job.TStat = "d";
                        this.confirmMsgs = "This job is being done ? ";
                        this.confirmModal.open();
                    }
                }
            ]
        }];
    }

    ngOnInit() { }

    ngOnChanges(changes: any) {
        if (this.mcb_name != undefined) this.job.MoN = this.mcb_name;
    }

    techStatusChanges(): void {
        if (this.job.JID != undefined) {
            this.pService.start();
            this.httpService.postData('api/jobstaus', this.job).
                subscribe(
                data => { },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while updating data!');
                    this.pService.done();
                },
                () => { 
                    this.pService.done(); 
                });
        }
    }

    handleRate(event): void {
        if (this.job.JID != undefined) {
            this.pService.start();
            this.httpService.postData('api/jobrating', this.job).
                subscribe(
                data => { },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while updating data!');
                    this.pService.done();
                },
                () => { 
                    this.pService.done(); 
                });
        }
    }

    photoViewClick() {
        this.isPhotoView = true;
        this.photoModal.open();
    }
    
    photoModalClose() {
        this.isPhotoView = false;
    }
}