import { Component, OnInit, OnDestroy, ViewChild, NgZone } from '@angular/core';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import {NgProgressService} from "ng2-progressbar";

//Service
import { DataChangedEvent } from '../service/datachangedevent';
import { HttpServices } from '../service/httpservices';

//Model
import { DashQuery } from '../dashboard/dashquery';
import { JobListNewQuery } from '../joblistnew/joblistnewquery';

@Component({
    selector: 'ground-dashboard',
    templateUrl: './total-dashboard.component.html'
})

export class TotalDashboardCP implements OnInit, OnDestroy {
    @ViewChild('jobDetailListModal', { static: false }) jobDetailListModal: BsModalComponent;
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    
    public dquery: DashQuery;
    public filteredDataList: any[] = [];//to calculate total
    public dataList: any[];
    public srObjT: any;
    public srObjSM: any;
    public joblistnewqry: JobListNewQuery;
    public title: string = '';
    public isJobDetailList: boolean = false;
    public search: string = '';
    public srObjJ: any;
    public toastkey = "ground-dashboard";

    constructor(private pService: NgProgressService ,public httpservice: HttpServices, public _dataChanged: DataChangedEvent, public _ngZone: NgZone) {
        this.pService.start();
        this.dquery = new DashQuery();
        this.dquery.PType = 'st';
        this.dquery.Period = 'e';
        this.dquery.Selected = null;
        this.dquery.SCID = null;
        this.dquery.STID = null;
        this.dquery.DID = null;
        this.dquery.FromDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
        this.dquery.ToDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 60);
        this.dquery.SearchText = '';

        this.joblistnewqry = new JobListNewQuery(null, null, null, null, 'a', this.dquery.FromDate, this.dquery.ToDate);

        // this.srObjT = this._dataChanged.jobTStatusChanged.subscribe(
        //     srDataT => { this._ngZone.run(() => { this.getDataList(); }) },
        //     err => {
        //         this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!');
        //         this.pService.done();
        //     },
        //     () => { }
        // );

        // this.srObjSM = this._dataChanged.jobSmStatusChanged.subscribe(
        //     srDataSM => { this._ngZone.run(() => { this.getDataList(); }) },
        //     err => {
        //         this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!');
        //         this.pService.done();
        //     },
        //     () => { }
        // );

        // this.srObjJ = this._dataChanged.jobChanged.subscribe(
        //     srData => { this._ngZone.run(() => { this.getDataList(); }) },
        //     err => {
        //         this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!');
        //         this.pService.done();
        //     },
        //     () => { }
        // );        
    }

    ngOnInit() {
        
        this.getDataList();
    };

    ngOnDestroy() {
        this.dquery =
            this.dataList =
            this.joblistnewqry =
            this.dquery = undefined;
        if (this.srObjT !== undefined) this.srObjT.unsubscribe();
        if (this.srObjSM !== undefined) this.srObjSM.unsubscribe();
        if (this.srObjJ !== undefined) this.srObjJ.unsubscribe();
    }

    refresh(){
        this.pService.start();
        this.getDataList();
    }

    getDataList(): void {
        if (this.dquery) {
            this.httpservice.getData('api/totaldashboard').subscribe(
                exp => {
                    this.dataList = exp;
                },
                err => {
                    this.httpservice.showToast(this.toastkey, 'error', err); 
                    this.pService.done();
                },
                () => {
                   this.pService.done();
                });
        } else {
            this.pService.done();
        }
    }

    periodChanged(): void {
        if (this.dquery.Period === 'f') {
            this.dquery.FromDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
            this.dquery.ToDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 60);
            this.getDataList();

        } else this.getDataList();

    }

    onSelectDate(): void {
        this.getDataList();
    }

    exportToCSV() {
        this.dquery.SearchText = this.search;
        this.httpservice.postData('api/exporttotaldashboard', this.dquery).subscribe(
            data => { },
            err => { },
            () => {
                // window.location.href = this.httpservice._url + 'excel/totaldashboard.csv';
                //window.location.href = 'http://linnservice.com/totaldashboard.csv';
                //window.location.href = 'http://localhost:48598/dashboardjobs.csv';
                this.httpservice.DirectLocToWindow("totaldashboard");
            }
        );
    }

    cellClickTotal(colfield, count , scenter) {
        if (this.search || count === 0) return;
        this.joblistnewqry.departmentId = null;//rdata.DID;
        this.joblistnewqry.servicecenterId = scenter.ScID;
        this.joblistnewqry.servicetypeId =null;
        this.joblistnewqry.period = this.dquery.Period;
        this.joblistnewqry.fromdate = this.dquery.FromDate;
        this.joblistnewqry.todate = this.dquery.ToDate;
        switch (colfield) {
            case 'Irreparable': {
                this.joblistnewqry.jobstatusId = 10;   // Status 1
                this.title = 'Job Detail List (Irreparable)';
                this.isJobDetailList = true;
                break;
            }
            case 'WPYGN': {
                this.joblistnewqry.jobstatusId = 13;  //  Status 2
                this.title = 'Job Detail List (WP YGN)';
                this.isJobDetailList = true;
                break;
            }
            case 'WPMDY': {
                this.joblistnewqry.jobstatusId = 14;   //  Status 3
                this.title = 'Job Detail List (WP MDY)';
                this.isJobDetailList = true;
                break;
            }
            case 'WaitPart': {
                this.joblistnewqry.jobstatusId = 15;   //  Status 4
                this.title = 'Job Detail List (Wait Part)';
                this.isJobDetailList = true;
                break;
            }
            case 'WP': {
                this.joblistnewqry.jobstatusId = 5;   //  Status 5
                this.title = 'Job Detail List (WP)';
                this.isJobDetailList = true;
                break;
            }
            case 'WaitingCustomerReply': {
                this.joblistnewqry.jobstatusId = 7;   //  Status 4
                this.title = 'Job Detail List (Waiting Customer Reply)';
                this.isJobDetailList = true;
                break;
            }
            // case 'PartDone': {
            //     this.joblistnewqry.jobstatusId = 16;  //  Status 6
            //     this.title = 'Job Detail List (Part Done)';
            //     this.isJobDetailList = true;
            //     break;
            // }
            case 'TDone': {
                this.joblistnewqry.jobstatusId = 3;  //  Status 7
                this.title = 'Job Detail List (Technical Done)';
                this.isJobDetailList = true;
                break;
            }
            case 'SDone': {
                this.joblistnewqry.jobstatusId = 2;  //  Status 8
                this.title = 'Job Detail List (Service Manager Done)';
                this.isJobDetailList = true;
                break;
            }
            case 'Warranty': {
                this.joblistnewqry.jobstatusId = 6; //  Status 9
                this.title = 'Job Detail List (Warranty)';
                this.isJobDetailList = true;
                break;
            }
            case 'CancelCustomer': {
                this.joblistnewqry.jobstatusId = 18; //  Status 18 for api/totaljoblistdetail
                this.title = 'Job Detail List (Customer Cancel)';
                this.isJobDetailList = true;
                break;
            }
            case 'Total': {                     
                this.joblistnewqry.jobstatusId = 20; // 20 to 12 Status for api/totaljoblistdetail for 
                this.title = 'Job Detail List (Total)';
                this.isJobDetailList = true;
                break;
            }
        }
        this.jobDetailListModal.open();
    }

    cellClick(colfield, rdata, scenter) {
        if (rdata === null || rdata === undefined || rdata[colfield] === 0) return;
        this.joblistnewqry.departmentId = null;//rdata.DID;
        this.joblistnewqry.servicecenterId = scenter.ScID;
        this.joblistnewqry.servicetypeId = rdata.STID;
        this.joblistnewqry.period = this.dquery.Period;
        this.joblistnewqry.fromdate = this.dquery.FromDate;
        this.joblistnewqry.todate = this.dquery.ToDate;
        switch (colfield) {
            case 'Irreparable': {
                this.joblistnewqry.jobstatusId = 10;   // Status 1
                this.title = 'Job Detail List (Irreparable)';
                this.isJobDetailList = true;
                break;
            }
            case 'WPYGN': {
                this.joblistnewqry.jobstatusId = 13;  //  Status 2
                this.title = 'Job Detail List (WP YGN)';
                this.isJobDetailList = true;
                break;
            }
            case 'WPMDY': {
                this.joblistnewqry.jobstatusId = 14;   //  Status 3
                this.title = 'Job Detail List (WP MDY)';
                this.isJobDetailList = true;
                break;
            }
            case 'WaitPart': {
                this.joblistnewqry.jobstatusId = 15;   //  Status 4
                this.title = 'Job Detail List (Wait Part)';
                this.isJobDetailList = true;
                break;
            }
            case 'WP': {
                this.joblistnewqry.jobstatusId = 5;   //  Status 5
                this.title = 'Job Detail List (WP)';
                this.isJobDetailList = true;
                break;
            }
            case 'WaitingCustomerReply': {
                this.joblistnewqry.jobstatusId = 7;   //  Status 4
                this.title = 'Job Detail List (Waiting Customer Reply)';
                this.isJobDetailList = true;
                break;
            }
            // case 'PartDone': {
            //     this.joblistnewqry.jobstatusId = 16;  //  Status 6
            //     this.title = 'Job Detail List (Part Done)';
            //     this.isJobDetailList = true;
            //     break;
            // }
            case 'TDone': {
                this.joblistnewqry.jobstatusId = 3;  //  Status 7
                this.title = 'Job Detail List (Technical Done)';
                this.isJobDetailList = true;
                break;
            }
            case 'SDone': {
                this.joblistnewqry.jobstatusId = 2;  //  Status 8
                this.title = 'Job Detail List (Service Manager Done)';
                this.isJobDetailList = true;
                break;
            }
            case 'Warranty': {
                this.joblistnewqry.jobstatusId = 6; //  Status 9
                this.title = 'Job Detail List (Warranty)';
                this.isJobDetailList = true;
                break;
            }
            case 'CancelCustomer': {
                this.joblistnewqry.jobstatusId = 18; //  Status 18 for api/totaljoblistdetail
                this.title = 'Job Detail List (Customer Cancel)';
                this.isJobDetailList = true;
                break;
            }
            case 'Total': {                     
                this.joblistnewqry.jobstatusId = 20; // 20 to 12 Status for api/totaljoblistdetail for 
                this.title = 'Job Detail List (Total)';
                this.isJobDetailList = true;
                break;
            }
        }
        this.jobDetailListModal.open();
    }

    jDListModalClose() {
        this.joblistnewqry = new JobListNewQuery(null, null, null, null, 'a', this.dquery.FromDate, this.dquery.ToDate);
        this.title = '';
        this.isJobDetailList = false;
        this.jobDetailListModal.close();
    }

    totalDashInfosValue(dashinfos: any) {
        if (dashinfos == null || dashinfos == undefined) return;

        if (this.search == null || this.search == undefined) this.search = '';
        let filteredData = [];
        filteredData = dashinfos.filter(j => (j.STN.trim()).toLowerCase().indexOf(this.search.toLowerCase().trim()) !== -1)
        this.filteredDataList = filteredData.map(a=> Object.assign({},a));
        return filteredData;
    }

    calculateTotal(propName:string, totalPropName:string, data: any){
        if(this.search == "" || this.search == null || this.search == undefined){
            return data[totalPropName];
        }else{
            let total = 0;
            if(this.filteredDataList!= null && this.filteredDataList != undefined && this.filteredDataList.length > 0){
                total = this.filteredDataList.map(a => a[propName]).reduce(function(a, b)
                {
                    return a + b;
                });
            }
            return total;
        }
    }
}