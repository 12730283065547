import { Component, ViewChild, NgZone, OnInit, OnDestroy } from '@angular/core';//OnInit, OnDestroy
import { Router } from '@angular/router';

// Thrid Library
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { LazyLoadEvent } from 'primeng/api';
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';
import { AuthService } from '../service/authservice';
import { DataChangedEvent } from '../service/datachangedevent';

//Model
import { JobViewModel } from '../joblist/jobviewmodel';
import {AdminJobListQuery} from '../adminjoblist/adminjoblistquery';

@Component({
    selector: 'i-adminjoblist',
    templateUrl: './adminjoblist.component.html'
})
export class AdminJobListCP implements OnInit, OnDestroy{
    public printMode: boolean = false;
    public showIncome: boolean = false;
    public jobstatusddown: SelectItem[] = [];
    public servicecenterddown: SelectItem[] = [];
    public servicetypeddown: SelectItem[] = [];
    public periodddown: SelectItem[] = [];
    public scList: any = null;
    public stList: any = null;

    public adminjoblistqry: AdminJobListQuery;
    public employees:any[];
    public empddown:SelectItem[];

    public datasource: any[] = [];
    public adminjoblist: JobViewModel[] = [];
    public totalRecords: number;
    @ViewChild('table', { static: false }) table: Table;
    public isTakenStatus:boolean;
    public toastkey = "i-adminjoblist";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;

    constructor(private pService: NgProgressService ,public httpService: HttpServices, public router: Router, public auth: AuthService, public _ngZone: NgZone, public _dataChanged: DataChangedEvent){
        this.pService.start();
        this.showIncome = false;
        this.isTakenStatus = false;
        this.defineAdminJobListQry();
    }
    ngOnInit(){
        this.setUpServiceCenterAndServiceType();
        this.setUpEmployees();
        this.prepareForAdminData().then(rps => {
            this.getAdminJobList();
        });
    }
    ngOnDestroy(){

    }
    getAdminJobList(){
        if (!this.adminjoblistqry.jobstatusId) { return; }

        if(!this.adminjoblistqry.empUN){this.adminjoblistqry.empUN='';}

        this.httpService.postData('api/adminjoblist', this.adminjoblistqry).subscribe(
            data => {
                this.datasource = data;
            },
            err => { 
                this.pService.done(); 
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!');},
            () => {
                this.pService.done();
                this.filterData();
            }
        );
    }
    filterData() {
        this.totalRecords = this.datasource.length;
        //this.adminjoblist = this.datasource.slice(0, 20);
        this.adminjoblist = this.datasource.map(a=> Object.assign({},a));
        this.adminjoblist.sort((a, b) => a.JDT < b.JDT ? 1 : (b.JDT < a.JDT ? -1 : 0));
        //this.table.paginate({ first: 0, rows: 20 });
    }
    setUpEmployees(){
        this.employees = [];
        this.pService.start();
        this.httpService.getData('api/loginuser').subscribe(
            data => {
                this.employees.push({label: '- Select Employee -', value: null});
                for(let obj of data){
                    if(obj.UT==='Service Manager'||obj.UT==='Assistance Manager'|| obj.UT==='Service Technician'||obj.UT==='Operator'){
                        this.employees.push({label: obj.Name+'/'+obj.UT, value:obj.UN});
                    }
                }
            },
            err => {},
            () => {}
        );
    }
    setUpJobStatus(){
        this.jobstatusddown = [
            { label: 'Received Jobs', value: 1 },
            { label: 'Service Manager Done', value: 2 },
            { label: 'Technician Done', value: 3 },
            { label: 'Taken', value: 4 },
            { label: 'Work In Progress', value: 5 },
            { label: 'Waiting Warranty', value: 6 },
            { label: 'Waiting Customer Reply', value: 7 },
            { label: 'FOC', value: 8 },
            { label: 'No Taken', value: 9 },
            { label: 'Unable to repair', value: 10 },
            { label: 'Unable to repair(Manager done)', value: 11 }
        ];
    }
    setUpPeriod(){
        this.periodddown = [
            { label: 'Today', value: 'a' },
            { label: 'This week', value: 'b' },
            { label: 'This Month', value: 'c' },
            { label: 'Within 2 Months', value: 'd' },
            { label: 'Within 3 Months', value: 'e' },
            { label: 'Custom Date', value: 'f' }];
    }
    setUpServiceCenterAndServiceType(){
        this.pService.start();
        this.httpService.getDataFJ(['api/getsc', 'api/getst']).subscribe(
            data => {
                this.scList = data[0];
                this.stList = data[1];
            },
            err => { this.httpService.showToast(this.toastkey, 'error', err); },
            () => {
                this.servicecenterddown.push({ label: '- Select Service Center -', value: null });
                this.servicetypeddown.push({ label: '- Select Service Type -', value: null });
                for (let sc of this.scList) {
                    this.servicecenterddown.push({ label: sc.Name, value: sc.ID });
                }

                for (let st of this.stList) {
                    this.servicetypeddown.push({ label: st.Name, value: st.ID });
                }
                this.pService.done();
            }
        );
    }
    defineAdminJobListQry(){
        let fromdate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
        let todate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 0);

        this.adminjoblistqry = new AdminJobListQuery(null, null, null, 1, null, 'a', fromdate, todate);
    }
    prepareForAdminData(): Promise<any>{
        return new Promise((resolve) => {
            this.setUpJobStatus();
            this.setUpPeriod();
            resolve(true);
        });
    }

    loadLazy(event: LazyLoadEvent) {
        setTimeout(() => {
            if (this.datasource) {
                this.adminjoblist = this.datasource.slice(event.first, (event.first + event.rows));
                this.adminjoblist.sort((a, b) => a.JDT < b.JDT ? 1 : (b.JDT < a.JDT ? -1 : 0));
            }
        }, 250);
    }
    filterChanged(changedData=null) {
        if(changedData==='department_changed'){
            this.servicetypeddown = [];
            this.servicetypeddown.push({label: '- Select Service Type -', value: null});
            for(let obj of this.stList){
                if(obj.DID===this.adminjoblistqry.departmentId){
                    this.servicetypeddown.push({label: obj.Name, value: obj.ID});
                }
            }
        }
        if(changedData==='job_status_changed'){
            if(this.adminjoblistqry.jobstatusId===4){
                this.isTakenStatus = true;
            }
            else this.isTakenStatus = false;
        }
        this.getAdminJobList();
    }
    onSelectDate() {
        this.getAdminJobList();
    }
    printjob() {
        window.print();
    }
    exportToCSV() {
        this.httpService.postData('api/exportadminjoblist', this.adminjoblistqry).subscribe(
            data => { },
            err => { },
            () => {               
                //window.location.href = this.httpService._url + 'excel/adminjoblist.csv';
                this.httpService.DirectLocToWindow("adminjoblist");
            }
        );
    }
    
}