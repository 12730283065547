import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import { SelectItem } from 'primeng/api';
import { LazyLoadEvent } from 'primeng/api';
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';
import { DataChangedEvent } from '../service/datachangedevent';

//Model
import { StockIn } from './stockin';
import { StockInDetail } from './stockindetail';
import { ServiceCenter } from '../servicecenter/servicecenter';
import { StockItem } from '../stockitem/stockitem';

@Component({
    selector: 'i-stockindetail',
    templateUrl: './stockindetail.component.html'
})

export class StockInDetailCP implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('entryModal', { static: false }) entryModal: BsModalComponent;
    @ViewChild('editStockInModal', { static: false }) editStockInModal: BsModalComponent;
    @ViewChild('deleteModal', { static: false }) deleteModal: BsModalComponent;
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    
    stockin: StockIn;
    tempStockIn: StockIn;
    stockInDetails: StockInDetail[] = [];
    selectedChk: StockInDetail[] = [];
    tempstockInDetail: StockInDetail;
    stockitems: SelectItem[];
    _stockitemsList: StockItem[];
    servicecenters: ServiceCenter[];
    servicecenterlist: SelectItem[];
    stockinForm: FormGroup;
    editstockinForm: FormGroup;
    printMode: boolean = false;
    sub: any;
    SIID: string;
    sourcedata: any[] = [];
    totalRecords: number;
    toastkey = "i-stockindetail";

    scChanged: any;
    siChanged: any;

    constructor(private pService: NgProgressService ,public httpService: HttpServices, public router: Router, public currentRoute: ActivatedRoute, public fb: FormBuilder, public _dataChanged: DataChangedEvent, public _ngZone: NgZone) {
        this.pService.start();
        this.stockin = new StockIn();

        this.sub = this.currentRoute.params.subscribe(param => {
            this.SIID = param['siid'];
        });

        this.scChanged = this._dataChanged.serviceCenterChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "servicecenter") {
                        this.afterBroadCastEvent(sRobj, this.servicecenters, 'ScID');
                    }
                });
            }
        });

        this.siChanged = this._dataChanged.stockItemChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "stockitem") {
                        this.afterBroadCastEvent(sRobj, this._stockitemsList, 'SID');
                    }
                });
            }
        });
    }
    ngOnInit() {
        this.CreateForm();
        this.GetRequiredData().then(exp => {
            if (exp) {
                if (this.SIID) {
                    this.getStockList();
                } else {
                    this.pService.done();
                }
            }
        });

    }
    ngOnDestroy() {
        this.stockin = this.tempStockIn = this.stockInDetails = this.selectedChk = this.tempstockInDetail = this.stockitems = this._stockitemsList = this.servicecenters =
            this.servicecenterlist = this.stockinForm = this.editstockinForm = this.sourcedata = undefined;

        if (this.sub != undefined) this.sub.unsubscribe();
        if (this.scChanged != undefined) this.scChanged.unsubscribe();
        if (this.siChanged != undefined) this.siChanged.unsubscribe();
    }
    ngAfterViewInit() {
        this.checkValid();
    }
    CreateForm(): void {
        this.stockinForm = this.fb.group({
            'SIDID': new FormControl(null),
            'SIID': new FormControl(this.SIID),
            'ScID': new FormControl(null, Validators.compose([Validators.required])),
            'ScN': new FormControl(''),
            'SID': new FormControl(null, Validators.compose([Validators.required])),
            'SN': new FormControl(''),
            'Qty': new FormControl(0),
            'Price': new FormControl(0)
        });
    }

    GetRequiredData(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let scList: any = [];
            let siList: any = [];

            this.httpService.getDataFJ(['api/servicecenter', 'api/stockitem']).subscribe(
                data => {
                    scList = data[0];
                    siList = data[1];
                },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                    resolve(false);
                },
                () => {
                    if (scList) {
                        this.servicecenters = scList.map(d => Object.assign({}, d));
                        this.setServiceCenterData(this.servicecenters);
                    }

                    if (siList) {
                        this._stockitemsList = siList.map(d => Object.assign({}, d));
                        this.setStockItemData(this._stockitemsList);
                    }

                    this.pService.done();
                    resolve(true);
                }
            );
        });
    }

    afterBroadCastEvent(objData, dataList, id) {
        if (objData && objData.Obj && dataList) {
            let ind = dataList.findIndex(temp => temp[id] === objData.Obj[id]);
            if (objData.Msg === 'deleted') {
                if (objData.ObjType.toLowerCase() === "servicecenter") {

                    this.httpService.getData('api/servicecenter').subscribe(
                        data => { this.servicecenters = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'); 
                        this.pService.done();
                     },
                        () => { this.setServiceCenterData(this.servicecenters); 
                            this.pService.done(); 
                        }
                    );

                } else if (objData.ObjType.toLowerCase() === "stockitem") {

                    this.httpService.getData('api/stockitem').subscribe(
                        data => { this._stockitemsList = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'); 
                        this.pService.done();
                     },
                        () => { this.setStockItemData(this._stockitemsList); 
                            this.pService.done(); 
                        }
                    );

                }
            }
            else {
                if (ind >= 0) dataList[ind] = objData.Obj;
                else dataList.unshift(objData.Obj);

                if (objData.ObjType.toLowerCase() === "servicecenter") {
                    this.setServiceCenterData(dataList);
                } else if (objData.ObjType.toLowerCase() === "stockitem") {
                    this.setStockItemData(dataList);
                }
            }
        }
    }

    setServiceCenterData(dataList: ServiceCenter[]) {
        this.servicecenterlist = [];
        this.servicecenterlist.push({ label: '- Select Service Center -', value: null });
        if (dataList) {
            dataList.map(sc => this.servicecenterlist.push({ label: sc.ScN, value: sc.ScID }));
        }
    }

    setStockItemData(dataList: StockItem[]) {
        this.stockitems = [];
        this.stockitems.push({ label: '- Select Stock-Item -', value: null });
        if (dataList) {
            dataList.map(si => this.stockitems.push({ label: si.SN + " / " + si.Cat + " / " + si.SID, value: si.SID }));
        }
    }

    getStockList(): void {
        this.httpService.getData('api/getstockinbyid/' + this.SIID).subscribe(
            data => this.stockin = data,
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
               this.pService.done();
            },
            () => { this.getStockInDetail(); });
    }

    getStockInDetail(): void {
        this.stockInDetails = [];
        this.httpService.getData('api/getstockindetail/' + this.SIID).subscribe(
            data => this.sourcedata = data,
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                this.pService.done();
            },
            () => {
                this.sourcedata.sort((a, b) => a.SID > b.SID ? 1 : (a.SID < b.SID ? -1 : 0));
                this.totalRecords = this.sourcedata.length;
                this.stockInDetails = this.sourcedata.slice(0, 20);
                this.pService.done();
            });
    }

    showAddNewForm(): void {
        this.CreateForm();
        this.entryModal.open();
        this.checkValid();
    }

    showDialogToEdit(std: StockInDetail): void {
        this.CreateForm();
        this.stockinForm.patchValue({ SIDID: std.SIDID, SIID: std.SIID, ScID: std.ScID, ScN: std.ScN, SID: std.SID, SN: std.SN, Qty: std.Qty, Price: std.Price });
        this.entryModal.open();
    }

    showDialogToDelete(): void {
        if (this.selectedChk.length > 0) {
            this.deleteModal.open();
        } else {
            this.httpService.showToast(this.toastkey, 'warn', 'Need to select row!');
        }
    }

    saveData(pdt): void {
        let tmpsidid;
        this.httpService.postData('api/stockindetail', this.stockinForm.value).subscribe(
            data => {
                tmpsidid = data.Message.toString();
            },
            err => {
                this.httpService.showToast(this.toastkey, 'error', err);
               this.pService.done();
            },
            () => {
                if (!this.stockinForm.controls['SIDID'].value) {
                    this.sourcedata.unshift({
                        SIDID: tmpsidid,
                        SIID: this.SIID,
                        ScID: this.stockinForm.controls['ScID'].value,
                        ScN: this.stockinForm.controls['ScN'].value,
                        SID: this.stockinForm.controls['SID'].value,
                        SN: this.stockinForm.controls['SN'].value,
                        Qty: this.stockinForm.controls['Qty'].value,
                        Price: this.stockinForm.controls['Price'].value
                    });

                    this.totalRecords += 1;
                }
                else {
                    let idx = this.sourcedata.findIndex(x => x.SIDID === this.stockinForm.controls['SIDID'].value);
                    this.sourcedata[idx].ScID = this.stockinForm.controls['ScID'].value;
                    this.sourcedata[idx].ScN = this.stockinForm.controls['ScN'].value;
                    this.sourcedata[idx].SID = this.stockinForm.controls['SID'].value;
                    this.sourcedata[idx].SN = this.stockinForm.controls['SN'].value;
                    this.sourcedata[idx].Qty = this.stockinForm.controls['Qty'].value;
                    this.sourcedata[idx].Price = this.stockinForm.controls['Price'].value;
                }

                this.httpService.showToast(this.toastkey, 'success', 'Successfully updated');
                this.entryModal.close();

                this.resetDatable(pdt);
            }
        );
    }

    setUpName(changedcomp) {
        if (changedcomp === 'sc') {
            let idx = this.servicecenterlist.findIndex(x => x.value === this.stockinForm.controls['ScID'].value);
            if (idx >= 0) { this.stockinForm.controls['ScN'].setValue(this.servicecenterlist[idx].label) };
        }
        else {
            let idx = this.stockitems.findIndex(x => x.value === this.stockinForm.controls['SID'].value);
            if (idx >= 0) { this.stockinForm.controls['SN'].setValue(this.stockitems[idx].label) }
        }
    }

    deleteData() {
        this.httpService.postData('api/stockindetail/multidelete/', this.selectedChk).
            subscribe(
                data => {
                    this.httpService.showUnsuccessDelMsg(this.toastkey, data);
                },
                err => {
                    this.doneDeleteProcess('error', 'Error occurs while deleting data!');
                },
                () => {
                    this.doneDeleteProcess('success', 'Successfully deleted');
                });
    }

    deleteModalClose() {
        this.deleteModal.close();
        this.httpService.AddClassToModal(true);
    }

    doneDeleteProcess(header: string, msg: string) {
        this.selectedChk = null;
        this.deleteModalClose();
        this.httpService.showToast(this.toastkey, header, msg);
    }

    printjob(): void { window.print(); }

    saveStockInData(): void {
        this.stockin = this.editstockinForm.value;

        this.httpService.postData('api/updatestockin', this.stockin).
            subscribe(data => { },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', err);
                    this.pService.done();
                },
                () => { this.editStockInModal.close(); });
    }
    formvalid = true;
    checkValid() {
        if (!this.stockinForm.valid) {
            this.formvalid = false;
        }
        else {
            this.formvalid = true;
        }
    }
    loadLazy(event: LazyLoadEvent) {
        setTimeout(() => {
            if (this.sourcedata && this.sourcedata.length > 0) {
                this.stockInDetails = this.sourcedata.slice(event.first, (event.first + event.rows));
            }
        }, 250);
    }
    resetDatable(pdt): void {
        pdt.reset();
    }
}

/*
CreateStockInForm(): void {
        this.editstockinForm = this.fb.group({
            'SIID': new FormControl(''),
            'SIDT': new FormControl(new Date().toLocaleDateString(), Validators.compose([Validators.required])),
            'Rmk': new FormControl('')
        });
    }
showStockInDialog() {
        this.CreateStockInForm();
        var sidt = new Date(this.stockin.SIDT).toLocaleDateString();
        this.editstockinForm.patchValue({ SIID: this.stockin.SIID, SIDT: sidt, Rmk: this.stockin.Rmk });
        this.editStockInModal.open();
    }

    this.CreateStockInForm();
*/