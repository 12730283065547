import {Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';

@Component({
    selector:'job-done-print-view',
    templateUrl: './jobdoneprintview.component.html',
})
export class JobDonePrintViewCP implements OnInit {
    @Input() qrcode;
    @Output() onClick = new EventEmitter<boolean> ();

    constructor(public aroute:ActivatedRoute, public router: Router) {
        // if(this.qrcode === undefined) this.qrcode = '';
    }

    ngOnInit() {
        if(this.qrcode != '' || this.qrcode != undefined ) {
            let tmp=Observable.timer(50,1).subscribe(data=>{
                tmp.unsubscribe();
                window.print();
            });
        }
    }
}