import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import { SelectItem } from 'primeng/api';
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';

//Model
import { StockTransfer } from './stocktransfer';
import { StockTransferDetail } from './stocktransferdetail';
import { StockItem } from '../stockitem/stockitem';

@Component({
    selector: 'i-stocktransferdetail',
    templateUrl: './stocktransferdetail.component.html'
})
export class StockTransferDetailCP implements OnInit {
    @ViewChild('deleteModal', { static: false }) deleteModal: BsModalComponent;
    @ViewChild('editModal', { static: false }) editModal: BsModalComponent;
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    
    public selectedChk: StockTransferDetail[];
    public stockitems: SelectItem[];
    public _stockitemsList: StockItem[];
    public std: StockTransfer;
    public stockTransDetails: StockTransferDetail[];
    public tempStock: StockTransferDetail;
    public stockTransForm: FormGroup;
    public printMode: boolean = false;
    public sub: any;
    public tid: string = "";
    public rowIdx: number;
    public toastkey = "i-stocktransferdetail";

    constructor(private pService: NgProgressService ,public currentRoute: ActivatedRoute, public httpService: HttpServices, public fb: FormBuilder) {
        this.std = new StockTransfer();
        this.tempStock = new StockTransferDetail();
        this.stockTransDetails = [];
        this.sub = this.currentRoute.params.subscribe(param => {
            this.tid = param['tid'];
            this.std.TID = this.tid;

        });
    }
    ngOnInit() {
        this.CreateForm();
        this.get_StockItems();
        if (this.tid != "") { this.getTransfer(); }
    }
    CreateForm(): void {
        this.stockTransForm = this.fb.group({
            'TID': new FormControl(this.tid),
            'SID': new FormControl(null, Validators.compose([Validators.required])),
            'Qty': new FormControl(0, Validators.required),
        });
    }
    getTransfer() {
        this.httpService.getData('api/stocktransferdetail/' + this.tid).subscribe(
            data => {
                this.std = data;
                this.stockTransDetails = this.std.STDetail;
            },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!');
                this.pService.done();
            },
            () => {
                this.stockTransDetails.sort((a, b) => a.SID > b.SID ? 1 : (a.SID < b.SID ? -1 : 0));
            });
    }
    get_StockItems(): void {
        this.httpService.getData('api/stockitem').subscribe(
            data => this._stockitemsList = data,
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                this.pService.done();
            },
            () => {
                this.stockitems = [];
                this.stockitems.push({ label: 'Select stockitem ', value: null });
                if (this._stockitemsList) {
                    this._stockitemsList.map(si => this.stockitems.push({ label: si.SN + " / " + si.Cat + " / " + si.SID, value: si.SID }));
                }
            });
    }

    isInt(n) {
        return Number(n) === n && n % 1 === 0;
    }

    Add(event, isNew: boolean): void {
        this.tempStock = new StockTransferDetail();
        if (isNew) {
            this.tempStock.TID = this.stockTransForm.controls['TID'].value;
            this.tempStock.SID = this.stockTransForm.controls['SID'].value;
            this.tempStock.Qty = this.stockTransForm.controls['Qty'].value;
        }
        else {
            if (event) {
                this.tempStock.TDID = event.TDID;
                this.tempStock.TID = event.TID;
                this.tempStock.SID = event.SID;
                if (event.Qty) {
                    var n = Number(event.Qty);
                    if (this.isInt(n)) {
                        this.tempStock.Qty = event.Qty;
                    }
                    else {
                        this.tempStock.Qty = 0;
                    }
                }
                else {
                    this.tempStock.Qty = 0;
                }
            }
        }

        this.findStock();

        this.httpService.postData('api/stocktransferdetail', this.tempStock).subscribe(
            data => {
                if (isNew) {
                    this.tempStock.TDID = data.TDID;
                    this.stockTransDetails.push(this.tempStock);
                }
            },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while inserting/updating data!');
                this.pService.done();
            },
            () => {
                this.CreateForm();
                this.stockTransDetails.sort((a, b) => a.SID > b.SID ? 1 : (a.SID < b.SID ? -1 : 0));
            }
        );
    }
    findIndexOfStock(selecteddata: StockTransferDetail): number {
        for (var _index in this.stockTransDetails) {
            if (this.stockTransDetails[_index].TDID === selecteddata.TDID) {
                return parseInt(_index);
            }
        }
    }
    findStock(): void {
        for (let obj of this._stockitemsList) {
            if (this.stockTransForm.controls['SID'].value == obj.SID) {
                this.tempStock.SN = obj.SN;
                this.tempStock.Cat = obj.Cat;
                break;
            }
        }
    }
    showDialogToDelete(): void {
        if (this.selectedChk != undefined) {
            this.deleteModal.open();
        } else {
            this.httpService.showToast(this.toastkey, 'warn', 'Need to select row!');
        }
    }
    deleteData() {
        this.httpService.postData('api/stocktransferdetail/multidelete/', this.selectedChk).
            subscribe(
                data => {
                    this.httpService.showUnsuccessDelMsg(this.toastkey, data);
                },
                err => {
                    this.doneDeleteProcess('error', 'Error occurs while deleting data!');
                },
                () => {
                    this.doneDeleteProcess('success', 'Successfully deleted');
                });
    }

    deleteModalClose() {
        this.deleteModal.close();
        this.httpService.AddClassToModal(true);
    }

    doneDeleteProcess(header: string, msg: string) {
        this.selectedChk = null;
        this.deleteModalClose();
        this.httpService.showToast(this.toastkey, header, msg);
    }

    printjob(): void {
        window.print();
    }

    goEdit(stf) {
        this.tempStock = new StockTransferDetail();
        this.tempStock.TDID = stf.TDID;
        this.tempStock.TID = stf.TID;
        this.tempStock.SID = stf.SID;
        this.tempStock.Qty = stf.Qty;
        this.tempStock.Cat = stf.Cat;
        this.tempStock.SN = stf.SN;
        this.editModal.open();
    }
    saveEditVal() {
        this.editModal.close();

        this.httpService.postData('api/stocktransferdetail', this.tempStock).subscribe(
            data => {
                let idx = this.stockTransDetails.findIndex(x => x.TDID === this.tempStock.TDID);
                if (idx >= 0) {
                    this.stockTransDetails[idx] = Object.assign({}, this.tempStock);
                }
            },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while inserting/updating data!');
                this.pService.done();
            },
            () => {
                this.editModal.close();
            }
        );
    }
}