import {Component, OnInit,Output,EventEmitter,ViewChild} from '@angular/core';

//Third Library
import {NgProgressService} from "ng2-progressbar";

//Service
import {HttpServices} from '../service/httpservices';

//Model
import {Accessory} from '../accessory/accessory';

@Component({
    selector: 'i-accessorydialog',
    templateUrl: './accessorydialog.component.html'
})

export class AccessoryDialogCP implements OnInit {
    @Output() onClick = new EventEmitter<Accessory>();
    @ViewChild('input', { static: false }) vc;

    public accessory: Accessory;
    public toastkey = "i-accessorydialog";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public httpService: HttpServices) { this.accessory = new Accessory(); }
    
    ngOnInit() {
        this.vc.nativeElement.focus();
    }

    onclick(isSaving: boolean): void {
        if (isSaving) {
            if (this.accessory.AcsN == undefined) return;
            if (this.accessory != undefined) {
                this.httpService.postData('api/accessory', this.accessory).
                    subscribe(
                    data => {this.accessory.AcsID = data},
                    err => {
                        this.httpService.showToast(this.toastkey, 'error', 'Error occurs while inserting/updating data!');
                        this.pService.done();
                    },
                    () => { this.onClick.emit(this.accessory); });
            }
        }
        else {this.onClick.emit()}
    }
}