import { Component, OnInit, ViewChild, ViewEncapsulation, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalComponent } from 'ng2-bs3-modal';
import { LoginUser } from "./loginuser/loginuser"
import { HttpServices } from './service/httpservices';
import { lFservice } from './service/lFservice';
import { AuthService } from './service/authservice';
import { GlobalFunc } from './service/globalfunction';

@Component({
  selector: 'external-login',
  templateUrl: './external-login.html',
  encapsulation: ViewEncapsulation.None,
})
export class ExternalLoginComponent implements OnInit, OnDestroy {
  @ViewChild('loginErrModal', { static: false }) loginErrModal: BsModalComponent;

  data: LoginUser;
  msgErr: string;
  chkRemember = false;
  showLogin = false;
  current_route;
  loginId: string;
  msgs: any;
  constructor(public router: Router, public auth: AuthService, public activeRoute: ActivatedRoute, public httpService: HttpServices) {

  }

  ngOnInit() {
    this.current_route = this.activeRoute.params.subscribe(param => {
      if (param !== undefined && param.loginObj !== undefined) {
        let tmp = JSON.parse(param.loginObj);
        setTimeout(() => {
          lFservice.Set_LF('AccessToken', tmp.access_token);
          this.getRequriedData(tmp);
        }, 300);
      }
    });
  }


  ngOnDestroy() {
    this.data = undefined;
    if (this.current_route !== undefined) this.current_route.unsubscribe();
  }

  getRequriedData(result: any) {
    if (result) {
      if (typeof result === 'object') {
        this.auth.setAllData(result);
        this.auth.storeCredential(result);
        //  lFservice.Set_LF('authinfo', result).then(result => {
        //     this.router.navigate(['/']);
        // });   
        // lFservice.Get_LF(GlobalFunc.CusKey).then(cusdata => {
        //   lFservice.Get_LF(GlobalFunc.MCBKey).then(mcbdata => {
        //     if (!cusdata && !mcbdata) {
        //       this.Get_Customers().then(abc => {
        //         this.Get_MCB().then(def => {

        //         }).catch(err => { this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Error occurs while getting model/cate/brand!' }); });
        //       }).catch(err => {  this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Error occurs while getting customers!' }); });
        //     }
        //   }).catch(err => { this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Error occurs while getting model/cate/brand from LocalData!' }); });

        // }).catch(err => { this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Error occurs while getting customers from LocalDat!' }); });

      }
      else {
        this.auth.clearLoginProperty(null, null);
        this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Invalid user name or password!' });
      }

    } else {
      this.msgErr = 'Authentication does not exist and please try again.';
      this.loginErrModal.open();
    }

  }

  errorMsg(err) {
    if (err === null || typeof err._body === "undefined") return;
    let errMsg: string = JSON.parse(err._body).Message;
    if (errMsg === undefined) errMsg = JSON.parse(err._body);
    this.msgErr = errMsg.startsWith('cex') ? errMsg.substring(6) : errMsg;
    this.loginErrModal.open();
  }

  ok() {
    //this.ld.sendLogout(this.loginId);
    this.loginErrModal.close();

  }


  // Get_Customers(): Promise<boolean> {
  //   return new Promise<boolean>((resolve, reject) => {
  //     let temp = [];
  //     lFservice.Get_LF(GlobalFunc.CusKey).then(value => {
  //       if (value == null) {
  //         this.httpService.getData('api/customerForDDown').subscribe(
  //           data => temp = data,
  //           err => {
  //             this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Error occurs while loading data from server!' });
  //             reject(null);
  //           },
  //           () => {
  //             lFservice.Set_LF(GlobalFunc.CusKey, temp).then(value => {
  //               resolve(true);
  //             }).catch(err => {
  //               this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Error occurs while loading data!' });
  //               reject(null);
  //             });
  //           });
  //       }
  //     }).catch(err => {
  //       this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Error occurs while loading mcb data!' });
  //       reject(null);
  //     });
  //   });
  // }

  // Get_MCB(): Promise<boolean> {
  //     return new Promise<boolean>((resolve, reject) => {
  //         let temp = [];
  //         lFservice.Get_LF(GlobalFunc.MCBKey).then(value => {
  //             if (value == null) {
  //                 this.httpService.getData('api/job/getmcb').subscribe(
  //                     data => temp = data,
  //                     err => {
  //                         this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Error occurs while loading  mcb from server!' });
  //                         reject(null);
  //                 },
  //                     () => {
  //                         lFservice.Set_LF(GlobalFunc.MCBKey, temp).
  //                             then(_value => {                                    
  //                                 resolve(true);
  //                             }).
  //                             catch(err => {
  //                                 this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Error occurs while loading mcb from server!' });
  //                             reject(null);
  //                             });                            
  //                     });
  //             }
  //         }).catch(err => {
  //             this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Error occurs while loading mcb data!' });
  //         reject(null);
  //     });
  //     });        
  // }
}

export class Login {
  public constructor(
    public loginId: string,
    public pwd: string) { }
}
