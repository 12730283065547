import { Component, OnInit, ViewChild } from '@angular/core';

// Thrid Library
import { SelectItem } from 'primeng/api';
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';

//Model
import { ServiceCenter } from '../servicecenter/servicecenter';
import { StockLedger, StockLedgerQuery, StockCategory } from './stockledger';

@Component({
    selector: 'i-stockledger',
    templateUrl: './stockledger.component.html'
})

export class StockLedgerCP implements OnInit {
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    
    stockledgers: StockLedger[] = [];
    slquery: StockLedgerQuery;
    scats: SelectItem[] = [];
    selectedCategory: any;
    startDate: Date;
    endDate: Date;

    scenter: SelectItem[] = [];
    selectedServiceCenter: any;
    public toastkey = "i-stockledger";

    constructor(private pService: NgProgressService ,public httpService: HttpServices) {
        this.scats.push({ label: 'Select a category', value: null });
        this.selectedCategory = null;

        this.scenter.push({ label: 'All', value: null });
        this.selectedServiceCenter = null;
    }

    ngOnInit() {
        let categories: StockCategory[] = [];
        this.httpService.getData('api/getstockcategory').subscribe(
            data => { categories = data },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while getting data of stock_category!\n' + err);
                this.pService.done();
            },
            () => { categories.forEach(x => { this.scats.push({ label: x.Cat, value: x.Cat }) }) }
        );

        let tmp: ServiceCenter[] = [];
        this.httpService.getData('api/servicecenter').subscribe(
            data => { tmp = data },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while getting data of service_center!\n' + err);
               this.pService.done();
            },
            () => { tmp.forEach(x => { this.scenter.push({ label: x.ScN, value: x.ScID }); }) }
        );

        this.startDate = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 7, 0, 0, 0));;
        this.endDate = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 0));
    }

    calculateSL() {
        this.slquery = new StockLedgerQuery(this.selectedCategory === null ? '' : this.selectedCategory, this.startDate, this.endDate, this.selectedServiceCenter);
        let temp: StockLedger[] = [];

        this.httpService.postData('api/stockledger', this.slquery).subscribe(
            data => { temp = data; },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while calculating stock_ledger!\n' + err);
                this.pService.done();
            },
            () => {
                this.stockledgers = temp;
                if (this.stockledgers.length === 0) {
                    this.httpService.showToast(this.toastkey, 'info', 'There is no record(s) by your filters');
                }
            }
        );
    }
}