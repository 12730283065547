import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Message, SelectItem } from 'primeng/api';

import {NgProgressService} from "ng2-progressbar";

import { HttpServices } from '../service/httpservices';
import { AuthService } from '../service/authservice';
import { Department } from '../department/department';

@Component({
    selector: 'stock-balance',
    templateUrl: './stockbalance.component.html'
})
export class StockBalance implements OnInit, OnDestroy {
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    public msgs: Message[] = [];
    public stocks: SelectItem[] = [];
    public selectedStock: any;
    public stocksBalance: any[];
    public showTable: boolean = false;
    public totalBal: number = 0;
    public showlabel: boolean = false;
    public noZeroStocks: any[] = [];
    public departments: SelectItem[] = [];
    public selectedDepartment: SelectItem;
    public toastkey = "i-department";
    constructor(private pService: NgProgressService ,public httpservices: HttpServices, public auth: AuthService) {
        //this.pService.start();
    }

    ngOnInit(): void {
        this.getDepartment();
        this.getStocks();
    }

    getDepartment(): void {
        if (this.auth.DID) {
            return;
        }

        let temp: Department[] = [];
        this.httpservices.getData('api/department').subscribe(
            data => {
                temp = data;
            },
            err => {
                this.httpservices.showToast(this.toastkey, 'error', 'Error occurs while loading department  data from server!');
                this.pService.done();
            },
            () => {
                this.departments = [];
                this.departments.push({ label: 'Select Department ', value: null });
                temp.map(dep => {
                    this.departments.push({ label: dep.DN, value: dep.DID });
                });

            });
    }

    getStocks(): void {
        let temp: any[];
        this.stocks = [];
        this.stocks.push({ label: 'Select Stocks ', value: null });

        if (!this.auth.DID && !this.selectedDepartment) {
            this.noZeroStocks = [];
            this.showTable = false;
            this.totalBal = 0;
            return;
        }
        
        this.httpservices.getData('api/stockitembydid/' + (this.auth.DID ? this.auth.DID : this.selectedDepartment)).subscribe(
            expdata => { temp = expdata ,this.pService.done();},
            err => {
                this.httpservices.showToast(this.toastkey, 'error', 'Error occurs while loading data!');
                this.pService.done();
            },
            () => {
                for (let obj of temp) {
                    this.stocks.push({ label: obj.SN + '( ' + obj.Cat + ' )', value: obj.SID });
                }
            }
        );

        this.pService.done();
    }

    calculateStockBal(): void {

        this.showTable = false;

        if (this.selectedStock == undefined || this.selectedStock == null) {
            this.showlabel = true;
            return;
        }

        this.totalBal = 0;

        this.httpservices.postData('api/stockBalance/', this.selectedStock).subscribe(
            data => { this.stocksBalance = data; },
            err => { this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Sorry, error occurs while loading stocks from server !' }) },
            () => {
                this.noZeroStocks = this.stocksBalance.filter(x => x.sB != 0);
                this.noZeroStocks.forEach(x => {
                    this.totalBal += x.sB;
                });
                if (this.totalBal != 0) this.showTable = true;
                else this.showlabel = true;
            }
        );
    }

    departmentChange(e) {
        this.getStocks();
    }

    ngOnDestroy(): void {
        this.msgs = this.stocks = this.selectedStock = this.stocksBalance = this.noZeroStocks = this.departments = this.selectedDepartment = undefined;
    }
}