import { Component, OnInit, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

// Thrid Library
import { SelectItem } from 'primeng/api';
import { BsModalComponent } from 'ng2-bs3-modal';
import { NgProgressService } from "ng2-progressbar";

//Service
import { DataChangedEvent } from '../service/datachangedevent';
import { HttpServices } from '../service/httpservices';
import { GlobalFunc } from '../service/globalfunction';

//Model
import { LoginUser } from './loginuser';
import { Department } from '../department/department';
import { ServiceType } from '../servicetype/servicetype';
import { ServiceCenter } from '../servicecenter/servicecenter';
import { AuthService } from '../service/authservice';

export declare interface ValidatorFn {
    (control: FormControl): { [key: string]: boolean } | null;
}

@Component({
    selector: 'i-loginuser',
    templateUrl: './loginuser.component.html'
})



export class LoginUserCP implements OnInit, OnDestroy {
    @ViewChild('entryModal', { static: false }) entryModal: BsModalComponent;
    @ViewChild('actionModal', { static: false }) actionModal: BsModalComponent;
    @ViewChild('deleteModal', { static: false }) deleteModal: BsModalComponent;
    @ViewChild('otherModal', { static: false }) otherModal: BsModalComponent;
    @ViewChild('input', { static: false }) vc;
    @ViewChild('input1', { static: false }) vc1;
    @ViewChild('ConfirmedPwdModal', { static: false }) ConfirmedPwdModal: BsModalComponent;
    @ViewChild('ChangedPwdModal', { static: false }) ChangedPwdModal: BsModalComponent;

    apiName: string = "loginuser";
    loginUsers: LoginUser[];
    selectedData: LoginUser;
    selectedChk: LoginUser[];
    actionlogs: any[];

    oServiceTypeList: ServiceType[];
    servicetypes: SelectItem[];

    oServiceCenterList: ServiceCenter[];
    serviceCenters: SelectItem[];

    oDeptList: Department[] = [];
    deptllist: SelectItem[];

    usertypes: SelectItem[] = [
        { label: "Select user type", value: null },
        { label: "Admin", value: "Admin" },
        { label: "Service Manager", value: "Service Manager" },
        { label: "Assistance Manager", value: "Assistance Manager" },
        { label: "Operator", value: "Operator" },
        { label: "Service Technician", value: "Service Technician" },
        { label: "Warranty", value: "Warranty" }];

    isNew: boolean = false;
    isEntryModalOpen: boolean = false;
    isEmailValid: boolean = true;
    totalRecords: number;
    toastkey = "i-loginuser";

    isConfPwdModalOpen: boolean = false;
    isChangedPwdModalOpen: boolean = false;

    errorUNString: string = null;
    errorNameString: string = null;
    errorPwdString: string = null;
    errorUTString: string = null;
    errorEmailString: string = null;
    errorDPhString: string = null;

    luChanged: any;
    depChanged: any;
    scChanged: any;
    stChanged: any;
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(public auth: AuthService ,private pService: NgProgressService, public httpService: HttpServices, public fb: FormBuilder, public _dataChanged: DataChangedEvent, public _ngZone: NgZone) {
        this.pService.start();
        this.luChanged = this._dataChanged.loginUserChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === this.apiName.toLowerCase()) {
                        this.afterBroadCastEvent(sRobj, this.loginUsers, "UN");
                    }
                });
            }
        });

        this.depChanged = this._dataChanged.departmentChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "department") {
                        this.afterBroadCastEvent(sRobj, this.oDeptList, 'DID');
                    }
                });
            }
        });

        this.scChanged = this._dataChanged.serviceCenterChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "servicecenter") {
                        this.afterBroadCastEvent(sRobj, this.oServiceCenterList, 'ScID');
                    }
                });
            }
        });

        this.stChanged = this._dataChanged.serviceTypeChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "servicetype") {
                        this.afterBroadCastEvent(sRobj, this.oServiceTypeList, 'STID');
                    }
                });
            }
        });
    }

    ngOnInit() {
        this.GetRequiredData();
    }

    ngOnDestroy() {
        this.loginUsers = this.selectedData = this.selectedChk = this.actionlogs = this.oServiceTypeList = this.servicetypes = this.oServiceCenterList = this.serviceCenters =
            this.oDeptList = this.deptllist = this.usertypes = undefined;

        if (this.luChanged != undefined) this.luChanged.unsubscribe();
        if (this.depChanged != undefined) this.depChanged.unsubscribe();
        if (this.scChanged != undefined) this.scChanged.unsubscribe();
        if (this.stChanged != undefined) this.stChanged.unsubscribe();

    }

    GetRequiredData(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let depList: any = [];
            let scList: any = [];
            let stList: any = [];
            let luList: any = [];
            this.httpService.getDataFJ(['api/department', 'api/servicecenter', 'api/servicetype', 'api/loginuser']).subscribe(
                data => {
                    depList = data[0];
                    scList = data[1];
                    stList = data[2];
                    luList = data[3];
                },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                    resolve(false);
                },
                () => {
                    if (depList) {
                        this.oDeptList = depList.map(d => Object.assign({}, d));
                        this.setDepartmentData(this.oDeptList);
                    }

                    if (scList) {
                        this.oServiceCenterList = scList.map(sc => Object.assign({}, sc));
                        this.setServiceCenterData(this.oServiceCenterList);
                    }

                    if (stList) {
                        this.oServiceTypeList = stList.map(st => Object.assign({}, st));
                        this.setServiceTypeData(this.oServiceTypeList);
                    }

                    if (luList) {
                        this.loginUsers = luList.map(a => Object.assign({}, a));
                        this.totalRecords = this.loginUsers.length;
                    }

                    this.pService.done();
                    resolve(true);
                }
            );
        });
    }

    afterBroadCastEvent(objData, dataList, id) {
        if (objData && objData.Obj && dataList) {
            let ind = dataList.findIndex(temp => temp[id] === objData.Obj[id]);
            if (objData.Msg === 'deleted') {
                if (objData.ObjType.toLowerCase() === "department") {

                    this.httpService.getData('api/department').subscribe(
                        data => { this.oDeptList = data || []; },
                        err => {
                            this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                            this.pService.done();
                        },
                        () => {
                            this.setDepartmentData(this.oDeptList);
                            this.pService.done();
                        }
                    );

                } else if (objData.ObjType.toLowerCase() === "servicecenter") {

                    this.httpService.getData('api/servicecenter').subscribe(
                        data => { this.oServiceCenterList = data || []; },
                        err => {
                            this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                            this.pService.done();
                        },
                        () => {
                            this.setServiceCenterData(this.oServiceCenterList);
                            this.pService.done();
                        }
                    );

                } else if (objData.ObjType.toLowerCase() === "servicetype") {

                    this.httpService.getData('api/servicetype').subscribe(
                        data => { this.oServiceTypeList = data || []; },
                        err => {
                            this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                            this.pService.done();
                        },
                        () => {
                            this.setServiceTypeData(this.oServiceTypeList);
                            this.pService.done();
                        }
                    );

                } else if (objData.ObjType.toLowerCase() === this.apiName.toLowerCase()) {

                    this.httpService.getData('api/loginuser').subscribe(
                        data => { this.loginUsers = data || []; },
                        err => {
                            this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                            this.pService.done();
                        },
                        () => {
                            this.totalRecords = this.loginUsers.length;

                            this.pService.done();
                        }
                    );

                }
            }
            else {
                if (ind >= 0) dataList[ind] = objData.Obj;
                else dataList.unshift(objData.Obj);

                if (objData.ObjType.toLowerCase() === "department") {
                    this.setDepartmentData(dataList);
                } else if (objData.ObjType.toLowerCase() === "servicecenter") {
                    this.setServiceCenterData(dataList);
                } else if (objData.ObjType.toLowerCase() === "servicetype") {
                    this.setServiceTypeData(dataList);
                }
            }
        }
    }

    setDepartmentData(dataList: Department[]) {
        this.deptllist = [];
        this.deptllist.push({ label: 'Select Department ', value: null });
        if (dataList) {
            dataList.map(dep => this.deptllist.push({ label: dep.DN, value: dep.DID }));
        }
    }

    setServiceCenterData(dataList: ServiceCenter[]) {
        this.serviceCenters = [];
        this.serviceCenters.push({ label: 'Select service center', value: null });
        if (dataList) {
            dataList.map(x => this.serviceCenters.push({ label: x.ScN, value: x.ScID }));
        }
    }

    setServiceTypeData(dataList: ServiceType[]) {
        this.servicetypes = [];
        this.servicetypes.push({ label: 'Select service type', value: null });
        if (dataList) {
            dataList.map(st => this.servicetypes.push({ label: st.STN, value: st.STID }));
        }
    }

    refresh() {
        this.pService.start();
        this.GetRequiredData();
    }

    findServiceType(para) {
        if (para != 0) {
            let ind = this.oServiceTypeList.findIndex(c => c.STID === para);
            if (ind > 0) {
                this.selectedData.STN = this.oServiceTypeList[ind].STN;
            }
        } else {
            this.selectedData.STN = '';
        }
    }

    findServiceCenter(para): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            if (para != 0) {
                let ind = this.oServiceCenterList.findIndex(c => c.ScID === para);
                if (ind > 0) {
                    this.selectedData.ScN = this.oServiceCenterList[ind].ScN;
                }
            } else {
                this.selectedData.ScN = '';
            }
            resolve(true);
        })
    }

    findByIndexServicetype(data, selected): number {
        for (var _index in data) {
            if (this.loginUsers[_index].UN === selected.UN) {
                return parseInt(_index);
            }
        }
    }

    save(pdt, gb) {
        if (this.isNotValid()) return;

        if (this.isNew) {
            this.selectedData.Pwd = GlobalFunc.HashString(this.selectedData.Pwd);
        }
        this.selectedData.UserName = this.selectedData.Name;
        this.findServiceType(this.selectedData.STID);
        this.findServiceCenter(this.selectedData.ScID);
        this.pService.start();

        this.httpService.postData('api/loginuser', this.selectedData).
            subscribe(
                ddd => {
                    // if (!this.isNew) {
                    //     let _index: number = this.findByIndexServicetype(this.loginUsers, this.selectedData);
                    //     this.loginUsers[_index] = this.selectedData;
                    // } else {
                    //     this.loginUsers.unshift(this.selectedData);
                    // }
                },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while inserting/updating data!');
                    this.pService.done();
                },
                () => {
                    //this.resetDatable(pdt, gb);
                    this.httpService.showToast(this.toastkey, 'success', 'Successfully inserted/updated');
                    this.entryModalClose();
                    this.pService.done();
                });
    }

    resetDatable(pdt, gb): void {
        if (gb && gb.value && gb.value != '') {
            gb.value = '';
            if (pdt) pdt.reset();
        }
    }

    deleteData(): void {
        this.httpService.postData('api/loginuser/multidelete/', this.selectedChk).
            subscribe(
                data => {
                    this.httpService.showUnsuccessDelMsg(this.toastkey, data);
                },
                err => {
                    this.doneDeleteProcess('error', 'Error occurs while deleting data!');
                },
                () => {
                    this.doneDeleteProcess('success', 'Successfully deleted');
                });
    }

    deleteModalClose() {
        this.deleteModal.close();
        this.httpService.AddClassToModal(true);
    }


    doneDeleteProcess(header: string, msg: string) {
        this.selectedChk = null;
        this.deleteModalClose();
        this.httpService.showToast(this.toastkey, header, msg);
    }

    actionModalClose() {
        this.actionModal.close();
        this.httpService.AddClassToModal(true);
    }

    exportToCSV() {
        this.httpService.postData('api/loginuser/export', null).subscribe(
            data => { },
            err => { },
            () => {
                //window.location.href = this.httpService._url + 'excel/loginuser.csv';
                this.httpService.DirectLocToWindow("loginuser");
            }
        );
    }

    entryModalOpen() {
        this.isEntryModalOpen = true;
        this.entryModal.open();
    }

    entryModalClose() {
        this.errorUNString = null;
        this.errorNameString = null;
        this.errorPwdString = null;
        this.errorUTString = null;
        this.errorEmailString = null;
        this.errorDPhString = null;
        this.isEntryModalOpen = false;
        this.entryModal.close();
    }

    showDialogToAdd(): void {
        this.isNew = true;
        this.selectedData = new LoginUser('', '', '', '', 0, 0, 0, '', '', '', '', '');
        this.entryModalOpen();
        if (this.vc != null && this.vc != undefined) this.vc.nativeElement.focus();
    }

    showDialogToEdit(loginUser: LoginUser): void {
        this.isNew = false;
        this.selectedData = Object.assign({}, loginUser);
        this.entryModalOpen();
        if (this.vc1 != null && this.vc1 != undefined) this.vc1.nativeElement.focus();
    }

    showDialogToDelete(): void {
        if (this.selectedChk != null && this.selectedChk != undefined && this.selectedChk.length > 0) {
            this.deleteModal.open();
        } else {
            this.httpService.showToast(this.toastkey, 'warn', 'Need to select row!');
        }
    }

    showLogDialog(): void {
        if (this.selectedData != undefined) {
            let temp = [];
            this.pService.start();
            this.httpService.getData('api/actionlog/' + this.selectedData.UN + '/' + 'LoginUser').subscribe(
                data => temp = data,
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                },
                () => {
                    if (temp != undefined) {
                        this.actionlogs = temp;
                        this.actionModal.open();
                    }
                    else { this.httpService.showToast(this.toastkey, 'warn', 'There is no record to show!') }
                    this.pService.done();
                });
        }
    }

    changedUN(e: string) {
        this.selectedData.UN = e;
        this.errorUNString = null;

        if (this.httpService.checkStringEmpty(this.selectedData.UN)) {
            this.errorUNString = 'User ID is required';
        } else {
            if (this.httpService.checkMaxLength(this.selectedData.UN, 20)) {
                this.errorUNString = 'Cannot exceed more than 20 characters.';
            } else {
                let isUserNameExist = false;
                this.httpService.postData('api/loginuser/IsUserNameExist', this.selectedData.UN).subscribe(
                    data => isUserNameExist = data,
                    err => { },
                    () => {
                        if (isUserNameExist) {
                            this.errorUNString = 'User ID is already exist.';
                        }
                    });
            }
        }
    }

    changedName(e: string) {
        this.selectedData.Name = e;
        this.errorNameString = null;

        if (this.httpService.checkStringEmpty(this.selectedData.Name)) {
            this.errorNameString = 'Name is required.';
        }

        if (this.httpService.checkMaxLength(this.selectedData.Name, 50)) {
            this.errorNameString = 'Cannot exceed more than 50 characters.';
        }
    }

    onChangedPassword(e: string) {
        this.selectedData.Pwd = e;
        this.errorPwdString = null;

        if (this.httpService.checkStringEmpty(this.selectedData.Pwd)) {
            this.errorPwdString = 'Password is required.';
        }

        if (this.httpService.checkMaxLength(this.selectedData.Pwd, 50)) {
            this.errorPwdString = 'Cannot exceed more than 50 characters.';
        }
    }

    changedUT(e) {
        this.selectedData.UT = e;
        this.errorUTString = null;

        if (this.httpService.checkStringEmpty(this.selectedData.UT)) {
            this.errorUTString = 'User Type is required.';
        }
    }

    changedEmail(e: string) {
        this.selectedData.Email = e;
        this.errorEmailString = null;

        if (this.httpService.checkStringEmpty(this.selectedData.Email)) {
            this.errorEmailString = 'Email is required.';
        } else {
            if (this.httpService.checkMaxLength(this.selectedData.Email, 50)) {
                this.errorEmailString = 'Cannot exceed more than 50 characters.';
            } else {
                this.httpService.checkEmail(this.selectedData.Email).then(valid => {
                    if (!valid) {
                        this.errorEmailString = 'Invalid email address.';
                    }
                    this.isEmailValid = valid;
                });
            }
        }
    }

    changedDPh(e: string) {
        this.selectedData.DPh = e;
        this.errorDPhString = null;

        if (this.httpService.checkStringEmpty(this.selectedData.DPh)) {
            this.errorDPhString = 'Department phone is required.';
        }

        if (!this.selectedData.DPh.match(/^[0-9]*$/)) {
            this.errorDPhString = 'Invalid department phone number.';
        }

        if (this.httpService.checkMaxLength(this.selectedData.DPh, 50)) {
            this.errorDPhString = 'Cannot exceed more than 50 characters.';
        }
    }

    isNotValid() {
        return this.selectedData === undefined ||
            this.selectedData === null ||
            this.httpService.checkStringEmpty(this.selectedData.UN) ||
            this.httpService.checkMaxLength(this.selectedData.UN, 20) ||
            this.httpService.checkStringEmpty(this.selectedData.Name) ||
            this.httpService.checkMaxLength(this.selectedData.Name, 50) ||
            this.httpService.checkStringEmpty(this.selectedData.Pwd) ||
            this.httpService.checkMaxLength(this.selectedData.Pwd, 50) ||
            this.httpService.checkStringEmpty(this.selectedData.UT) ||
            this.httpService.checkStringEmpty(this.selectedData.Email) ||
            !this.isEmailValid ||
            this.httpService.checkStringEmpty(this.selectedData.DPh) ||
            this.selectedData.DPh.match(/^[0-9]*$/) == null ||
            this.selectedData.DPh.match(/^[0-9]*$/) == undefined ||
            this.httpService.checkMaxLength(this.selectedData.DPh, 50);
    }

    changedPassword(): void {
        this.isConfPwdModalOpen = true;
        this.ConfirmedPwdModal.open();
    }

    editFormResultCurPwd(e: boolean) {
        this.isConfPwdModalOpen = false;
        this.ConfirmedPwdModal.close();
        if (e) {
            this.isChangedPwdModalOpen = true;
            this.ChangedPwdModal.open();
        }
    }

    editFormResultChangedPwd(e: any) {
        if (e != null && e!= undefined) {
            //this.selectedData = e;
            this.httpService.showToast(this.toastkey, 'success', 'Successfully update password.');    
            if(this.selectedData.UN == this.auth.UN){
                this.httpService.logout();
            }
        }
        this.isChangedPwdModalOpen = false;
        this.ChangedPwdModal.close();
    }
}