import { Component, OnInit, ViewChild, Input, OnDestroy, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import { SelectItem } from 'primeng/api';
import { UUID } from 'angular2-uuid';
import {NgProgressService} from "ng2-progressbar";

//Service
import { AuthService } from '../service/authservice';
import { HttpServices } from '../service/httpservices';
import { GlobalFunc } from '../service/globalfunction';


//Model
import { StockItem } from '../stockitem/stockitem';
import { JobPart } from '../job/jobpart';
import { StockInHandStatus } from '../job/stockinhandstatus';

@Component({
    selector: 'i-jobpart',
    templateUrl: './jobpart.component.html'
})

export class JobPartCP implements OnInit, OnDestroy, OnChanges {
    @Input() JID: string;
    @Input() DID: number;
    @Input() displayPrintStyle: boolean;
    @Input() brandName: string;
    @Input() isModal: boolean = false;

    @ViewChild('actionModal', { static: false }) actionModal: BsModalComponent;
    @ViewChild('entryModal', { static: false }) entryModal: BsModalComponent;
    @ViewChild('deleteModal', { static: false }) deleteModal: BsModalComponent;
    public toggle: boolean = false;
    public selectedChk: JobPart[];
    public selectedData: JobPart;
    public actionlogs: any[];
    public stockitems: SelectItem[];
    public _stockitemsList: StockItem[];
    public isNew: boolean = false;
    public jobparts: JobPart[] = [];
    public jobPartForm: FormGroup;
    public stockinHandStatus: StockInHandStatus;
    public ifValueChanged: boolean = false;
    public toastkey = "i-jobpart";
   // @ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public httpService: HttpServices, public fb: FormBuilder, public auth: AuthService) {
        this.stockinHandStatus = new StockInHandStatus();

        this.jobparts = [];
    }

    CreateForm(): void {
        this.jobPartForm = this.fb.group({
            'JPID': new FormControl(),
            'JID': new FormControl(''),
            'SID': new FormControl(0, Validators.required),
            'SN': new FormControl(''),
            'Cat': new FormControl(''),
            'Rqty': new FormControl(0),
            'Stus': new FormControl(''),
        });
    }

    ngOnInit() {
        // this.CreateForm();
        // this.getStockItemDDown();

        // if (this.JID != undefined) {
        //     this.GetJobParts();
        // }
    }

    ngOnDestroy() {
        // this.JID=undefined;
        // this.jobparts=[];
    }

    ngOnChanges(event) {
        // if(this.jobparts.length>0){
        //     this.jobparts=[];
        // }

        this.CreateForm();
        this.getStockItemDDown();

        if (this.JID) {
            this.GetJobParts();
        }
    }

    GetJobParts() {
        let temp = [];
        this.httpService.getData('api/getjobpart/' + this.JID + '/' + UUID.UUID()).subscribe(
            data => {
                temp = data;
            },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                this.pService.done();
            },
            () => {
                this.jobparts = temp;
            });
    }

    RefreshData(): void {
        let temp = [];
        if (this.JID == undefined) return;
        this.httpService.getData('getjobpart/' + this.JID).subscribe(
            data => temp = data,
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                this.pService.done();
            },
            () => {
                this.jobparts = temp;
            });
    }

    getStockItemDDown() {
        this._stockitemsList = [];
        this.stockitems = [];
        this.stockitems.push({ label: 'Select stockitem', value: null });

        this.httpService.getData('api/stockitembydid/' + (this.auth.DID ? this.auth.DID : this.DID)).subscribe(
            expdata => { this._stockitemsList = expdata },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data!');
                this.pService.done();
            },
            () => {
                for (let si of this._stockitemsList) {
                    if (this.brandName && this.brandName !== '') {
                        if (si.SN.includes(this.brandName)) {
                            this.stockitems.push({ label: si.SN + " / " + si.Cat + " / " + si.SID, value: si.SID });
                        }
                    }
                    else {
                        this.stockitems.push({ label: si.SN + " / " + si.Cat + " / " + si.SID, value: si.SID });

                    }
                }
            }
        );
    }

    findStockItem(): void {
        let i;
        let len = this.stockitems.length;
        for (i = 0; i < len; i++) {
            if (this._stockitemsList[i].SID === this.jobPartForm.controls['SID'].value) {
                this.jobPartForm.controls['SN'].patchValue(this._stockitemsList[i].SN);
                this.jobPartForm.controls['Cat'].patchValue(this._stockitemsList[i].Cat);
                break;
            }
        }
    }

    showDialogToAdd(): void {
        this.ifValueChanged = false;
        this.isNew = true;
        this.CreateForm();
        this.jobPartForm.controls['JID'].patchValue(this.JID);
        this.entryModal.open();
    }
    showDialogToEdit(jobpart: JobPart): void {
        this.isNew = false;
        this.CreateForm();
        this.stockitemChange(jobpart.SID);
        this.selectedData = jobpart;

        this.jobPartForm.patchValue({ JPID: jobpart.JPID, JID: this.JID, SID: jobpart.SID, Rqty: jobpart.Rqty });
        this.entryModal.open();
    }

    showDialogToDelete(): void {
        if (this.selectedChk != undefined) {
            this.deleteModal.open();
        } else {
            this.httpService.showToast(this.toastkey, 'warn', 'Need to select row!');
        }
    }

    showLogDialog(): void {
        if (this.selectedData != undefined) {
            let temp = [];
            this.httpService.getData('api/actionlog/' + this.selectedData.JPID.toUpperCase() + '/' + 'JobParts').subscribe(
                data => temp = data,
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                },
                () => {
                    if (temp != undefined) { this.actionlogs = temp; this.actionModal.open(); }
                    else { this.httpService.showToast(this.toastkey, 'warn', 'There is no record to show!'); }
                });
        }
    }

    entryModalClose() {
        this.entryModal.close();
        this.httpService.AddClassToModal(this.isModal);
    }

    stockitemChange(val): void {
        if (val != 0 && val != null) {

            this.ifValueChanged = true;
            let temp = [];
            this.httpService.getData('api/jobpartstatus/' + val + '/' + this.auth.ScID).subscribe(
                data => {
                    this.stockinHandStatus.StockInHand = data.StockInHand;
                    this.stockinHandStatus.Status = data.Status;
                },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                },
                () => { });
        }
    }

    saveData(): void {
        if (this.JID == undefined) return;
        if (this.jobPartForm == undefined) return;

        this.jobPartForm.controls["Stus"].patchValue(this.stockinHandStatus.Status);

        this.httpService.postData('api/jobpart', this.jobPartForm.value).
            subscribe(
                data => {
                    if (this.isNew) {
                        this.jobPartForm.controls['JPID'].patchValue(data.JPID);
                        this.jobPartForm.controls['Stus'].patchValue(data.Stus);
                        this.findStockItem();
                        this.jobparts.unshift(this.jobPartForm.value);
                    }
                    else {
                        this.jobPartForm.controls['Stus'].patchValue(data.Stus);
                        this.findStockItem();
                        this.jobparts[GlobalFunc.findSelectedIndex(this.jobparts, this.selectedData)] = this.jobPartForm.value;
                    }
                },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while inserting/updating data!');
                    this.pService.done();
                },
                () => {
                    this.entryModalClose();
                });
    }

    deleteModalClose() {
        this.deleteModal.close();
        this.httpService.AddClassToModal(this.isModal);
    }

    deleteData(): void {
        let jp = this.selectedChk;
        jp.forEach(value => {
            this.httpService.postData('api/job/jobpartdelete/', value.JPID).subscribe(
                data => {
                    this.jobparts.splice(GlobalFunc.findSelectedIndex(this.jobparts, value), 1);
                },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while deleting data!');
                    this.pService.done();
                },
                () => { this.httpService.showToast(this.toastkey, 'success', 'Successfully deleted'); }
            );
        });

        this.selectedChk = null;
        this.deleteModalClose();
    }

    actionModalClose() {
        this.actionModal.close();
        this.httpService.AddClassToModal(this.isModal);
    }
}