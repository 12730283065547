import { Component, OnInit, ViewChild, NgZone, Output, Input, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';

// Thrid Library
import {NgProgressService} from "ng2-progressbar";

//Service
import { DataChangedEvent } from '../service/datachangedevent';
import { HttpServices } from '../service/httpservices';
import { GlobalFunc } from '../service/globalfunction';

//Model
import { AuthService } from '../service/authservice';
import { LoginUser } from '../loginuser/loginuser';

@Component({
    selector: 'i-changedPassword',
    templateUrl: './changedPassword-entry.html'
})
export class ChangedPasswordEntryCP implements OnInit {
    @Output() frmClick: EventEmitter<any> = new EventEmitter<any>();
    @Input() isModal: boolean = false;
    @Input() data: LoginUser;
    @ViewChild('input', { static: false }) vc;
    btnProcess:boolean = false;

    tempPwd: string | any = '';
    errorPwdString ='';
   
    showPass: boolean = false;

    //border color
    //validCurrPass = true;
    //vaildASCII: boolean = true;
    msgErr: string ='';
    
    public toastkey = "i-changedPassword";

    constructor(private pService: NgProgressService ,public httpService: HttpServices, public fb: FormBuilder, public _dataChanged: DataChangedEvent, public _ngZone: NgZone, public auth: AuthService) {
        //this.pService.start();
        this._dataChanged.customerChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "") {
                        
                    }
                });
            }
        });
    }

    ngOnInit(): void {      
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.vc.nativeElement.focus();
        }, 500);
    }

    refresh() {
        this.tempPwd = '';
        this.msgErr = undefined;
        this.pService.done();
    }

    valChangeloginPwd(e: string) {
        this.tempPwd = e;
        this.checkWarning();
    }

    checkWarning():Promise<boolean> {
        return new Promise<boolean>((resolve)=>{
            this.errorPwdString = '';
            if (this.httpService.checkStringEmpty(this.tempPwd)) {
                this.errorPwdString = 'Please fill new password.';
            }
    
            if (this.httpService.checkMaxLength(this.tempPwd, 50)) {
                this.errorPwdString = 'Cannot exceed more than 50 characters.';
            }
    
            if(!this.httpService.validASCII(this.tempPwd)){
                this.errorPwdString ="Unicode characters are not alllowed in password.";
            }
            resolve(true);
        })                
    }

    isNotValid(): boolean {
        return !this.httpService.checkStringEmpty(this.errorPwdString);
    }

    showPassword() {
        this.showPass = !this.showPass;
    }

    cancel() {
        if (this.isModal) {
            this.frmClick.emit(undefined);
        }
    }

    gotoConfirm(event: any){
        if (event.keyCode === 13) {
            this.save();
        }
    }

    save() {
        if(this.btnProcess) return;
        this.checkWarning().then(()=>{
            if(!this.httpService.checkStringEmpty(this.errorPwdString)){
                return;
            }else{
                this.btnProcess = true;
                this.pService.start();
                let obj = Object.assign({}, this.data);        
                obj.Pwd = GlobalFunc.HashString(this.tempPwd);
                let param = {
                    "UN" : obj.UN,                 
                    "Pwd" : obj.Pwd                 
                 }
                this.httpService.postData('api/saveResetPwd', param).subscribe(
                    exp => {
                        this.btnProcess = false;
                        
                    },
                    err => {
                        this.btnProcess= false;
                        this.httpService.showToast(this.toastkey, 'error', 'Error occurs while inserting/updating data!');
                        this.pService.done();
                    },
                    () => {
                        //this.resetDatable(pdt, gb);
                        //this.httpService.showToast(this.toastkey, 'success', 'Successfully inserted/updated');                
                        this.pService.done();
                        if (this.isModal) {
                            this.frmClick.emit(obj);
                        }
                    }
                );
            }
        })        
        
    }
}