import { Component, OnInit, ViewChild, Input, OnChanges, NgZone } from '@angular/core';

// Thrid Library
import { MenuItem } from 'primeng/api';
import { BsModalComponent } from 'ng2-bs3-modal';
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';
import { DataChangedEvent } from '../service/datachangedevent';

//Model
import { Job } from '../job/job';
import { Customer } from '../customer/customer';
import {JobViewModel} from '../joblist/jobviewmodel';

@Component({
    selector: 'i-dashboardsupview',
    templateUrl: './dashboardsupervisorview.component.html',
    styleUrls: ['./animate.css']
})
export class DashboardSupViewCP implements OnInit, OnChanges {
    public items: MenuItem[];
    public isPhotoView: boolean = false;
    @ViewChild('confirmModal', { static: false }) confirmModal: BsModalComponent;
    @ViewChild('jobdoneModal', { static: false }) jobdoneModal: BsModalComponent;
    @ViewChild('photoModal', { static: false }) photoModal: BsModalComponent;
    @Input() tstat: string;
    @Input() job: Job;
    @Input() mcb_name: string;
    @Input() isModal: boolean = false;

    public tempStatus: string = "";

    public confirmMsgs: string = "";
    public customer: Customer;
    public customers: Customer[] = [];
    public animateStyle: string;

    public jobv:JobViewModel;
    public toastkey = "i-dashboardsupview";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public httpService: HttpServices, public _dataChanged: DataChangedEvent, public _ngZone: NgZone) {
        this.animateStyle = '';
        this.subscribeToEvent();
        this.jobv=new JobViewModel();
    }

    ngOnInit() {
        this.customer = new Customer();
    }

    subscribeToEvent(): void {
        this._dataChanged.jobTRateChanged.subscribe((changedData) => {
            if (changedData.Value1 != undefined) {
                if (changedData.Value1 == this.job.JID) {
                    this._ngZone.run(() => {
                        this.animateStyle = 'animated shake';
                        this.job.TProg = changedData.Value2;
                    });
                } else { this.animateStyle = ''; }
            }
        });
    }

    ngOnChanges(changes: any) {
        if (this.mcb_name != undefined) this.job.MoN = this.mcb_name;

        if (this.tstat != undefined) {
            if (this.tstat == 'd') {
                this.items = [{
                    label: 'Menu',
                    items: [
                        {
                            label: 'Re-check', command: (event) => {
                                this.tempStatus = 'b';
                                this.confirmMsgs = 'Are you sure to Re-check ? ';
                                this.confirmModal.open();
                            }
                        },
                        {

                            label: 'Done', command: (event) => {
                                this.tempStatus = 'c';
                                this.confirmMsgs = 'This job is being done ? ';
                                this.confirmModal.open();
                            }
                        }
                    ]
                }];
            }
            else if (this.tstat == 'c') {
                this.items = [{
                    label: 'Menu',
                    items: [
                        {
                            label: 'Done', command: (event) => {
                                this.tempStatus = 'c';
                                this.confirmMsgs = 'This job is being done ? ';
                                this.confirmModal.open();
                            }
                        }
                    ]
                }];
            }
            else {
                this.items = [];
            }
        }
    }

    supStatusChanges(): void {
        this.confirmModalClose();

        if (this.job.JID != undefined) {
            if (this.tempStatus == 'c') {

                this.job.SStat = 'c';
                this.GetCustomerByID();
                this.jobdoneModal.open();
            }
            else if (this.tempStatus == 'b') {
                //this.job.SStat = 'b';

                this.jobv.JID=this.job.JID;
                this.jobv.SStat='b';
                this.jobv.TStat=this.job.TStat;
                this.jobv.RSM=this.job.RSM;

                
                this.httpService.postData('api/jobsupervisorrecheck2', this.jobv).subscribe(
                    data => { },
                    err => {
                        this.httpService.showToast(this.toastkey, 'error', 'Error occurs while updating data!');
                        this.pService.done();
                    },
                    () => {
                        this._ngZone.run(() => {
                            if (this.job.SStat == 'b') {
                                this.job.TStat = 'a';
                                this.job.TProg = 0;
                            }
                        });
                    }
                );
            }
            else {
                this.job.SStat = this.tempStatus;
                this.httpService.postData('api/jobsupervisorstaus', this.job).subscribe(
                    data => {},
                    err => {
                        this.httpService.showToast(this.toastkey, 'error', 'Error occurs while updating data!');
                        this.pService.done();
                    },
                    () => {}
                );
            }
        }
    }

    GetCustomerByID(): void {
        let temp;
        this.httpService.getData('api/getcustomer/' + this.job.CID).subscribe(
            data => temp = data,
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                this.pService.done();
            },
            () => {this.customer = temp;}
        );
    }

    onCloseModal() {
        this.jobdoneModal.close();
        this.httpService.AddClassToModal(this.isModal);
    }

    changePanelTitle(JT: string, JAT1: string, TStat: string, WSDT, WADT): string {
        if (JAT1 == undefined || JAT1 == "") {
            return "warnTitle";
        }

        if (TStat == 'c') {
            if (WSDT != null && WADT != null) {
                return "expessTitle";
            }
            else {
                return "warrantyTitle";
            }
        }

        if (JT == "E") {
            return "expessTitle";
        }
        else if (JT == "S") {
            return "standardTitle";
        }
    }

    photoViewClick() {
        this.isPhotoView = true;
        this.photoModal.open();
    }

    photoModalClose() {
        this.isPhotoView = false;
        this.httpService.AddClassToModal(this.isModal);
    }

    confirmModalClose(){
        this.confirmModal.close();
        this.httpService.AddClassToModal(this.isModal);
    }
}