import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

// Thrid Library
import { Observable } from 'rxjs';
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';

@Component({
    selector: 'i-custTakenPrint',
    templateUrl: './printcomp.html'
})
export class PrintCusTakenVoucherComp implements OnInit, OnDestroy {
    sub: any;
    jobId: any;
    printObj: any;
    takenDate: Date;
    BAmo: number;
    public toastkey = "i-custTakenPrint";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public router: Router, public aroute: ActivatedRoute, public httpservice: HttpServices, public location: Location) {
        this.jobId = '';
        this.takenDate = new Date();
        this.BAmo = 0;

        this.sub = this.aroute.params.subscribe(param => {
            this.jobId = param['jid'];
        });

        this.printObj = { JID: null, JIDR: null, JOB: '', TAmo: 0, DAmo: 0, Crec: 0, Chg: 0, CN: '', Ph: '', Addr: '', jobVoucherData: null, MCB: '', MoID: null }
    }

    ngOnInit() {
        this.httpservice.getData('api/getjobvoucherdata/' + this.jobId).subscribe(
            data => { this.printObj = data; },
            err => {
                this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while getting data!');
                this.pService.done();
            },
            () => {
                let tmp = Observable.timer(50, 1).subscribe(rps => {
                    tmp.unsubscribe();
                    window.print();
                });
            }
        );
    }

    ngOnDestroy() {
        this.sub = undefined;
        this.printObj = undefined;
        this.jobId = undefined;
        this.BAmo = undefined;
    }

    goBack() {
        this.location.back();
    }
}