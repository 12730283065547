import { Component, OnInit, ViewChild, NgZone, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';
import { GlobalFunc } from '../service/globalfunction';
import { AuthService } from '../service/authservice';

//Model
import { Customer } from './customer';
import { SelectItem } from 'primeng/api';

@Component({
    selector: 'i-customerdialog',
    templateUrl: './customerdialog.component.html'
})

export class CustomerDialogCP implements OnInit, AfterViewInit {
    @Input() customer: Customer = new Customer();
    @Output() onClick = new EventEmitter<Customer>();
    @ViewChild('cn',{ static: false }) cn;
    @ViewChild('actionModal',{ static: false }) actionModal: BsModalComponent;

    public isValid: boolean = false;
    public emailValid: boolean = true;
    public isNew: boolean;
    public customers: Customer[];
    public filteredCities: any[];
    public actionlogs: any[];
    public customerID: string;
    public toastkey = "i-customerdialog";    
    isDuplicatePhone: boolean;
    townshipList: SelectItem[] = [
        { label: 'Select Township', value: null },
        { label: 'ဇမ္ဗူသီရိမြို့နယ်', value: 'ဇမ္ဗူသီရိမြို့နယ်' },
        { label: 'ဇေယျာသီရိမြို့နယ်', value: 'ဇေယျာသီရိမြို့နယ်' },
        { label: 'တပ်ကုန်းမြို့နယ်', value: 'တပ်ကုန်းမြို့နယ်' },
        { label: 'ဒက္ခိဏသီရိမြို့နယ်', value: 'ဒက္ခိဏသီရိမြို့နယ်' },
        { label: 'ပုဗ္ဗသီရိမြို့နယ်', value: 'ပုဗ္ဗသီရိမြို့နယ်' },
        { label: 'ပျဉ်းမနားမြို့နယ်', value: 'ပျဉ်းမနားမြို့နယ်' },
        { label: 'လယ်ဝေးမြို့နယ်', value: 'လယ်ဝေးမြို့နယ်' },
        { label: 'သာဝတ္ထိမြို့', value: 'သာဝတ္ထိမြို့' },
        { label: 'အေလာမြို့', value: 'အေလာမြို့' },
        { label: 'ဥတ္တရသီရိမြို့နယ်', value: 'ဥတ္တရသီရိမြို့နယ်' },
    ];
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public httpService: HttpServices, public fb: FormBuilder, public _ngZone: NgZone, public auth: AuthService) { 
        //this.pService.start();
    }

    ngOnInit() {        
    }

    ngAfterViewInit() {
        if (this.cn != null || this.cn != undefined) this.cn.nativeElement.focus();
    }

    newObj(): Customer {
        return new Customer(null, null, null, null, null, null, false, false, false);
    }
    
    showLogDialog(): void {
        if (this.customer != undefined) {
            let temp = [];
            this.httpService.getData('api/actionlog/' + this.customer.CID + '/' + 'Customer').subscribe(
                data => temp = data,
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                },
                () => {
                    if (temp != undefined) { this.actionlogs = temp; this.actionModal.open(); }
                    else { this.httpService.showToast(this.toastkey, 'warn', 'There is no record to show!'); }
                });
        }
    }

    onclick(isSaving: boolean): void {
        if (isSaving) {
            if (!this.customer) return;
            this.httpService.postData('api/customer', this.customer).subscribe(
                data => { this.customer.CID = data; },
                err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while inserting/updating data!'),
                () => { this.onClick.emit(this.customer); });

        }
        else { this.onClick.emit() }
    }


    actionModalClose() {
        this.actionModal.close();
        //this.httpService.AddClassToModal(true);
    }

    onChangedPhone(e: string) {
        this.customer.Ph = e.trim();
        this.httpService.postData("api/customer/checkphdup", { Ph: this.customer.Ph }).subscribe(
            data => this.isDuplicatePhone = data,
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
            },
            () => {
            }
        );
    }

    IsNotValid(): boolean {
        return !this.customer.CN ||
            !this.customer.Ph ||
            this.customer.Ph.length > 50 ||
            !this.customer.Ph.match(/^[0-9]*$/) ||
            (this.customer.Em && !this.customer.Em.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) ||
            (this.customer.Em && this.customer.Em.length > 50) ||
            (this.customer.City && this.customer.City.length > 50) ||
            this.isDuplicatePhone
    }

}