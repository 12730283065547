import { Component, OnInit, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

// Thrid Library
import { SelectItem } from 'primeng/api';
import { LazyLoadEvent } from 'primeng/api';
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';
import { DataChangedEvent } from '../service/datachangedevent';
import { GlobalFunc } from '../service/globalfunction';

//Model
import { TechReviewQuery } from './techreviewquery';
import { Job } from '../job/job';
import { MCB } from '../job/mcb';
import { LoginUser } from '../loginuser/loginuser';
import { AuthService } from '../service/authservice';

@Component({
    selector: 'i-techreview',
    templateUrl: './techreview.component.html'
})
export class TechReviewCp implements OnInit, OnDestroy {

    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    
    printMode: boolean = false;
    periods: SelectItem[];
    reviewlist: Job[] = [];
    query: TechReviewQuery;
    _listOfMon: MCB[] = [];
    loginUsers: LoginUser[] = [];
    users: SelectItem[];
    techStatus: SelectItem[];
    status: string = "";
    recheckCount: number = 0;

    sourcedata: any[] = [];
    totalRecords: number;
    toastkey = "i-techreview";

    luChanged: any;
    modelChanged: any;
    brandChanged: any;
    catChanged: any;

    constructor(private pService: NgProgressService ,public httpService: HttpServices, public router: Router, public auth: AuthService, public _dataChanged: DataChangedEvent, public _ngZone: NgZone) {
        this.pService.start();
        this.periods = [];
        this.periods = GlobalFunc.periodList();
        this.techStatusBind();
        this.query = new TechReviewQuery();

        this.luChanged = this._dataChanged.loginUserChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "loginuser") {
                        this.afterBroadCastEvent(sRobj, this.loginUsers, 'UN');
                    }
                });
            }
        });

        this.modelChanged = this._dataChanged.modelChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "model") {
                        this.GetMcbInBroadcast();
                    }
                });
            }
        });

        this.brandChanged = this._dataChanged.brandChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "brand") {
                        this.GetMcbInBroadcast();
                    }
                });
            }
        });

        this.catChanged = this._dataChanged.categoryChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "category") {
                        this.GetMcbInBroadcast();
                    }
                });
            }
        });
    }

    ngOnInit() {
        this.query.TStat = 'a';
        this.query.Period = 'a';
        this.defaultDateTime();

        this.GetRequiredData().then(exp => {
            if (exp) {
                this.Search();
            }
        });
    }

    ngOnDestroy() {
        this.periods = this.reviewlist = this.query = this._listOfMon = this.loginUsers = this.users = this.techStatus = this.sourcedata = undefined;

        if (this.luChanged != undefined) this.luChanged.unsubscribe();
        if (this.modelChanged != undefined) this.modelChanged.unsubscribe();
        if (this.brandChanged != undefined) this.brandChanged.unsubscribe();
        if (this.catChanged != undefined) this.catChanged.unsubscribe();
    }

    GetRequiredData(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let luList: any = [];
            let mcbList: any = [];
            this.httpService.getDataFJ(['api/loginuser', 'api/job/getmcb']).subscribe(
                data => {
                    luList = data[0];
                    mcbList = data[1];
                },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                    resolve(false);
                },
                () => {
                    if (luList) {
                        this.loginUsers = luList.map(lu => Object.assign({}, lu));
                        this.setLoginUserData(this.loginUsers);
                    }

                    if (mcbList) {
                        this._listOfMon = mcbList.map(m => Object.assign({}, m));
                    }

                    this.pService.done();
                    resolve(true);
                }
            );
        });
    }

    GetMcbInBroadcast() {
        let mcbList = [];
        this.httpService.getData('api/job/getmcb').subscribe(
            data => { mcbList = data || []; },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                this.pService.done();
            },
            () => {
                this._listOfMon = mcbList.map(m => Object.assign({}, m));
            }
        );
    }

    afterBroadCastEvent(objData, dataList, id) {
        if (objData && objData.Obj && dataList) {
            let ind = dataList.findIndex(temp => temp[id] === objData.Obj[id]);
            if (objData.Msg === 'deleted') {
                if (objData.ObjType.toLowerCase() === "loginuser") {

                    this.httpService.getData('api/loginuser').subscribe(
                        data => { this.loginUsers = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'); 
                        this.pService.done(); 
                    },
                        () => { this.setLoginUserData(this.loginUsers); 
                            this.pService.done(); 
                        }
                    );

                }else if (objData.ObjType.toLowerCase() === "model" || objData.ObjType.toLowerCase() === "brand" || objData.ObjType.toLowerCase() === "category") {
                    this.GetMcbInBroadcast();
                }
            }
            else {
                if (ind >= 0) dataList[ind] = objData.Obj;
                else dataList.unshift(objData.Obj);

                if (objData.ObjType.toLowerCase() === "loginuser") {
                    this.setLoginUserData(dataList);
                }
            }

            
        }
    }

    setLoginUserData(dataList: LoginUser[]) {
        this.users = [];
        this.users.push({ value: null, label: "Select technician" });
        if (dataList) {
            dataList.map(lu => {
                if (lu.UT === 'Service Technician') {
                    if (this.auth.DID) {
                        if (lu.DID === this.auth.DID) {
                            this.users.push({ value: lu.UN, label: lu.Name });
                        }
                    } else {
                        this.users.push({ value: lu.UN, label: lu.Name });
                    }
                }
            });
        }
    }

    getmcbName(moid: number): string {
        if (this._listOfMon == undefined) return "";
        for (let obj of this._listOfMon) {
            if (obj.MoID == moid) {
                return obj.MoN + " / " + obj.CatN + " / " + obj.BN;
            }
        }
    }

    techStatusBind() {
        this.techStatus = [];
        this.techStatus.push({ value: 'a', label: "Work In Progress" });
        this.techStatus.push({ value: 'd', label: "Done" });
        this.techStatus.push({ value: 'e', label: "Unable to repair" });
    }

    printjob(): void {
        window.print();
    }

    Search(): void {
        this.reviewlist = [];
        if (this.query.TStat == 'a') this.status = "Work In Progress";
        else if (this.query.TStat == 'd') this.status = "Done";
        else this.status = "Unable to repair";

        this.recheckCount = 0;

        this.httpService.postData('api/get/techreview', this.query).subscribe(
            data => {
                //this.reviewlist = data;
                this.sourcedata = data;
                this.totalRecords = this.sourcedata.length;
            },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                this.pService.done();
            },
            () => {
                if (this.sourcedata != undefined) {
                    for (let review of this.sourcedata) {
                        if (review.MoID != undefined) {
                            review.MoN = this.getmcbName(review.MoID);
                            this.recheckCount += review.RCC;
                        }
                    }
                }
                // this.reviewlist = this.sourcedata.slice(0, 20);
                this.reviewlist = this.sourcedata.map(a => Object.assign({}, a));
            });
    }

    loadLazy(event: LazyLoadEvent) {
        setTimeout(() => {
            if (this.sourcedata) {
                this.reviewlist = this.sourcedata.slice(event.first, (event.first + event.rows));
            }
        }, 250);
    }

    usersChanged() {
        this.Search();
    }

    valueChanged(): void {
        this.status = "";
        this.Search();
    }

    periodChange(): void {
        this.defaultDateTime();
        this.Search();
    }

    datesChange() {
        this.Search();
    }

    defaultDateTime() {
        this.query.FromDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
        this.query.ToDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 60);
    }

    exportToCSV() {
        this.httpService.postData('api/get/techreview/export', this.query).subscribe(
            data => { },
            err => { },
            () => {
                //window.location.href = this.httpService._url + 'excel/techreview.csv';
                this.httpService.DirectLocToWindow("techreview");
            }
        );
    }
}