import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../authservice';

@Injectable()
export class JustLogin implements CanActivate {

    constructor(public auth: AuthService) { }

    canActivate() {

        return this.auth.isLoggedIn;
        
    }
}