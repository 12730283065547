export class Job {
    constructor(
        public JID?,
        public JIDR?,
        public JDT?,
        public DID?,
        public JCUN?,
        public JAT1?,
        public JAT2?,
        public CID?,
        public JOB?,
        public STID?,

        public MoID?,
        public HAPP?,
        public APPDT?,
        public DAmo?,
        public OAcc?,
        public RC?,
        public JT?,
        public RT?,
        public TStat?,
        public TProg?,

        public TDnDT?,
        public TDBy?,
        public SStat?,
        public SDnDT?,
        public SDBy?,
        public Taken?,
        public TDT?,
        public SAmo?,
        public PAmo?,
        public TAmo?,

        public Crec?,
        public Chg?,
        public WSDT?,
        public WADT?,
        public WR?,
        public RCC?,
        public ScID?,
        public SuppN?,
        public UN?,
        public DeviceID?,
        public RSM?,
        public FOC?,

        public STN?,
        public MoN?,
        public JobAccessories?,
        public HasPhoto?,

        public Imei?,
        public JK?,
        public Customer?,
        public Model?,
        public JobPhotos?,
        public APDH?,//Appointment date history
    ) { }
}