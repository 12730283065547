import { Component, OnInit, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

// Thrid Library
import { SelectItem } from 'primeng/api';
import { BsModalComponent } from 'ng2-bs3-modal';
import {NgProgressService} from "ng2-progressbar";
import { DataChangedEvent } from '../service/datachangedevent';

//Service
import { HttpServices } from '../service/httpservices';
import { AuthService } from '../service/authservice';

//Model
import { JobViewModel } from '../joblist/jobviewmodel';
import { WarrantyListQuery } from './wrrantylistquery';
import { Department } from '../department/department';
import { LoginUser } from '../loginuser/loginuser';
import { Model } from '../model/model';
import { Job } from '../job/job';
import { ServiceCenter } from '../servicecenter/servicecenter';

@Component({
    selector: 'i-warranty',
    templateUrl: './warranty.component.html'
})
export class WarrantyCP implements OnInit, OnDestroy {
    @ViewChild('entryModal', { static: false }) entryModal: BsModalComponent;
    jvm: JobViewModel[] = [];
    wForm: FormGroup;
    printMode: boolean = false;
    _loginUsers: LoginUser[];

    _departments: Department[];
    departmentlist: SelectItem[] = [];

    _models: Model[];
    modelslist: SelectItem[];

    serviceCenters: ServiceCenter[] = [];
    servicecenterlist: SelectItem[] = [];

    warrantyListQuery: WarrantyListQuery = new WarrantyListQuery(false, new Date(), new Date(), 1, -1, null);
    warrantyOptions = [{ label: 'Show all NOT delivered', value: 1 },
    { label: 'Show all NOT arrived', value: 2 },
    { label: 'Search by Check-In date', value: 3 },
    { label: 'Search by Appointment date', value: 4 },
    { label: 'Search by Delivered date', value: 5 },
    { label: 'Search by Arrived date', value: 6 }];

    suppliers = [];
    filteredSuppN: any[];
    samo: number = 0;
    pamo: number = 0;
    tamo: number = 0;
    wsdtIsNull: boolean = false;
    sourcedata: any[] = [];
    totalRecords: number;
    toastkey = "i-warranty";

    depChanged: any;
    luChanged: any;
    scChanged: any;
    jscChanged: any;
    jobChanged: any;
    jtstChanged: any;
    jsmstChanged: any;
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    
    constructor(private pService: NgProgressService ,public httpService: HttpServices, public fb: FormBuilder, public auth: AuthService, public _dataChanged: DataChangedEvent, public _ngZone: NgZone) {
        this.pService.start();

        this.CreateForm();

        this.depChanged = this._dataChanged.departmentChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "department") {
                        this.afterBroadCastEvent(sRobj, this._departments, 'DID');
                    }
                });
            }
        });

        this.luChanged = this._dataChanged.loginUserChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "loginuser") {
                        this.afterBroadCastEvent(sRobj, this._loginUsers, 'UN');
                    }
                });
            }
        });

        this.scChanged = this._dataChanged.serviceCenterChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "servicecenter") {
                        this.afterBroadCastEvent(sRobj, this.serviceCenters, 'ScID');
                    }
                });
            }
        });

        this.jscChanged = this._dataChanged.jobServiceCenterChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.Obj && (sRobj.Obj.ScID === this.warrantyListQuery.ScID || this.warrantyListQuery.ScID === -1) && (sRobj.Obj.DID === this.warrantyListQuery.DID || !this.warrantyListQuery.DID)) {
                this._ngZone.run(() => {
                    this.GetData();
                });
            }
        });

        this.jobChanged = this._dataChanged.jobChanged.subscribe((sRobj) => {
            if (sRobj) {
                if (sRobj.Msg === 'deleted' && sRobj.Value2 && sRobj.Value2.ScID === this.auth.ScID && (sRobj.Value2.ScID === this.warrantyListQuery.ScID || this.warrantyListQuery.ScID === -1) && (sRobj.Value2.DID === this.warrantyListQuery.DID || !this.warrantyListQuery.DID)) {
                    this._ngZone.run(() => {
                        this.GetData();
                    });
                }
                else if (sRobj.Obj && this.auth.ScID === sRobj.Obj.ScID && (sRobj.Obj.ScID === this.warrantyListQuery.ScID || this.warrantyListQuery.ScID === -1) && (sRobj.Obj.DID === this.warrantyListQuery.DID || !this.warrantyListQuery.DID)) {
                    this._ngZone.run(() => {
                        this.GetData();
                    });
                }
            }
        });

        this.jtstChanged = this._dataChanged.jobTStatusChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.Value2 && sRobj.Value2.ScID === this.auth.ScID && (sRobj.Value2.ScID === this.warrantyListQuery.ScID || this.warrantyListQuery.ScID === -1) && (sRobj.Value2.DID === this.warrantyListQuery.DID || !this.warrantyListQuery.DID)) {
                this._ngZone.run(() => {
                    this.GetData();
                });
            }
        });

        this.jsmstChanged = this._dataChanged.jobSmStatusChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.Value2 && sRobj.Value2.ScID === this.auth.ScID && (sRobj.Value2.ScID === this.warrantyListQuery.ScID || this.warrantyListQuery.ScID === -1) && (sRobj.Value2.DID === this.warrantyListQuery.DID || !this.warrantyListQuery.DID)) {
                this._ngZone.run(() => {
                    this.GetData();
                });
            }
        });
    }

    ngOnInit() {
        this.GetRequiredData().then(exp => {
            if (exp) {
                this.Get_Suppliers();
                this.GetData(); // new
            }
        });

    }

    ngOnDestroy() {

        this.jvm = this.wForm = this._loginUsers = this._departments = this.departmentlist = this._models =
            this.modelslist = this.serviceCenters = this.servicecenterlist = this.warrantyListQuery = this.warrantyOptions = this.suppliers = this.filteredSuppN =
            this.sourcedata = undefined;

        if (this.depChanged != undefined) this.depChanged.unsubscribe();
        if (this.luChanged != undefined) this.luChanged.unsubscribe();
        if (this.scChanged != undefined) this.scChanged.unsubscribe();
        if (this.jscChanged != undefined) this.jscChanged.unsubscribe();
        if (this.jobChanged != undefined) this.jobChanged.unsubscribe();
        if (this.jtstChanged != undefined) this.jtstChanged.unsubscribe();
        if (this.jsmstChanged != undefined) this.jsmstChanged.unsubscribe();

    }

    CreateForm(): void {
        this.wForm = this.fb.group({
            'JID': new FormControl(0),
            'WSDT': new FormControl(),
            'WADT': new FormControl(),
            'WR': new FormControl(''),
            'SuppN': new FormControl()
        });
    }

    GetRequiredData(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let depList: any = [];
            let luList: any = [];
            let scList: any = [];
            this.httpService.getDataFJ(['api/department', 'api/loginuser', 'api/servicecenter']).subscribe(
                data => {
                    depList = data[0];
                    luList = data[1];
                    scList = data[2];
                },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                    resolve(false);
                },
                () => {
                    if (depList) {
                        this._departments = depList.map(d => Object.assign({}, d));
                        this.setDepartmentData(this._departments);
                    }

                    if (luList) {
                        this._loginUsers = luList.map(lu => Object.assign({}, lu));
                    }

                    if (scList) {
                        this.serviceCenters = scList.map(sc => Object.assign({}, sc));
                        this.setServiceCenterData(this.serviceCenters);
                    }
                    this.pService.done();
                    resolve(true);
                }
            );
        });
    }

    GetData() {
        this.httpService.postData('api/jobwarranty', this.warrantyListQuery).subscribe(
            data => {
                this.sourcedata = data;
                this.totalRecords = this.sourcedata.length;
                this.jvm = this.sourcedata;
            },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!');
                this.pService.done();
            },
            () => {
                if (this.sourcedata != undefined) {
                    if (this.sourcedata.length > 0) {
                        for (let wrty of this.sourcedata) {
                            wrty.Name = this.getLoginUserName(wrty.JCUN);
                        }
                        this.jvm = this.sourcedata.map(a => Object.assign({}, a));
                    }
                    else {
                        this.httpService.showToast(this.toastkey, 'warn', 'There is no warranty !');
                    }
                }
            });
    }

    Get_Suppliers(): void {
        let temp = [];
        this.httpService.getData('api/getSuppliers').subscribe(
            data => temp = data,
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  service type from server!');
                this.pService.done();
            },
            () => {
                this.suppliers = [];
                this.suppliers.push({ label: 'Select Suppliers', value: null });
                if (temp == undefined) return;
                temp.forEach(x => { this.suppliers.push({ label: x.ID, value: x.ID }) })
            }
        );
    }

    afterBroadCastEvent(objData, dataList, id) {
        if (objData && objData.Obj && dataList) {
            let ind = dataList.findIndex(temp => temp[id] === objData.Obj[id]);
            if (objData.Msg === 'deleted') {
                if (objData.ObjType.toLowerCase() === "department") {

                    this.httpService.getData('api/department').subscribe(
                        data => { this._departments = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'); 
                        this.pService.done(); 
                    },
                        () => { this.setDepartmentData(this._departments); 
                            this.pService.done(); 
                        }
                    );

                } else if (objData.ObjType.toLowerCase() === "servicecenter") {

                    this.httpService.getData('api/servicecenter').subscribe(
                        data => { this.serviceCenters = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                        this.pService.done(); 
                        },
                        () => { this.setServiceCenterData(this.serviceCenters); 
                            
                            this.pService.done(); 
                        }
                    );

                } else if (objData.ObjType.toLowerCase() === "loginuser") {

                    this.httpService.getData('api/loginuser').subscribe(
                        data => { this._loginUsers = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'); 
                           this.pService.done(); 
                    },
                        () => { 
                            this.pService.done(); 
                        }
                    );

                }
            }
            else {
                if (ind >= 0) dataList[ind] = objData.Obj;
                else dataList.unshift(objData.Obj);

                if (objData.ObjType.toLowerCase() === "department") {
                    this.setDepartmentData(dataList);
                } else if (objData.ObjType.toLowerCase() === "servicecenter") {
                    this.setServiceCenterData(dataList);
                }
            }


        }
    }

    setDepartmentData(dataList: Department[]) {
        this.departmentlist = [];
        this.departmentlist.push({ label: '- Select Department -', value: null });
        if (dataList) {
            dataList.map(dep => this.departmentlist.push({ label: dep.DN, value: dep.DID }));
        }
    }

    setServiceCenterData(dataList: ServiceCenter[]) {
        this.servicecenterlist = [];
        this.servicecenterlist.push({ label: 'Select service center', value: -1 });
        if (dataList) {
            dataList.map(x => this.servicecenterlist.push({ label: x.ScN, value: x.ScID }));
        }
    }

    openEntryModal(jv: JobViewModel): void {
        this.wForm.patchValue({
            JID: jv.JID,
            WSDT: jv.WSDT != null ? new Date(jv.WSDT) : null,
            WADT: jv.WADT != null ? new Date(jv.WADT) : null,
            WR: jv.WR,
            SuppN: jv.SuppN
        });
        var index = this.findByIndexJvm(jv.JID);
        this.samo = this.jvm[index].SAmo;
        this.pamo = this.jvm[index].PAmo;
        this.tamo = this.jvm[index].TAmo;

        if (jv.WSDT == null) this.wsdtIsNull = true;
        else this.wsdtIsNull = false;

        this.entryModal.open();
    }

    findByIndexJvm(jid): number {
        for (var _index in this.jvm) {
            if (this.jvm[_index].JID === jid) {
                return parseInt(_index);
            }
        }
    }

    save(): void {
        if (this.wForm.controls['JID'].value != null) {
            let job = new Job();
            job.JID = this.wForm.controls['JID'].value;
            job.WSDT = this.wForm.controls['WSDT'].value;
            job.WADT = this.wForm.controls['WADT'].value;
            job.WR = this.wForm.controls['WR'].value;
            job.SuppN = this.wForm.controls['SuppN'].value;
            job.SAmo = this.samo;
            job.PAmo = this.pamo;
            job.TAmo = this.tamo;
            job.DeviceID = '';

            this.httpService.postData('api/job/jobwarranty', job).
                subscribe(
                    data => { },
                    err => {
                        this.httpService.showToast(this.toastkey, 'error', 'Error occurs while updating data!');
                        this.pService.done();
                    },
                    () => {
                        this.httpService.showToast(this.toastkey, 'success', 'Save successfully!');
                        var index = this.findByIndexJvm(job.JID);
                        if (job.WSDT != "")
                            this.jvm[index].WSDT = job.WSDT;
                        else
                            this.jvm[index].WSDT = null;

                        if (job.WADT != "")
                            this.jvm[index].WADT = job.WADT;
                        else
                            this.jvm[index].WADT = null;

                        this.jvm[index].WR = job.WR;
                        this.jvm[index].SuppN = job.SuppN;
                        this.jvm[index].SAmo = job.SAmo;
                        this.jvm[index].PAmo = job.PAmo;
                        this.jvm[index].TAmo = job.TAmo;

                        this.entryModal.close();
                    });
        }
    }

    getLoginUserName(Name: string): string {
        if (this._loginUsers == undefined) return "";
        for (let obj of this._loginUsers) {
            if (obj.UN == Name) {
                return obj.Name;
            }
        }
    }

    onchangeWOptions(event): void {
        this.WrtyChange(event).then(x => { this.GetData() });
    }

    WrtyChange(event) {
        return new Promise<any>((resolve, reject) => {
            if (event.value == 3 || event.value == 4 || event.value == 5 || event.value == 6)
                this.warrantyListQuery.ChkDate = true;
            else
                this.warrantyListQuery.ChkDate = false;

            this.warrantyListQuery.FromDate = new Date();
            this.warrantyListQuery.ToDate = new Date();

            this.warrantyListQuery.OptionValue = event.value;
            resolve(true);
        });
    }

    printjob(): void {
        window.print();
    }

    search(event): void {
        let query = event.query;
        this.filteredSuppN = [];
        for (let obj of this.suppliers) {
            let value = obj.label;
            if (value == null) return;
            if (value.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                this.filteredSuppN.push(value);
            }
        }
    }

    calculateAmount(): void {
        this.tamo = this.samo + this.pamo;
    }

    exportToCSV() {
        this.httpService.postData('api/jobwarranty/export', this.warrantyListQuery).subscribe(
            data => { },
            err => { },
            () => {
                //window.location.href = this.httpService._url + 'excel/jobwarranty.csv';
                this.httpService.DirectLocToWindow("jobwarranty");
            }
        );
    }
}