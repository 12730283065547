declare var require;
const local = require("localforage");

export class lFservice {
    constructor() { }

    static Config_LF(): void {
        local.config({
            driver: local.WEBSQL,
            name: "LnnService",
            version: "2.0",
            size: 52428800,
            storeName: 'keyvaluepairs',
            description: ''
        });
    }

    static  Set_LF(DbKey: string, DbValue: any): Promise<any> {
        return local.setItem(DbKey, DbValue);
    }

    static Get_LF(DbKey: string): Promise<any> {        
        return local.getItem(DbKey);
    }

    static Remove_LF(DbKey: string): Promise<any> {
        return local.removeItem(DbKey);
    }    

    static RemoveAllData(): void {
        local.clear();
        // local.removeItem(GlobalFunc.AcKey);
        // local.removeItem(GlobalFunc.CTKey);
        // local.removeItem(GlobalFunc.BrKey);
        // local.removeItem(GlobalFunc.MKey);
        // local.removeItem(GlobalFunc.MCBKey);
        // local.removeItem(GlobalFunc.CusKey);
        // local.removeItem(GlobalFunc.DPKey);
        // local.removeItem(GlobalFunc.SCKey);
        // local.removeItem(GlobalFunc.STKey);
        // local.removeItem(GlobalFunc.SIKey);
        // local.removeItem(GlobalFunc.CJKey);
        // local.removeItem(GlobalFunc.SplKey);
        // local.removeItem(GlobalFunc.LUKey);
        //local.removeItem(GlobalFunc.JSftKey);
    }

   

    
}

