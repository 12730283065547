import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

//Third Library
import { NgProgressService } from "ng2-progressbar";

//Model
import { HttpServices } from '../service/httpservices';
import { StockIn } from './stockin';
import { StockInDetail } from './stockindetail';
import { UUID } from 'angular2-uuid';
import { ServiceCenter } from '../servicecenter/servicecenter';
import { StockItem } from '../stockitem/stockitem';
import { SelectItem } from 'primeng/api';
@Component({
    selector: 'i-purchase',
    templateUrl: './purchase-entry.component.html'
})
export class PurchaseEntryCP {
    public stockIn: StockIn;
    public toastkey = "i-stockin";

    stockinDetailList: Array<StockInDetail> = [];
    servicecenters: ServiceCenter[];
    servicecenterlist: SelectItem[];

    stockitemsList: StockItem[];
    stockitems: SelectItem[];
    current_route: any;
    SIID: string;
    errorInd: Array<{ item: boolean, scenter: boolean }> = [];

    constructor(private pService: NgProgressService, public httpService: HttpServices, public router: Router, public activeRoute: ActivatedRoute) {
        this.pService.start();
        this.stockIn = new StockIn('', undefined, '', '', '');
    }

    ngAfterViewInit() {
        this.GetRequiredData().then(() => {
            this.current_route = this.activeRoute.params.subscribe((param: any) => {
                if (param && param['siid']) {
                    this.getStockList(param['siid']);
                }
                else {
                    this.stockIn.SIDT = new Date();
                    this.pService.done();
                }
            })
        });
    }

    getStockList(SIID: any): void {
        this.httpService.getData('api/getstockinbyid/' + SIID).subscribe(
            data => {
                this.stockIn = data;
                this.stockIn.SIDT = new Date(data.SIDT);
            },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                this.pService.done();
            },
            () => { this.getStockInDetail(SIID); });
    }

    getStockInDetail(SIID: any): void {
        this.stockinDetailList = [];
        this.httpService.getData('api/getstockindetail/' + SIID).subscribe(
            data => this.stockinDetailList = data,
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                this.pService.done();
            },
            () => {
                this.pService.done();
            });
    }

    GetRequiredData(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let scList: any = [];
            let siList: any = [];

            this.httpService.getDataFJ(['api/servicecenter', 'api/stockitem']).subscribe(
                data => {
                    scList = data[0];
                    siList = data[1];
                },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                    resolve(false);
                },
                () => {
                    if (scList) {
                        this.servicecenters = scList.map(d => Object.assign({}, d));
                        this.setServiceCenterData(this.servicecenters);
                    }

                    if (siList) {
                        this.stockitemsList = siList.map(d => Object.assign({}, d));
                        this.setStockItemData(this.stockitemsList);
                    }

                    this.pService.done();
                    resolve(true);
                }
            );
        });
    }

    setServiceCenterData(dataList: ServiceCenter[]) {
        this.servicecenterlist = [];
        this.servicecenterlist.push({ label: '- Select Service Center -', value: null });
        if (dataList) {
            dataList.map(sc => this.servicecenterlist.push({ label: sc.ScN, value: sc.ScID }));
        }
    }

    setStockItemData(dataList: StockItem[]) {
        this.stockitems = [];
        this.stockitems.push({ label: '- Select Stock-Item -', value: null });
        if (dataList) {
            dataList.map(si => this.stockitems.push({ label: si.SN + " / " + si.Cat + " / " + si.SID, value: si.SID }));
        }
    }

    addStockInDetail() {
        var stockInDetails = new StockInDetail(UUID.UUID(), '', '', '', '', '', 1, 0);
        stockInDetails.ScID = this.httpService.auth.ScID;
        this.stockinDetailList.push(stockInDetails);
    }

    deleteDetails(data: StockInDetail) {
        let ind = this.stockinDetailList.findIndex((rt: any) => rt.SIDID === data.SIDID);
        if (ind >= 0) this.stockinDetailList.splice(ind, 1);
        this.checkValid();
    }

    filterChanged() {
        this.checkValid();
    }

    checkValid(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.errorInd = [];
            if (this.stockinDetailList && this.stockinDetailList.length > 0) {
                for (let i = 0; i < this.stockinDetailList.length; i++) {
                    this.errorInd[i] = { item: false, scenter: false };
                    this.errorInd[i].item = this.stockinDetailList[i].SID ? false : true;
                    this.errorInd[i].scenter = this.stockinDetailList[i].ScID ? false : true;
                }
            }
            resolve(true);
        })
    }

    save() {
        this.checkValid().then(() => {
            if (this.stockinDetailList && this.stockinDetailList.findIndex((d: any) => !d.SID || !d.ScID) > -1) return;
            this.httpService.postData('api/updatestockin', this.stockIn).
                subscribe(data => {
                    if (!this.stockIn.SIID) {
                        this.stockIn.SIID = data.Message;
                    }
                    if (this.stockinDetailList && this.stockinDetailList.length > 0) {
                        this.stockinDetailList.map(d => {
                            d.SIID = this.stockIn.SIID;
                            d.SIDID = "";
                        })

                        this.httpService.postData('api/multistockindetail', this.stockinDetailList).
                            subscribe(data => {
                                this.httpService.showToast(this.toastkey, 'success', 'Save Successfully');
                                setTimeout(()=>{
                                    var link = ['stockinlist'];
                                    this.router.navigate(link);
                                },300)
                            },
                                err => {
                                    this.httpService.showToast(this.toastkey, 'error', err);
                                    this.pService.done();
                                },
                                () => { });
                    } 
                    else {
                        this.httpService.showToast(this.toastkey, 'success', 'Save Successfully');
                        setTimeout(()=>{
                            var link = ['stockinlist'];
                            this.router.navigate(link);
                        },300)
                    }
                },
                    err => {
                        this.httpService.showToast(this.toastkey, 'error', err);
                        this.pService.done();
                    },
                    () => {

                    });
        })
    }

    cancel() {
        var link = ['stockinlist'];
        this.router.navigate(link);
    }
}