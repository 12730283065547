import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

//third libraries
import {NgProgressService} from "ng2-progressbar";
import { GlobalFunc } from '../service/globalfunction';

//service
import { HttpServices } from '../service/httpservices';
import { AuthService } from '../service/authservice';

//model
import { LoginUser } from '../loginuser/loginuser';

@Component({
    selector: 'login-app',
    templateUrl: './login.html'
})

export class LoginApplication implements OnInit,AfterViewInit {

    public loginForm: FormGroup;
    public loginUsers: LoginUser[];
    public toastkey = "login-app";

    @ViewChild('loginname', { static: false }) loginname;
    @ViewChild('pwd', { static: false }) pwd;
    public loggedIn: boolean = false;
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public router: Router, public auth: AuthService, public httpService: HttpServices, public fb: FormBuilder) {

        this.auth.getAuthInfo().then(n => {
            if (n) {
                this.auth.setAllData(n);
                this.router.navigate(['/']);
            }
            else {
                if (!this.router.url.includes("external-login")) {
                    this.logout();
                }
            }
        }).catch(err => {
            this.logout();
        });
     }

     logout() {
        this.httpService.logout().then(res => {
            
        });
    }

    ngOnInit() {
        this.GetLoginUsers();
        this.httpService.GetDepartment();
        this.httpService.GetServiceCenters();
        this.CreateForm();
    }

    ngAfterViewInit(){
        if(this.loginname){
            this.loginname.nativeElement.focus();
        }
    }

    GetLoginUsers() {
        this.loginUsers = [];
        this.pService.start();
        this.httpService.getData('api/loginuser_unAuth').subscribe(
            data => {
                this.loginUsers = data;
                this.pService.done();
            },
            err =>{
                this.httpService.showToast(this.toastkey, 'error','Error occurs while loading Login users from server!');
                this.pService.done();
            }
        );
    }

    CreateForm() {
        this.loginForm = this.fb.group({
            'UN': new FormControl('', Validators.required),
            'Pwd': new FormControl('', Validators.required),
            'DID': new FormControl(null),
            'ScID': new FormControl(null)
        });
    }

    login(loginuser: LoginUser) {
        //Hash Base64
        loginuser.Pwd = GlobalFunc.HashString(loginuser.Pwd);

        this.httpService.logIn(loginuser.UN, loginuser.Pwd).subscribe(
            result => {
                if (typeof result === 'object') {
                    result.did = loginuser.DID;
                    result.scid = loginuser.ScID;

                    // this.auth.loginName = result.name;
                    // this.auth.authtoken = result.access_token;
                    // this.auth.role = result.role;
                    // this.auth.DID = result.did;
                    // this.auth.ScID = result.scid;
                    // this.auth.DPh = result.dph;

                    // // let depIndex = this.httpService.deptllist.findIndex(dep => dep.value === result.did)
                    // // if (depIndex >= 0)
                    // //     result.dn = this.httpService.deptllist[depIndex].label;
                    // // else
                    // //     this.auth.DN = '';

                    // // let scIndex = this.httpService.serviceCenters.findIndex(sc => sc.value === result.scid);
                    // // if (scIndex >= 0)
                    // //     result.scn = this.httpService.serviceCenters[scIndex].label;
                    // // else
                    // //     result.scn = '';

                    // this.auth.DN = result.dn;
                    // this.auth.ScN = result.scn;

                    // this.auth.STID = result.stid;
                    // this.auth.STN = result.stn;
                    // this.auth.UN = result.un;
                    // this.auth.Addr = result.addr;
                    // this.auth.isLoggedIn = true;

                    this.auth.setAllData(result);


                    this.loggedIn = true;
                    this.auth.storeCredential(result);
                    // lFservice.Get_LF(GlobalFunc.CusKey).then(cusdata => {
                    //     lFservice.Get_LF(GlobalFunc.MCBKey).then(mcbdata => {
                    //         if (!cusdata && !mcbdata) {
                    //             this.pService.start();
                    //             this.Get_Customers().then(abc => {
                    //                 this.Get_MCB().then(def => {

                    //                     this.pService.done();
                    //                 }).catch(err => { 
                    //                     this.pService.done(); 
                    //                     //this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Error occurs while getting model/cate/brand!' }); 
                    //                     this.httpService.showToast(this.toastkey, 'error','Error occurs while getting model/cate/brand!');
                    //                 });
                    //             }).catch(err => { 
                    //                 this.pService.done(); 
                    //                 //this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Error occurs while getting customers!' }); 
                    //                 this.httpService.showToast(this.toastkey, 'error','Error occurs while getting customers!');
                    //             });
                    //         }
                    //     }).catch(err => { 
                    //         //this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Error occurs while getting model/cate/brand from LocalData!' }); 
                    //         this.httpService.showToast(this.toastkey, 'error','Error occurs while getting model/cate/brand from LocalData!');
                    //     });

                    // }).catch(err => { 
                    //     //this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Error occurs while getting customers from LocalDat!' }); 
                    //     this.httpService.showToast(this.toastkey, 'error','Error occurs while getting customers from LocalDat!');
                    // });
                }
                else {
                    this.auth.clearLoginProperty(null, null);
                    //this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Invalid user name or password!' });
                    this.httpService.showToast(this.toastkey, 'error','Invalid user name or password!');
                }
            }, err => { 
                //this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Invalid user name or password!' });
                this.httpService.showToast(this.toastkey, 'error','Invalid user name or password!');
            },
            () => { }
        );
    }

    loginName_ModelChange(event) {
        if (this.loginUsers != undefined) {
            let temp: LoginUser = new LoginUser();
            temp = this.loginUsers.filter(value => value.UN == event)[0];

            if (temp != undefined) {
                if (temp.UT == 'Admin') {
                    this.loginForm.controls['DID'].disable(); this.loginForm.controls['ScID'].disable();
                }
                else {
                    this.loginForm.patchValue({ DID: temp.DID, ScID: temp.ScID });
                }
            }
            else this.loginForm.patchValue({ DID: null, ScID: null });
        }
    }

    setFocusNext(event) {
        if (event.keyCode === 13) {
            this.pwd.nativeElement.focus();
        }
    }

    goLogin(event, loginuser: LoginUser) {
        if (event.keyCode === 13) {
            if (this.loginForm.controls['UN'].value === null || this.loginForm.controls['UN'].value === undefined) return;
            this.login(loginuser);
        }
    }

    // Get_Customers(): Promise<boolean> {
    //     return new Promise<boolean>((resolve, reject) => {
    //         let temp = [];
    //         lFservice.Get_LF(GlobalFunc.CusKey).then(value => {
    //             if (value == null) {
    //                 this.httpService.getData('api/customerForDDown').subscribe(
    //                     data => temp = data,
    //                     err => {
    //                         this.httpService.showToast(this.toastkey, 'error','Error occurs while loading data from server!');
    //                         reject(null);
    //                     },
    //                     () => {
    //                         lFservice.Set_LF(GlobalFunc.CusKey, temp).then(value => {
    //                             resolve(true);
    //                         }).catch(err => {
    //                             this.httpService.showToast(this.toastkey, 'error','Error occurs while loading data!');
    //                             reject(null);
    //                         });
    //                     });
    //             }
    //         }).catch(err => {
    //             this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading mcb data!');
    //             reject(null);
    //         });
    //     });
    // }

    // Get_MCB(): Promise<boolean> {
    //     return new Promise<boolean>((resolve, reject) => {
    //         let temp = [];
    //         lFservice.Get_LF(GlobalFunc.MCBKey).then(value => {
    //             if (value == null) {
    //                 this.httpService.getData('api/job/getmcb').subscribe(
    //                     data => temp = data,
    //                     err => {
    //                         this.httpService.showToast(this.toastkey, 'error','Error occurs while loading  mcb from server!');
    //                         reject(null);
    //                     },
    //                     () => {
    //                         lFservice.Set_LF(GlobalFunc.MCBKey, temp).
    //                             then(_value => {
    //                                 resolve(true);
    //                             }).
    //                             catch(err => {
    //                                 this.httpService.showToast(this.toastkey, 'error','Error occurs while loading mcb from server!');
    //                                 reject(null);
    //                             });
    //                     });
    //             }
    //         }).catch(err => {
    //             this.httpService.showToast(this.toastkey, 'error','Error occurs while loading mcb data!');
    //             reject(null);
    //         });
    //     });
    // }
}
