import { Component, ViewChild, Input, OnInit } from '@angular/core';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';
import { AuthService } from '../service/authservice';

//Model
import { JobPhoto } from '../job/jobphoto';

@Component({
    selector: 'i-jobphoto',
    templateUrl: './jobphoto.component.html'
})

export class JobPhotoCP implements OnInit {
    @Input() JID: string;
    @Input() jobPhotos: JobPhoto[] = [];
    @ViewChild('confirmModal', { static: false }) confirmModal: BsModalComponent;
    public deleteJPID: string;
    src: string = "";
    //jobphotos: JobPhoto[] = [];
    public toastkey = "i-jobphoto";

    // resizeOptions: ResizeOptions = {
    //     resizeMaxHeight: 256,
    //     resizeMaxWidth: 256
    // };
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public httpService: HttpServices, public auth: AuthService) { }

    ngOnInit(){
        
        if(this.jobPhotos !== null && this.jobPhotos !== undefined){
            this.jobPhotos.map(jp=>{
                jp.Photo = "data:image/png;base64," + jp.Photo;
            });
        }
    }

    // selected(imageResult: ImageResult) {
    //     this.src = imageResult.resized && imageResult.resized.dataURL || imageResult.dataURL;
    // }

    confirmDelete(jpid: string) {
        this.deleteJPID = jpid;
        this.confirmModal.open();
    }

    deletePhoto() {
        // let index: number = -1;
        // for (var _index in this.jobphotos) {
        //     if (this.jobphotos[_index].JPID == this.deleteJPID) {
        //         index = parseInt(_index);
        //     }
        // }
        const index = this.jobPhotos.findIndex(jp => jp.JPID == this.deleteJPID);
        if (index >= 0) {
            this.httpService.postData('api/jobphotodelete', this.deleteJPID).subscribe(
                data => { },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while deleting data from server!');
                    this.pService.done();
                },
                () => {
                    this.jobPhotos.splice(index, 1);
                    this.confirmModal.close();
                }
            );
        }
    }

    add() {
        let jobphoto = new JobPhoto();
        jobphoto.JID = this.JID;
        jobphoto.JPID = undefined;
        jobphoto.Photo = this.src;

        this.httpService.postData('api/jobphoto', jobphoto).subscribe(
            data => { jobphoto.JPID = data.JPID; },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                this.pService.done();
            },
            () => { 
                if(this.jobPhotos !== null && this.jobPhotos !== undefined){
                    this.jobPhotos.push(jobphoto);
                    this.src = '';
                }
                
            });
            
        
    }

    cancel() {
        this.src = '';
    }
    
    fileChangeListener($event) {
        if ($event.target === undefined) return;
        var image:any = new Image();
        var myReader:FileReader = new FileReader();
        var that = this;

        this.resizeImage($event.target.files[0],1000,1000).then(blob=>{
            myReader.onloadend = function (loadEvent:any) {
                image.src = loadEvent.target.result;
                that.src = loadEvent.target.result;
            };
            myReader.readAsDataURL(blob);
        });
    }

    resizeImage(file:File, maxWidth:number, maxHeight:number):Promise<Blob> {
        return new Promise((resolve, reject) => {
            let image = new Image();
            image.src = window.URL.createObjectURL(file);
            image.onload = () => {
                let width = image.width;
                let height = image.height;
                
                if (width <= maxWidth && height <= maxHeight) {
                    resolve(file);
                }
    
                let newWidth;
                let newHeight;
    
                if (width > height) {
                    newHeight = height * (maxWidth / width);
                    newWidth = maxWidth;
                } else {
                    newWidth = width * (maxHeight / height);
                    newHeight = maxHeight;
                }
    
                let canvas = document.createElement('canvas');
                canvas.width = newWidth;
                canvas.height = newHeight;
    
                let context = canvas.getContext('2d');
    
                context.drawImage(image, 0, 0, newWidth, newHeight);
    
                canvas.toBlob(resolve, file.type);
            };
            image.onerror = reject;
        });
    }
}