export class Customer {
    constructor(
        public CID?,
        public CN?,
        public PhT?,
        public Ph?,
        public Em?,
        public Addr?,
        public City?,
        public nSMS?,
        public nEm?,
        public nPh?) { }
}