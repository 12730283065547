import {Component,Inject,forwardRef} from '@angular/core';
import {AppComponent} from './app.component';

@Component({
    selector: 'app-footer',
    template: `
        <div class="footer">
            <div style="margin-top:35px;">
                <span class="footer-text-right"><span class="ui-icon-copyright"></span> <span>All Rights Reserved</span></span>
            </div>
        </div>
    `
})
export class AppFooter {}