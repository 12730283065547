import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';

// Thrid Library
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';

//Model
import { JobViewModel } from '../joblist/jobviewmodel';
import { MCB } from '../job/mcb';

@Component({
    selector: 'i-deviceid',
    templateUrl: './deviceid.component.html'
})
export class DeviceIDCP implements OnInit, AfterViewInit {
    @ViewChild('vcdeviceId', { static: false }) vcdeviceId;
    public deviceId: any;
    public jobs: JobViewModel[] = [];
    public _listOfMon: MCB[];
    public printMode: boolean = false;
    public toastkey = "i-deviceid";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public httpservice: HttpServices) {
        this.pService.start();
        this._listOfMon = [];
    }

    ngOnInit() {
        this.Get_MCB();
    }

    ngAfterViewInit() {
        if (this.vcdeviceId) {
            this.vcdeviceId.nativeElement.focus();
        }
    }

    Get_MCB(): void {
        this.httpservice.getData('api/job/getmcb').subscribe(
            data => this._listOfMon = data,
            err => {
                this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!');
                this.pService.done();
            },
            () => {
                this.pService.done();
            });
    }

    lookJobsByDId() {
        if (this.deviceId == undefined || this.deviceId == null || this.deviceId == '') {
            this.httpservice.showToast(this.toastkey, 'warn', 'There is no DeviceID!');
            return;
        }
        this.pService.start();

        this.httpservice.postData('api/getalljobsbydid', this.deviceId).subscribe(
            data => { this.jobs = data },
            err => {
                this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!');
                this.pService.done();
            },
            () => {
                if (this.jobs != undefined) {
                    for (let obj of this.jobs) {
                        obj.MoN = this.getmcbName(obj.MoID);
                    }
                }
                this.pService.done();
            }
        );
    }

    getmcbName(moid: number): string {
        if (this._listOfMon == undefined) return "";

        for (let obj of this._listOfMon) {
            if (obj.MoID == moid) {
                return obj.MoN + " / " + obj.CatN + " / " + obj.BN;
            }
        }
    }

    printjobs() {
        window.print();
    }
}