import { Component, Input, Output, EventEmitter, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';
import { AuthService } from '../service/authservice';

//Model
import { Customer } from '../customer/customer';
import { Job } from '../job/job';

declare var require;
var CryptoJS = require("crypto-js");

@Component({
    selector: 'i-jobdone',
    templateUrl: './jobdone.component.html'
})
export class JobDoneCP implements OnInit, OnDestroy {
    @Output() onClick = new EventEmitter<boolean>();
    @Input() job;
    @Input() customer;
    @Input() isModal: boolean = false;
    @ViewChild('printQRCodeModal', { static: false }) printQRCodeModal: BsModalComponent;

    public BAmo: number = 0;
    //public qrdata: string='';
    public isprint: boolean;
    public sub: any;
    public jobID: string = '';
    public tmpdeviceID: string = '';
    public techName: string = '';
    public showQRPrintDialog: boolean = false;
    public toastkey = "i-jobdone";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public httpService: HttpServices, public router: Router, public aroute: ActivatedRoute, public auth: AuthService) {
        this.isprint = false;
        this.BAmo = 0;

        // if (this.job === undefined) {
        //     this.initJob();
        //     this.customer = new Customer();
        //     //this.qrdata = '';
        // }
        // this.sub = this.aroute.params.subscribe(
        //     param => {
        //         if (param['jobid'] != undefined) {
        //             this.jobID = param['jobid'];
        //             this.getJobByJobID().then(d => {
        //                 this.getCustomerByID().then(d => {
        //                     this.getTechName().then(d => {
        //                     });
        //                 });
        //                 this.calculateAmount();
        //             });
        //         }
        //     }
        // );
    }

    ngOnInit(){
        if (this.job === undefined) {
            this.initJob();
            this.customer = new Customer();
            //this.qrdata = '';
        }
        this.sub = this.aroute.params.subscribe(
            param => {
                if (param['jobid'] != undefined) {
                    this.jobID = param['jobid'];
                    this.getJobByJobID().then(d => {
                        this.getCustomerByID().then(d => {
                            this.getTechName().then(d => {
                            });
                        });
                        this.calculateAmount();
                    });
                }
            }
        );
    }

    ngOnDestroy() {
        if (this.sub === undefined || this.sub === null) this.sub.unsubscribe();
    }

    getJobByJobID(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (this.jobID != undefined) {
                this.httpService.getData('api/getjob/' + this.jobID).subscribe(
                    expdata => { this.job = expdata; },
                    err => {
                        this.httpService.showToast(this.toastkey, 'error', 'Error occurs while getting job data !');
                        resolve(false);
                    },
                    () => { resolve(true); });
            }
            else resolve(false);
        });
    }

    getCustomerByID(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (this.job.CID != null) {
                let temp;
                this.httpService.getData('api/getcustomer/' + this.job.CID).subscribe(
                    data => temp = data,
                    err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'); 
                    this.pService.done();
                     resolve(false); },
                    () => { if (temp != undefined) { this.customer = temp }; resolve(true); });
            }
            else resolve(false);
        });
    }

    getTechName(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.httpService.getData('api/gettechname/' + this.job.JAT1).subscribe(
                data => { this.techName = data; },
                err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while getting technician name !'); 
                this.pService.done(); 
                resolve(false); },
                () => { resolve(true); });
        });
    }

    calculateAmount(): void {
        // if (this.job.SAmo != undefined && this.job.PAmo != undefined && this.job.DAmo != undefined) {
        //     this.job.TAmo = this.job.SAmo + this.job.PAmo;
        //     this.BAmo = this.job.TAmo - this.job.DAmo;
        // }
        // else this.BAmo = 0;

        this.job.TAmo = this.job.SAmo + this.job.PAmo;
        this.BAmo = this.job.TAmo - this.job.DAmo;
    }

    onclick(isSaving: boolean): void {
        if (isSaving) {
            this.job.SStat = 'c';
            this.job.Taken = false;
            if (this.job.DeviceID === null || this.job.DeviceID === undefined) this.job.DeviceID = '';

            this.httpService.postData('api/job/jobdone', this.job).subscribe(
                data => { },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while updating data!\n\n' + err);
                    this.pService.done();
                },
                () => { this.onClick.emit(); });
        }
        else {
            if (this.jobID != '' || this.jobID != undefined) this.router.navigate(['joblist']);
            this.onClick.emit();
        }
    }

    generateDeviceID(jid: any): void {
        this.tmpdeviceID = jid;
        var hash = CryptoJS.SHA1(this.job.JID);
        let hashstring: string = CryptoJS.enc.Hex.stringify(hash);
        this.job.DeviceID = hashstring;
    }

    printQRCode(): void {
        if (this.tmpdeviceID != '') {
            this.saveDeviceID().then(d => {
                if ((this.job != undefined || this.job != null) && (this.job.DeviceID != undefined || this.job.DeviceID != null || this.job.DeviceID != '')) {
                    this.showQRPrintDialog = true;
                    this.printQRCodeModal.open();
                }
            });
        }
        else {
            if (this.job.DeviceID != undefined || this.job.DeviceID != null || this.job.DeviceID != '') {
                this.showQRPrintDialog = true;
                this.printQRCodeModal.open();
            }
        }
    }

    saveDeviceID(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            let tmp: any;

            this.httpService.getData('api/job/getdeviceId/' + this.tmpdeviceID).subscribe(
                data => { tmp = data; },
                err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while saving devieID !'); 
                this.pService.done(); 
                resolve(false); },
                () => {
                    if (tmp.length > 0) {
                        this.job.DeviceID = tmp;
                    }
                    resolve(true);
                }
            );
        })
    }

    initJob() {
        this.job = new Job(
            null, null, null, this.auth.DID, this.auth.loginName, '', '', null, '', null,
            null, false, new Date(), 0, '', '', 'E', '', '', 0,
            null, '', '', null, '', false, null, 0, 0, 0,
            0, 0, null, null, '', 0, this.auth.ScID, '', this.auth.loginName, '',
            '', false, '', '', null, 0, '', '', null, null, [], ''
        );
    }

    printQRCodeModalDismiss() {
        this.showQRPrintDialog = false;
        this.printQRCodeModal.dismiss();
        this.httpService.AddClassToModal(this.isModal);
    }
}