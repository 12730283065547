import { Component, OnInit, ViewChild, OnDestroy, NgZone, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import {NgProgressService} from "ng2-progressbar";
import { SelectItem } from 'primeng/api';

//Service
import { HttpServices } from '../service/httpservices';
import { AuthService } from '../service/authservice';
import { DataChangedEvent } from '../service/datachangedevent';
import { GlobalFunc } from '../service/globalfunction';

//Model
import { JobViewModel } from '../joblist/jobviewmodel';
import { DropDownValue } from '../job/dropdownvalue';

@Component({
    selector: 'i-techjoblist2',
    templateUrl: './techjoblist2.component.html'
})
export class TechJobListCP2 implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('confirmModal', { static: false }) confirmModal: BsModalComponent;
    @ViewChild('techStatusModal', { static: false }) techStatusModal: BsModalComponent;
    @ViewChild('tr', { static: false }) tr;

    @ViewChild('jobModal', { static: false }) jobModal: BsModalComponent;
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    
    jobs: JobViewModel[] = [];
    jobv: JobViewModel;

    objT: any;
    objSM: any;
    objJ: any;
    rowGroupMetadata: any;

    rbval: string;
    title: string = '';
    techRemark: string;
    jobRemark: string;
    note:string;
    jobmodaltitle: string;
    jid: string;
    brandName: string = '';
    toastkey = "i-techjoblist2";

    printMode: boolean = false;
    pRadioReChk: boolean;
    pRadioJobReChk: boolean;
    pRadioNote: boolean;
    showJobModal: boolean;

    oDropDownValueList: DropDownValue[];
    dropdownValues: DropDownValue[] = [];

    selectedValues: string;
    isunder500width: boolean = false;

    jobRemarks: SelectItem[] = [{ label: 'Select job\'s remark', value: null },
    { label: 'Service Only', value: 'Service Only' },
    { label: 'Parts Change', value: 'Parts Change' },
    { label: 'YGN Service', value: 'YGN Service' },
    { label: 'YGN Service2', value: 'YGN Service2' },
    { label: 'MDY Service', value: 'MDY Service' }];

    technicianRemark: string;

    constructor(private pService: NgProgressService ,public httpservice: HttpServices, public auth: AuthService, public router: Router, public _dataChanged: DataChangedEvent, public _ngZone: NgZone) {
        this.pService.start();
        this.pRadioReChk = false;
        this.pRadioJobReChk = false;
        this.pRadioNote = false;

        this.techRemark = '';
        this.jobRemark = '';

        this.objJ = _dataChanged.jobChanged.subscribe(
            srDataJ => {
                this._ngZone.run(() => this.getTechJobList());
            },
            err => { this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!') },
            () => { }
        );

        this.objT = _dataChanged.jobTStatusChanged.subscribe(
            srDataT => {
                this._ngZone.run(() => this.getTechJobList());
            },
            err => { this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!') },
            () => { }
        );

        this.objSM = _dataChanged.jobSmStatusChanged.subscribe(
            srDataSM => {
                this._ngZone.run(() => this.getTechJobList());
            },
            err => { this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!') },
            () => { }
        );
        this.jobv = new JobViewModel();
        this.jobmodaltitle = '';
    }

    ngOnInit(): void {
        this.getTechJobList();
        this.getDropdownValue();
        this.updateRowGroupMetaData();
    }

    ngOnDestroy() {
        if (this.objT != undefined) this.objT.unsubscribe();
        if (this.objSM != undefined) this.objSM.unsubscribe();
        if (this.objJ != undefined) this.objJ.unsubscribe();
    }

    ngAfterViewInit(){
        this.isunder500width = window.innerWidth < 500;
    }

    resize(e) {
        this.isunder500width = window.innerWidth < 500;
    }

    getTechJobList(): void {
        this.httpservice.postData('api/techjoblist2', this.auth.UN).subscribe(
            data => {
                this.jobs = data;
                this.pService.done();
            },
            err => {
                this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while loading  technician jobs from server!')
                this.pService.done();
            },

            () => {
                this.pService.done();
                this.jobs.sort((a, b) => a.JDT > b.JDT ? 1 : (b.JDT > a.JDT ? -1 : 0));
            }
        );
    }

    reBindTechRemarkMultiSelect(): void {

        if(this.jobv.TStat == 'e'){ //show input box only in Unable to repair status
            this.technicianRemark = this.jobv.RT;
            return;
        }

        this.dropdownValues = [];
        
        if (this.oDropDownValueList !== null && this.oDropDownValueList !== undefined) {
            if (this.jobv !== undefined && this.jobv !== null) {

                if (this.jobv.RT !== undefined && this.jobv.RT !== null && this.jobv.RT !== "") {
                    const ddvalueList = [];

                    let tempSplitList = this.jobv.RT.split(",", this.jobv.RT.length - 1);
                    if (tempSplitList != null && tempSplitList != undefined && tempSplitList.length > 0) {
                        tempSplitList.map(d => {
                            let ddvObj = this.oDropDownValueList.find(ddv => ddv.TechnicianRemark == d);
                            if (ddvObj != null && ddvObj != undefined) {
                                ddvalueList.push(ddvObj);
                            }
                        });

                        this.dropdownValues = ddvalueList.map(d => Object.assign({}, d));
                        this.selectedValues = this.jobv.RT;
                    }
                }
            }
        }
    }

    getDropdownValue(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let ddvList: any = [];
            let param = {"Type":"b"};
            this.httpservice.postData('api/DropDownValue/GetByType',param).subscribe(
                data => {
                    ddvList = data;
                },
                err => {
                    this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                    resolve(false);
                },
                () => {
                    //dropdown value
                    if (ddvList) {
                        this.oDropDownValueList = ddvList.slice();
                        // this.oDropDownValueList = ddvList.filter(
                        //     (obj, i, arr) => obj.TechnicianRemark != null && obj.TechnicianRemark != undefined && obj.TechnicianRemark != "" && (arr.findIndex(t => t.TechnicianRemark === obj.TechnicianRemark) === i)
                        // ).map(d => Object.assign({}, d)); //distinct & filter
                        this.oDropDownValueList.sort((a, b) => a.Description > b.Description ? 1 : (b.Description > a.Description ? -1 : 0));
                        //this.oDropDownValueList = ddvList.filter(v => v.TechnicianRemark != null && v.TechnicianRemark != undefined && v.TechnicianRemark != "").map(d => Object.assign({}, d));
                        this.reBindTechRemarkMultiSelect();
                    }
                    resolve(true);
                }
            );
        });
    }

    printjob(): void {
        window.print();
    }

    openStateModal(jv: JobViewModel): void {
        //this.jobv = jv;
        this.jobv = GlobalFunc.CloneData(jv, JobViewModel);
        this.brandName = jv.BN.slice(0, jv.BN.indexOf('/'));

        this.confirmModal.open();
        this.rbval = '';
        this.pRadioReChk = false;
        this.pRadioJobReChk = false;
        this.pRadioNote = false;

        this.jobRemark = this.jobv.JK;
        this.reBindTechRemarkMultiSelect();
    }

    AssignTechinicianRemark() {
        if(this.jobv.TStat != 'e'){
            if (this.dropdownValues !== null) {
                this.jobv.RT = "";
                this.dropdownValues.map(ja => {
                    this.jobv.RT = this.jobv.RT + (this.jobv.RT != "" ? "," : "") + ja.Description;
                });
            }  
        }else{
            this.jobv.RT = this.technicianRemark;
        }
    }

    AssignTechRemarkSelectedValue() {
        if (this.dropdownValues !== null) {
            this.selectedValues = "";
            this.dropdownValues.map(ja => {
                this.selectedValues = this.selectedValues + (this.selectedValues != "" ? ", " : "") + ja.Description;
            });
        }
    }

    techStatusChanges(): void {
        this.confirmModal.close();

        if (this.jobv && this.rbval) {
            switch (this.rbval) {
                case 'Waiting Customer Reply': {
                    this.jobv.TStat = 'b';
                    this.title = 'Waiting Customer Reply';
                    this.techStatusModalOpen();
                    break;
                }
                case 'Waiting Warranty': {
                    this.jobv.TStat = 'c';
                    this.jobv.WSDT = new Date();
                    this.title = 'Waiting Warranty';
                    this.techStatusModalOpen();
                    break;
                }
                case 'Done': {
                    this.jobv.TStat = 'd';
                    this.title = 'Technician Job Done ';
                    this.techStatusModalOpen();
                    break;
                }

                // case 'Hardware YGN': {
                //     this.jobv.TStat = 'f';
                //     this.saveTechStateChanges();
                //     break;
                // }

                // case 'Hardware MDY': {
                //     this.jobv.TStat = 'g';
                //     this.saveTechStateChanges();
                //     break;
                // }

                case 'Waiting Part': {
                    this.jobv.TStat = 'h';
                    this.saveTechStateChanges();
                    break;
                }

                // case 'Part Done': {
                //     this.jobv.TStat = 'i';
                //     this.saveTechStateChanges();
                //     break;
                // }
                // case 'Write Technician Remark': {
                //     this.jobv.RT = this.techRemark;
                //     this.saveTechStateChanges().then(rps => {
                //         let idx = this.jobs.findIndex(x => x.JID === this.jobv.JID);
                //         if (idx >= 0) {
                //             this.jobs[idx].RT = this.jobv.RT;
                //         }
                //     });
                //     break;
                // }

                case 'Choose Technician Remark': {
                    //this.jobv.RT = this.techRemark;
                    this.saveTechStateChanges().then(rps => {
                        let idx = this.jobs.findIndex(x => x.JID === this.jobv.JID);
                        if (idx >= 0) {
                            this.jobs[idx].RT = this.jobv.RT;
                        }
                    });
                    break;
                }
                case 'UnableRepair': {
                    this.jobv.TStat = 'e';
                    this.title = 'Technician Job Done (Unable to repair)';
                    this.techStatusModalOpen();
                    break;
                }
                case 'CancelCustomer': {
                    this.jobv.TStat = 'j';
                    this.title = 'Technician Job Done (Customer Cancel)';
                    this.techStatusModalOpen();
                    break;
                }
                case 'Job\'s Remark': {
                    this.jobv.JK = this.jobRemark;
                    this.saveTechStateChanges();
                    break;
                }
                case 'Note':{
                    this.jobv.RC = this.note;
                    this.saveTechStateChanges();
                }
            }
        }
    }

    saveTechStateChanges(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!this.pRadioReChk) {
                this.techStatusModal.close();
            }

            if (this.jobv.JID) {
                
                this.AssignTechinicianRemark();
                this.pService.start();
                this.httpservice.postData('api/jobstaus2', this.jobv).subscribe(
                    data => { },
                    err => { this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while updating data!'); reject(err) },
                    () => {
                        this.pService.done();
                        this.httpservice.showToast(this.toastkey, 'success', 'Technician state change successfully !');
                        resolve('ok');
                    }
                );
            }
        });
    }

    onRowSelect(e: any) {
        this.AssignTechRemarkSelectedValue();
    }

    onRowUnselect(e: any) {
        this.AssignTechRemarkSelectedValue();
    }

    handleHeaderCheckboxToggle(e: any){
        this.AssignTechRemarkSelectedValue();
    }

    // radioBtnChange(result,isJobRemark = false) {
    //     this.pRadioReChk = result;
    //     this.pRadioJobReChk = isJobRemark;
    //     if (result) {
    //         let timerSub = Observable.timer(50, 1000).subscribe(t => {
    //             this.tr.nativeElement.focus();
    //         }, err => { }, () => { timerSub.unsubscribe(); });
    //     }
    // }

    radioBtnChange(result, isJobRemark = false, isNote = false) {
        this.pRadioReChk = result;
        this.pRadioJobReChk = isJobRemark;
        this.pRadioNote = isNote;
        // if (result) {
        //     this.dropdownValues = [];
        //     let timerSub = Observable.timer(50, 1000).subscribe(t => {
        //         this.tr.nativeElement.focus();
        //     }, err => { }, () => { timerSub.unsubscribe(); });
        // }

        if (result) {
            this.reBindTechRemarkMultiSelect();
        } else {
            this.dropdownValues = [];
        }

        if(isNote){
            this.note = this.jobv.RC;
        }
    }

    gotoCreateJob() {
        // this.jobmodaltitle = 'Create Job';
        // this.showJobModal = true;
        // this.jid = null;
        // this.jobModal.open();
        this.router.navigate(['/createjob']);
    }

    onClickEmit(event) {
        this.showJobModal = false;
        this.jobModal.close();
        if (this.jid != undefined) this.jid = undefined;
    }

    onSort() {
        this.updateRowGroupMetaData();
    }

    updateRowGroupMetaData() {
        this.rowGroupMetadata = {};
        if (this.jobs) {
            for (let i = 0; i < this.jobs.length; i++) {
                let rowData = this.jobs[i];
                let jstate = rowData.JobState;
                if (i == 0) {
                    this.rowGroupMetadata[jstate] = { index: 0, size: 1 };
                }
                else {
                    let previousRowData = this.jobs[i - 1];
                    let previousRowGroup = previousRowData.JobState;
                    if (jstate === previousRowGroup)
                        this.rowGroupMetadata[jstate].size++;
                    else
                        this.rowGroupMetadata[jstate] = { index: i, size: 1 };
                }
            }
        }
    }

    customSort(event) {
        event.data.sort((a, b) => {
            let result = 0;
            let groupField_a = a.JobState;
            let groupField_b = b.JobState;
            let sortField_a = a[event.field];
            let sortField_b = b[event.field];
            if (groupField_a == groupField_b || event.field == "JobState") {
                if (sortField_a == null && sortField_b != null) result = -1;
                else if (sortField_a != null && sortField_b == null) result = 1;
                else if (sortField_a == null && sortField_b == null) result = 0;
                else if (sortField_a == null && sortField_b != null) result = -1;
                else if (typeof sortField_a === 'string' && typeof sortField_b === 'string') result = sortField_a.localeCompare(sortField_b);
                else result = (sortField_a < sortField_b) ? -1 : (sortField_a == sortField_b) ? 0 : 1;
            }
            return (event.order * result);
        });
    }

    techStatusModalOpen() {
        
        this.reBindTechRemarkMultiSelect();
        this.techStatusModal.open();
    }
}