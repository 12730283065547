import { Injectable, EventEmitter } from '@angular/core';
import { SrMessage } from '../model/message';

@Injectable()
export class DataChangedEvent {
    public accessoryChanged: EventEmitter<SrMessage>;
    public brandChanged: EventEmitter<SrMessage>;
    public categoryChanged: EventEmitter<SrMessage>;
    public customerChanged: EventEmitter<SrMessage>;
    public departmentChanged: EventEmitter<SrMessage>;
    public loginUserChanged: EventEmitter<SrMessage>;
    public modelChanged: EventEmitter<SrMessage>;
    public serviceCenterChanged: EventEmitter<SrMessage>;
    public serviceTypeChanged: EventEmitter<SrMessage>;
    public stockItemChanged: EventEmitter<SrMessage>;
    public jobTStatusChanged: EventEmitter<SrMessage>;
    public jobChanged: EventEmitter<SrMessage>;
    public jobTRateChanged: EventEmitter<SrMessage>;
    public jobSmStatusChanged: EventEmitter<SrMessage>;
    public jobWarrantyStatusChanged: EventEmitter<SrMessage>;
    //public jobChanged: EventEmitter<SrMessage>;
    public searchjobChanged: EventEmitter<any>;
    public jobServiceCenterChanged: EventEmitter<SrMessage>;

    constructor() {
        this.accessoryChanged = new EventEmitter<SrMessage>();
        this.brandChanged = new EventEmitter<SrMessage>();
        this.categoryChanged = new EventEmitter<SrMessage>();
        this.customerChanged = new EventEmitter<SrMessage>();
        this.departmentChanged = new EventEmitter<SrMessage>();
        this.loginUserChanged = new EventEmitter<SrMessage>();
        this.modelChanged = new EventEmitter<SrMessage>();
        this.serviceCenterChanged = new EventEmitter<SrMessage>();
        this.serviceTypeChanged = new EventEmitter<SrMessage>();
        this.stockItemChanged = new EventEmitter<SrMessage>();
        this.jobTStatusChanged = new EventEmitter<SrMessage>();
        this.jobChanged = new EventEmitter<SrMessage>();
        this.jobTRateChanged = new EventEmitter<SrMessage>();
        this.jobSmStatusChanged = new EventEmitter<SrMessage>();
        this.jobWarrantyStatusChanged = new EventEmitter<SrMessage>();
        this.jobServiceCenterChanged = new EventEmitter<SrMessage>();
        //this.jobChanged = new EventEmitter<SrMessage>();
        this.searchjobChanged = new EventEmitter<any>();
    }
}