import { Component, OnInit, ViewChild, NgZone, OnDestroy, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, DatePipe } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { NgForm } from '@angular/forms';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import { SelectItem } from 'primeng/api';
import { NgProgressService } from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';
import { lFservice } from '../service/lFservice';
import { GlobalFunc } from '../service/globalfunction';
import { ServiceType } from '../servicetype/servicetype';
import { AuthService } from '../service/authservice';
import { DataChangedEvent } from '../service/datachangedevent';

//Model
import { Customer } from '../customer/customer';
import { LoginUser } from '../loginuser/loginuser';
import { Job } from './job';
import { MCB } from './mcb';
import { Accessory } from '../accessory/accessory';
import { DropDownValue } from './dropdownvalue';

@Component({
    selector: 'i-job',
    templateUrl: './job.component.html'
})
export class JobCP implements OnInit, OnDestroy {
    @Input() jid: string;
    @Input() isModal: boolean = false;
    @Output() onClick = new EventEmitter<string>();

    @ViewChild('scanDeviceIdModal', { static: false }) scanDeviceIdModal: BsModalComponent;
    @ViewChild('accessoryModal', { static: false }) accessoryModal: BsModalComponent;
    @ViewChild('customerModal', { static: false }) customerModal: BsModalComponent;
    @ViewChild('actionModal', { static: false }) actionModal: BsModalComponent;
    @ViewChild('deleteModal', { static: false }) deleteModal: BsModalComponent;
    @ViewChild('modelModal', { static: false }) modelModal: BsModalComponent;
    @ViewChild('deviceID', { static: false }) deviceID;
    @ViewChild('scanCustomerModal', { static: false }) scanCustomerModal: BsModalComponent;
    @ViewChild('scancustomerId', { static: false }) scancustomerId;
    @ViewChild(NgForm, { static: false }) form;

    job: Job = this.newJob();
    customer: any;//= { vir_label: "", vir_value: 0 };
    model: any;// = { vir_label: "", vir_value: 0 };

    servicetypes: SelectItem[];
    jobRemarks: SelectItem[] = [{ label: 'Select job\'s remark', value: null },
    { label: 'Service Only', value: 'Service Only' },
    { label: 'Parts Change', value: 'Parts Change' },
    { label: 'YGN Service', value: 'YGN Service' },
    { label: 'YGN Service2', value: 'YGN Service2' },
    { label: 'MDY Service', value: 'MDY Service' }];
    loginusers: SelectItem[];

    //customers: any[] = [];
    mcbs: any[];
    _listofAccessory: Accessory[];
    jobAccessories: Accessory[] = [];
    _listOfMon: MCB[];
    filteredSuppN: any[];
    actionlogs: any[];
    results: any[] = [];
    modlresults: any[] = [];

    isAccessoryModalOpen: boolean = false;
    isModelModalOpen: boolean = false;
    isCusModalOpen: boolean = false;
    jobbydidempty: boolean = false;
    isAccordion: boolean = false;
    isCreateJob: boolean = false;
    visibleJP: boolean = false;
    didempty: boolean = false;
    Warrantychecked: boolean = false;
    isPrint: boolean = false;
    unvalidCustomer: boolean;
    unvalidModel: boolean;
    showJobError: boolean;

    toastkey = "i-job";
    cusScantoastkey = "cusscan-toast";
    jobmsgs: string = '';
    title: string = "Create New Job";
    sub: any;
    suppliers;

    oDropDownValueList: DropDownValue[];
    dropdownValues: DropDownValue[] = [];

    selectedValues: string;
    selectedAccessories: string;
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private ngzone: NgZone, private pService: NgProgressService, public httpService: HttpServices, public auth: AuthService, public currentRoute: ActivatedRoute, public router: Router, public location: Location, public fb: FormBuilder, public _dataChanged: DataChangedEvent, public datepipe: DatePipe) {

        this.visibleJP = false;
        this.Warrantychecked = false;
        this.suppliers = [];
        this.mcbs = [];

        this.sub = this._dataChanged.modelChanged.subscribe(
            rdata => { },
            err => { },
            () => { }
        );
        this.sub = this._dataChanged.customerChanged.subscribe(
            rdata => { },
            err => { },
            () => { }
        );
        this.sub = this._dataChanged.serviceTypeChanged.subscribe(
            rdata => {  },
            err => { },
            () => { }
        );
        this.sub = this._dataChanged.loginUserChanged.subscribe(
            rdata => { },
            err => { },
            () => { }
        );
        this.sub = this._dataChanged.accessoryChanged.subscribe(
            rdata => { },
            err => { },
            () => { }
        );

        this.unvalidCustomer = false;
        this.unvalidModel = false;
    }

    ngOnInit() {
        this.auth.getAuthInfo().then(n => {
            if (n) {
                this.auth.setAllData(n);
                this.job = this.newJob();
            } else {
                this.job = this.newJob();
            }
            this.GetData();
            //this.Get_Customers();
            this.Get_MCB();
            this.Get_ServiceType();
            this.Get_ServiceTechnician();
            this.Get_Accessory();
            this.Get_Suppliers();
            this.GetDropDownValue();
        })
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.is450Width = window.innerWidth <= 450 ? true : false;
        });
    }

    resize(e) {
        setTimeout(() => {
            this.is450Width = window.innerWidth <= 450 ? true : false;
        });
    }

    ngOnDestroy() {
        if (!this.isPrint) {
            if (this.isAccordion && !this.isCreateJob) {
                if (this.job.JID) {
                    this.httpService.postData('api/job/jobdelete/', this.job.JID).subscribe(data => { }, err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while deleting data!'));
                }
            }
        }
        this.job = undefined;
        this.customer = undefined;
        this.model = undefined;
    }

    GetData() {
        if (this.jid) {
            this.pService.start();
            this.httpService.getData("api/job/" + this.jid).subscribe(
                data => {
                    if (data) {
                        this.job = data;
                        this.searchCustomer(this.job?.Customer?.Ph)
                    } else {
                        this.job = this.newJob();
                    }
                },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                },
                () => {
                    if (this.job) {
                        this.job.APPDT = new Date(this.job.APPDT);
                        if (this.job.JID) {
                            this.title = "Edit Job";
                            if (this.job.SStat === 'a' && this.job.TStat === 'c') {
                                this.Warrantychecked = true;
                            }
                        }
                        else {
                            this.Warrantychecked = false;
                        }

                        if (this.job.Customer) {
                            this.customer = { vir_label: this.job.Customer.CN + " / " + this.job.Customer.Ph + " / " + this.job.Customer.City, vir_value: this.job.CID };
                        }

                        if (this.job.Model) {
                            this.model = { vir_label: this.job.Model.MoN + " / " + this.job.Model.CatN + " / " + this.job.Model.BN, vir_value: this.job.MoID };
                        }

                        this.ReBindAccessoryMultiSelect();
                        this.reBindDescriptionMultiSelect();
                    }
                    this.pService.done();
                }
            );
        }
    }

    // Get_Customers(): void {
    //     //this._listOfCustomer = [];
    //     this.customers = [];
    //     lFservice.Get_LF(GlobalFunc.CusKey).then(value => {
    //         if (value == null) {
    //             this.pService.start();
    //             this.httpService.getData('api/customerForDDown').subscribe(
    //                 data => {
    //                     this.customers = data || [];

    //                     lFservice.Set_LF(GlobalFunc.CusKey, this.customers).then(value => {
    //                         this.customers.map(c => {
    //                             c.vir_label = c.CN + " / " + c.Ph + (c.City ? " / " + c.City : "");
    //                             c.vir_value = c.CID;
    //                         });
    //                     }).catch(err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data!'));
    //                 },
    //                 err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'),
    //                 () => {
    //                     this.pService.done();
    //                 }
    //             );
    //         }
    //         else {
    //             this.customers = value;
    //             this.customers.map(c => {
    //                 c.vir_label = c.CN + " / " + c.Ph + (c.City ? " / " + c.City : "");
    //                 c.vir_value = c.CID;
    //             });
    //         }
    //     }).catch(err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading mcb data!'));
    // }

    Get_MCB(): void {
        this._listOfMon = [];
        lFservice.Get_LF(GlobalFunc.MCBKey).then(value => {
            if (value == null) {
                this.pService.start();
                this.httpService.getData('api/job/getmcb').subscribe(
                    data => this._listOfMon = data,
                    err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!'),
                    () => {
                        lFservice.Set_LF(GlobalFunc.MCBKey, this._listOfMon).then(_value => {
                            this.getMCBSourceData(this._listOfMon);
                        }).catch(err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading mcb from server!'));
                        this.pService.done();
                    }
                );
            }
            else {
                this._listOfMon = value;
                this.getMCBSourceData(this._listOfMon);
            }
        }).catch(err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading mcb data!'));
    }

    Get_ServiceType(): void {
        let temp = [];
        lFservice.Get_LF(GlobalFunc.STKey).then(value => {
            if (!value) {
                this.pService.start();
                this.httpService.getData('api/servicetype').subscribe(
                    data => temp = data,
                    err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  service type from server!'),
                    () => {
                        lFservice.Set_LF(GlobalFunc.STKey, temp);
                        this.ServiceTypeDropDown(temp);
                        this.pService.done();
                    });
            }
            else {
                this.ServiceTypeDropDown(value);
            }
        }).catch(err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading service type data!'));
    }

    Get_ServiceTechnician() {
        let temp = [];
        lFservice.Get_LF(GlobalFunc.LUKey).then(value => {
            if (!value) {
                this.pService.start();
                this.httpService.getData('api/loginuser').subscribe(
                    data => temp = data,
                    err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading categories data from server!'),
                    () => {
                        lFservice.Set_LF(GlobalFunc.LUKey, temp);
                        this.LoginUserDropoDown(temp);
                        this.pService.done();
                    });
            }
            else {
                this.LoginUserDropoDown(value);
            }
        }).catch(err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data!'));
    }

    Get_Accessory(): void {
        let temp = [];
        lFservice.Get_LF(GlobalFunc.AcKey).then(value => {
            if (!value) {
                this.pService.start();
                this.httpService.getData('api/accessory').subscribe(
                    data => temp = data,
                    err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'),
                    () => {
                        lFservice.Set_LF(GlobalFunc.AcKey, temp).
                            catch(err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data!'));
                        this._listofAccessory = temp;
                        this.ReBindAccessoryMultiSelect();
                        this.pService.done();
                    });
            }
            else {
                this._listofAccessory = value;
                this.ReBindAccessoryMultiSelect();
            }
        }).catch(err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data!'));
    }

    Get_Suppliers(): void {
        let temp = [];
        lFservice.Get_LF(GlobalFunc.SplKey).then(value => {
            if (value == null) {
                this.pService.start();
                this.httpService.getData('api/getSuppliers').subscribe(
                    data => temp = data,
                    err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  service type from server!'),
                    () => {
                        lFservice.Set_LF(GlobalFunc.SplKey, temp).then(value => {
                            this.suppliers = [];
                            this.suppliers.push({ label: 'Select Suppliers', value: null });
                            if (temp == undefined) return;
                            temp.forEach(x => { this.suppliers.push({ label: x.ID, value: x.ID }) });
                            this.pService.done();
                        }).catch(err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while setting data into Local!'));
                    }
                );
            }
            else {
                this.suppliers = [];
                this.suppliers.push({ label: 'Select Suppliers', value: null });
                if (value == undefined) return;
                value.forEach(x => { this.suppliers.push({ label: x.ID, value: x.ID }) });
            }
        }).catch(err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data!'));
    }

    GetDropDownValue(): void {
        let ddvList: any = [];
        this.pService.start();
        this.httpService.getData('api/dropDownValue').subscribe(
            data => ddvList = data,
            err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading decription from server!'),
            () => {
                if (ddvList) {
                    this.oDropDownValueList = ddvList.filter(
                        (obj, i, arr) => obj.Description != null && obj.Description != undefined && obj.Description != "" && (arr.findIndex(t => t.Description === obj.Description) === i)
                    ).map(d => Object.assign({}, d)); //distinct & filter
                    this.oDropDownValueList.sort((a, b) => a.Description > b.Description ? 1 : (b.Description > a.Description ? -1 : 0));
                    //this.oDropDownValueList = ddvList.filter(v => v.Description != null && v.Description != undefined && v.Description != "").map(d => Object.assign({}, d));
                    this.reBindDescriptionMultiSelect();
                }
                this.pService.done();
            }
        );
    }

    reBindDescriptionMultiSelect(): void {

        if (this.oDropDownValueList !== null && this.oDropDownValueList !== undefined) {
            if (this.job !== undefined && this.job !== null) {
                if (this.job.JOB !== undefined && this.job.JOB !== null && this.job.JOB !== "") {
                    const ddvalueList = [];

                    let tempSplitList = this.job.JOB.split(",", this.job.JOB.length - 1);

                    if (tempSplitList != null && tempSplitList != undefined && tempSplitList.length > 0) {
                        tempSplitList.map(d => {
                            let ddvObj = this.oDropDownValueList.find(ddv => ddv.Description == d);
                            if (ddvObj != null && ddvObj != undefined) {
                                ddvalueList.push(ddvObj);
                            }
                        });

                        this.dropdownValues = ddvalueList.map(d => Object.assign({}, d));
                        this.selectedValues = this.job.JOB;
                        //this.AssignDescriptionSelectedValue();
                    }
                }
            }
        }
    }

    AssignDescriptionSelectedValue() {
        if (this.dropdownValues !== null) {
            this.selectedValues = "";
            this.dropdownValues.map(ja => {
                this.selectedValues = this.selectedValues + (this.selectedValues != "" ? ", " : "") + ja.Description;
            });
        }
    }

    onRowSelect(e: any) {
        this.AssignDescriptionSelectedValue();
    }

    onRowUnselect(e: any) {
        this.AssignDescriptionSelectedValue();
    }

    handleHeaderCheckboxToggle(e: any) {
        this.AssignDescriptionSelectedValue();
    }

    newJob() {
        return new Job(
            null, null, null, this.auth.DID, this.auth.UN, null, '', null, '', null, null, true, new Date(), 0, '', '', 'S', '', '', 0, null, '', '', null, '', false, null, 0, 0, 0,
            0, 0, null, null, '', 0, this.auth.ScID, '', this.auth.UN, '', '', false, '', '', null, 0, '', '', null, null, [], ''
        );
    }

    getMCBSourceData(data): void {
        if (data == undefined) return;

        this.mcbs = [];
        let tmp = '';

        if (this.auth.DID !== null) {
            for (let mcb of data) {
                if (mcb.DID === this.auth.DID) {
                    if (mcb.MoN !== tmp) {
                        this.mcbs.push({ vir_label: mcb.MoN + " / " + mcb.CatN + " / " + mcb.BN, vir_value: mcb.MoID });
                    }
                    tmp = <string>mcb.MoN;
                }
            }
        }
        else {
            for (let mcb of data) {
                if (mcb.MoN !== tmp) {
                    this.mcbs.push({ vir_label: mcb.MoN + " / " + mcb.CatN + " / " + mcb.BN, vir_value: mcb.MoID });
                }
                tmp = <string>mcb.MoN;
            }
        }
    }

    ServiceTypeDropDown(data: ServiceType[]): void {
        if (!data) return;

        this.servicetypes = [];
        this.servicetypes.push({ label: 'Select service type', value: null });

        // if (this.job) {
        //     if (this.job.JID) {
        //         for (let st of data) {
        //             this.servicetypes.push({ label: st.STN, value: st.STID });
        //         }
        //     }
        //     else {
        //         if (!this.auth.DID) {
        //             for (let st of data) {
        //                 this.servicetypes.push({ label: st.STN, value: st.STID });
        //             }
        //         }
        //         else {
        //             for (let st of data) {
        //                 if (st.DID == this.auth.DID) {
        //                     this.servicetypes.push({ label: st.STN, value: st.STID });
        //                 }
        //             }
        //         }
        //     }
        // }

        if ((this.job && this.job.JID) || (this.auth && !this.auth.DID)) {
            data.map(d => this.servicetypes.push({ label: d.STN, value: d.STID }));
        } else {
            data.map(d => {
                if (d.DID == this.auth.DID) {
                    this.servicetypes.push({ label: d.STN, value: d.STID });
                }
            });
        }
    }

    LoginUserDropoDown(data: LoginUser[]) {
        if (data == undefined || this.job === undefined) return;

        this.loginusers = [];
        this.loginusers.push({ label: "Select technician", value: null });

        if (this.job.JID || !this.auth.DID) {
            for (let lu of data) {
                if (lu.UT == "Service Technician") {
                    this.loginusers.push({ label: lu.Name + ' (' + lu.ScN + ')', value: lu.UN });
                }
            }
        }
        else {
            for (let lu of data) {
                if (this.auth.DID) {
                    if (lu.UT == "Service Technician" && lu.DID === this.auth.DID && lu.ScID === this.auth.ScID) {
                        this.loginusers.push({ label: lu.Name + ' (' + lu.ScN + ')', value: lu.UN });
                    }
                }
            }
        }
    }

    // AccessoryDropDown(data: Accessory[]): void {
    //     this.accessories = [];
    //     if (data) {
    //         for (let acces of data) {
    //             this.accessories.push({ label: acces.AcsN, value: acces.AcsID });
    //         }
    //         this.ReBindAccessoryMultiSelect();
    //     }
    // }

    ReBindAccessoryMultiSelect(): void {

        if (this._listofAccessory !== null && this._listofAccessory !== undefined) {
            if (this.job !== undefined && this.job !== null) {

                if (this.job.JobAccessories !== undefined && this.job.JobAccessories !== null) {
                    const accessoryList = [];
                    this.job.JobAccessories.map(j => {
                        let accessoryObj = this._listofAccessory.find(acc => acc.AcsID === parseInt(j));
                        if (accessoryObj !== null && accessoryObj !== undefined) {
                            accessoryList.push(accessoryObj);
                        }
                    });

                    this.jobAccessories = accessoryList.map(a => Object.assign({}, a));
                    this.AssignAccessorySelectedValue();
                }
            }
        }
    }

    AssignAccessorySelectedValue() {
        if (this.jobAccessories !== null) {
            this.selectedAccessories = "";
            this.jobAccessories.map(ja => {
                this.selectedAccessories = this.selectedAccessories + (this.selectedAccessories != "" ? ", " : "") + ja.AcsN;
            });
        }
    }

    onRowSelectAccessory(e: any) {
        this.AssignAccessorySelectedValue();
    }

    onRowUnselectAccessory(e: any) {
        this.AssignAccessorySelectedValue();
    }

    handleHeaderAccessoryCheckboxToggle(e: any) {
        this.AssignAccessorySelectedValue();
    }

    searchCus(event) {
        if (!event) return;
        if (event.query.length >= 4) {
            this.searchCustomer(event.query);                    
        }
    }

    onSearchSubmit(event) {
        if (!event) return;
        // if (event.query.length >= 5){                  
        //     this.searchCustomer(event.query);
        // }        
        this.searchCustomer(event.target.value);
    }

    searchCustomer(name: string): void {        
        //this.results = [];
        this.pService.start();
        let param = { name: name };
        this.httpService.postData('api/customer/customersearch', param).subscribe(
            data => {
                this.results = data || [];
                this.results.map(c => {
                    c.vir_label = c.CN + " / " + c.Ph + (c.City ? " / " + c.City : "");
                    c.vir_value = c.CID;
                });
            },
            err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'),
            () => {
                this.pService.done();
            }
        );
    }

    searchModl(event) {
        let qstring = event.query;
        if (qstring.length >= 2) {
            this.modlresults = [];
            for (let obj of this.mcbs) {
                if (obj.vir_label.toLowerCase().includes(qstring.toLowerCase())) { this.modlresults.push(obj); }
            }
        }
    }

    assignCustomer(cusObj: Customer) {
        if (cusObj) {
            let ind = this.results.findIndex(x => x.CID === cusObj.CID);
            if (ind >= 0) this.results[ind] = Object.assign({}, cusObj);
            else {
                this.results.push(Object.assign({}, cusObj))
                lFservice.Set_LF(GlobalFunc.CusKey, this.results);
                this.results.map(c => {
                    c.vir_label = c.CN + " / " + c.Ph + (c.City ? " / " + c.City : "");
                    c.vir_value = c.CID;
                });
            }
            this.customer = { vir_label: cusObj.CN + " / " + cusObj.Ph + (cusObj.City ? " / " + cusObj.City : ""), vir_value: cusObj.CID };
            this.unvalidCustomer = false;
        }
        else {
            let index = this.results.findIndex(cus => (this.customer && cus.vir_label === this.customer.vir_label));

            if (index < 0) {
                this.job.CID = null;
                this.unvalidCustomer = true;
            }
        }
    }

    autoCompleteSelect(caller) {        
        if (caller === 'customer') {
            this.job.CID = this.customer.vir_value;
            this.unvalidCustomer = false;
        }
        else {
            this.job.MoID = this.model.vir_value;
            this.unvalidModel = false;
        }
    }

    autoCompleteBlur(caller) {        
        if (caller === 'customer') {
            if (typeof this.customer === 'string') {                    
                this.unvalidCustomer = true;
            }
        }
        else {
            if (typeof this.model === 'string') {
                this.unvalidModel = true;
            }
        }
    }

    AssignJobAccessories() {
        if (this.jobAccessories !== null) {
            this.job.JobAccessories = [];
            this.jobAccessories.map(ja => {
                this.job.JobAccessories.push(ja.AcsID.toString());
            });
        }
    }

    AssignDescription() {
        if (this.dropdownValues !== null) {
            this.job.JOB = "";
            this.dropdownValues.map(ja => {
                this.job.JOB = this.job.JOB + (this.job.JOB != "" ? "," : "") + ja.Description;
            });
        }
    }

    CreateJob(isbtnCreate): void {
        this.job.CID = this.customer.vir_value;
        this.job.MoID = this.model.vir_value;

        if (this.unvalidModel || this.unvalidCustomer || !this.job.HAPP || !this.form.valid) return;

        this.AssignDescription();

        if ((this.job.CID === null || this.job.CID === '')
            || (this.job.STID === null || this.job.STID === '')
            || (this.job.MoID === null || this.job.MoID === '')
            || (this.job.JOB === null || this.job.JOB === '')
            || (this.job.JAT1 === null || this.job.JAT1 === '')
        ) {
            this.httpService.showToast(this.toastkey, 'error', 'Please, fill Require-Data !');
            this.showJobError = (this.job.JOB === null || this.job.JOB === '');
            return;
        }

        this.job.Customer = this.results.find(cus => cus.CID == this.job.CID);
        this.job.Model = this._listOfMon.find(m => m.MoID == this.job.MoID);

        if (this.job.JID !== null) this.jobmsgs = 'edit';
        else {
            this.jobmsgs = 'create';
            this.job.JDT = new Date();
        }
        this.isCreateJob = isbtnCreate;

        this.AssignJobAccessories();

        if (this.Warrantychecked) {
            this.job.SStat = 'a';
            this.job.TStat = 'c';
        }
        else {
            this.job.SStat = '';
            this.job.TStat = 'a';
            this.job.SuppN = '';
        }

        if (this.job.RSM == null) {
            this.job.RSM = '';
        }

        //if (!this.job.HAPP) this.job.APPDT = null;
        if (this.job.APPDT) {
            this.job.APPDT.setSeconds(0);
            this.job.APPDT.setMilliseconds(0);
            let apot_date = this.datepipe.transform(this.job.APPDT, 'dd-MMM-yyyy hh:mm a');
            if (!this.job.APDH) this.job.APDH = '';
            if (!this.job.APDH.includes(apot_date)) {
                this.job.APDH += (!this.job.APDH || this.job.APDH.endsWith(",") ? "" : ", ") + apot_date.toString();
            }
        }

        this.pService.start();        
        this.httpService.postData('api/job', this.job).subscribe(
            data => {
                this.job.JID = data.JID;
                this.job.JIDR = data.JIDR;
            },
            err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while inserting/updating data!'),
            () => {
                if (!this.isPrint) {
                    if (this.isCreateJob) this.navigationPage();
                }
                else {
                    if (this.job) {
                        this.router.navigate(['/jobprintview', this.job.JID]);
                    }
                }
                this.pService.done();
            }
        );
    }

    cancelJob(): void {
        this.jobmsgs = 'cancel';
        this.navigationPage();
    }

    deleteJob() {
        if (this.auth.role == 'Admin' || this.auth.role == 'Service Manager' && (this.job.JID !== null && this.job.JID !== undefined)) {
            this.deleteModal.open();
        }
    }

    delJob() {
        this.deleteModal.close();

        if (this.job.JID !== null || this.job.JID !== undefined) {
            this.pService.start();
            this.httpService.postData('api/job/jobdelete', this.job.JID).subscribe(
                expdata => { },
                err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while deleting job !') },
                () => {
                    this.jobmsgs = 'del';
                    this.pService.done();
                    this.navigationPage();
                }
            );
        }
    }

    navigationPage() {
        if (this.isModal) {
            this.onClick.emit(this.jobmsgs);
        } else {
            this.router.navigate(['/joblist']);
        }
    }

    deleteModalDismiss() {
        this.deleteModal.dismiss();
        this.httpService.AddClassToModal(this.isModal);
    }

    search(event): void {
        let query = event.query;
        this.filteredSuppN = [];
        for (let sup of this.suppliers) {
            let value = sup.label;
            if (value == null) return;
            if (value.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                this.filteredSuppN.push(value);
            }
        }
    }

    onJPTabOpenJobPhoto(): void {
        this.onJPTabOpen();
        this.visibleJP = true;
    }

    onJPTabOpen(): void {
        if (this.job.JID == null && this.job.JID == undefined) {
            this.isAccordion = true;
            this.CreateJob(false);
        }
    }

    showLogDialog(): void {
        if (this.job.JID !== undefined) {
            let temp = [];
            this.pService.start();
            this.httpService.getData('api/actionlog/' + this.job.JID.toUpperCase() + '/' + 'Jobs').subscribe(
                data => temp = data,
                err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'),
                () => {
                    if (temp !== undefined) { this.actionlogs = temp; this.actionModal.open(); }
                    else { this.httpService.showToast(this.toastkey, 'warn', 'There is no record to show!') }
                    this.pService.done();
                });
        }
    }

    SetAccessoryData(): void {
        lFservice.Set_LF(GlobalFunc.AcKey, this._listofAccessory);
    }

    SetCustomerData(): void {
        // lFservice.Set_LF(GlobalFunc.CusKey, this.results);
    }

    SetMCBData(): void {
        lFservice.Set_LF(GlobalFunc.MCBKey, this._listOfMon);
    }

    scanDeviceID(): void {
        this.job.DeviceID = '';
        this.didempty = false; this.jobbydidempty = false;
        this.scanDeviceIdModal.open();

        this.deviceID.nativeElement.focus();
    }

    OKDeviceID(): void {
        if (this.job.DeviceID == '') {
            this.didempty = true;
            if (this.jobbydidempty) {
                this.jobbydidempty = false;
            }
            this.deviceID.nativeElement.focus();
        }
        else {
            if (this.didempty) this.didempty = false;

            let tmp: any;
            this.pService.start();
            this.httpService.getData('api/job/getjobbydeviceId2/' + this.job.DeviceID).subscribe(
                data => { tmp = data[0] },
                err => {
                    this.scanDeviceIdModal.close();
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while getting job data by device ID !');
                },
                () => {
                    if (tmp == undefined) {
                        this.jobbydidempty = true;
                        this.job.DeviceID = '';
                        this.deviceID.nativeElement.focus();
                    }
                    else {
                        this.scanDeviceIdModal.close();
                        this.job.CID = tmp.CID;
                        this.job.MoID = tmp.MoID;
                        this.job.STID = tmp.STID;
                        this.job.JOB = tmp.JOB;

                        let tpaCust = this.results.filter(cust => cust.vir_value === tmp.CID);
                        if (tpaCust.length > 0) this.customer = tpaCust[0];
                        let tpaMon = this.mcbs.filter(mcb => mcb.vir_value === tmp.MoID);
                        if (tpaMon.length > 0) this.model = tpaMon[0];
                    }
                    this.pService.done();
                }
            );
        }
    }

    scanDeviceIdModalClose() {
        this.scanDeviceIdModal.close();
        this.httpService.AddClassToModal(this.isModal);
    }

    onKeydownDId(event): void {
        if (event.keyCode == 13) {
            this.OKDeviceID();
        }
    }

    actionModalClose() {
        this.actionModal.close();
        this.httpService.AddClassToModal(this.isModal);
    }

    curr_dt() {
        let curdt: Date;
        curdt = new Date();
    }

    RefreshModl() {
        this.pService.start();
        let temp = [];
        this.httpService.getData('api/job/getmcb').subscribe(
            data => temp = data,
            err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!'),
            () => {
                lFservice.Set_LF(GlobalFunc.MCBKey, temp).then(_value => {
                    this._listOfMon = _value;
                    this.getMCBSourceData(this._listOfMon);
                }).catch(err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading mcb from server!'));
                this.pService.done();
            }
        );
    }



    ////////////////////////////////////////Scan Customer Data/////////////////////////////////////

    scanCustomerdata: string;
    cusInfo = { phone: '', name: '' };
    btnProcess: boolean;
    is450Width: boolean = false;

    scanCustomer() {
        this.btnProcess = false;
        this.scanCustomerModal.open();
        this.onInputFocus();
    }

    customerByPhone(phone: string): Promise<Customer> {
        return new Promise<Customer>((resolve, reject) => {
            if (phone) {
                this.httpService.postData('api/customer/byphone/', { "Ph": phone }).subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        this.httpService.showToast(this.cusScantoastkey, 'error', 'Error occurs while getting Customer by Phone!');
                        reject(undefined);
                    },
                )
            } else {
                this.httpService.showToast(this.cusScantoastkey, 'error', 'Error occurs while getting Customer by Phone!');
                resolve(undefined);
            }
        });
    }

    changedScanCustomer(event) {
        if (event.keyCode == 13) {
            let strAry = this.scanCustomerdata.split(";", 2);
            if (strAry && strAry.length > 0) {
                this.cusInfo.phone = strAry[0];
                this.cusInfo.name = strAry[1];
                this.customerByPhone(this.cusInfo.phone).then(customer => {
                    if (customer) {
                        this.assignCustomer(customer);
                        this.closeScanCustomerModal();
                    }
                    else {
                        this.getCustomerFormLinn();
                    }
                });
            }
        }
    }

    getCustomerFormLinn() {
        if (this.cusInfo) {
            let param = { phoneNumber: this.cusInfo ? this.cusInfo.phone : '' };
            let tmpObj;
            this.httpService.linnPostData('customer/get-info', param).then(expdata => {
                tmpObj = expdata;                
                if (tmpObj.responseCode === '1') {
                    this.btnProcess = true;
                    let customerObj = new Customer('', '', '', '', '', '', '', '', '', '');
                    if (tmpObj && tmpObj.data !== undefined && tmpObj.data !== null) {
                        customerObj.CID = 0;
                        customerObj.CN = tmpObj.data.name;
                        customerObj.City = tmpObj.data.addressTownship;
                        customerObj.Em = '';
                        customerObj.Ph = tmpObj.data.phoneNumber;
                        customerObj.nEm = false;
                        customerObj.nPh = true;
                        customerObj.nSMS = true;
                        customerObj.Addr = tmpObj.data.address;
                        this.createCustomer(customerObj);
                    }
                } else {
                    this.scanCustomerdata = undefined;
                    if (tmpObj.error && tmpObj.error.length > 0) {
                        this.httpService.showToast(this.cusScantoastkey, 'error', tmpObj.error[0].errorMessage);
                    } else {
                        this.httpService.showToast(this.cusScantoastkey, 'error', tmpObj.responseMessage);
                    }
                }
            }).catch(err => {
                this.scanCustomerdata = undefined;
                this.httpService.showToast(this.cusScantoastkey, 'error', tmpObj.responseMessage);
            });
        }
    }

    // getCustomerFormLinn() {
    //     if (this.cusInfo) {
    //         let param = { phoneNumber: this.cusInfo ? this.cusInfo.phone : '' };
    //         let tmpObj;
    //         this.httpService.linnPostData('customer/get-info', param).subscribe(
    //             expdata => {
    //                 tmpObj = expdata;
    //                 if (tmpObj.responseCode === '1') {
    //                     this.btnProcess = true;
    //                     let customerObj = new Customer('', '', '', '', '', '', '', '', '', '');
    //                     if (tmpObj && tmpObj.data !== undefined && tmpObj.data !== null) {
    //                         customerObj.CID = 0;
    //                         customerObj.CN = tmpObj.data.name;
    //                         customerObj.City = tmpObj.data.addressTownship;
    //                         customerObj.Em = '';
    //                         customerObj.Ph = tmpObj.data.phoneNumber;
    //                         customerObj.nEm = false;
    //                         customerObj.nPh = true;
    //                         customerObj.nSMS = true;
    //                         customerObj.Addr = tmpObj.data.address;
    //                         this.createCustomer(customerObj);
    //                     }
    //                 } else {
    //                     this.scanCustomerdata = undefined;
    //                     if (tmpObj.error && tmpObj.error.length > 0) {
    //                         this.httpService.showToast(this.cusScantoastkey, 'error', tmpObj.error[0].errorMessage);
    //                     } else {
    //                         this.httpService.showToast(this.cusScantoastkey, 'error', tmpObj.responseMessage);
    //                     }
    //                 }
    //             },
    //             err => {
    //                 this.scanCustomerdata = undefined;
    //                 this.httpService.showToast(this.cusScantoastkey, 'error', tmpObj.responseMessage)
    //             });
    //     }
    // }

    createCustomer(customer): void {
        if (customer !== undefined) {
            this.httpService.postData('api/customer', customer).
                subscribe(
                    data => { customer.CID = data },
                    err => this.httpService.showToast(this.cusScantoastkey, 'error', 'Error occurs while inserting/updating data!'),
                    () => {
                        this.assignCustomer(customer);
                        this.closeScanCustomerModal();
                    }
                );
        }
    }

    onInputFocus() {
        setTimeout(() => {
            if (document.getElementById('scancustomerId')) {
                this.scancustomerId.nativeElement.focus();
            }
        });
    }

    onDismissScanCustomer() {
        this.httpService.AddClassToModal(this.isModal);
        this.scanCustomerdata = undefined;
        this.cusInfo = { phone: '', name: '' };
    }

    closeScanCustomerModal() {
        this.scanCustomerdata = undefined;
        this.cusInfo = { phone: '', name: '' };
        this.scanCustomerModal.close();
        this.httpService.AddClassToModal(this.isModal);
    }

    //////////////////////////////////////////Customer Modal/////////////////////////////////////

    addNewCust() {
        this.isCusModalOpen = true;
        this.customerModal.open();
    }

    onCloseCustomerModal(customer: Customer) {
        this.isCusModalOpen = false;
        this.assignCustomer(customer);
        this.customerModal.close();

        this.httpService.AddClassToModal(this.isModal);
    }

    onDismissCustomerModal() {
        this.httpService.AddClassToModal(this.isModal);
        this.isCusModalOpen = false;
    }

    //////////////////////////////////////Model Modal/////////////////////////////////////
    addNewModel() {
        this.isModelModalOpen = true;
        this.modelModal.open();
    }

    onDismissModelModal() {
        this.Get_MCB();
        this.httpService.AddClassToModal(this.isModal);
    }

    onCloseModelModal(moId) {
        this.isModelModalOpen = false;

        if (moId !== undefined) {
            this._listOfMon = [];
            this.pService.start();
            this.httpService.getData('api/job/getmcb').subscribe(
                data => {
                    this._listOfMon = data || [];
                    lFservice.Set_LF(GlobalFunc.MCBKey, this._listOfMon);
                    this.getMCBSourceData(this._listOfMon);

                    let mcb = this._listOfMon.filter(x => x.MoID === moId);
                    if (mcb.length > 0) {
                        this.model = { vir_label: mcb[0].MoN + " / " + mcb[0].CatN + " / " + mcb[0].BN, vir_value: mcb[0].MoID }
                        this.unvalidModel = false;
                    }
                },
                err => this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!'),
                () => {
                    this.pService.done();
                }
            );
        }
        else {
            let index = this.mcbs.findIndex(m => m.vir_label === this.model.vir_label);

            if (index < 0) {
                this.job.MoID = null;
                this.unvalidModel = true;
            }
        }
        this.modelModal.close();
        this.httpService.AddClassToModal(this.isModal);
    }

    /////////////////////////////////////Accessory Modal/////////////////////////////////////

    accessoryModalOpen() {
        this.isAccessoryModalOpen = true;
        this.accessoryModal.open();
    }

    onCloseAccessoryModal(accessory: Accessory): void {
        if (accessory !== undefined) {
            if (this.jobAccessories == undefined) this.jobAccessories = [];
            let accesAry = this._listofAccessory.map(a => Object.assign({}, a));
            for (let aces of accesAry) {
                if (aces.AcsID == accessory.AcsID) {
                    // this.jobAccessories.push(accessory.AcsID);
                    this.jobAccessories.push(accessory);
                    break;
                }
                else {
                    this._listofAccessory.push(accessory);
                    this.SetAccessoryData();
                    // this.jobAccessories.push(accessory.AcsID);
                    this.jobAccessories.push(accessory);
                    break;
                }
            }
        }
        this.isAccessoryModalOpen = false;
        this.accessoryModal.close();
        this.httpService.AddClassToModal(this.isModal);
    }

    @HostListener('document:click', ['$event'])
    handleKeyboardEvent(e: KeyboardEvent) {
        if (this.btnProcess) return;
        this.onInputFocus();
    }
}