import { Component, OnInit, ViewChild, NgZone } from '@angular/core';

//third libraries
import { BsModalComponent } from 'ng2-bs3-modal';
import { GlobalFunc } from '../service/globalfunction';
import { DataChangedEvent } from '../service/datachangedevent';
import {NgProgressService} from "ng2-progressbar";

//service
import { HttpServices } from '../service/httpservices';

//model
import { Accessory } from './accessory';

@Component({
    selector: 'i-accessory',
    templateUrl: './accessory.component.html'
})

export class AccessoryCP implements OnInit {
    @ViewChild('entryModal', { static: false }) entryModal: BsModalComponent;
    @ViewChild('actionModal', { static: false }) actionModal: BsModalComponent;
    @ViewChild('deleteModal', { static: false }) deleteModal: BsModalComponent;

    @ViewChild('input', { static: false }) vc;
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;

    apiName: string = "accessory";
    id = 'AcsID';
    public accessories: Accessory[];
    public accessory: Accessory = new Accessory();
    public isNew: boolean;

    public selectedChk: Accessory[];
    public selectedData: Accessory;
    public actionlogs: any[];

    public totalRecords: number;
    public toastkey = "i-accessory";

    constructor(private pService: NgProgressService ,public httpService: HttpServices, public _dataChanged: DataChangedEvent, public _ngZone: NgZone) {
        this.pService.start();
        this._dataChanged.accessoryChanged.subscribe((sRobj) => {
            //update this.accessories array..                                    
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === this.apiName.toLowerCase()) {
                        this.afterBroadCastEvent(sRobj);
                    }
                });
            }
        });
    }

    ngOnInit() {
        //intialize data
        this.GetData();
    }

    GetData(): void {
        let aList: any = [];
        this.httpService.getData('api/accessory').subscribe(
            data => {
                aList = data;
            },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                this.pService.done();
            },
            () => {
                if (aList) {
                    this.accessories = aList.map(a => Object.assign({}, a));
                    this.totalRecords = this.accessories.length;
                }
                this.pService.done();
            });
    }

    afterBroadCastEvent(objData) {
        if (objData && objData.Obj) {
            let ind = this.accessories.findIndex(temp => temp[this.id] === objData.Obj[this.id]);
            if (objData.Msg === 'deleted') {
                this.RefreshData();
            }
            else {

                if (ind >= 0) this.accessories[ind] = objData.Obj;
                else this.accessories.unshift(objData.Obj);
            }
        }
    }

    RefreshData() {
        this.pService.start();
        this.GetData();
    }

    showDialogToAdd(): void {
        this.isNew = true;
        this.accessory = new Accessory();
        this.entryModal.open();
        this.vc.nativeElement.focus();
    }

    showDialogToEdit(accessory: Accessory): void {
        this.isNew = false;
        this.selectedData = accessory;
        this.accessory = new Accessory();
        this.accessory = GlobalFunc.CloneData(accessory, Accessory);
        this.entryModal.open();
        this.vc.nativeElement.focus();
    }

    showDialogToDelete(): void {
        if (this.selectedChk != null && this.selectedChk != undefined && this.selectedChk.length > 0) {
            this.deleteModal.open();
        } else {
            this.httpService.showToast(this.toastkey, 'warn', 'Need to select row!');
        }
    }

    showLogDialog(): void {
        if (this.selectedData != undefined) {
            let temp = [];
            this.httpService.getData('api/actionlog/' + this.selectedData.AcsID + '/' + 'Accessory').subscribe(
                data => temp = data,
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                },
                () => {
                    if (temp != undefined) { this.actionlogs = temp; this.actionModal.open(); }
                    else { this.httpService.showToast(this.toastkey, 'warn', 'There is no record to show!') }
                });
        }
    }

    save(pdt, gb): void {
        if (this.accessory.AcsN == null) return;
        if (this.accessory != undefined) {
            this.httpService.postData('api/accessory', this.accessory).
                subscribe(
                    data => { },
                    err => {
                        this.httpService.showToast(this.toastkey, 'error', 'Error occurs while inserting/updating data!');
                        this.pService.done();
                    },
                    () => {
                        this.resetDatable(pdt, gb);
                        this.httpService.showToast(this.toastkey, 'success', 'Successfully inserted/updated');
                        this.entryModal.close();
                    });
        }
    }

    deleteData() {
        this.httpService.postData('api/accessory/multidelete/', this.selectedChk).
            subscribe(
                data => {
                    this.httpService.showUnsuccessDelMsg(this.toastkey, data);
                },
                err => {
                    this.doneDeleteProcess('error', 'Error occurs while deleting data!');
                },
                () => {
                    this.doneDeleteProcess('success', 'Successfully deleted');
                });
    }

    deleteModalClose() {
        this.deleteModal.close();
        this.httpService.AddClassToModal(true);
    }

    doneDeleteProcess(header: string, msg: string) {
        this.selectedChk = null;
        this.deleteModalClose();
        this.httpService.showToast(this.toastkey, header, msg);
    }

    resetDatable(pdt, gb): void {
        if (gb && gb.value && gb.value != '') {
            gb.value = '';
            if (pdt) pdt.reset();
        }
    }

    actionModalClose() {
        this.actionModal.close();
        this.httpService.AddClassToModal(true);
    }

    exportToCSV() {
        this.httpService.postData('api/accessory/export', null).subscribe(
            data => { },
            err => { },
            () => {
                //window.location.href = this.httpService._url + 'excel/accessory.csv';
                this.httpService.DirectLocToWindow("accessory");
            }
        );
    }
}