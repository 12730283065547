import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[AppNotiColor]'
})
export class AppNotiColorDirective {
    @Input() appdate;
    // @Input() sstat='';
    @Input() sstat;

    @Input() tstat;
    constructor(public el: ElementRef) { }
    ngOnInit() {
        //modified
        if (this.tstat === 'e' || this.tstat === 'j') {
            this.el.nativeElement.style.color = 'blue';
        }
        else {
            if (this.appdate && this.sstat) {
                if (this.sstat === 'c' || this.sstat === 'd') this.el.nativeElement.style.color = 'black';
                else {
                    if (!this.appdate) this.el.nativeElement.style.color = 'black';
                    else {
                        let ttmp = new Date(this.appdate);
                        let curdate = new Date();
                        if (curdate >= ttmp) this.el.nativeElement.style.color = 'red';
                        else this.el.nativeElement.style.color = 'black';
                    }
                }
            }
        }
        //modified end
        // if(this.appdate && this.sstat){
        //     if(this.sstat==='c' || this.sstat==='d') this.el.nativeElement.style.color='black';
        //     else{
        //         if (!this.appdate) this.el.nativeElement.style.color='black';
        //         else{
        //             let ttmp=new Date(this.appdate);
        //             let curdate = new Date();
        //             if(curdate>=ttmp) this.el.nativeElement.style.color='red';
        //             else this.el.nativeElement.style.color='black';
        //         }
        //     }
        // }
    }
}