import { Component, OnInit, Input, ViewChild } from '@angular/core';

//Third Library
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';

//Model
import { JobPhoto } from './jobphoto';

@Component({
    selector: 'i-jobphotoview',
    templateUrl: './jobphotoview.component.html'
})

export class JobPhotoViewCP implements OnInit {
    @Input() JID: string;

    public jobphotos: JobPhoto[];
    public toastkey = "i-jobphotoview";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public httpService: HttpServices) { }

    ngOnInit() {
        this.GetJobPhoto();
    }

    GetJobPhoto() {
        let temp = [];
        if (this.JID != undefined) {
            this.httpService.getData('api/getjobphoto/' + this.JID).subscribe(
                data => {
                    temp = data;
                },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                },
                () => { this.jobphotos = temp; });
        }
    }
}