export class DashQuery {
    constructor(
        public PType?: any,
        public Selected?: any,
        public Period?: any,
        public FromDate?: any,
        public ToDate?: any,
    
        public SCID?: any,
        public STID?: any,
    
        public DID?: any,
    ){}
    
    SearchText?: any;
}