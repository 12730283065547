export class StockInDetail {
    constructor(
        public SIDID?,
        public SIID?,
        public ScID?,
        public ScN?,
        public SID?,
        public SN?,
        /*public Cat?,*/
        public Qty?,
        public Price?) { }
}