import { Component, OnInit, ViewChild, NgZone, Input } from '@angular/core';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import {NgProgressService} from "ng2-progressbar";

//Service
import { DataChangedEvent } from '../service/datachangedevent';
import { HttpServices } from '../service/httpservices';
import { GlobalFunc } from '../service/globalfunction';

//Model
import { Brand } from './brand';

@Component({
    selector: 'i-brand',
    templateUrl: './brand.component.html'
})
export class BrandCP implements OnInit {
    @ViewChild('entryModal', { static: false }) entryModal: BsModalComponent;
    @ViewChild('actionModal', { static: false }) actionModal: BsModalComponent;
    @ViewChild('deleteModal', { static: false }) deleteModal: BsModalComponent;
    @ViewChild('input', { static: false }) vc;
    @Input() isModal: boolean = false;

    apiName: string = "brand";
    id = 'BID';
    public brands: Brand[];
    public brand: Brand = new Brand();
    public isNew: boolean = false;
    public selectedChk: Brand[];
    public selectedData: Brand;
    public actionlogs: any[];

    public totalRecords: number;
    public toastkey = "i-brand";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    

    constructor(private pService: NgProgressService ,public httpService: HttpServices, public _dataChanged: DataChangedEvent, public _ngZone: NgZone) {
        this.pService.start();
        this._dataChanged.brandChanged.subscribe((sRobj) => {
            //update this.brands array..            
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === this.apiName.toLowerCase()) {
                        this.afterBroadCastEvent(sRobj);
                    }
                });
            }
        });
    }

    ngOnInit() {
        //intialize data
        this.GetData();
    }

    GetData(): void {
        let bList = [];
        this.httpService.getData('api/brand').subscribe(
            data => {
                bList = data;
            },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                this.pService.done();
            },
            () => {
                if (bList) {
                    this.brands = bList.map(a => Object.assign({}, a));
                    this.totalRecords = this.brands.length;
                }
                this.pService.done();
            });
    }

    afterBroadCastEvent(objData) {
        if (objData && objData.Obj) {
            let ind = this.brands.findIndex(temp => temp[this.id] === objData.Obj[this.id]);
            if (objData.Msg === 'deleted') {
                this.refresh();
            }
            else {

                if (ind >= 0) this.brands[ind] = objData.Obj;
                else this.brands.unshift(objData.Obj);
            }
        }
    }

    refresh() {
        this.pService.start();
        this.GetData();
    }

    showDialogToAdd(): void {
        this.isNew = true;
        this.brand = new Brand();
        this.entryModal.open();

        this.vc.nativeElement.focus();
    }
    showDialogToEdit(brand: Brand): void {
        this.isNew = false;
        this.selectedData = brand;
        this.brand = new Brand();
        this.brand = GlobalFunc.CloneData(brand, Brand);
        this.entryModal.open();
        this.vc.nativeElement.focus();
    }
    showDialogToDelete(): void {
        if (this.selectedChk != null && this.selectedChk != undefined && this.selectedChk.length > 0) {
            this.deleteModal.open();
        } else {
            this.httpService.showToast(this.toastkey, 'warn', 'Need to select row!');
        }
    }
    showLogDialog(): void {
        let temp = [];
        let _url = 'api/actionlog/' + this.selectedData.BID + '/' + 'Brand';
        this.httpService.getData(_url).subscribe(
            data => temp = data,
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                this.pService.done();
            },
            () => {
                if (temp != undefined) {
                    this.actionlogs = (temp);
                    this.actionModal.open();
                }
                else {
                    this.httpService.showToast(this.toastkey, 'warn', 'There is no record to show!');
                }
            });
    }

    entryModalClose() {
        this.entryModal.close();
        this.httpService.AddClassToModal(this.isModal);
    }

    save(pdt, gb): void {
        if (this.brand.BN == null) return;
        if (this.brand != undefined) {
            this.httpService.postData('api/brand', this.brand).
                subscribe(
                    data => { },
                    err => {
                        this.httpService.showToast(this.toastkey, 'error', 'Error occurs while inserting/updating data!');
                       this.pService.done();
                    },
                    () => {
                        this.resetDatable(pdt, gb);
                        this.GetData();
                        this.httpService.showToast(this.toastkey, 'success', 'Successfully inserted/updated');
                        this.entryModal.close();
                        this.httpService.AddClassToModal(this.isModal);
                    });
        }
    }

    deleteData() {
        this.httpService.postData('api/brand/multidelete', this.selectedChk).
            subscribe(
                data => {
                    this.httpService.showUnsuccessDelMsg(this.toastkey, data);
                },
                err => {
                    this.doneDeleteProcess('error', 'Error occurs while deleting data!');
                },
                () => {
                    this.doneDeleteProcess('success', 'Successfully deleted');
                });
    }

    deleteModalClose() {
        this.deleteModal.close();
        this.httpService.AddClassToModal(this.isModal);
    }


    doneDeleteProcess(header: string, msg: string) {
        this.selectedChk = null;
        this.deleteModalClose();
        this.httpService.showToast(this.toastkey, header, msg);
    }

    resetDatable(pdt, gb): void {
        if (gb && gb.value && gb.value != '') {
            gb.value = '';
            if (pdt) pdt.reset();
        }
    }

    actionModalClose() {
        this.actionModal.close();
        this.httpService.AddClassToModal(true);
    }

    exportToCSV() {
        this.httpService.postData('api/brand/export', null).subscribe(
            data => { },
            err => { },
            () => {
                //window.location.href = this.httpService._url + 'excel/brand.csv';
                this.httpService.DirectLocToWindow("brand");
            }
        );
    }
}