export class StockLedger {
    constructor(
        public SID,//StockItem pk
        public SN,//StockItem
        public Cat,//StockItem
        public oBalance,
        public sIn,
        public sUsed,
        public Adj,
        public sTransferIn,
        public sTransferOut,
        public cBalance
    ) { }

}

export class StockCategory {
    constructor(
        public Cat
    ) { }
}

export class StockLedgerQuery {
    constructor(
        public Cat,
        public StartDate,
        public EndDate,
        public ScID
    )
    { }
}