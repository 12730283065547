

declare var require;
var CryptoJS = require("crypto-js");

export class GlobalFunc {
    //Accessory
    static AcKey: string = "Ac_Key";

    //Category
    static CTKey: string = "CT_Key";

    //Brand
    static BrKey: string = "Br_Key";

    //Model
    static MKey: string = "M_Key";

    //Model,Category,Brand(MCB)
    static MCBKey: string = "MCB_Key";

    //Customer
    static CusKey: string = "Cus_Key";

    //Department
    static DPKey: string = "DP_Key";

    //Service Center
    static SCKey: string = "SC_Key";

    //Service Type
    static STKey: string = "St_Key";

    //Stock Item
    static SIKey: string = "SI_Key";

    //Current Jobs
    static CJKey: string = "CJ_Key";

    //Supplier
    static SplKey: string = "Spl_Key";

    //LoginUser
    static LUKey: string = "LU_Key";

    //JobShift for unable-repair
    //static JSftKey: string = "JSft_Key";

    static findSelectedIndex(temp, selected): number {
        return temp.indexOf(selected);
    }

    static findIndexByID(temp, selected, objtype: string): number {
        if (objtype == 'Accessory') {
            for (var _index in temp) {
                if (temp[_index].AcsID === selected.AcsID) {
                    return parseInt(_index);
                }
            }
            return -1;
        } else if (objtype == 'Brand') {
            for (var _index in temp) {
                if (temp[_index].BID === selected.BID) {
                    return parseInt(_index);
                }
            }
            return -1;
        } else if (objtype == 'Category') {
            for (var _index in temp) {
                if (temp[_index].CatID === selected.CatID) {
                    return parseInt(_index);
                }
            }
            return -1;
        } else if (objtype == 'Customer') {
            for (var _index in temp) {
                if (temp[_index].CID === selected.CID) {
                    return parseInt(_index);
                }
            }
            return -1;
        } else if (objtype == 'Department') {
            for (var _index in temp) {
                if (temp[_index].DID === selected.DID) {
                    return parseInt(_index);
                }
            }
            return -1;
        } else if (objtype == 'Model') {
            for (var _index in temp) {
                if (temp[_index].MoID === selected.MoID) {
                    return parseInt(_index);
                }
            }
            return -1;
        } else if (objtype == 'ServiceCenter') {
            for (var _index in temp) {
                if (temp[_index].ScID === selected.ScID) {
                    return parseInt(_index);
                }
            }
            return -1;
        } else if (objtype == 'ServiceType') {
            for (var _index in temp) {
                if (temp[_index].STID === selected.STID) {
                    return parseInt(_index);
                }
            }
            return -1;
        } else if (objtype == 'StockItem') {
            for (var _index in temp) {
                if (temp[_index].SID === selected.SID) {
                    return parseInt(_index);
                }
            }
            return -1;
        } else if (objtype == 'LoginUser') {
            for (var _index in temp) {
                if (temp[_index].UN === selected.UN) {
                    return parseInt(_index);
                }
            }
            return -1;
        }

        return -2;
    }

    static CloneData(temp, model): any {
        let data = new model();
        for (let prop in temp) {
            data[prop] = temp[prop];
        }
        return data;
    }

    static filterData(query, data: any[]): any[] {
        let filtered: any[] = [];
        // for (let i = 0; i < data.length; i++) {
        //     let temp = data[i];
        //     if (temp.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        //         filtered.push(temp);
        //     }
        // }
        for(let obj of data){
            let temp = obj;
            if (temp.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                filtered.push(temp);
            }
        }
        return filtered;
    }

    static HashString(value: string): string {
                
        var hash = CryptoJS.MD5(value);        
        let hashstring: string = CryptoJS.enc.Base64.stringify(hash);
        
        let result: string = '';
        // for (let i = 0; i < hashstring.length; i++) {        
        //     if (hashstring[i] == '+')
        //         result += 'A';
        //     else
        //         result += hashstring[i];
        // }
        for(let obj of hashstring){
            if (obj == '+')
                result += 'A';
            else
                result += obj;
        }
        return result;
    }

    static periodList(): any {
        let periods = [];
        periods.push({ value: 'a', label: 'Today' });
        periods.push({ value: 'b', label: 'This week' });
        periods.push({ value: 'c', label: 'This Month' });
        periods.push({ value: 'd', label: 'Within 2 Months' });
        periods.push({ value: 'e', label: 'Within 3 Months' });
        periods.push({ value: 'f', label: 'Custom Date' });
        return periods;
    }

    static changeDateTime(datetimeObj): any {
        if (datetimeObj === null) return null;
        else {
            let tmpdatetime = new Date(new Date(datetimeObj).getFullYear(), new Date(datetimeObj).getMonth(), new Date(datetimeObj).getDate(),
                new Date(datetimeObj).getHours(), new Date(datetimeObj).getMinutes(), new Date(datetimeObj).getSeconds(), new Date(datetimeObj).getMilliseconds());

            tmpdatetime.setMinutes((tmpdatetime.getMinutes()) + 30);
            tmpdatetime.setHours((tmpdatetime.getHours()) + 6);
            return tmpdatetime;
        }
    }

    static GetNow(): Date{
        let d : Date = new Date();
        let result: Date = new Date(d.getFullYear() + '-'+ d.getMonth() + '-'+ d.getDate() + 'T' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds() + '.7217519+06:30');
        return  result;


    }
}