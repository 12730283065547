import { Component, ViewChild, NgZone, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

// Thrid Library
import { Table } from 'primeng/table';
import {NgProgressService} from "ng2-progressbar";

//Service
import { DataChangedEvent } from '../service/datachangedevent';
import { HttpServices } from '../service/httpservices';
import { AuthService } from '../service/authservice';
import { Department } from '../department/department';
import { ServiceCenter } from '../servicecenter/servicecenter';


@Component({
    selector: 'i-over7',
    templateUrl: './over7.component.html'
})
export class Over7CP implements OnInit, OnDestroy {
    @ViewChild('table', { static: false }) table: Table;
    @ViewChild('pdt', { static: false }) pdt;
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    
    printMode: boolean = false;
    jobs: any[] = [];
    filteredjobs: any[] = [];
    totalRecords: number;

    oServiceCenterList: ServiceCenter[] = [];
    servicecenterlist: any[] = [];

    oDeptList: Department[] = [];
    deptllist: any[] = [];

    overdaylist = [{ label: '3', value: 3 }, { label: '7', value: 7 }, { label: '10', value: 10 }, { label: '15', value: 15 }, { label: '20', value: 20 }];

    _department: any;
    _servicecenter: any;
    _overday: any;
    _deptName: string = '';
    _scName: string = '';

    toastkey = "i-over7";

    depChanged: any;
    scChanged: any;

    constructor(private pService: NgProgressService ,public httpService: HttpServices, public router: Router, public _ngZone: NgZone, public auth: AuthService, public _dataChanged: DataChangedEvent) {
        this.pService.start();
        this._overday = this.overdaylist[0].value;

        this.depChanged = this._dataChanged.departmentChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "department") {
                        this.afterBroadCastEvent(sRobj, this.oDeptList, 'DID');
                    }
                });
            }
        });

        this.scChanged = this._dataChanged.serviceCenterChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "servicecenter") {
                        this.afterBroadCastEvent(sRobj, this.oServiceCenterList, 'ScID');
                    }
                });
            }
        });
    }

    ngOnInit() {
        this.GetRequiredData().then(exp => {
            if (exp) {
                if (!this._department && this.deptllist && this.deptllist.length > 0) {
                    this._department = this.deptllist[0].value;
                }
                if (!this._servicecenter && this.servicecenterlist && this.servicecenterlist.length > 0) {
                    this._servicecenter = this.servicecenterlist[0].value;
                }
                this.getOverDayJobs();
            }
        });
    }

    ngOnDestroy() {
        this.jobs = this.filteredjobs = this.oServiceCenterList = this.servicecenterlist = this.oDeptList = this.deptllist = this.overdaylist = this._department =
            this._servicecenter = this._overday = undefined;

        if (this.scChanged != undefined) this.scChanged.unsubscribe();
        if (this.depChanged != undefined) this.depChanged.unsubscribe();
    }

    getOverDayJobs() {
        if (this._department === null || this._servicecenter === null) return;

        this.pService.start();
        var param = {
            "did" : this._department,
            "scid" : this._servicecenter,
            "overday" : this._overday,
            "noTaken" : true
        }
        //this.httpService.getData('api/job/getoverdayjobs/' + this._department + '/' + this._servicecenter + '/' + this._overday).subscribe(
            this.httpService.postData('api/job/getoverdayjobs', param).subscribe(
            data => { this.jobs = data; this.pService.done();},
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while getting job data !');
                this.pService.done();
            },
            () => {
                this.getDifferDays().then(rps => {
                    this.filterData();
                    this.pService.done();
                }).catch(err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while getting differ days !');
                    this.pService.done();
                });
            }
        );
        //getting them for printMode !
        this.getDeptName();
        this.getSCName();
    }

    GetRequiredData(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let depList: any = [];
            let scList: any = [];
            this.httpService.getDataFJ(['api/department', 'api/servicecenter']).subscribe(
                data => {
                    depList = data[0];
                    scList = data[1];
                },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                    resolve(false);
                },
                () => {
                    if (depList) {
                        this.oDeptList = depList.map(d => Object.assign({}, d));
                        this.setDepartmentData(this.oDeptList);
                    }

                    if (scList) {
                        this.oServiceCenterList = scList.map(s => Object.assign({}, s));
                        this.setServiceCenterData(this.oServiceCenterList);
                    }

                    resolve(true);
                }
            );
        });
    }

    afterBroadCastEvent(objData, dataList, id) {
        if (objData && objData.Obj && dataList) {
            let ind = dataList.findIndex(temp => temp[id] === objData.Obj[id]);
            if (objData.Msg === 'deleted') {
                if (objData.ObjType.toLowerCase() === "department") {
                    
                    this.httpService.getData('api/department').subscribe(
                        data => { this.oDeptList = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'); 
                        this.pService.done(); 
                    },
                        () => { 
                            this.setDepartmentData(this.oDeptList);
                            this.pService.done(); 
                        }
                    );

                } else if (objData.ObjType.toLowerCase() === "servicecenter") {

                    this.httpService.getData('api/servicecenter').subscribe(
                        data => { this.oServiceCenterList = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'); 
                        this.pService.done(); 
                    },
                        () => { 
                            this.setServiceCenterData(this.oServiceCenterList);
                            this.pService.done(); 
                        }
                    );
                    
                }
            }
            else {
                if (ind >= 0) dataList[ind] = objData.Obj;
                else dataList.unshift(objData.Obj);

                if (objData.ObjType.toLowerCase() === "department") {
                    this.setDepartmentData(dataList);
                } else if (objData.ObjType.toLowerCase() === "servicecenter") {
                    this.setServiceCenterData(dataList);
                }
            }
        }
    }

    setDepartmentData(dataList: Department[]) {
        this.deptllist = [];
        if (dataList) {
            dataList.map(x => {
                if (x.DID === this.auth.DID) {
                    this._department = x.DID;
                }
                this.deptllist.push({ label: x.DN, value: x.DID });
            });
        }
    }

    setServiceCenterData(dataList: ServiceCenter[]) {
        this.servicecenterlist = [];
        if (dataList) {
            dataList.map(x => {
                if (x.ScID === this.auth.ScID) {
                    this._servicecenter = x.ScID;
                }
                this.servicecenterlist.push({ label: x.ScN, value: x.ScID });
            });
        }
    }

    getDifferDays(): Promise<any> {
        return new Promise((resolve) => {
            let count = 0;
            this.jobs.forEach(j => {
                count++;
                var date1 = new Date(j.JDT);
                var date2 = new Date();

                var timeDiff = Math.abs(date2.getTime() - date1.getTime());
                j.diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
            });
            if (count === this.jobs.length) resolve('OK');
        });
    }

    getDeptName() {
        let tmp = this.deptllist.filter(dt => dt.value === this._department);
        this._deptName = tmp[0].label;
    }

    getSCName() {
        let tmp = this.servicecenterlist.filter(sc => sc.value === this._servicecenter);
        this._scName = tmp[0].label;
    }

    servicecentertodropdown(data: any[]): Promise<any> {
        return new Promise((resolve) => {
            this.servicecenterlist = [];
            data.forEach(x => {
                if (x.ScID === this.auth.ScID) {
                    this._servicecenter = x.ScID;
                }
                this.servicecenterlist.push({ label: x.ScN, value: x.ScID });
            });
            //this._servicecenter = this.servicecenterlist[0].value;
            resolve('OK');
        });
    }

    departmenttodropdown(data: any[]): Promise<any> {
        return new Promise((resolve) => {
            this.deptllist = [];
            data.forEach(x => {
                if (x.DID === this.auth.DID) {
                    this._department = x.DID;
                }
                this.deptllist.push({ label: x.DN, value: x.DID });
            });
            //this._department = this.deptllist[0].value;
            resolve('OK');
        });
    }

    filterData() {
        this.totalRecords = this.jobs.length;
        // this.filteredjobs = this.jobs.slice(0, 20);        
        this.filteredjobs = this.jobs.map(a => Object.assign({}, a));
        //this.table.paginate({ first: 0, rows: 20 });
    }

    onChanged(event) { this.getOverDayJobs(); }

    goJobDetail(rdata) {
        //this.router.navigate(['/searchjob', rdata.trim()]); 
        // this.router.navigate(['/searchjob']).then(obj => {
        //     this._datachanged.searchjobChanged.emit(rdata.trim());
        // });
        this.router.navigate(['/searchjob', rdata, 'JID']);
    }

    printjob() { window.print(); }

    exportToCSV() {
        //this.pdt.exportCSV(); 
        this.httpService.getData('api/job/exportoverdayjobs/' + this._department + '/' + this._servicecenter + '/' + this._overday).subscribe(
            data => { },
            err => { },
            () => {
                //window.location.href = this.httpService._url + 'excel/unfinishedjobs.csv';
                this.httpService.DirectLocToWindow("unfinishedjobs");
            }
        );
    }
}

