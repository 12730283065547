import { Injectable, EventEmitter } from '@angular/core';
import { SrMessage } from '../model/message';
import 'ms-signalr-client';
import { HttpServices } from './httpservices';

declare var $;

@Injectable()
export class SignalRService {

    public proxy;
    public proxyName: string = 'LinnServiceHub';
    public connection;

    public messageReceived: EventEmitter<SrMessage>;
    public connectionEstablished: EventEmitter<Boolean>;
    public connectionExists: Boolean;

    constructor(public httpservice: HttpServices) {        
        this.connectionEstablished = new EventEmitter<Boolean>();
        this.messageReceived = new EventEmitter<SrMessage>();
        this.connectionExists = false;



        this.connection = $.hubConnection(httpservice._url + '/signalr');
        this.proxy = this.connection.createHubProxy(this.proxyName);
        this.registerOnServerEvents();
        this.startConnection();
    }

    public sendMessage(message: SrMessage) {
        this.proxy.invoke('SendMessage', message);
    }

    public startConnection(): void {
        this.connection.start().done((data) => {
            this.connectionEstablished.emit(true);
            this.connectionExists = true;
        }).fail((error) => {
            this.connectionEstablished.emit(false);
        });
    }

    public registerOnServerEvents(): void {
        this.proxy.on('broadcastMessage', (data: SrMessage) => {                        
            this.messageReceived.emit(data);
        });
    }
}