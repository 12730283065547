import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'i-default2',
    template:''
})
export class BlankWithParamCP implements OnInit, OnDestroy {
    public sub: any;
    public jid: string='';

    constructor(public currentRoute: ActivatedRoute, public router: Router) {
        this.sub = this.currentRoute.params.subscribe(param => { this.jid = param['jid'] });
    }

    ngOnInit(): void {
        if (this.jid !== undefined || this.jid !== '' || this.jid !== null) {
            this.router.navigate(['/searchjob', this.jid]).then(rps => {this.jid = null});
        }
    }

    ngOnDestroy(): void {
        if (this.sub !== undefined) {
            this.sub.unsubscribe();
        }
    }    
}