import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import { LazyLoadEvent } from 'primeng/api';
import {NgProgressService} from "ng2-progressbar";

//Service
import { DataChangedEvent } from '../service/datachangedevent';
import { HttpServices } from '../service/httpservices';
import { GlobalFunc } from '../service/globalfunction';

//Model
import { Customer } from './customer';
import { AuthService } from '../service/authservice';

@Component({
    selector: 'i-customer',
    templateUrl: './customer.component.html'
})

export class CustomerCP implements OnInit {
    @ViewChild('entryModal', { static: false }) entryModal: BsModalComponent;
    @ViewChild('deleteModal', { static: false }) deleteModal: BsModalComponent;
    @ViewChild('input', { static: false }) vc;

    apiName: string = "customer";
    id = 'CID';
    public userForm: FormGroup;
    public emaliValid: boolean = true;
    public isValid: boolean = false;
    public isCusModalOpen: boolean = false;
    public customers: Customer[];
    public selectedCustomer: Customer;
    public selectedChk: Customer[];
    public isNew: boolean;
    public actionlogs: any[];
    public filteredCities: any[];

    public totalRecords: number;
    public toastkey = "i-customer";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    
    constructor(private pService: NgProgressService ,public httpService: HttpServices, public fb: FormBuilder, public _dataChanged: DataChangedEvent, public _ngZone: NgZone, public auth: AuthService) {
        this.pService.start();
        this._dataChanged.customerChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === this.apiName.toLowerCase()) {
                        this.afterBroadCastEvent(sRobj);
                    }
                });
            }
        });
    }

    ngOnInit() {
        //Creating Form
        this.CreateForm();
        //intialize data     
        this.GetData();
    }

    GetData(): void {
        let cusList = [];
        this.httpService.getData('api/customer').subscribe(
            data => {
                cusList = data;
            },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                this.pService.done();
            },
            () => {
                if (cusList) {
                    this.customers = cusList.map(c => Object.assign({}, c));
                    this.totalRecords = this.customers.length;
                }
                this.pService.done();
            });
    }

    afterBroadCastEvent(objData) {
        if (objData && objData.Obj) {
            let ind = this.customers.findIndex(temp => temp[this.id] === objData.Obj[this.id]);
            if (objData.Msg === 'deleted') {
                this.RefreshData();
            }
            else {
                if (ind >= 0) this.customers[ind] = objData.Obj;
                else this.customers.unshift(objData.Obj);
            }
        }
    }

    RefreshData() {
        this.pService.start();
        this.GetData();
    }

    getCities(): any[] {
        let temp: any = [];
        this.customers.forEach(({ City: c }) => { temp.push(c); });
        //Remove duplicate object with set
        return Array.from(new Set(temp)).sort();
    }

    search(event): void {
        let query = event.query;
        this.filteredCities = GlobalFunc.filterData(query, this.getCities());
    }

    CreateForm(): void {
        this.userForm = this.fb.group({
            'CID': new FormControl(0),
            'CN': new FormControl('', Validators.compose([Validators.required, Validators.maxLength(50)])),
            'Ph': new FormControl('', Validators.compose([Validators.required, Validators.maxLength(50)])),
            'Em': new FormControl(''),
            'Addr': new FormControl(''),
            'City': new FormControl('', Validators.compose([Validators.maxLength(50)])),
            //'DID': new FormControl(this.auth.DID, Validators.required),
            'nSMS': new FormControl(false),
            'nEm': new FormControl(false),
            'nPh': new FormControl(false)
        });
        //Form Change
        this.userForm.valueChanges.subscribe(data => this.isValid = !this.checkFormValid());
    }

    checkFormValid(): boolean {
        let isValid: boolean = false;
        if (this.userForm.controls['CN'].value == "")
            isValid = true;
        else if (this.userForm.controls['CN'].value.length >= 50)
            isValid = true;
        if (this.userForm.controls['Ph'].value == "")
            isValid = true;
        else if (this.userForm.controls['Ph'].value && this.userForm.controls['Ph'].value.length >= 50)
            isValid = true;
        if (this.userForm.controls['Em'].value && this.userForm.controls['Em'].value.length != 0) {
            if (!this.userForm.controls['Em'].value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
                this.emaliValid = false; isValid = true;
            }
            else { this.emaliValid = true }
        }
        else { this.emaliValid = true }

        if (this.userForm.controls['City'].value.length >= 50)
            isValid = true;
        return isValid;
    }

    AddNewCustomer() {
        this.isCusModalOpen = true;
        this.selectedCustomer = new Customer();
        this.entryModal.open();
    }

    EditCustomer(customer: Customer) {
        this.isCusModalOpen = true;
        this.selectedCustomer = customer;
        this.entryModal.open();
    }

    onCloseCustomerModal(customer: Customer, pdt: any, gb) {
        this.isCusModalOpen = false;
        this.selectedCustomer = null;

        if (customer) {
            this.resetDatable(pdt, gb);
            //this.GetData();
            this.httpService.showToast(this.toastkey, 'success', 'Successfully inserted/updated');
        }

        this.entryModal.close();
    }

    showDialogToDelete(): void {
        if (this.selectedChk != null && this.selectedChk != undefined && this.selectedChk.length > 0) {
            this.deleteModal.open();
        } else {
            this.httpService.showToast(this.toastkey, 'warn', 'Need to select row!');
        }
    }

    deleteData(): void {
        this.httpService.postData('api/customer/multidelete/', this.selectedChk).
            subscribe(
                data => {
                    this.httpService.showUnsuccessDelMsg(this.toastkey, data);
                },
                err => {
                    this.doneDeleteProcess('error', 'Error occurs while deleting data!');
                },
                () => {
                    this.doneDeleteProcess('success', 'Successfully deleted');
                });
    }

    deleteModalClose() {
        this.deleteModal.close();
        //this.httpService.AddClassToModal(true);
    }

    doneDeleteProcess(header: string, msg: string) {
        this.selectedChk = null;
        this.deleteModalClose();
        this.httpService.showToast(this.toastkey, header, msg);
    }

    resetDatable(pdt, gb): void {
        if (gb && gb.value && gb.value != '') {
            gb.value = '';
            if (pdt) pdt.reset();
        }
    }

    getDeptName(did) {
        if (did === null) return '';
        let ind = this.httpService.deptllist.findIndex(expv => expv.value === did);

        if (ind >= 0) {
            return this.httpService.deptllist[ind].label;
        }
    }

    exportToCSV() {
        this.httpService.postData('api/customer/export', null).subscribe(
            data => { },
            err => { },
            () => {
                // window.location.href = this.httpService._url + 'excel/customer.csv';
                this.httpService.DirectLocToWindow("customer");
            }
        );
    }


}