import { Component, OnInit, NgZone, ViewChild } from '@angular/core';

//Third Library
import {NgProgressService} from "ng2-progressbar";

//Service
import { DataChangedEvent } from '../service/datachangedevent';
import { HttpServices } from '../service/httpservices';

//Model
import { Job } from '../job/job';
import { MCB } from '../job/mcb';

@Component({
    selector: 'i-dashboardsup',
    templateUrl: './dashboardsupervisor.component.html',
    styleUrls: ['./animate.css']

})

export class DashBoardSupCP implements OnInit {
    public jobs: Job[] = [];
    public _listOfMon: MCB[] = [];
    public animateJID: string = "";

    public startPBar: any;
    public donePBar: any;
    public toastkey = "i-dashboardsup";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public httpService: HttpServices, public _dataChanged: DataChangedEvent, public _ngZone: NgZone) {
        this.pService.start();
        this._dataChanged.jobTStatusChanged.subscribe((changedData) => {
            this._ngZone.run(() => {
                this.animateJID = changedData.Value1;
                this.GetRequiredData();
            });
        });

        this._dataChanged.jobSmStatusChanged.subscribe((changedData) => {
            this._ngZone.run(() => {
                this.GetRequiredData();
            });
        });
    }

    getAnimationS(_jid: string): string {
        if (_jid == this.animateJID) {
            return 'animated shake';
        }
        return '';
    }

    ngOnInit() {
        this.GetRequiredData();
    }

    getmcbName(moid: number): string {
        if (this._listOfMon == undefined) return "";
        for (var i = 0; i < this._listOfMon.length; i++) {
            if (this._listOfMon[i].MoID == moid) {
                return this._listOfMon[i].MoN + " / " + this._listOfMon[i].CatN + " / " + this._listOfMon[i].BN;
            }
        }
    }

    GetRequiredData(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let mcbList: any = [];
            let dsList: any = [];
            this.httpService.getDataFJ(['api/job/getmcb', 'api/dashboardsup']).subscribe(
                data => {
                    mcbList = data[0];
                    dsList = data[1];
                },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                    resolve(false);
                },
                () => {
                    if (mcbList) {
                        this._listOfMon = mcbList.map(m => Object.assign({}, m));
                    }

                    if (dsList) {
                        this.jobs = dsList.map(d => Object.assign({}, d));
                    }

                    this.pService.done();
                    resolve(true);
                }
            );
        });
    }
}