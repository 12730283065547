import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

//Third Library
import {NgProgressService} from "ng2-progressbar";

//Model
import { HttpServices } from '../service/httpservices';
import { StockIn } from './stockin';

@Component({
    selector: 'i-stockin',
    templateUrl: './stockin.component.html'
})
export class StockInCP implements OnInit {
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    
    public stockIn: StockIn;
    public printMode: boolean = false;
    @ViewChild('input', { static: false }) vc;
    public toastkey = "i-stockin";
    
    constructor(private pService: NgProgressService ,public httpService: HttpServices, public router: Router) {
        this.stockIn = new StockIn('', undefined, '', '', '');
    }
    ngOnInit() {
        if (this.vc != undefined) this.vc.nativeElement.focus();
    }
    onChange(evt: any) {
        var files = evt.target.files;
        var file = files[0];
        if (files && file) {
            var reader = new FileReader();
            reader.onload = (function (file) {
                return file;
            })(this.stockIn.EFN = file.name);

            reader.onloadend = () => {
                this.stockIn.UploadFileData = btoa(reader.result.toString());
                this.stockIn.EFN = file.name;
            }
            reader.readAsBinaryString(file);
        }
    }
    goToList() {
        var link = ['/stockinlist'];
        this.router.navigate(link);
    }
    saveData(): void {
        this.httpService.postData('api/stockin/uploadfile', this.stockIn).subscribe(
            data => { },
            err => {
                this.httpService.showToast(this.toastkey, 'error', err);
                this.pService.done();
            },
            () => {
                this.goToList();
            }
        );
    }
}