//import * as  AuthStorage from 'localforage';

declare var require;
const AuthStorage = require("localforage");

import { Http, Headers, ConnectionBackend } from '@angular/http';
import {Injectable} from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
    public loginName: string;
    public authtoken: string;
    public isLoggedIn: boolean;
    public role: string;
    public DID: number;
    public ScID: number;
    public STID: number;
    public STN: string;
    public UN: string;
    public Addr: string;
    public ScN: string;

    public DN: string;
    public DPh: string;
    
    public isAuthorized: boolean = false;
    public appRole: any;

    constructor(public http: Http, public router: Router) {
        AuthStorage.config({
            driver: AuthStorage.WEBSQL,
            name: "LnnService",
            version: "2.0",
            size: 52428800,
            storeName: 'keyvaluepairs',
            description: ''
        });
    }

    getAuthInfo(): Promise<any> {        
        return AuthStorage.getItem('authinfo');
    }

    storeCredential(authinfo: any) {
        AuthStorage.setItem('authinfo', authinfo).then(result => {
            this.router.navigate(['/']);
        });   
    }

    setAllData(data){
        this.loginName = data.name;
        this.authtoken = data.access_token;
        this.role = data.role;
        this.DID = data.did;
        this.ScID = data.scid;
        this.DPh = data.dph;
        this.DN = data.dn;
        this.ScN = data.scn;
        this.STID = data.stid;
        this.STN = data.stn;
        this.UN = data.un;
        this.Addr = data.addr;
        this.isLoggedIn = true;
        this.isAuthorized =true;
        this.appRole = data.role;
    }

    // logIn(username: string, password: string) {
    //     let header = new Headers();
    //     header.append('Content-Type', 'application/x-www-form-urlencoded');
    //     var data = "grant_type=password&username=" + username + "&password=" + password;
    //     return this.http.post(http.url +  'token', data, { headers: header, method: 'post' }).map(res => res.json());
    //     //return this.http.post('http://localhost:48598/token', data, { headers: header, method: 'post' }).map(res => res.json());
    // }

    // logOut() {
    //     this.clearLoginProperty(0,0);
    //     AuthStorage.removeItem('authinfo');      
    // }

    clearLoginProperty(did: number, scid: number): void {
        this.loginName = '';  
        this.authtoken=''; 
        this.role = '';
        this.DID = did;
        this.ScID = scid;
        this.DN = '';
        this.DPh = '';
        this.ScN = '';
        this.STID = 0;
        this.STN = '';
        this.UN =  '';
        this.Addr = '';
        this.isLoggedIn = false;
        this.isAuthorized =false;
        this.appRole = '';
    }
}