import { Component } from '@angular/core';
@Component({
    selector: 'i-blank',
    template: `
    <div>
        
    </div>`
})
export class BlankCP {
    constructor() {

    }
}