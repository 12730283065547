import { Component, OnInit, ViewChild, NgZone, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import { SelectItem } from 'primeng/api';
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';

//Model
import { Category } from '../category/category';
import { AuthService } from '../service/authservice';

@Component({
    selector: 'i-modeldialog',
    templateUrl: './modeldialog.component.html'
})

export class ModelDialogCP implements OnInit {
    @Output() onClick = new EventEmitter<string>();
    @ViewChild('categoryModal', { static: false }) categoryModal: BsModalComponent;
    @ViewChild('input', { static: false }) vc;

    @Input() isModal: boolean = false;
    public modelForm: FormGroup;
    public categories: Category[];
    public catlist: SelectItem[];
    public toastkey = "i-modeldialog";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public httpService: HttpServices, public fb: FormBuilder, public _ngZone: NgZone, public auth: AuthService) {
        this.pService.start();
    }

    ngOnInit() {
        this.CreateForm();
        this.GetCategory();
    }

    CreateForm(): void {
        this.modelForm = this.fb.group({
            'MoID': new FormControl(0),
            'MoN': new FormControl('', Validators.compose([Validators.required, Validators.maxLength(50)])),
            'CatID': new FormControl(0, Validators.required),
            'CatN': new FormControl('')
        });

        if (this.vc != null || this.vc != undefined) this.vc.nativeElement.focus();
    }

    GetCategory(): void {
        let cList = [];
        this.httpService.getData('api/category').subscribe(
            data => cList = data,
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                this.pService.done();
            },
            () => {
                this.catlist = [];
                this.catlist.push({ label: 'Select category', value: null });
                if (cList) {
                    cList.map(x => {
                        if (x.DID === this.auth.DID) this.catlist.push({ label: x.CatN + " (" + x.BN + ")", value: x.CatID });
                    });
                }
                this.pService.done();
            });
    }

    // loadtodropdown(data: any[]): void {
    //     this.catlist = [];
    //     this.catlist.push({ label: 'Select category', value: null });
    //     data.forEach(x => {
    //         if (x.DID === this.auth.DID) this.catlist.push({ label: x.CatN + " (" + x.BN + ")", value: x.CatID });
    //     });
    // }

    findCategory(para) {
        if (para != 0) {
            if (this.categories != undefined) {
                for (let cat of this.categories) {
                    if (cat.CatID === para) {
                        this.modelForm.controls['CatN'].patchValue(cat.CatN);
                        break;
                    }
                }
            } else {
                this.modelForm.controls['CatN'].patchValue('');
            }
        }
    }

    onclick(isSaving: boolean): void {
        if (isSaving) {
            if (this.modelForm != undefined) {
                this.pService.start();
                this.httpService.postData('api/model', this.modelForm.value).
                    subscribe(
                        ddd => {
                            this.modelForm.controls['MoID'].patchValue(ddd);
                        },
                        err => {
                            this.httpService.showToast(this.toastkey, 'error', 'Error occurs while inserting/updating data!');
                            this.pService.done();
                        },
                        () => {
                            this.onClick.emit(this.modelForm.controls['MoID'].value);
                            //this.onClick.emit(this.modelForm.value);
                            this.pService.done();
                        });
            }
        } else {
            this.onClick.emit();
        }
    }

    onDismissCategoryModal() {
        this.GetCategory();
        this.httpService.AddClassToModal(this.isModal);
    }

    CategoryModalClose() {
        this.categoryModal.close();
        this.GetCategory();
        this.httpService.AddClassToModal(this.isModal);
    }
}