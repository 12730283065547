import { Component, OnInit, ViewChild, Input, OnDestroy, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UUID } from 'angular2-uuid';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import { NgProgressService } from "ng2-progressbar";
import { DataChangedEvent } from '../service/datachangedevent';

//Service
import { HttpServices } from '../service/httpservices';
import { DropDownValue } from './dropdownvalue';

//Model


@Component({
    selector: 'i-description',
    templateUrl: './description.component.html'
})

export class DescriptionCp implements OnInit, OnDestroy {
    @ViewChild('modal', { static: false }) modal: BsModalComponent;
    @ViewChild('entryModal', { static: false }) entryModal: BsModalComponent;
    @ViewChild('deleteModal', { static: false }) deleteModal: BsModalComponent;
    @ViewChild('input', { static: false }) vc;
    @Input() isModal: boolean = false;
    apiName: string = "DropDownValue";
    id = 'Id';
    public dataList: Array<DropDownValue> = [];
    public dataListFilter: Array<DropDownValue> = [];
    public toastkey = "i-description";
    public isNew: boolean;
    public descObj: DropDownValue;
    public selectedChk: DropDownValue[];
    public actionlogs: any[];
    public search;
    current_route;

    selectedType: any = 'a';    
    notvalidInput ={ description : false   };

    constructor(private pService: NgProgressService, public httpService: HttpServices, public activeRoute: ActivatedRoute, public _dataChanged: DataChangedEvent, public _ngZone: NgZone) {        
        this.pService.start();
        this._dataChanged.departmentChanged.subscribe((sRobj) => {
            //this.LoadDataFromLocalForage();
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === this.apiName.toLowerCase()) {
                        
                        this.afterBroadCastEvent(sRobj);
                    }
                });
            }
        });
    }
    
    afterBroadCastEvent(objData) {
        if (objData && objData.Obj) {
            let ind = this.dataList.findIndex(temp => temp[this.id] === objData.Obj[this.id]);
            if (objData.Msg === 'deleted') {
                this.refresh();
            }
            else {
                if (ind >= 0) this.dataList[ind] = objData.Obj;
                else this.dataList.unshift(objData.Obj);
            }
        }
    }

    ngOnInit(): void { 
        this.current_route = this.activeRoute.params.subscribe((param: any) => {
            if(param){
                this.search = "";
                this.selectedType = param.type;
                this.GetData();
            }
        });        
    }

    ngOnDestroy(): void {
        this.dataList = this.dataListFilter = undefined;
    }

    GetData() {
        let dtList: DropDownValue[] = [];
        let param ={ "Type": this.selectedType };
        this.httpService.postData('api/' + this.apiName + '/GetByType', param).subscribe(
            data => {
                dtList = data;
            },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                this.pService.done();
            },
            () => {
                this.dataList = dtList.map(a => Object.assign({}, a));
                this.filterData();
                this.pService.done();
            }
        );
    }

    filterData() {
        this.dataListFilter = this.dataList || [];
        // if (this.selectedType) {
        //     this.dataListFilter = this.dataListFilter.filter(s => s.Type == this.selectedType);
        // }
        if (this.search && this.search != '') {
            this.dataListFilter = this.dataListFilter.filter((s) => (s.Description ? s.Description.trim() : "").toLowerCase().indexOf(this.search.toLowerCase().trim()) != -1);
        }
    }

    goSearch(event) {
        if (event.keyCode === 13) {
            this.filterData();
        }
    }

    refresh() {
        this.pService.start();
        this.GetData();
    }

    ChangedType(event: any) {
        this.selectedType = event;
        this.filterData();
    }

    showDialogToAdd(): void {
        this.isNew = true;        
        this.descObj = new DropDownValue(UUID.UUID(), '', '', this.selectedType, '');
        this.entryModal.open();        
    }

    showDialogToEdit(description: DropDownValue): void {
        this.isNew = false;        
        this.descObj = Object.assign({}, description);        
        this.entryModal.open();
        this.vc.nativeElement.focus();
    }

    showDialogToDelete(): void {
        if (this.selectedChk != null && this.selectedChk != undefined && this.selectedChk.length > 0) { this.deleteModal.open(); }
        else { this.httpService.showToast(this.toastkey, 'warn', 'Need to select row!'); }
    }

    deleteData(): void {
        this.httpService.postData('api/DropDownValue/multidelete/', this.selectedChk).
            subscribe(
                data => {
                    this.httpService.showUnsuccessDelMsg(this.toastkey, data);
                },
                err => {
                    this.doneDeleteProcess('error', 'Error occurs while deleting data!');
                },
                () => {
                    this.doneDeleteProcess('success', 'Successfully deleted');
                });
    }

    deleteModalClose() {
        this.deleteModal.close();
        this.httpService.AddClassToModal(this.isModal);
    }


    doneDeleteProcess(header: string, msg: string) {
        this.selectedChk = null;
        this.deleteModalClose();
        this.httpService.showToast(this.toastkey, header, msg);
    }

    entryModalClose() {
        this.notvalidInput = {description : false };
        this.entryModal.close();
        this.httpService.AddClassToModal(this.isModal);
    }

    modalClose() {
        this.modal.close();
        this.httpService.AddClassToModal(true);
    }

    save(): void {        
        this.checkValid().then(s=>{
            if(this.notvalidInput.description) return;
            this.pService.start();
            this.httpService.postData('api/' + this.apiName, this.descObj).
                subscribe(
                    ddd => { },
                    err => {
                        this.notvalidInput = {description : false };
                        this.httpService.showToast(this.toastkey, 'error', 'Error occurs while inserting/updating data!');
                        this.pService.done();
                    },
                    () => {
                        //this.resetDatable(pdt, gb);
                        this.notvalidInput = {description : false };
                        this.GetData();
                        this.httpService.showToast(this.toastkey, 'success', 'Successfully inserted/updated!');
                        this.entryModal.close();
                        this.pService.done();
                        this.httpService.AddClassToModal(this.isModal);
                    });
        })
    }

    showLogDialog(): void {
        if (this.descObj != undefined) {
            let temp = [];
            this.pService.start();
            this.httpService.getData('api/actionlog/' + this.descObj.Id.toUpperCase() + '/' + 'DropDownValue').subscribe(
                data => temp = data,
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                },
                () => {
                    if (temp != undefined) { this.actionlogs = temp; this.modal.open(); }
                    else { this.httpService.showToast(this.toastkey, 'warn', 'There is no record to show!'); }
                    this.pService.done();
                });
        }
    }

    valChangebDescription(event: string) {
        this.descObj.Description = event;
        this.checkValid();
    }

    checkValid(): Promise<boolean>{
        return new Promise<boolean>((resolve)=>{
            this.notvalidInput.description = this.httpService.checkStringEmpty(this.descObj.Description);    
            resolve(true);
        })        
    }

    exportToCSV() {
        let param = {Type: this.selectedType};
        this.httpService.postData('api/DropDownValue/ExportByType', param).subscribe(
            data => { },
            err => { },
            () => {
                //window.location.href = this.httpService._url + 'excel/department.csv';
                this.httpService.DirectLocToWindow("dropdownvalue");
            }
        );
    }
}
