import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response } from '@angular/http';
import { Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/observable';
import { AuthService } from './authservice';
import { SelectItem } from 'primeng/api';
import { lFservice } from "./lFservice";
import { MessageService } from 'primeng/api';

@Injectable()
export class HttpServices {
    //_url: string = "http://linnservice.com/";
   // _url: string = "http://linnservice.itstar.io/";
   _url = window.location.origin + '/';

    public deptllist: SelectItem[];

    public serviceCenters: SelectItem[];

    periods = [{ value: 'a', label: 'Today' },
    { value: 'b', label: 'This week' },
    { value: 'c', label: 'This Month' },
    { value: 'd', label: 'Within 2 Months' },
    { value: 'e', label: 'Within 3 Months' },
    { value: 'f', label: 'Custom Date' }
    ];

    public mobileWidth: boolean = false;

    public searchData: string = '';
    public searchType: string = 'JID';
    dateFormat = "dd/M/yy";
    dateTimeFormat = "dd/MMM/yyyy hh:mm:ss a";
    dtFormatNoSecond = "dd/MMM/yy hh:mm a";


    constructor(public router: Router, public _http: Http, public httpClient: HttpClient, public auth: AuthService, public messageService: MessageService) {
    }

    GetDepartment() {
        let temp = [];
        this.getData('api/department_nonAuth').subscribe(
            data => {
                temp = data;
                this.deptllist = [];
                this.deptllist.push({ label: '- Select Department -', value: null });
                if (temp == undefined) return;
                temp.forEach(x => {
                    if (x.DN !== '') this.deptllist.push({ label: x.DN, value: x.DID });
                });
            },
            err => { });
    }

    GetServiceCenters() {
        let temp = [];
        this.getData('api/servicecenter_nonAuth').subscribe(
            data => {
                temp = data;
                this.serviceCenters = [];
                this.serviceCenters.push({ label: 'Select service center', value: null });
                if (temp == undefined) return;
                temp.forEach(x => this.serviceCenters.push({ label: x.ScN, value: x.ScID }));
            },
            err => { });
    }

    createAuthorizationHeader(headers: Headers): Promise<boolean> {
        return new Promise((resolve, reject) => {
            headers.append('Content-Type', 'application/json');
            if (this.auth.authtoken) {
                headers.append("Authorization", 'Bearer ' + this.auth.authtoken);
            }
        });

    }

    getData(paramURL: string) {
        let headers = new Headers();
        this.createAuthorizationHeader(headers);        
        return this._http.get(this._url + paramURL, { headers }).map(res => res.json());

    }



    getDataFJ(paramURL: string[]) {
        let headers = new Headers();
        this.createAuthorizationHeader(headers);

        let qarray: any[] = [];

        for (let obj of paramURL) {
            qarray.push(this._http.get(this._url + obj, { headers }).map((res: Response) => res.json()));
        }
        return Observable.forkJoin(qarray);
    }

    postData(paramURL: string, data: any) {
        let headers = new Headers();
        this.createAuthorizationHeader(headers);
        return this._http.post(this._url + paramURL, JSON.stringify(data), { headers: headers, method: 'post' }).map(res => res.json());
    }

    // linnPostData(paramURL: string, data: any) {
    //     let Url = 'https://shopping.linncomputer.com/api/';
    //     let headers = new Headers();
    //     this.createAuthorizationHeader(headers);
    //     return this._http.post(Url + paramURL, JSON.stringify(data), { headers: headers, method: 'post' }).map(res => res.json());
    // }

    linnPostData(apiName: string, data: any): Promise<any> {
        return new Promise((resolve, reject) => {
                this.httpClient.request(this.request( 'https://shopping.linncomputer.com/api/' + apiName, 'POST', JSON.stringify(data))).subscribe(
                    e => {

                        if (e.type === HttpEventType.UploadProgress || e.type === HttpEventType.DownloadProgress) {
                        }
                        else if (e instanceof HttpResponse) {
                            resolve(e.body);
                        }

                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    request(apiName: string, type: string, data = undefined) {
        let setHeaders = {
            setHeaders: {
                'Content-Type': 'application/json',
            }
        }
        return new HttpRequest(type, apiName, data, { reportProgress: true, }).clone(setHeaders);
    };

    /////////////////////////////////////////
    logout(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (this.auth.UN) {
                this.sendLogout(this.auth.UN).then(exp => {
                    resolve(true);
                }).catch(err => {
                    reject(err);
                });;
            } else {
                this.goToLogin();
                this.router.navigate(['/login']);
            }
        });
    }

    sendLogout(loginId: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            let temp = [];
            this.getData('api/loginuser/logout/' + loginId).subscribe(

                data => {

                    lFservice.Get_LF('AccessToken').then(res => {
                        if (res) {
                            this.goToLogin();
                        }
                        else {
                            this.goToLogin();
                            this.router.navigate(['/login']);
                        }
                        resolve(true);
                    })
                },
                err => {
                    reject(err);
                });
        })

    }

    goToLogin() {
        this.auth.clearLoginProperty(0, 0);
        lFservice.RemoveAllData();
    }

    logIn(username: string, password: string) {
        let header = new Headers();
        header.append('Content-Type', 'application/x-www-form-urlencoded');
        var data = "grant_type=password&username=" + username + "&password=" + password;
        return this._http.post(this._url + 'token', data, { headers: header, method: 'post' }).map(res => res.json());
    }

    ///////////////////////////////////////
    validASCII(e: string) {
        let vaildASCII = true;
        if (this.checkStringEmpty(e)) return vaildASCII;
        for (let i = 0; i < e.length; i++) {
            if (e.charCodeAt(i) >= 32 && e.charCodeAt(i) <= 126) vaildASCII = true;
            else {
                vaildASCII = false;
                break;
            }
        }
        return vaildASCII;
    }

    checkStringEmpty(data: string): boolean {
        if (data === undefined || data === null || data.trim() === '') {
            return true;
        }
        return false;
    }

    isValidString(val: string, max = 0, min = 0) {
        if (val) {
            if (val.trim() === '') return false;
            else if (max !== 0 && val.length > max) return false;
            else if (min !== 0 && val.length < min) return false;
            else return true;
        }
        else return false;

    }

    checkMaxLength(data: string, max: number): boolean {
        if (data === undefined || data === null) return false;
        if (data.length > max) {
            return true;
        }
        return false;
    }

    checkEmail(data: string): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            if (data.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                resolve(true);
            }
            else {
                if (data == undefined || data.toString().trim() == "") { resolve(true); }
                else { resolve(false); }
            }
        });
    }

    AddClassToModal(ismodal: boolean) {        
        if (ismodal) {
            var element = document.getElementById("index-body");

            if (!element.classList.contains("modal-open")) {
                element.classList.add("modal-open");
            }
        }
    }

    showToast(keystr: string, toasttype: string, message: string) {
        let summarymsg = "";
        switch (toasttype.trim()) {
            case "error": summarymsg = "Error Message";
                break;
            case "warn": summarymsg = "Warn Message";
                break;
            case "info": summarymsg = "Info Message";
                break;
            case "success": summarymsg = "Success Message";
                break;
        }
        this.messageService.add({ key: keystr, severity: toasttype, summary: summarymsg, detail: message });
    }

    DirectLocToWindow(fileName: string) {
        if (fileName != null) {
            window.location.href = this._url + 'excel/' + fileName + '.csv';
        }
    }

    showUnsuccessDelMsg(key: string, data: any) {
        if (data && Array.isArray(data)) {
            this.showToast(key, 'error', 'We are not able to delete ' + data.length + (data.length === 1 ? 'row.' : 'rows.'));
        }
    }
};