export class JobListNewDeptQuery{
    constructor(
        public jobstatusId?, 
        public departmentId?, 
        public servicecenterId?, 
        public servicetypeId?, 
        public period?, 
        public fromdate?, 
        public todate?, 
        public noTaken?, 
        public noSDone?, 
        public jat?,
        public jobremark?
        ){}
}