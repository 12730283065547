import { Component, NgZone, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

// Thrid Library
// import { NgProgressComponent } from 'ngx-progressbar';

//Service
import { GlobalFunc } from './service/globalfunction';
import { SignalRService } from './service/signalrservice';
import { DataChangedEvent } from './service/datachangedevent';
import { AuthService } from './service/authservice';
import { HttpServices } from './service/httpservices';
import {NgProgressService} from "ng2-progressbar";

//Model
import { AppComponent } from './app.component';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.html',
})
export class AppTopBar implements AfterViewInit {
    public toastkey = "app-topbar";
    //public jobId: string='';
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    
    constructor(private pService: NgProgressService ,public httpservice: HttpServices, public _ngZone: NgZone, public app: AppComponent, public auth: AuthService, public router: Router, public _signalR: SignalRService, public _datachanged: DataChangedEvent) {
        this.subscribeToEvents();
        //this.jobId = null;

    }

    subscribeToEvents() {
        this._signalR.connectionEstablished.subscribe(() => { });

        this._signalR.messageReceived.subscribe((data) => {
            this.app.notiMessages.unshift({ msg: data.ObjType + ' was ' + data.Msg + ' by ' + data.UN, tmi: new Date(data.At).toLocaleTimeString(), fname: data.Msg + '.png' });
            this.app.notiCount = this.app.notiMessages.length;

            if (this.httpservice.auth.role === 'Service Technician') {
                if (data.ObjType === "Job") {
                    if (data.Msg === 'deleted') {
                        if (data.Value2 && (data.Value2.JAT1 === this.httpservice.auth.UN || data.Value2.JAT2 === this.httpservice.auth.UN)) {
                            this.generateNotiSound('3-tone-notification');
                        }
                    } else {
                        if (data.Obj && (data.Obj.JAT1 === this.httpservice.auth.UN || data.Obj.JAT2 === this.httpservice.auth.UN)) {
                            this.generateNotiSound('3-tone-notification');
                        }
                    }
                }
                else if (data.ObjType === 'Service manager job status') {
                    if (data.Value1 != 'done' && data.Value2 && (data.Value2.JAT1 === this.httpservice.auth.UN || data.Value2.JAT2 === this.httpservice.auth.UN)) {
                        this.generateNotiSound('3-tone-notification');
                    }
                }
                else if (data.ObjType === 'Service technician status') {
                    if (data.Value2 && data.Value2.JobState != 'Unable to repair' && data.Value2.JobState != 'Technician Done' && (data.Value2.JAT1 === this.httpservice.auth.UN || data.Value2.JAT2 === this.httpservice.auth.UN)) {
                        this.generateNotiSound('3-tone-notification');
                    }
                }
                else if (data.ObjType === 'Service technician rate') {
                    if (data.Obj && (data.Obj.JAT1 === this.httpservice.auth.UN || data.Obj.JAT2 === this.httpservice.auth.UN)) {
                        this.generateNotiSound('3-tone-notification');
                    }
                }
            } else {
                if (data.ObjType != 'Logout') {
                    this.generateNotiSound('3-tone-notification');
                }
            }
        },
            err => {
                this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while receiving for message of signalR');
                this.pService.done();
            },
            () => { });
    }

    goSearch(event) {
        if (event.keyCode === 13) {

            if (!this.httpservice.searchData || this.httpservice.searchData === '' || !this.httpservice.searchType) {
                return;
            }
            // this.router.navigate(['/searchjob',this.httpservice.searchData,this.httpservice.searchType]).then(obj => {
            //     this._datachanged.searchjobChanged.emit({ searchdata: this.httpservice.searchData, searchtype: this.httpservice.searchType });
            // });

            this.router.navigate(['/searchjob',this.httpservice.searchData,this.httpservice.searchType]);
        }
    }

    removeNoti(nm: any) {
        this.app.notiMessages.splice(GlobalFunc.findSelectedIndex(this.app.notiMessages, nm), 1);
        this.app.notiCount = this.app.notiMessages.length;
    }

    generateNotiSound(sound_filename: string) {
        if(this.auth.loginName == null || this.auth.loginName == undefined || this.auth.loginName == ''){
            return;
        }
        document.getElementById("sound").innerHTML = '<audio autoplay="autoplay"><source src="./assets/' + sound_filename + '.mp3" type="audio/mpeg" /><source src="./assets/' + sound_filename + '.ogg" type="audio/ogg" /><embed hidden="true" autostart="true" loop="false" src="./assets/' + sound_filename + '.mp3" /></audio>';
    }

    resize(e) {
        this.httpservice.mobileWidth = window.innerWidth < 650;
    }

    ngAfterViewInit() {
        this.httpservice.mobileWidth = window.innerWidth < 650;
    }

}
