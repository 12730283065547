import { Component, OnInit, ViewChild, NgZone, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

// Thrid Library
import { SelectItem } from 'primeng/api';
import { BsModalComponent } from 'ng2-bs3-modal';
import {NgProgressService} from "ng2-progressbar";

//Service
import { DataChangedEvent } from '../service/datachangedevent';
import { HttpServices } from '../service/httpservices';

//Model
import { Category } from './category';
import { Brand } from '../brand/brand';
import { AuthService } from '../service/authservice';
import { Department } from '../department/department';

@Component({
    selector: 'i-category',
    templateUrl: './category.component.html'
})

export class CategoryCP implements OnInit, OnDestroy {
    @ViewChild('entryModal', { static: false }) entryModal: BsModalComponent;
    @ViewChild('actionModal', { static: false }) actionModal: BsModalComponent;
    @ViewChild('deleteModal', { static: false }) deleteModal: BsModalComponent;
    @ViewChild('otherModal', { static: false }) otherModal: BsModalComponent;
    @ViewChild('deptModal', { static: false }) deptModal: BsModalComponent;
    @ViewChild('input', { static: false }) vc;
    @Input() isModal: boolean = false;

    apiName: string = "category";
    //id = 'CatID';
    public catForm: FormGroup;
    public isNew: boolean;
    public categories: Category[] = [];
    public selectedData: Category;
    public selectedChk: Category[] = [];
    public actionlogs: any[] = [];
    public brands: Brand[] = [];
    public brandlist: SelectItem[] = [];
    public depts: Department[] = [];
    public deptlist: SelectItem[] = [];
    selectedBId: any;

    public totalRecords: number;
    public toastkey = "i-category";

    catChanged: any;
    depChanged: any;
    brandChanged: any;
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    
    constructor(private pService: NgProgressService ,public httpService: HttpServices, public fb: FormBuilder, public _dataChanged: DataChangedEvent, public _ngZone: NgZone, public auth: AuthService) {
        this.pService.start();

        this.catChanged = this._dataChanged.categoryChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === this.apiName.toLowerCase()) {
                        this.afterBroadCastEvent(sRobj, this.categories, 'CatID');
                    }
                });
            }
        });

        this.depChanged = this._dataChanged.departmentChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "department") {
                        this.afterBroadCastEvent(sRobj, this.depts, 'DID');
                    }
                });
            }
        });

        this.brandChanged = this._dataChanged.brandChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "brand") {
                        this.afterBroadCastEvent(sRobj, this.brands, 'BID');
                    }
                });
            }
        });
    }

    ngOnInit() {
        this.CreateForm();
        this.GetRequiredData();
    }

    GetRequiredData(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let depList: any = [];
            let catList: any = [];
            let bList: any = [];
            this.httpService.getDataFJ(['api/department', 'api/category', 'api/brand']).subscribe(
                data => {
                    depList = data[0];
                    catList = data[1];
                    bList = data[2];
                },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                    resolve(false);
                },
                () => {
                    if (depList) {
                        this.depts = depList.map(d => Object.assign({}, d));
                        this.setDepartmentData(this.depts);
                    }

                    if (catList) {
                        this.categories = catList.map(a => Object.assign({}, a));
                        this.totalRecords = this.categories.length;
                    }

                    if (bList) {
                        this.brands = bList.map(b => Object.assign({}, b));
                        this.setBrandData(this.brands);
                    }

                    this.pService.done();
                    resolve(true);
                }
            );
        });
    }

    afterBroadCastEvent(objData, dataList, id) {
        if (objData && objData.Obj && dataList) {
            let ind = dataList.findIndex(temp => temp[id] === objData.Obj[id]);
            if (objData.Msg === 'deleted') {
                if (objData.ObjType.toLowerCase() === "department") {

                    this.httpService.getData('api/department').subscribe(
                        data => { this.depts = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'); 
                        this.pService.done(); 
                    },
                        () => { this.setDepartmentData(this.depts); 
                            this.pService.done(); 
                        }
                    );

                } else if (objData.ObjType.toLowerCase() === "brand") {

                    this.httpService.getData('api/brand').subscribe(
                        data => { this.brands = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'); 
                        this.pService.done(); 
                    },
                        () => { this.setBrandData(this.brands); 
                            this.pService.done(); 
                        }
                    );

                } else if (objData.ObjType.toLowerCase() === this.apiName.toLowerCase()) {

                    this.httpService.getData('api/category').subscribe(
                        data => { this.categories = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                        this.pService.done(); 
                    },
                        () => { this.totalRecords = dataList.length; this.pService.done(); 
                    }
                    );

                }
            }
            else {
                if (objData.ObjType.toLowerCase() === this.apiName.toLowerCase()) {
                    if (this.selectedBId && this.selectedBId !== objData.Obj.BID) { //need to update data by selected brandid
                        return;
                    }
                }

                if (ind >= 0) dataList[ind] = objData.Obj;
                else dataList.unshift(objData.Obj);

                if (objData.ObjType.toLowerCase() === "department") {
                    this.setDepartmentData(dataList);
                } else if (objData.ObjType.toLowerCase() === "brand") {
                    this.setBrandData(dataList);
                }
            }


        }
    }

    setDepartmentData(dataList: Department[]) {
        this.deptlist = [];
        this.deptlist.push({ label: 'Select Department', value: null });
        if (dataList) {
            dataList.map(x => { this.deptlist.push({ label: x.DN, value: x.DID }); });
        }
    }

    setBrandData(dataList: Brand[]) {
        this.brandlist = [];
        this.brandlist.push({ label: '- All -', value: null });
        if (dataList) {
            dataList.map(x => { this.brandlist.push({ label: x.BN, value: x.BID }); });
        }
    }

    refresh() {
        this.pService.start();
        this.GetRequiredData();
    }

    CreateForm(): void {
        this.catForm = this.fb.group({
            'CatID': new FormControl(0),
            'CatN': new FormControl('', Validators.compose([Validators.required, Validators.maxLength(50)])),
            'BID': new FormControl(0),
            'BN': new FormControl(''),
            'DID': new FormControl(0),
            'DN': new FormControl('')
        });
    }

    findBrand(para) {
        if (para != 0) {
            for (var i = 0; i < this.brands.length; i++) {
                if (this.brands[i].BID === para) {
                    this.catForm.controls['BN'].patchValue(this.brands[i].BN);
                    break;
                }
            }
        } else { this.catForm.controls['BN'].patchValue('') }
    }

    findDept(para) {
        if (para != 0) {
            for (var i = 0; i < this.deptlist.length; i++) {
                if (this.depts[i].DID === para) {
                    this.catForm.controls['DN'].patchValue(this.depts[i].DN);
                    break;
                }
            }
        } else { this.catForm.controls['DN'].patchValue('') }
    }

    showDialogToAdd(): void {
        this.isNew = true;
        this.CreateForm();
        this.entryModal.open();
        this.vc.nativeElement.focus();
    }

    showDialogToEdit(category: Category): void {
        this.isNew = false;
        this.CreateForm();
        this.selectedData = category;
        this.catForm.patchValue({ CatID: category.CatID, CatN: category.CatN, BID: category.BID, BN: category.BN, DID: category.DID, DN: category.DN });
        this.entryModal.open();
        this.vc.nativeElement.focus();
    }

    showDialogToDelete(): void {
        if (this.selectedChk != null && this.selectedChk != undefined && this.selectedChk.length > 0) {
            this.deleteModal.open();
        } else { this.httpService.showToast(this.toastkey, 'warn', 'Need to select row!') }
    }

    showLogDialog(): void {
        if (this.selectedData != undefined) {
            let temp = [];
            this.httpService.getData('api/actionlog/' + this.selectedData.CatID + '/' + 'Category').subscribe(
                data => temp = data,
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                },
                () => {
                    if (temp != undefined) {
                        this.actionlogs = temp;
                        this.actionModal.open();
                    }
                    else { this.httpService.showToast(this.toastkey, 'warn', 'There is no record to show!') }
                });
        }
    }

    findByIndexCategory(data, selected): number {
        for (var _index in data) {
            if (this.categories[_index].CatID === this.selectedData.CatID) {
                return parseInt(_index);
            }
        }
    }

    save(pdt, gb): void {
        if (this.catForm != undefined) {
            this.findBrand(this.catForm.controls['BID'].value);
            this.findDept(this.catForm.controls['DID'].value);

            this.httpService.postData('api/category', this.catForm.value).
                subscribe(
                    ddd => {
                        if (this.isNew) {
                            this.catForm.controls['CatID'].patchValue(ddd);
                            //this.categories.unshift(this.catForm.value);
                        }
                        else {
                            let _index: number = this.findByIndexCategory(this.categories, this.selectedData);
                            this.categories[_index] = this.catForm.value;
                        }
                    },
                    err => {
                        this.httpService.showToast(this.toastkey, 'error', 'Error occurs while inserting/updating data!');
                        this.pService.done();
                    },
                    () => {
                        this.resetDatable(pdt, gb);
                        this.httpService.showToast(this.toastkey, 'success', 'Successfully inserted/updated');
                        this.entryModalClose();
                    });
        }
    }

    deleteData() {
        this.httpService.postData('api/category/multidelete/', this.selectedChk).
            subscribe(
                data => {
                    this.httpService.showUnsuccessDelMsg(this.toastkey, data);
                },
                err => {
                    this.doneDeleteProcess('error', 'Error occurs while deleting data!');
                },
                () => {
                    this.doneDeleteProcess('success', 'Successfully deleted');
                });
    }

    deleteModalClose() {
        this.deleteModal.close();
        this.httpService.AddClassToModal(this.isModal);
    }

    doneDeleteProcess(header: string, msg: string) {
        this.selectedChk = null;
        this.deleteModalClose();
        this.httpService.showToast(this.toastkey, header, msg);
    }

    resetDatable(pdt, gb): void {
        if (gb && gb.value && gb.value != '') {
            gb.value = '';
            if (pdt) pdt.reset();
        }
    }

    BrandsChanged(event): void {
        if (!event) return;
        this.selectedBId = event.value;
        this.getCategorybyConditionChange(this.selectedBId);
    }

    getCategorybyConditionChange(bid) {
        if (bid === null) {
           this.pService.start();
            this.httpService.getData('api/category').subscribe(
                data => { this.categories = data || []; },
                err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'); 
                this.pService.done(); 
            },
                () => {
                    this.totalRecords = this.categories.length;
                    this.pService.done();
                }
            );
        }
        else {
            this.getCategoriesByBId(bid);
        }
    }

    getCategoriesByBId(bid): void {
        this.httpService.getData('api/category/getbybid/' + bid).subscribe(
            expdata => { this.categories = expdata; },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data!');
                this.pService.done();
            },
            () => { }
        );
    }

    otherModalDismiss() {
        //this.GetBrand();
        this.httpService.AddClassToModal(true);
    }

    otherModalClose() {
        this.otherModal.close();
        //this.GetBrand();
        this.httpService.AddClassToModal(true);
    }

    deptModalClose() {
        this.deptModal.close();
        //this.GetDept();
        this.httpService.AddClassToModal(true);
    }

    actionModalClose() {
        this.actionModal.close();
        this.httpService.AddClassToModal(true);
    }

    entryModalClose() {
        this.entryModal.close();
        this.httpService.AddClassToModal(this.isModal);
    }

    exportToCSV() {
        this.httpService.postData('api/category/export', null).subscribe(
            data => { },
            err => { },
            () => {
                // window.location.href = this.httpService._url + 'excel/category.csv';
                this.httpService.DirectLocToWindow("category");
            }
        );
    }

    ngOnDestroy() {
        this.catForm = this.categories = this.selectedData = this.selectedChk = this.actionlogs = this.brands = this.brandlist = this.depts = this.deptlist = undefined;

        if (this.catChanged != undefined) this.catChanged.unsubscribe();
        if (this.depChanged != undefined) this.depChanged.unsubscribe();
        if (this.brandChanged != undefined) this.brandChanged.unsubscribe();
    }
}