import { Component, OnInit, ViewChild, NgZone, Output, Input, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';

// Thrid Library
import {NgProgressService} from "ng2-progressbar";

//Service
import { DataChangedEvent } from '../service/datachangedevent';
import { HttpServices } from '../service/httpservices';
import { GlobalFunc } from '../service/globalfunction';

//Model
import { AuthService } from '../service/authservice';
import { LoginUser } from '../loginuser/loginuser';

@Component({
    selector: 'i-confirmPassword',
    templateUrl: './confirmPassword-entry.html'
})

export class ConfirmPasswordEntryCP implements OnInit {    
    @Output() frmClick: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() isModal: boolean = false;
    @Input() appUser: LoginUser;
    @ViewChild('input', { static: false }) vc;

    curPassword: string | any = '';
    vaildASCII: boolean = true;
    showPass: boolean = false;

    //border color
    validCurrPass = true;
    msgErr: string ='';
    
    public toastkey = "i-confirmPassword";

    constructor(private pService: NgProgressService ,public httpService: HttpServices, public fb: FormBuilder, public _dataChanged: DataChangedEvent, public _ngZone: NgZone, public auth: AuthService) {
        this.pService.start();
        this._dataChanged.customerChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "") {
                        
                    }
                });
            }
        });
    }

    ngOnInit(): void {        
        this.getLoginData();       
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.vc.nativeElement.focus();
        }, 500);
    }

    getLoginData(): void{
        this.httpService.getData("api/loginuser/" + this.auth.UN).subscribe(
            data => {
                this.appUser = data;

            },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                this.pService.done();
            },
            () => {               
                this.pService.done();
            }
        );        
    }

    showPassword() {
        this.showPass = !this.showPass;
    }
    
    gotoConfirm(event: any){
        if (event.keyCode === 13) {
            this.confirm();
        }
    }

    confirm() {
        if (GlobalFunc.HashString(this.curPassword) === this.appUser.Pwd) {            
            if (this.isModal) {
                this.frmClick.emit(true);
                this.curPassword = this.msgErr = undefined;
            }
        } else {
            this.msgErr = 'Sorry, your password is not correct!';
        }
    }

    refresh() {
        this.curPassword = '';
        this.msgErr = undefined;
        this.pService.done();
    }

    cancel() {
        if (this.isModal) {
            this.frmClick.emit(false);
        }
    }

    changedCurPwd(e: string) {
        this.curPassword = e;
        this.checkWarning();
    }

    checkWarning() {
        ///////////// change border red color////////////////////////////
        this.msgErr = undefined;
        this.validCurrPass = !this.httpService.checkStringEmpty(this.curPassword);
        //this.vaildASCII = this.httpService.validASCII(this.curPassword);              
    }

    isNotValid(): boolean {
        return  this.httpService.checkStringEmpty(this.curPassword);
    }
}