import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../authservice';

@Injectable()
export class Service_Tech_Role implements CanActivate {

    constructor(public auth: AuthService) { }

    canActivate() {
        if (this.auth.isLoggedIn) {
            //return (this.auth.role == 'Service Technician')
            return (this.auth.role == 'Service Technician' || this.auth.role == 'Assistance Manager' || this.auth.role == 'Service Manager' || this.auth.role == 'Admin');
        } else {
            return false;
        }
    }
}