import { Component, Input} from '@angular/core';
@Component({
    selector: 'i-log',
    template: ` 
      <p-dataView [value]="logs" [paginator]="true" [rows]="4" class="viewheader-none">    
        <ng-template let-log pTemplate="listItem">
        
              <div [ngStyle]="{'border-bottom': logs.length > 1? '1px solid #bdbdbd' : ''}" class="clearfix car-item">
                <div class="ui-grid-row">
                    <div class="ui-grid-col-12">
                        <div class="ui-grid ui-grid-responsive ui-fluid" style="padding: 10px;">
                            <div class="ui-grid-row">
                                <div class="ui-grid-col-3" style="font-weight: bold;">Action Time : </div>
                                <div class="ui-grid-col-9">{{log.ATD}}</div>
                            </div>        
                            <div class="ui-grid-row">
                                <div class="ui-grid-col-3" style="font-weight: bold;">Action Type : </div>
                                <div class="ui-grid-col-9">{{log.ATyp}}</div>
                            </div>    
                            <div class="ui-grid-row">
                                <div class="ui-grid-col-3" style="font-weight: bold;">Log : </div>
                                <div class="ui-grid-col-9">{{log.LogD}}</div>
                            </div>    
                            <div class="ui-grid-row">
                                <div class="ui-grid-col-3" style="font-weight: bold;">User : </div>
                                <div class="ui-grid-col-9">{{log.UN}}</div>
                            </div>                   
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-dataView>
`
})
export class LogCP {
    @Input() logs: any[];
}