import { Component, OnInit, ViewChild, NgZone, OnDestroy } from '@angular/core';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import { SelectItem } from 'primeng/api';
import {NgProgressService} from "ng2-progressbar";

//Service
import { DataChangedEvent } from '../service/datachangedevent';
import { HttpServices } from '../service/httpservices';
import { GlobalFunc } from '../service/globalfunction';

//Model
import { ServiceType } from './servicetype';
import { Department } from '../department/department';

@Component({
    selector: 'i-servicetype',
    templateUrl: './servicetype.component.html'
})

export class ServiceTypeCP implements OnInit, OnDestroy {
    @ViewChild('entryModal', { static: false }) entryModal: BsModalComponent;
    @ViewChild('actionModal', { static: false }) actionModal: BsModalComponent;
    @ViewChild('deleteModal', { static: false }) deleteModal: BsModalComponent;
    @ViewChild('otherModal', { static: false }) otherModal: BsModalComponent;

    @ViewChild('input', { static: false }) vc;

    apiName: string = "servicetype";
    id = 'STID';
    serviceTypeList: ServiceType[] = [];
    servicetype: ServiceType = new ServiceType();
    isNew: boolean;
    selectedChk: ServiceType[] = [];
    selectedData: ServiceType;
    actionlogs: any[] = [];

    oDeptList: Department[] = [];
    deptllist: SelectItem[] = [];

    totalRecords: number;
    toastkey = "i-servicetype";

    depChanged: any;
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public httpService: HttpServices, public _dataChanged: DataChangedEvent, public _ngZone: NgZone) {
        this.pService.start();
        this._dataChanged.serviceTypeChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === this.apiName.toLowerCase()) {
                        this.afterBroadCastEvent(sRobj, this.serviceTypeList, 'STID');
                    }
                });
            }
        });

        this.depChanged = this._dataChanged.departmentChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "department") {
                        this.afterBroadCastEvent(sRobj, this.oDeptList, 'DID');
                    }
                });
            }
        });
    }

    ngOnInit() {
        this.GetRequiredData();
    }

    ngOnDestroy() {
        this.serviceTypeList = this.servicetype = this.selectedChk = this.selectedData = this.actionlogs = this.oDeptList = this.deptllist = undefined;
        if (this.depChanged != undefined) this.depChanged.unsubscribe();
    }

    GetRequiredData(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let depList: any = [];
            let stList: any = [];
            this.httpService.getDataFJ(['api/department', 'api/servicetype']).subscribe(
                data => {
                    depList = data[0];
                    stList = data[1];
                },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                    resolve(false);
                },
                () => {
                    if (depList) {
                        this.oDeptList = depList.map(d => Object.assign({}, d));
                        this.setDepartmentData(this.oDeptList);
                    }

                    if (stList) {
                        this.serviceTypeList = stList.map(a => Object.assign({}, a));
                        this.totalRecords = this.serviceTypeList.length;
                    }
                    this.pService.done();
                    resolve(true);
                }
            );
        });
    }

    afterBroadCastEvent(objData, dataList, id) {
        if (objData && objData.Obj && dataList) {
            let ind = dataList.findIndex(temp => temp[id] === objData.Obj[id]);
            if (objData.Msg === 'deleted') {
                if (objData.ObjType.toLowerCase() === "department") {

                    this.httpService.getData('api/department').subscribe(
                        data => { this.oDeptList = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'); 
                        this.pService.done(); 
                    },
                        () => { this.setDepartmentData(this.oDeptList); 
                            this.pService.done(); 
                        }
                    );

                } else if (objData.ObjType.toLowerCase() === this.apiName.toLowerCase()) {

                    this.httpService.getData('api/servicetype').subscribe(
                        data => { this.serviceTypeList = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                        this.pService.done(); 
                    },
                        () => { this.totalRecords = this.serviceTypeList.length; 
                            this.pService.done(); 
                        }
                    );

                }
            }
            else {
                if (ind >= 0) dataList[ind] = objData.Obj;
                else dataList.unshift(objData.Obj);

                if (objData.ObjType.toLowerCase() === "department") {
                    this.setDepartmentData(dataList);
                }
            }
        }
    }

    setDepartmentData(dataList: Department[]) {
        this.deptllist = [];
        this.deptllist.push({ label: 'Select Department ', value: null });
        if (dataList) {
            dataList.map(dep => this.deptllist.push({ label: dep.DN, value: dep.DID }));
        }
    }

    refresh() {
        this.pService.start();
        this.GetRequiredData();
    }

    showDialogToAdd(): void {
        this.isNew = true;
        this.servicetype = new ServiceType();
        this.entryModal.open();
        this.vc.nativeElement.focus();
    }

    showDialogToEdit(servicetype: ServiceType): void {
        this.isNew = false;
        this.selectedData = servicetype;
        this.servicetype = new ServiceType();
        this.servicetype = GlobalFunc.CloneData(servicetype, ServiceType);
        this.entryModal.open();
        this.vc.nativeElement.focus();
    }

    showDialogToDelete(): void {
        if (this.selectedChk != null && this.selectedChk != undefined && this.selectedChk.length > 0) {
            this.deleteModal.open();
        } else {
            this.httpService.showToast(this.toastkey, 'warn', 'Need to select row!');
        }
    }

    showLogDialog(): void {
        if (this.selectedData != undefined) {
            let temp = [];
            this.httpService.getData('api/actionlog/' + this.selectedData.STID + '/' + 'ServiceType').subscribe(
                data => temp = data,
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                   this.pService.done();
                },
                () => {
                    if (temp != undefined) { this.actionlogs = temp; this.actionModal.open(); }
                    else { this.httpService.showToast(this.toastkey, 'warn', 'There is no record to show!'); }
                });
        }
    }

    save(pdt, gb): void {
        if (this.servicetype.STN == undefined) return;
        if (this.servicetype != undefined) {
            this.httpService.postData('api/servicetype', this.servicetype).
                subscribe(
                    data => { },
                    err => {
                        this.httpService.showToast(this.toastkey, 'error', 'Error occurs while inserting/updating data!');
                        this.pService.done();
                    },
                    () => {
                        this.resetDatable(pdt, gb);
                        this.httpService.showToast(this.toastkey, 'success', 'Successfully inserted/updated');
                        this.entryModal.close();
                    });
        }
    }

    deleteData() {
        this.httpService.postData('api/servicetype/multidelete/', this.selectedChk).
            subscribe(
                data => {
                    this.httpService.showUnsuccessDelMsg(this.toastkey, data);
                },
                err => {
                    this.doneDeleteProcess('error', 'Error occurs while deleting data!');
                },
                () => {
                    this.doneDeleteProcess('success', 'Successfully deleted');
                });
    }

    deleteModalClose() {
        this.deleteModal.close();
        this.httpService.AddClassToModal(true);
    }

    doneDeleteProcess(header: string, msg: string) {
        this.selectedChk = null;
        this.deleteModalClose();
        this.httpService.showToast(this.toastkey, header, msg);
    }

    resetDatable(pdt, gb): void {
        if (gb && gb.value && gb.value != '') {
            gb.value = '';
            if (pdt) pdt.reset();
        }
    }

    actionModalClose() {
        this.actionModal.close();
        this.httpService.AddClassToModal(true);
    }

    otherModalClose() {
        this.otherModal.close();
        //this.getDepartment();
        this.httpService.AddClassToModal(true);
    }

    exportToCSV() {
        this.httpService.postData('api/servicetype/export', null).subscribe(
            data => { },
            err => { },
            () => {
                //window.location.href = this.httpService._url + 'excel/servicetype.csv';
                this.httpService.DirectLocToWindow("servicetype");
            }
        );
    }
}