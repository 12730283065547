export class StockItem {
    constructor(
        public SID?, 
        public SN?, 
        public Cat?, 
        public STID?, 
        public BID?,
        public MinQ?, 
        public UN?, 
        public STN?, 
        public BN?,
        public UploadFile?, 
        public EFN?
    ) { }
}