export class AdminJobListQuery{
    constructor(
        public departmentId?, 
        public servicecenterId?, 
        public servicetypeId?, 
        public jobstatusId?, 
        public empUN?, 
        public period?, 
        public fromdate?, 
        public todate?){}
}