import { Component, OnInit, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

// Thrid Library
import { SelectItem } from 'primeng/api';
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';
import { AuthService } from '../service/authservice';
import { DataChangedEvent } from '../service/datachangedevent';

//Model
import { TakenListQuery } from './takenlistquery';
import { ServiceCenter } from '../servicecenter/servicecenter';
import { Department } from '../department/department';
import { Job } from '../job/job';
import { MCB } from '../job/mcb';

@Component({
    selector: 'i-takenlist',
    templateUrl: './takenlist.component.html'

})
export class TakenListCP implements OnInit, OnDestroy {
    printMode: boolean = false;
    periods: SelectItem[] = [];
    jobtypes: SelectItem[] = [];
    query: TakenListQuery;
    servicecenters: ServiceCenter[] = [];
    servicecenterlist: SelectItem[] = [];

    oDeptList: Department[] = [];
    deptllist: SelectItem[];

    _listOfMon: MCB[] = [];
    takenList: Job[] = [];

    amount: any = { serviceCharges: 0, partsAmount: 0, totalAmount: 0, depositAmount: 0, balanceAmount: 0 };
    toastkey = "i-takenlist";

    depChanged: any;
    scChanged: any;
    modelChanged: any;
    brandChanged: any;
    catChanged: any;

    constructor(private pService: NgProgressService ,public httpService: HttpServices, public router: Router, public auth: AuthService, public _dataChanged: DataChangedEvent, public _ngZone: NgZone) {
        this.pService.start();
        this.bindJobType();

        this.depChanged = this._dataChanged.departmentChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "department") {
                        this.afterBroadCastEvent(sRobj, this.oDeptList, 'DID');
                    }
                });
            }
        });

        this.scChanged = this._dataChanged.serviceCenterChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "servicecenter") {
                        this.afterBroadCastEvent(sRobj, this.servicecenters, 'ScID');
                    }
                });
            }
        });

        this.modelChanged = this._dataChanged.modelChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "model") {
                        this.GetMcbInBroadcast();
                    }
                });
            }
        });

        this.brandChanged = this._dataChanged.brandChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "brand") {
                        this.GetMcbInBroadcast();
                    }
                });
            }
        });

        this.catChanged = this._dataChanged.categoryChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "category") {
                        this.GetMcbInBroadcast();
                    }
                });
            }
        });
    }

    ngOnInit() {
        this.GetRequiredData().then(exp => {
            if (exp) {
                this.query = new TakenListQuery(this.auth.ScID, null, 'a', 'E', new Date(), new Date());
            }
        });
    }

    ngOnDestroy() {
        this.periods = this.jobtypes = this.query = this.servicecenters = this.servicecenterlist = this.oDeptList = this.deptllist = this._listOfMon = this.takenList =
            this.amount = undefined;

        if (this.depChanged != undefined) this.depChanged.unsubscribe();
        if (this.scChanged != undefined) this.scChanged.unsubscribe();
        if (this.modelChanged != undefined) this.modelChanged.unsubscribe();
        if (this.brandChanged != undefined) this.brandChanged.unsubscribe();
        if (this.catChanged != undefined) this.catChanged.unsubscribe();
    }

    bindJobType(): void {
        this.jobtypes = [];
        this.jobtypes.push({ value: null, label: 'All' });
        this.jobtypes.push({ value: 'S', label: 'Standard' });
        this.jobtypes.push({ value: 'E', label: 'Express' });
    }

    GetRequiredData(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let depList: any = [];
            let scList: any = [];
            let mcbList: any = [];
            this.httpService.getDataFJ(['api/department', 'api/servicecenter', 'api/job/getmcb']).subscribe(
                data => {
                    depList = data[0];
                    scList = data[1];
                    mcbList = data[2];
                },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                    resolve(false);
                },
                () => {
                    if (depList) {
                        this.oDeptList = depList.map(d => Object.assign({}, d));
                        this.setDepartmentData(this.oDeptList);
                    }

                    if (scList) {
                        this.servicecenters = scList.map(sc => Object.assign({}, sc));
                        this.setServiceCenterData(this.servicecenters);
                    }

                    if (mcbList) {
                        this._listOfMon = mcbList.map(m => Object.assign({}, m));
                    }

                    resolve(true);
                }
            );
        });
    }

    GetMcbInBroadcast() {
        let mcbList = [];
        this.httpService.getData('api/job/getmcb').subscribe(
            data => { mcbList = data || []; },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                this.pService.done();
            },
            () => {
                this._listOfMon = mcbList.map(m => Object.assign({}, m));
            }
        );
    }

    afterBroadCastEvent(objData, dataList, id) {
        if (objData && objData.Obj && dataList) {
            let ind = dataList.findIndex(temp => temp[id] === objData.Obj[id]);
            if (objData.Msg === 'deleted') {
                if (objData.ObjType.toLowerCase() === "department") {

                    this.httpService.getData('api/department').subscribe(
                        data => { this.oDeptList = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'); 
                        this.pService.done(); 
                    },
                        () => { this.setDepartmentData(this.oDeptList); 
                            this.pService.done(); 
                        }
                    );

                } else if (objData.ObjType.toLowerCase() === "servicecenter") {

                    this.httpService.getData('api/servicecenter').subscribe(
                        data => { this.servicecenters = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'); 
                        this.pService.done(); 
                    },
                        () => { this.setServiceCenterData(this.servicecenters); 
                            this.pService.done(); 
                        }
                    );

                } else if (objData.ObjType.toLowerCase() === "model" || objData.ObjType.toLowerCase() === "brand" || objData.ObjType.toLowerCase() === "category") {
                    this.GetMcbInBroadcast();
                }
            }
            else {
                if (ind >= 0) dataList[ind] = objData.Obj;
                else dataList.unshift(objData.Obj);

                if (objData.ObjType.toLowerCase() === "department") {
                    this.setDepartmentData(dataList);
                } else if (objData.ObjType.toLowerCase() === "servicecenter") {
                    this.setServiceCenterData(dataList);
                }
            }
        }
    }

    setDepartmentData(dataList: Department[]) {
        this.deptllist = [];
        this.deptllist.push({ label: 'All', value: null });
        if (dataList) {
            dataList.map(dep => this.deptllist.push({ label: dep.DN, value: dep.DID }));
        }
    }

    setServiceCenterData(dataList: ServiceCenter[]) {
        this.servicecenterlist = [];
        this.servicecenterlist.push({ label: 'All', value: null });
        if (dataList) {
            dataList.map(x => { this.servicecenterlist.push({ label: x.ScN, value: x.ScID }); });
        }
    }

    getmcbName(moid: number): string {
        if (this._listOfMon == undefined) return "";
        for (let obj of this._listOfMon) {
            if (obj.MoID == moid) {
                return obj.MoN + " / " + obj.CatN + " / " + obj.BN;
            }
        }
    }

    Search(): void {
        this.amount = { serviceCharges: 0, partsAmount: 0, totalAmount: 0, depositAmount: 0, balanceAmount: 0 };

        if (this.query.ScID == null) this.query.ScID = 0;
        if (this.query.DID == null) this.query.DID = 0;

        if (this.query.JT == null) this.query.JT = '';

        this.httpService.postData('api/get/takenlist', this.query).subscribe(
            data => this.takenList = data,
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                this.pService.done();
            },
            () => {
                if (this.takenList.length == 0) this.httpService.showToast(this.toastkey, 'warn', 'There is No taken list!');
                if (this.takenList != undefined) {
                    this.takenList.forEach(x => {
                        if (x.MoID != undefined) {
                            x.MoN = this.getmcbName(x.MoID);
                        }
                        this.amount.serviceCharges += x.SAmo;
                        this.amount.partsAmount += x.PAmo;
                        this.amount.totalAmount += x.TAmo;
                        this.amount.depositAmount += x.DAmo;
                        this.amount.balanceAmount += x.TAmo + x.DAmo;
                    })
                }
            });
    }

    printjob(): void {
        window.print();
    }

    periodChange(): void {
        this.query.FromDate = this.query.ToDate = new Date();
    }
}